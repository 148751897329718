import { createTypes } from 'reduxsauce'

export default createTypes(`
  SET_CONNECTION_STATUS
  SET_AUTHENTICATED_WITH_FIREBASE
  SET_NOTIFICATIONS_ENABLED
  SET_CRITICAL_ALERTS_ENABLED
  SET_ALARM_NOTIFICATION_CHANNEL_ENABLED
  SET_LOCK_SCREEN_NOTIFICATION_ENABLED
  SET_BACKGROUND_APP_REFRESH_ENABLED
  SET_CURRENT_SCREEN
  SET_DEVICE_TOKEN
  SET_CONTACTS_PERMISSION_STATUS
  SET_LATEST_RELEASE
  SET_CONNECTED_TO_IAP
  ADD_SKU_DETAILS
  SET_COLOR_SCHEME
  SET_WEB_COLOR_SCHEME
  TOGGLE_CATEGORIZED_ALARM_VIEW
  SET_SEEN_SPECIFY_TIMEZONE_ALERT
  SET_CURRENTLY_SELECTED_ALARM_ID
  SET_CURRENTLY_SELECTED_GROUP_ID
  SET_CURRENTLY_SELECTED_CONTACT_ID
  SET_SHOW_APP_RUNNING_ELSEWHERE_ALERT
  SET_MOBILE_AND_WEB_TIMEZONE_MISMATCH_DETECTED
  SET_TAB_ACTIVE_INDEX
  SHOW_NEW_ALARM_WIZARD
  SHOW_NEW_INSTANT_ALARM_WIZARD
  SHOW_EDIT_ALARM_WIZARD
  SHOW_ALARM_CONVERSATION_SCREEN
  HIDE_ALARM_CONVERSATION_SCREEN
  SHOW_NEW_GROUP_WIZARD
  SHOW_EDIT_GROUP_WIZARD
  HIDE_NEW_ALARM_WIZARD
  HIDE_NEW_INSTANT_ALARM_WIZARD
  HIDE_EDIT_ALARM_WIZARD
  HIDE_NEW_GROUP_WIZARD
  HIDE_EDIT_GROUP_WIZARD
  SHOW_ALARM_DETAILS_SCREEN
  SHOW_INSTANT_ALARM_DETAILS_SCREEN
  HIDE_ALARM_DETAILS_SCREEN
  HIDE_INSTANT_ALARM_DETAILS_SCREEN
  SHOW_SELECT_ALARM_TYPE_SCREEN
  SHOW_GROUP_DETAILS_SCREEN
  HIDE_GROUP_DETAILS_SCREEN
  SHOW_CONTACT_DETAILS_SCREEN
  SHOW_CHANGE_MEMBER_STATE_SCREEN
  HIDE_CONTACT_DETAILS_SCREEN
  HIDE_CHANGE_MEMBER_STATE_SCREEN
  SHOW_REPORT_A_PROBLEM_SCREEN
  HIDE_REPORT_A_PROBLEM_SCREEN
  SHOW_PROFILE_AND_SETTINGS_SCREEN
  HIDE_PROFILE_AND_SETTINGS_SCREEN
  SHOW_PARTICIPANT_ALARM_DETAILS_SCREEN
  SHOW_INSTANT_PARTICIPANT_ALARM_DETAILS_SCREEN
  HIDE_PARTICIPANT_ALARM_DETAILS_SCREEN
  SET_CURRENTLY_SELECTED_ALARM_CATEGORY_ID
  SHOW_ALARM_HISTORY_SCREEN
  HIDE_ALARM_HISTORY_SCREEN
  HIDE_SELECT_ALARM_TYPE_SCREEN
  SET_SHOW_QUICK_REMINDER
  RESET_INSPECTOR_PANEL
  SET_IOS_NUM_NOTIFICATIONS_SCHEDULED
  SET_SHOW_SIDEBAR
  SHOW_DUPLICATE_ALARM_WIZARD
  HIDE_DUPLICATE_ALARM_WIZARD
  SET_BATTERY_OPTIMIZATION_DISABLED
  SET_SCHEDULE_EXACT_ALARMS_ENABLED
  SET_SHOW_ALARM_LIST_SEARCH_BAR
  SET_APP_UPDATE_AVAILABLE
  SET_CURRENTLY_SELECTED_TASK_LIST_ID
  SET_SHOW_ALERT_INCIDENTS
  SET_SHOW_RECENTLY_DELETED_ALARMS
  
  ADD_ALARM_ACKNOWLEDGEMENT
  REMOVE_ALARM_ACKNOWLEDGEMENTS
  ADD_ALARM_STATUS_CHANGE
  REMOVE_ALARM_STATUS_CHANGES
  ADD_ALARM_ACKNOWLEDGEMENTS
  UPDATE_ALARMS_ACKNOWLEDGEMENTS
  ADD_ALARM_STATUS_CHANGES
  UPDATE_ALARMS_STATUS_CHANGES

  SET_USER_SETTINGS
  SET_RING_PARTICIPANT_ALARMS_BY_DEFAULT
  SET_RING_ON_EARPHONE_ONLY
  SET_GESTURE_TO_DISMISS_ALARM
  SET_NOTIFICATION_SETTINGS
  SET_ALARM_RINGTONE
  SET_RING_ON_VIBRATE
  SET_TIME_FORMAT
  SET_GLOBAL_ALARM_RINGER_SETTINGS
  SET_SPECIFY_TIMEZONE_FOR_ALARM
  SET_DEFAULT_TIMEZONE_FOR_ALARM
  SET_GRADUALLY_INCREASE_VOLUME
  SET_MUTE_ALARMS
  SET_FIRST_DAY_OF_WEEK
  
  SET_PROGRESS
  RESET_PROGRESS
  
  SET_USER_INFO
  SET_USER_NAME
  SET_USER_AVATAR_IMAGES
  DELETE_USER_AVATAR_IMAGES
  ADD_BLOCKED_CONTACTS
  SET_USER_EMAIL
  SET_RATE_THE_APP_USER_INFO
  SET_USER_MOBILE_NUMBER
  ADD_SYSTEM_ALERTS
  ADD_SYSTEM_ALERT
  REMOVE_SYSTEM_ALERT
  INCREMENT_OWN_ALARMS_COUNT
  SET_ALARMS_COUNT
  SET_PURCHASES
  ADD_PURCHASE
  SET_MAX_ALLOWED_ALARMS
  SET_DEVICE_MANUFACTURER
  SET_JOIN_DATE
  SET_SHAREABLE_ALARM_LINKS_COUNT
  SET_ALARMS_SAVED_TO_CALENDAR_COUNT
  SET_ALARM_CATEGORIES
  CREATE_ALARM_CATEGORY
  UPDATE_ALARM_CATEGORY
  DELETE_ALARM_CATEGORY
  ADD_ALARM_TO_ALARM_CATEGORY
  REMOVE_ALARM_FROM_ALARM_CATEGORY
  HIDE_ALARM_CATEGORY
  UNHIDE_ALARM_CATEGORY
  SET_DEVICE_OS
  SET_INSTANT_ALARMS_COUNT
  ADD_ENTERPRISE_SUBSCRIPTIONS

  UPDATE_ALARM
  UPDATE_ALARMS
  ADD_LOCAL_ALARM
  REMOVE_LOCAL_ALARM
  UPDATE_LOCAL_ALARM
  EXPIRE_ALARMS
  REMOVE_LOCAL_ALARMS
  SOFT_UPDATE_LOCAL_ALARM
  SOFT_UPDATE_LOCAL_PARTICIPANT_ALARM
  UPDATE_ALARMS_LOCALLY
  UPDATE_PARTICIPANT_ALARMS_LOCALLY
  UNSNOOZE_ALARMS_LOCALLY
  UNSNOOZE_PARTICIPANT_ALARMS_LOCALLY
  SET_EXISTING_ALARMS_LOADED
  SET_RECENTLY_DELETED_ALARMS
  ADD_LOCAL_CHECKLIST
  UPDATE_LOCAL_CHECKLIST
  REMOVE_LOCAL_CHECKLIST
  SET_CHECKLISTS

  ADD_BACKUP_ALARM
  REMOVE_BACKUP_ALARM
  REMOVE_PARTICIPANT_ALARMS
  EXPIRE_PARTICIPANT_ALARMS

  UPDATE_GROUP
  UPDATE_GROUP_AVATAR_IMAGES
  DELETE_GROUP_AVATAR_IMAGES
  REMOVE_GROUP
  
  ADD_CONTACT
  ADD_CONTACTS
  ADD_PHONE_CONTACTS
  DELETE_CONTACT
  SET_CONTACT_AVATAR_IMAGES
  DELETE_CONTACT_AVATAR_IMAGES
  SET_TOTAL_NUMBER_OF_CONTACTS
  SET_CONTACTS_LAST_UPDATED_AT
  ADD_INVITED_CONTACT
  DELETE_INVITED_CONTACT

  ADD_CONNECTION
  ADD_CONNECTIONS
  SET_CONNECTION_AVATAR_IMAGES
  DELETE_CONNECTION_AVATAR_IMAGES

  ADD_ADD_ALARM_PENDING_ACTION
  REMOVE_PENDING_ACTION
  REMOVE_ALL_PENDING_ACTIONS
  ADD_DELETE_ALARM_PENDING_ACTION
  ADD_EDIT_ALARM_PENDING_ACTION
  ADD_ENABLE_DISABLE_ALARM_PENDING_ACTION
  ADD_START_SNOOZE_FOR_CREATOR_SOFT_PENDING_ACTION
  ADD_STOP_SNOOZE_FOR_CREATOR_SOFT_PENDING_ACTION
  ADD_START_SNOOZE_FOR_PARTICIPANT_SOFT_PENDING_ACTION
  ADD_STOP_SNOOZE_FOR_PARTICIPANT_SOFT_PENDING_ACTION
  REMOVE_SOFT_PENDING_ACTION
  ADD_MARK_PERSONAL_ALARM_DONE_SOFT_PENDING_ACTION
  ADD_SET_PARTICIPANT_ALARM_RESPONSE_SOFT_PENDING_ACTION
  ADD_SET_RECIPIENT_ALARM_RESPONSE_SOFT_PENDING_ACTION
  ADD_MARK_RECIPIENT_ALARM_DONE_SOFT_PENDING_ACTION
  ADD_SET_PAST_ALARM_OCCURRENCE_RESPONSE_SOFT_PENDING_ACTION
  ADD_UPDATE_PARTICIPANT_ALARM_RINGER_SETTINGS_SOFT_PENDING_ACTION
  ADD_UPDATE_PARTICIPANT_ALARM_PRE_REMINDER_DURATION_SOFT_PENDING_ACTION
  ADD_UPDATE_ALARM_RINGER_SETTINGS_SOFT_PENDING_ACTION
  ADD_UPDATE_ALARM_PRE_REMINDER_SOFT_PENDING_ACTION
  ADD_UPDATE_GLOBAL_ALARM_RINGER_SETTINGS_SOFT_PENDING_ACTION
  ADD_UPDATE_RING_PARTICIPANT_ALARMS_BY_DEFAULT_SETTING_SOFT_PENDING_ACTION
  ADD_UPDATE_RING_ON_VIBRATE_SETTING_SOFT_PENDING_ACTION
  ADD_SKIP_PERSONAL_ALARM_SOFT_PENDING_ACTION
  ADD_SKIP_PERSONAL_PARTICIPANT_ALARM_SOFT_PENDING_ACTION
  ADD_SKIP_RECIPIENT_ALARM_SOFT_PENDING_ACTION
  ADD_SKIP_RECIPIENT_CREATOR_ALARM_SOFT_PENDING_ACTION
  ADD_ADD_ALARM_TO_ALARM_CATEGORY_SOFT_PENDING_ACTION
  ADD_REMOVE_ALARM_FROM_ALARM_CATEGORY_SOFT_PENDING_ACTION
  ADD_DELETE_ALARM_CATEGORY_SOFT_PENDING_ACTION
  ADD_UPDATE_ALARM_CATEGORY_SOFT_PENDING_ACTION
  ADD_CREATE_ALARM_CATEGORY_SOFT_PENDING_ACTION
  ADD_MARK_PARTICIPANT_RESPONSE_FOR_GROUP_ALARM_SOFT_PENDING_ACTION
  ADD_MARK_CREATOR_RESPONSE_FOR_GROUP_ALARM_SOFT_PENDING_ACTION
  ADD_UPDATE_RING_ON_EARPHONE_ONLY_SOFT_PENDING_ACTION
  ADD_UPDATE_GESTURE_TO_DISMISS_ALARM_SOFT_PENDING_ACTION
  ADD_UPDATE_NOTIFICATION_SETTINGS_SOFT_PENDING_ACTION
  ADD_UPDATE_SPECIFY_TIMEZONE_FOR_ALARM_SOFT_PENDING_ACTION
  ADD_ADD_CHECKLIST_PENDING_ACTION
  ADD_EDIT_CHECKLIST_PENDING_ACTION
  ADD_REMOVE_CHECKLIST_PENDING_ACTION
  
  ADD_UPDATE_GRADUALLY_INCREASE_VOLUME_SOFT_PENDING_ACTION

  ADD_MESSAGES_TO_ALARM_CONVERSATION
  ADD_NEW_MESSAGE_TO_ALARM_CONVERSATION
  SET_IS_LOADING_EARLIER_MESSAGES_FOR_ALARM
  PROCESS_FIRST_MESSAGE_KEY_FOR_ALARM_CONVERSATION
  SET_UNSEEN_MESSAGES_FOR_ALARM
  UPDATE_CURRENTLY_TYPING_USERS_FOR_ALARM

  UPDATE_PENDING_CONVERSATION_MESSAGE_FOR_ALARM
  UPDATE_PENDING_FEEDBACK
  UPDATE_PENDING_PROBLEM

  RESET_APP
  BATCH_ACTIONS

  SET_ADDITIONAL_SETUP_INSTRUCTIONS
  REMOVE_SYSTEM_MESSAGE
  ADD_HIDDEN_SYSTEM_MESSAGE
`)
