module.exports = {
  alarms: 'Alarm',
  contacts: 'Kontak',
  groups: 'Grup',
  settings: 'Pengaturan',
  notificationsTab: 'Pemberitahuan',
  specifyTimeInFutureForAlarm: 'Alarm tidak bisa di masa lalu.',
  specifyTimeInFutureForPreReminder: 'Pra-pengingat untuk alarm sudah berlalu.',
  specifyNameForGroup: 'Nama grup diperlukan.',
  updatingGroupName: 'Memperbarui informasi grup.',
  unableToEditGroupName:
    'Tidak dapat memperbarui informasi grup. Silakan coba lagi setelah beberapa waktu.',
  selectSmallerImage:
    'Gambar lebih besar dari 10 MB. Pilih gambar yang lebih kecil.',
  sendingVerificationCode:
    'Kirimkan kode verifikasi ke {{formattedMobileNumber}}.',
  verifyingCode: 'Memverifikasi kode.',
  creatingUserProfile: 'Menyiapkan...',
  unabeToCreateUserProfile:
    'Tidak dapat membuat akun saat ini. Silakan coba lagi dalam beberapa saat.',
  unableToSendVerificationCode:
    'Tidak dapat mengirim kode verifikasi. {{error}}',
  unableToVerifyCode: 'Tidak dapat memverifikasi kode. {{error}}',
  unableToRegisterUserWithoutMobileNumber:
    'Tidak dapat melakukan penyiapan. {{error}}',
  permissionDeniedToAccessCameraIos:
    'Galarm tidak memiliki izin untuk mengakses kamera. \n\nBuka Pengaturan > Galarm dan izinkan akses ke kamera.',
  permissionDeniedToAccessCameraAndroid:
    'Galarm tidak memiliki izin untuk mengakses kamera.\n\nBuka Pengaturan > Aplikasi > Galarm > Izin dan izinkan akses ke kamera.',
  permissionDeniedToAccessPhotosIos:
    'Galarm tidak memiliki izin untuk mengakses foto.\n\nBuka Pengaturan > Galarm dan izinkan akses ke foto.',
  permissionDeniedToAccessPhotosAndroid:
    'Galarm tidak memiliki izin untuk mengakses foto.\n\nBuka Pengaturan > Aplikasi > Galarm > Izin dan izinkan akses ke penyimpanan.',
  permissionDeniedToAccessCalendarIos:
    'Galarm tidak memiliki izin untuk mengakses kalender.\nBuka Pengaturan > Kalender dan izinkan akses ke Galarm.',
  permissionDeniedToAccessCalendarAndroid:
    'Galarm tidak memiliki izin untuk mengakses kalender.\nBuka Pengaturan > Aplikasi > Galarm > Izin dan izinkan akses ke Kalender.',
  verificationCodeSent: 'Kode verifikasi dikirim ke {{formattedMobileNumber}}.',
  rateTheApp:
    'Kami harap Anda menemukan Galarm bermanfaat. Mohon luangkan waktu sejenak untuk menilai kami di Play Store. Terima kasih atas dukungan Anda.',
  backgroundAppRefreshDisabledPermission:
    'Alarm mungkin tidak berdering karena Galarm tidak memiliki izin untuk melakukan penyegaran aplikasi latar belakang.\n\nKetuk "Aktifkan", dan aktifkan "Penyegaran Aplikasi Latar Belakang".',
  selectedGroupUnselected:
    'Anda dapat memilih grup atau serangkaian kontak sebagai peserta. Grup yang telah dipilih sebelumnya tidak akan dipilih. Lanjutkan?',
  selectedContactsUnselected:
    'Anda dapat memilih grup atau sekumpulan kontak sebagai peserta. Kontak yang telah dipilih sebelumnya tidak akan dipilih. Lanjutkan?',
  notificationsDisabledIos:
    'Alarm tidak akan berdering karena Galarm tidak memiliki izin untuk menampilkan pemberitahuan.\n\nBuka Pengaturan > Galarm > Pemberitahuan dan aktifkan "Peringatan", "Suara", dan "Spanduk".',
  notificationsDisabledAndroid:
    'Pemberitahuan alarm tidak akan muncul di layar.\n\nKetuk "Aktifkan", pilih "Pemberitahuan" dan izinkan pemberitahuan.',
  alarmNotificationChannelDisabled:
    'Notifikasi alarm tidak akan muncul di layar. \n\nKetuk "Aktifkan", dan izinkan notifikasi alarm muncul di layar.',
  alarmLockScreenNotificationlDisabled:
    'Pemberitahuan alarm tidak akan muncul di layar kunci. \n\nKetuk "Aktifkan", dan izinkan notifikasi alarm ditampilkan pada layar kunci.',
  alarmWillNotRingDueToNotificationsDisabledIos:
    'Alarm tidak akan berdering karena notifikasi dinonaktifkan.\n\nBuka Pengaturan > Galarm > Pemberitahuan dan aktifkan "Peringatan", "Suara", dan "Spanduk".',
  alarmWillNotRingDueToCriticalAlertsDisabledIos:
    'Alarm tidak akan berdering saat ponsel dibisukan karena peringatan penting dinonaktifkan.\n\nBuka Pengaturan > Galarm > Pemberitahuan dan aktifkan "Peringatan Kritis".',
  alarmWillNotRingDueToNotificationsDisabledAndroid:
    'Alarm tidak akan muncul di layar karena notifikasi dinonaktifkan.\n\nKetuk "Aktifkan", pilih "Pemberitahuan" dan izinkan pemberitahuan.',
  noNotificationsScheduledAlarmInPast:
    'Alarm sudah berlalu. Tidak ada pemberitahuan yang dijadwalkan.',
  alarmOverAllowedLimit: {
    zero: '\nAnda telah mencapai batas alarm {{limit}} alarm.\n\nHarap hapus atau gunakan kembali alarm yang sudah ada.',
    one: 'Anda telah mencapai batas alarm {{limit}} alarm.\n\nAnda memiliki 1 alarm tidak aktif yang dapat Anda hapus atau gunakan kembali.',
    other:
      'Anda telah mencapai batas alarm {{limit}} alarm.\n\nAnda memiliki {{count}} alarm tidak aktif yang dapat Anda hapus atau gunakan kembali.'
  },
  alarmAlreadyConfirmed: 'Anda sudah mengonfirmasi.',
  alarmAlreadyDeclined: 'Anda sudah menolak.',
  participantMovedToPosition: '{{name}} pindah ke posisi {{count}}.',
  memberIsFirstParticpant: '{{name}} adalah peserta pertama.',
  memberIsLastParticpant: '{{name}} adalah peserta terakhir.',
  cantContinueWithRegistrationWhenOffline:
    'Tidak dapat melanjutkan pendaftaran karena perangkat sedang offline.',
  cantContinueWithRegistrationWhenOfflineWithTryAgain:
    'Tidak dapat melanjutkan pendaftaran karena perangkat sedang offline. Pastikan Anda terhubung ke internet.',
  confirmBlockContact:
    'Anda tidak akan lagi menerima alarm apa pun dari {{name}}. Lanjutkan?',
  confirmUnblockContact:
    'Membuka blokir {{name}} juga akan memulihkan alarm yang diblokir dari mereka. Lanjutkan?',
  unblockingContact: 'Membuka blokir {{name}} dan memulihkan alarm.',
  unableToUnblockContact: 'Tidak dapat membuka blokir kontak. {{error}}',
  blockingContact: 'Memblokir {{name}}.',
  unableToBlockContact: 'Tidak dapat memblokir kontak. {{error}}',
  cantAddContactToAlarmBecauseBlocked:
    'Anda memblokir {{name}}. Buka blokir mereka untuk menambahkannya sebagai peserta alarm.',
  cantAddContactToGroupBecauseBlocked:
    'Anda memblokir {{name}}. Buka blokir mereka untuk menambahkannya sebagai anggota grup.',
  sendMessageNotAllowedForPastAlarms:
    'Pesan tidak dapat dikirim untuk alarm yang tidak aktif.',
  nameIsRequired: 'Nama harus diisi.',
  confirmDisableGroupAlarm:
    'Menonaktifkan alarm grup juga akan menonaktifkan alarm untuk peserta lain. Lanjutkan?',
  removeMemberFromGroup: 'Apakah Anda ingin menghapus {{name}} dari grup ini?',
  leaveGroupConfirm: 'Apakah Anda ingin keluar dari grup ini?',
  deviceOffline:
    'Perangkat sedang offline. Pastikan Anda tersambung ke internet dan coba lagi.',
  cantUpdateGroupDefaulConfigWhenOffline:
    'Konfigurasi grup yang dimodifikasi tidak akan diterapkan pada alarm di masa mendatang karena perangkat dalam keadaan offline.',
  specifyFeedback: 'Diperlukan umpan balik.',
  specifyEmailId: 'Alamat email diperlukan.',
  specifyValidEmailId: 'Alamat email tidak valid.',
  specifyProblem: 'Deskripsi masalah diperlukan.',
  appInstalledUsingSameNumberOnDifferentPhone:
    'Anda telah menginstal Galarm di perangkat yang berbeda menggunakan nomor yang sama. Aplikasi pada perangkat ini diatur ulang.',
  invalidUid:
    'Akun pengguna ini sudah tidak ada lagi. Aplikasi Galarm pada perangkat ini diatur ulang.',
  accountDeleted: 'Akun Anda telah dihapus.',
  deleteAccount: 'Menghapus Akun Saya',
  deletingAccount: 'Menghapus akun Anda',
  howCanWeImprove:
    'Kami turut berduka cita atas kepergian Anda. Bagaimana kita bisa meningkatkannya?',
  noMusicAlert: 'Tidak ditemukan file musik.',
  noLongerAvailableMusic:
    '{{fileName}} tidak lagi tersedia. Nada dering telah diubah menjadi {{defaultRingtoneName}}',
  userInactiveTitle: 'Selamat datang kembali!',
  userInactiveMessage:
    'Buat alarm untuk hal-hal penting dan jangan pernah melewatkannya.',
  createAlarm: 'Buat Alarm',
  updatedAppAvailable: 'Versi baru dari aplikasi ini tersedia.',
  alarmOccurrenceDisabled: 'Dinonaktifkan untuk kejadian ini',
  ownAlarmOccurrenceDone: {
    zero: 'Ditandai selesai di {{timeString}}',
    one: 'Ditandai selesai di {{timeString}} pada {{dateString}}'
  },
  ownAlarmOccurrenceSkipped: {
    zero: 'Dilewati di {{timeString}}',
    one: 'Dilewati di {{timeString}} pada {{dateString}}'
  },
  enabledAlarmsCount: {
    one: '1 alarm diaktifkan.',
    other: '{{count}} alarm diaktifkan.'
  },
  enabledAndNotEnabledAlarmsCount: {
    one: '1 alarm diaktifkan. Alarm tanpa kejadian di masa mendatang tetap dinonaktifkan.',
    other:
      '{{count}} alarm diaktifkan. Alarm tanpa kejadian di masa mendatang tetap dinonaktifkan.'
  },
  disabledAlarmsCount: {
    one: '1 alarm dinonaktifkan.',
    other: '{{count}} alarm dinonaktifkan.'
  },
  ownAlarmOccurrenceUnacknowledged: 'Tidak menjawab',
  participantAlarmOccurrenceDone: {
    zero: 'Ditandai dilakukan oleh {{participantName}} di {{timeString}}',
    one: 'Ditandai dilakukan oleh {{participantName}} di {{timeString}} pada {{dateString}}'
  },
  participantAlarmOccurrenceSkipped: {
    zero: 'Dilewati oleh {{participantName}} di {{timeString}}',
    one: 'Dilewati oleh {{participantName}} di {{timeString}} pada {{dateString}}'
  },
  participantAlarmOccurrenceUnacknowledged:
    '{{participantName}} tidak menanggapi',
  groupAlarmOccurrenceConfirmed: {
    zero: 'Dikonfirmasi di {{timeString}}',
    one: 'Dikonfirmasi di {{timeString}} pada {{dateString}}'
  },
  groupAlarmOccurrenceDeclined: {
    zero: 'Ditolak di {{timeString}}',
    one: 'Ditolak di {{timeString}} pada {{dateString}}'
  },
  warning: 'Peringatan',
  info: 'Info',
  confirm: 'Konfirmasi',
  error: 'Kesalahan',
  cantUploadImage: 'Tidak dapat mengunggah gambar',
  cantDeleteImage: 'Tidak Dapat Menghapus Gambar',
  cantEditGroup: 'Tidak dapat mengedit grup',
  cantAddNewContact: 'Tidak Dapat Menambahkan Kontak Baru',
  cantDeleteContact: 'Tidak Dapat Menghapus Kontak',
  cantEditContact: 'Tidak Dapat Mengedit Kontak',
  cantSaveAlarm: 'Tidak Dapat Menyimpan Alarm',
  cantSaveGroup: 'Tidak Dapat Menyimpan Grup',
  cantReloadContacts: 'Tidak Dapat Memuat Ulang Kontak',
  cantTakePhoto: 'Tidak Dapat Mengambil Foto',
  cantExportToCalendar: 'Tidak Dapat Mengekspor ke Kalender',
  cantOpenPhotoLibrary: 'Tidak dapat membuka Perpustakaan Foto',
  cantDeleteAlarmHistory: 'Tidak Dapat Menghapus Riwayat Alarm',
  cannotDuplicateAlarm: 'Tidak Dapat Menduplikasi Alarm',
  upgradeToPremiumToDuplicateAlarm:
    'Tingkatkan ke Premium untuk membuat lebih banyak alarm grup dan teman.',
  confirmAcceptAlarm: 'Terima Alarm?',
  confirmRejectAlarm: 'Tolak Alarm?',
  deleteAlarm: 'Hapus Alarm?',
  restoreAlarmConfirmation: 'Pulihkan Alarm?',
  respondToRepeatingAlarm: {
    zero: 'Anda ditambahkan sebagai penerima alarm {{alarmName}} oleh {{alarmCreatorName}}. Anda akan diberi tahu di {{alarmTimeString}} {{alarmRepetitionString}} mulai {{alarmDateString}}',
    one: 'Anda ditambahkan sebagai peserta untuk alarm {{alarmName}} oleh {{alarmCreatorName}}. Anda akan diberi tahu di {{alarmTimeString}} {{alarmRepetitionString}} mulai {{alarmDateString}}'
  },
  respondToOneTimeAlarm: {
    zero: 'Anda ditambahkan sebagai penerima alarm {{alarmName}} oleh {{alarmCreatorName}}. Anda akan diberi tahu di {{alarmTimeString}} {{alarmDateString}}',
    one: 'Anda ditambahkan sebagai peserta untuk alarm {{alarmName}} oleh {{alarmCreatorName}}. Anda akan diberi tahu di {{alarmTimeString}} {{alarmDateString}}'
  },
  cantMoveMemberUp: 'Tidak Bisa Bergerak {{name}} Naik',
  cantMoveMemberDown: 'Tidak bisa bergerak {{name}} Down',
  decline: 'Menolak',
  cantEditProfile: 'Tidak dapat mengedit profil',
  cantSelectContact: 'Tidak Dapat Memilih Kontak',
  cantSendMessage: 'Tidak dapat mengirim pesan',
  cantSendVerificationCode: 'Tidak Dapat Mengirim Kode Verifikasi',
  confirmBlockContactTitle: 'Konfirmasikan Kontak Blokir',
  confirmUnblockContactTitle: 'Konfirmasi Buka Blokir Kontak',
  cantSaveProfile: 'Tidak dapat menyimpan profil',
  cantBlockContact: 'Tidak Dapat Memblokir Kontak',
  cantUnblockContact: 'Tidak Dapat Membuka Blokir Kontak',
  cantLoadEarlierMessages: 'Tidak Dapat Memuat Pesan Sebelumnya',
  cantRestoreAlarm: 'Tidak Dapat Memulihkan Alarm',
  cantSendFeedback: 'Tidak Dapat Mengirimkan Umpan Balik',
  cantSubmitProblem: 'Tidak Dapat Mengirimkan Masalah',
  cantDeletePastAlarmsInBulk:
    'Tidak Dapat Menghapus Alarm Tidak Aktif Secara Massal',
  cantAddNewGroup: 'Tidak Dapat Menambahkan Grup Baru',
  alreadySnoozing: 'Sudah Menunda',
  markAlarmUndone:
    "Anda telah menandai alarm ini 'Selesai' {{durationString}} yang lalu. Membatalkan?",
  markAlarmUnskipped:
    'Anda melewatkan alarm ini {{durationString}} yang lalu. Membatalkan?',
  cantUndo: 'Tidak Dapat Membatalkan',
  fetchMusicFromPhone: 'Menemukan musik yang tersedia.',
  enableAllAlarmsInCategory: 'Mengaktifkan semua alarm dalam kategori ini?',
  disableAllAlarmsInCategory: 'Menonaktifkan semua alarm dalam kategori ini?',
  allAlarmsAlreadyEnabled: 'Semua alarm dalam kategori ini sudah diaktifkan.',
  allAlarmsAlreadyDisabled:
    'Semua alarm dalam kategori ini sudah dinonaktifkan.',
  time: 'Waktu',
  timeForBuddy: 'Waktu untuk Buddy',
  timeForYou: 'Ingatkan saya di',
  name: 'Nama',
  title: 'Judul',
  notes: 'Catatan',
  repeat: 'Ulangi',
  preReminder: 'Pra-Pengingat',
  remove: 'Menghapus',
  preReminderShortAlert: '{{duration}} Pra-Pengingat',
  preReminderTitle: '{{alarmName}} di {{date}}',
  preReminderGroupHelperText:
    'Pra-pengingat ini hanya untuk Anda. Peserta alarm lainnya dapat mengatur pra-pengingat mereka sendiri setelah mereka menerima alarm.',
  addPreReminder: 'Tambahkan Pengingat Awal',
  helperTextForPreReminder:
    'Ketuk tombol reset di bagian atas untuk menghapus pra-pengingat.',
  edit: 'Sunting',
  delete: 'Menghapus',
  creator: 'Pencipta',
  useAsDefaultGroupConfiguration: 'Terapkan ke Alarm Masa Depan',
  done: 'Selesai.',
  skipped: 'Dilewati',
  save: 'Simpan',
  members: 'Anggota',
  mobileNumber: 'Nomor Telepon',
  enter: 'Masukkan',
  addMembers: 'Tambah Anggota',
  respond: 'Menanggapi',
  yes: 'Ya.',
  no: 'Tidak.',
  cancel: 'Batal',
  participants: 'Peserta',
  takePhoto: 'Ambil Foto',
  chooseFromLibrary: 'Pilih Dari Perpustakaan',
  resendCode: 'Kirim Ulang Kode',
  register: 'Daftar',
  inviteFriends: 'Bagikan Aplikasi Ini',
  invite: 'Mengundang',
  incorrectNumber: 'Nomor yang salah?',
  date: 'Tanggal',
  cascadingAlarmInterval: 'Waktu antara peringatan peserta',
  ringParticipantAlarmsByDefault: 'Membunyikan alarm tanpa menerima',
  alarmRingtone: 'Nada Dering Alarm',
  pickASong: 'Memilih musik dari telepon',
  privacyPolicy: 'Kebijakan Privasi',
  sendFeedback: 'Umpan balik',
  galarmWeb: 'Galarm Web',
  galarmEnterprise: 'Galarm Pro',
  galarmAdmin: 'Admin Galarm',
  troubleshooting: 'Pemecahan masalah',
  alarmDidntRingHelp: 'Tolong! Alarm saya tidak berdering',
  autoStartSettingHelperText:
    'Jika ponsel Anda memiliki pengaturan mulai otomatis, pastikan mulai otomatis diaktifkan untuk Galarm.',
  batteryOptimizationHelperText:
    'Menonaktifkan pengoptimalan baterai untuk Galarm',
  troubleshootingHelperText:
    'Pengaturan berikut ini mungkin mencegah alarm berdering seperti yang diharapkan. Silakan tinjau pengaturan ini.',
  getMoreHelpText: 'Masih mengalami masalah?',
  autoStartSetting: 'Mulai otomatis',
  batteryOptimization: 'Optimalisasi baterai',
  battery: 'Baterai',
  rateTheAppSettings: 'Beri Nilai Aplikasi Ini',
  about: 'Tentang & Bantuan',
  leaveGroup: 'Tinggalkan Grup',
  removeGroup: 'Hapus Grup',
  viewMember: 'Melihat {{memberName}}',
  removeMember: 'Menghapus {{memberName}}',
  changeMemberState:
    'Mengubah siapa yang dapat memberi peringatan {{memberName}}',
  changeYourGroupState: 'Ubah siapa yang dapat mengingatkan Anda',
  numMembersInGroup: 'Anggota: {{count}}',
  notifications: 'Pemberitahuan Aplikasi dinonaktifkan',
  backgroundAppRefresh: 'Penyegaran Aplikasi Latar Belakang dinonaktifkan',
  groupsInCommon: 'Kelompok yang Sama',
  alarmsInCommon: 'Alarm yang Sama',
  close: 'Tutup',
  responsePending: 'Tanggapan Anda?',
  later: 'Kemudian',
  install: 'Memasang',
  all: 'Semua',
  numYears: { one: '1 tahun', other: '{{count}} tahun' },
  numMonths: { one: '1 bulan', other: '{{count}} bulan' },
  numDays: { one: '1 hari', other: '{{count}} hari' },
  hours: { one: '1 jam', other: '{{count}} jam' },
  minutes: { one: '1 menit', other: '{{count}} menit' },
  seconds: { one: '1 detik', other: '{{count}} detik' },
  minutesSmall: '{{count}} min',
  secondsSmall: '{{count}} detik',
  hoursAfter: { one: '1 jam setelahnya', other: '{{count}} jam setelahnya' },
  minutesAfter: {
    one: '1 menit setelah',
    other: '{{count}} beberapa menit setelah'
  },
  hoursAfterBuddy: {
    one: '1 jam setelah teman',
    other: '{{count}} jam setelah sobat'
  },
  minutesAfterBuddy: {
    one: '1 menit setelah sobat',
    other: '{{count}} beberapa menit setelah sobat'
  },
  longerThanAnHour: '> 1 jam',
  licenses: 'Lisensi Pihak Ketiga',
  version: 'Versi',
  termsOfService: 'Ketentuan Layanan',
  showPrivacyPolicy: '> Kebijakan Privasi',
  showTermsOfService: '> Ketentuan Layanan',
  showLicenses: '> Lisensi Pihak Ketiga',
  change: 'Perubahan',
  snooze: 'Tunda',
  ringOnVibrate: 'Berdering dengan Senyap',
  vibrate: 'Bergetar',
  ringOnEarphoneOnly: 'Alarm dering hanya pada earphone',
  ringOnSpeakerAndEarphone: 'Alarm dering pada speaker dan earphone',
  whenEarphoneIsConnected: 'Saat earphone tersambung',
  tapGesture: 'Ketuk',
  slideGesture: 'Geser',
  silent: 'Diam.',
  volume: 'Volume',
  soundAndVibration: 'Suara dan Getaran',
  advancedSettings: 'Pengaturan Lanjutan',
  gestureToDismissAnAlarm: 'Gerakan untuk mematikan alarm',
  someNotificationsAreMuted: 'Beberapa notifikasi dibisukan',
  allNotificationsEnabled: 'Semua notifikasi diaktifkan',
  notificationSettings: 'Pengaturan Pemberitahuan',
  galarmWebNotifications: 'Pemberitahuan Web Galarm',
  alarmsfromOthersNotifications: 'Pemberitahuan Alarm Peserta',
  sharedAlarmNotifications: 'Pemberitahuan Alarm Bersama',
  alarmChatNotifictions: 'Pemberitahuan Obrolan Alarm',
  groupNotifications: 'Pemberitahuan Grup',
  alarmAcknowledgementNotifications: 'Pemberitahuan Pengakuan Alarm',
  send: 'Kirim',
  prev: 'Sebelumnya',
  snoozing: '{{timeString}}',
  specifySnoozeDuartion: 'Menentukan durasi tunda',
  chooseDifferentDuration: 'Pilih durasi yang berbeda',
  noCalendarsFound: 'Tidak ada kalender yang ditemukan pada perangkat.',
  eventWillEndAt: 'Acara akan berakhir di {{timeString}}.',
  selectDurationWithinTheSameDay: 'Acara tidak dapat melewati batas hari.',
  cannotMapRepeatTypeInTheDeviceCalendar:
    'Tidak dapat "Ekspor ke Kalender" karena pengaturan "Ulangi" alarm Anda tidak didukung oleh kalender.',
  alarmSavedToCalendar: 'Alarm telah berhasil disimpan ke kalender.',
  faq: 'Pertanyaan yang Sering Diajukan',
  openSettings: 'Buka Pengaturan',
  openAppSettings: 'Buka Pengaturan Aplikasi',
  alreadyDone: 'Selesai.',
  enableAutoStart: 'Mengaktifkan Mulai Otomatis',
  restrictedApps: 'Aplikasi yang Dibatasi',
  unmonitoredApps: 'Aplikasi yang tidak dipantau',
  enableProtected: 'Aplikasi yang Dilindungi',
  manageAppLaunch: 'Mengelola Peluncuran Aplikasi',
  unblockContact: 'Buka Blokir Kontak',
  blockContact: 'Blokir Kontak',
  call: 'Panggilan {{name}}',
  text: 'Teks {{name}}',
  chat: 'Mengobrol',
  ok: 'BAIKLAH.',
  stopSnooze: 'Hentikan Tunda',
  emailId: 'Alamat Email Anda',
  feedback: 'Umpan balik',
  submit: 'Kirim',
  reportAProblem: 'Laporkan Masalah',
  enableAlarm: 'Aktifkan Alarm',
  restoreAlarm: 'Pulihkan Alarm',
  deletePastAlarms: 'Menghapus Alarm Tidak Aktif',
  accept: 'Menerima',
  defaultPersonalAlarmName: 'Pengingat Pribadi',
  defaultGroupAlarmName: 'Acara Kelompok',
  defaultRecipientAlarmName: 'Alarm Teman',
  search: 'Pencarian',
  recent: 'Terbaru',
  upcoming: 'Mendatang',
  missed: 'Merindukan',
  expired: 'Kadaluarsa',
  past: 'Masa lalu',
  active: 'Aktif',
  inactive: 'Tidak aktif',
  allAlarms: 'Semua Alarm',
  myAlarms: 'Alarm saya',
  participantAlarms: 'Alarm Peserta',
  pendingParticipantAlarms: 'Alarm Baru dari Orang Lain',
  alarmsWithUnreadChatMessages: 'Alarm dengan Obrolan Baru',
  systemNotifications: 'Pesan Sistem',
  missedAlarms: 'Alarm tidak terjawab',
  recentlyDeletedAlarms: 'Alarm yang Baru Saja Dihapus',
  searchAlarms: 'Cari Alarm',
  participant: 'Peserta',
  you: 'Anda',
  none: 'Tidak ada',
  onceOnly: 'Sekali saja',
  weekdays: 'Hari kerja',
  weekends: 'Akhir pekan',
  everyDayString: 'Setiap hari',
  everySunday: 'Setiap hari Minggu',
  everyMonday: 'Setiap hari Senin',
  everyTuesday: 'Setiap hari Selasa',
  everyWednesday: 'Setiap hari Rabu',
  everyThursday: 'Setiap hari Kamis',
  everyFriday: 'Setiap hari Jumat',
  everySaturday: 'Setiap hari Sabtu',
  sunday: 'Minggu',
  monday: 'Senin',
  tuesday: 'Selasa',
  wednesday: 'Rabu',
  thursday: 'Kamis',
  friday: 'Jumat',
  saturday: 'Sabtu',
  january: 'Januari',
  february: 'Februari',
  march: 'Maret',
  april: 'April',
  may: 'Mei',
  june: 'Juni',
  july: 'Juli',
  august: 'Agustus',
  september: 'September',
  october: 'Oktober',
  november: 'November',
  december: 'Desember',
  jan: 'Jan',
  feb: 'Feb',
  mar: 'Mar',
  apr: 'Apr',
  shortMay: 'Mei',
  jun: 'Jun',
  jul: 'Jul',
  aug: 'Agu',
  sep: 'Sep',
  oct: 'Okt',
  nov: 'Nov',
  dec: 'Des',
  sun: 'Min',
  mon: 'Mon',
  tue: 'Tue',
  wed: 'Rab',
  thu: 'Kam',
  fri: 'Fri',
  sat: 'Sat',
  first: '1',
  second: '2',
  third: '3',
  fourth: '4',
  fifth: '5',
  every: 'Setiap',
  oddNumberedDays: 'Tanggal Bernomor Ganjil',
  evenNumberedDays: 'Tanggal Bernomor Genap',
  noParticipants: 'Tidak Ada Peserta',
  typeYourMessage: 'Ketik pesan Anda',
  cantPostMessageToPastAlarm:
    'Tidak dapat mengirim pesan ke alarm yang tidak aktif',
  groupName: 'Kelompok: {{name}}',
  alarm: 'Alarm',
  confirmed: 'dikonfirmasi',
  declined: 'ditolak',
  Done: 'Selesai.',
  Skip: 'Lewati',
  Confirm: 'Konfirmasi',
  Decline: 'Menolak',
  Accept: 'Menerima',
  today: 'hari ini',
  on: 'pada',
  recipientAlarm: 'Alarm Teman',
  recipient: 'Penerima',
  remindMeAfter: 'Ingatkan aku.',
  remindMeIn: 'Ingatkan saya di',
  newRecipientAlarm: 'Alarm Teman Baru',
  editRecipientAlarm: 'Edit Alarm Teman',
  newInstantAlarm: 'Alarm Instan Baru',
  instantAlarms: 'Alarm Instan',
  alarmDone: 'Alarm Ditandai Selesai',
  alarmSkipped: 'Alarm Dilewati',
  undo: 'Membatalkan',
  enterVerificationCode: 'Masukkan 4 digit kode di bawah ini:',
  initializing: 'Inisialisasi',
  tryAgain: 'Coba Lagi',
  nMore: '+ {{count}} lebih lanjut',
  enableNotification: 'Aktifkan',
  enableAlarmNotificationChannel: 'Aktifkan',
  enableBackgroundAppRefresh: 'Aktifkan',
  viewImpactedAlarms: 'Alarm',
  viewImpactedGroups: 'Grup',
  dismiss: 'Bubarkan.',
  minsInterval: '+ {{count}}m',
  hoursInterval: '+ {{count}}h',
  daysInterval: '+ {{count}}d',
  snoozeUnitHours: 'Jam',
  snoozeUnitMinutes: 'Menit',
  snoozeUnitDays: 'Hari',
  selectMinutes: 'Pilih menit',
  selectHours: 'Pilih jam',
  selectDays: 'Pilih hari',
  selectHoursAndMinutes: 'Pilih jam dan menit',
  enterDuration: 'Durasi',
  snoozeUnit: 'Unit',
  specifySnoozeUnit: 'Menentukan unit tunda',
  specifySnoozeDuration: 'Menentukan durasi tunda',
  duration: 'Durasi',
  quickAlarmName: {
    zero: '{{minutes}} pengingat min',
    one: '{{hours}} pengingat jam',
    other: '{{hours}} jam {{minutes}} pengingat menit'
  },
  alarmTitleForGoogleVoiceTimerCommand: '{{durationString}} pengingat',
  addedAlarmFromVoiceCommand:
    'Menambahkan alarm untuk {{alarmName}} di {{dateString}}',
  addedTimerFromVoiceCommand: 'Menambahkan pengatur waktu untuk {{duration}}',
  clear: 'Jelas',
  ringtoneDuration: 'Durasi Nada Dering',
  chooseDuration: 'Ketuk durasi. Anda dapat mengetuk beberapa kali.',
  newAlarm: 'Alarm Baru',
  quickAlarm: 'Pengingat Cepat',
  quickAlarmWillRingAt: 'Alarm akan berdering di {{timeString}}.',
  preReminderWillRingAt:
    'Pengingat awal untuk alarm ini akan berdering \ndi {{timeString}}.',
  random: 'Acak',
  enableAllAlarms: 'Mengaktifkan semua alarm',
  disableAllAlarms: 'Menonaktifkan semua alarm',
  deleteAllAlarms: 'Menghapus semua alarm',
  hideCategory: 'Sembunyikan Kategori',
  unhideCategory: 'Perlihatkan Kategori',
  dontShowHiddenCategories: 'Jangan tampilkan kategori tersembunyi',
  showHiddenCategories: 'Menampilkan kategori tersembunyi',
  welcomeAlarmsTitle: 'Mari kita mulai!',
  welcomeAlarmsMessage: 'Berikut ini beberapa alarm yang umum digunakan:',
  onboardingAlarms: 'Alarm Penyambutan',
  hiddenAlarmCategories: 'Kategori tersembunyi',
  restorePurchases: 'Mengembalikan Pembelian',
  noAvailablePurchasesFound:
    'Tidak ditemukan pembelian yang tersedia untuk akun Anda.\n\nKirimkan umpan balik kepada kami melalui "Pengaturan > Umpan Balik" jika Anda memiliki pembelian valid yang tidak dapat dikembalikan.',
  restoringPurchases: 'Memulihkan pembelian',
  fetchingContacts: 'Memeriksa kontak mana yang menggunakan Galarm',
  enterNameAndUploadPhotoForUser:
    'Masukkan nama Anda di bawah ini dan unggah foto profil Anda secara opsional',
  enterNameAndUploadPhotoForGroup:
    'Masukkan nama di bawah ini dan unggah foto grup secara opsional',
  noContactsWithAppInstalled:
    'Tidak ada seorang pun di kontak Anda yang menggunakan Galarm.',
  galarmContacts: { one: '1 Kontak Galarm', other: '{{count}} Kontak Galarm' },
  contactsPendingInvitation: {
    one: '1 undangan yang tertunda',
    other: '{{count}} undangan yang tertunda'
  },
  otherContacts: { one: '1 kontak lainnya', other: '{{count}} kontak lainnya' },
  over100Contacts: 'Lebih dari 100 kontak lainnya',
  noAlarms:
    'Anda tidak memiliki alarm apa pun. Ketuk + untuk menambahkan alarm baru.',
  noContactsWeb: {
    one: 'Kami tidak menemukan kontak yang menggunakan Galarm. Bisa jadi kami tidak memiliki izin untuk mengakses kontak Anda atau tidak ada seorang pun di kontak Anda yang menggunakan Galarm. Anda dapat menyinkronkan kontak Anda dari aplikasi seluler.\n\nAnda dapat menambahkan kontak menggunakan Galarm ke alarm Anda dengan berbagai cara untuk mendapatkan manfaat dari aplikasi',
    other:
      'Silakan mendaftar di aplikasi seluler untuk mulai menambahkan kontak ke alarm Anda.'
  },
  viewingAlarmsInCommon: 'Alarm yang sama dengan {{name}}',
  viewingGroupsInCommon: 'Grup yang memiliki kesamaan dengan {{contactName}}',
  changeGroupMemberConfigTextForCascadingAlarm:
    'Peserta yang aktif akan diberitahukan dalam urutan yang ditampilkan.',
  changeGroupMemberConfigTextForSimultaneousAlarm:
    'Hanya peserta aktif yang akan diberi tahu.',
  changeGroupMemberStateText: {
    zero: 'Ubah anggota yang dapat memberi tahu Anda saat alarm dibuat untuk grup "{{groupName}}".\n\nAnda hanya akan diberi tahu jika alarm dibuat oleh anggota yang diizinkan.',
    one: 'Mengubah anggota yang dapat memberi tahu {{memberName}} ketika alarm dibuat untuk grup "{{groupName}}".\n\n{{memberName}} hanya akan diberi tahu jika alarm dibuat oleh anggota yang diizinkan.'
  },
  allowed: 'Diizinkan',
  notAllowed: 'Tidak Diizinkan',
  changeStatus: 'Anggota yang Diizinkan',
  noUpcomingAlarms: 'Tidak ada alarm yang akan datang',
  showMore: 'Tampilkan Lebih Banyak',
  nMorePastAlarms: {
    one: '1 lagi alarm yang tidak aktif',
    other: '{{count}} lebih banyak alarm yang tidak aktif'
  },
  shareAppMessage:
    'Saya menyelesaikan semua pekerjaan saya secara tepat waktu dengan membuat pengingat untuk mereka menggunakan aplikasi Galarm. Melalui aplikasi ini, kami juga bisa saling menyertakan satu sama lain dalam pengingat kami. Saya sarankan untuk mengunduh aplikasi alarm dan pengingat yang inovatif ini. {{downloadLink}}',
  addingMembers: 'Menambah anggota.',
  unableToAddMembers: 'Tidak dapat menambahkan anggota. {{error}}',
  removingMember: 'Menghapus anggota.',
  unableToRemoveMember: 'Tidak dapat menghapus anggota. {{error}}',
  leavingGroup: 'Meninggalkan grup.',
  unableToLeaveGroup: 'Tidak dapat meninggalkan grup. {{error}}',
  expiredPersonalAlarmForCreator: {
    zero: 'Alarm ini telah kedaluwarsa {{durationString}} yang lalu. Ketuk "Selesai" untuk menyelesaikan alarm.',
    one: 'Alarm ini telah kedaluwarsa {{durationString}} yang lalu. Ketuk "Selesai" untuk menyelesaikan alarm dan memberi tahu peserta.',
    other:
      'Alarm ini telah kedaluwarsa {{durationString}} yang lalu. Ketuk "Selesai" untuk menyelesaikan alarm dan memberi tahu peserta.'
  },
  donePersonalAlarmForCreator:
    'Alarm ini telah kedaluwarsa {{durationString}} yang lalu. Alarm ditandai sebagai selesai.',
  skippedPersonalAlarmForCreator:
    'Alarm ini sudah kedaluwarsa {{durationString}} lalu. Anda telah melewatkan alarm.',
  expiredGroupAlarm:
    'Alarm ini telah kedaluwarsa {{durationString}} lalu. Mohon konfirmasikan keikutsertaan Anda.',
  expiredPersonalAlarmForParticipant:
    'Alarm ini telah kedaluwarsa {{durationString}} lalu. Mohon diingatkan {{creatorName}}.',
  alarmDurationAndroid: 'Setel alarm untuk {{durationString}} mulai sekarang.',
  alarmDurationIos: 'Setel alarm untuk {{durationString}} mulai sekarang.',
  alarmFeedbackHeader:
    'Anda akan mengirimkan sebagian informasi alarm ini untuk tujuan debugging. Data pribadi apa pun seperti nama alarm, catatan, atau peserta tidak akan dibagikan.',
  privacyPolicyHeader:
    'Menghormati privasi Anda selalu menjadi prioritas kami. Sama seperti Anda, kami menghargai privasi, jadi kami ingin Anda tahu bahwa kami tidak membagikan informasi pribadi Anda kepada siapa pun, kecuali jika diperlukan untuk memasang aplikasi atau jika diwajibkan oleh hukum. Dalam semua kasus, kami tidak pernah menjual informasi Anda. Sesederhana itu!\n \nAlarm yang Anda buat hanya dapat dilihat di perangkat Anda dan, jika alarm menyertakan peserta lain, di perangkat peserta tersebut.\n \nKami mengakses buku alamat/daftar kontak ponsel Anda hanya untuk mengisi tab kontak di Galarm. Kami tidak menyimpan daftar tersebut untuk tujuan lain. Kami menghormati bahwa itu adalah buku alamat Anda!\n \nKami mengakses kamera dan foto Anda hanya untuk memungkinkan Anda mengatur gambar profil yang bagus. Foto ini akan muncul sebagai avatar Anda atau sebagai foto profil grup yang Anda edit. Pengguna Galarm lain yang menjadi anggota alarm yang Anda atur akan dapat melihat gambar ini.\n\nKami mengirimkan IP publik perangkat Anda dan ID uniknya dengan semua permintaan, misalnya, saat meminta dan memverifikasi kode selama proses pendaftaran. Informasi ini tidak ditautkan ke akun Anda dan hanya digunakan untuk menentukan potensi aktivitas berbahaya.\n \nSilakan tinjau kebijakan privasi lengkap untuk detail lebih lanjut tentang informasi yang kami kumpulkan dan bagaimana kami menggunakannya.',
  termsOfServiceHeader:
    'Dengan menggunakan aplikasi Galarm, Anda menyetujui Ketentuan Layanan kami, yang mengatur akses dan penggunaan Anda terhadap aplikasi seluler ini serta fitur dan layanan yang ditawarkannya. \n\nMoto Galarm sederhana saja - membantu Anda agar tidak pernah melewatkan acara apa pun!  Baik itu mengatur Alarm Pribadi untuk peringatan bangun tidur, tepat waktu untuk janji temu, dan mengatur pengingat untuk kegiatan seperti minum obat tepat waktu, atau membuat Alarm Grup untuk mengatur kegiatan grup seperti menonton film, acara olahraga, dan pesta, Galarm memungkinkan Anda untuk memiliki jam alarm sosial untuk saling mengingatkan dan melacak status satu sama lain dan berkomunikasi.\n\nHarap gunakan Galarm hanya jika Anda menyetujui persyaratan ini. Di bawah ini adalah tautannya.',
  acknowledgedPersonalAlarm:
    'Anda telah menandai alarm "Selesai" {{durationString}} tadi.',
  skippedPersonalAlarm: 'Anda melewatkan alarm {{durationString}} yang lalu.',
  authenticatingWithServer: 'Menghubungkan...',
  selectParticipantsForPersonalAlarm:
    'Pilih grup atau sekelompok orang yang bertindak sebagai cadangan untuk alarm pribadi Anda.',
  selectParticipantsForGroupAlarm:
    'Pilih grup atau sekumpulan orang untuk aktivitas grup Anda.',
  acknowledgedGroupAlarm:
    'Anda {{acknowledgedAsString}} alarm ini {{durationString}} lalu.',
  acknowledgedExpiredGroupAlarm:
    'Alarm ini sudah kedaluwarsa {{durationString}} lalu. Anda {{acknowledgedAsString}} alarm ini.',
  acknowledgedPersonalAlarmForParticipant:
    '{{creatorName}} menandai alarm "Selesai" {{durationString}} lalu.',
  acknowledgedExpiredPersonalAlarmForParticipant:
    'Alarm ini telah kedaluwarsa {{durationString}} yang lalu. {{creatorName}} menandai alarm "Selesai".',
  entityNotFound:
    'Ups! Kami tidak dapat menemukan {{entityType}} yang dipilih. Kami mungkin masih mengambilnya atau mungkin sudah dihapus.',
  goBack: 'Kembali',
  uploadingImageToServer: 'Mengunggah gambar ke server.',
  deletingImageFromServer: 'Menghapus gambar dari server',
  errorUploadingImageToServer:
    'Kesalahan saat mengunggah gambar ke server. Silakan coba lagi nanti.',
  unableToRemoveGroup: 'Tidak dapat menghapus grup. {{error}}',
  addingGroup: 'Menambahkan grup.',
  editingGroup: 'Kelompok pengeditan.',
  unableToAddGroup: 'Tidak dapat menambahkan grup. {{error}}',
  unableToEditGroup: 'Tidak dapat mengedit grup. {{error}}',
  thanksForFeedback:
    'Terima kasih telah membantu kami untuk menjadi lebih baik.',
  licensesHeader:
    'Galarm mencakup perangkat lunak yang dilisensikan oleh pihak ketiga. Silakan lihat halaman Lisensi Pihak Ketiga kami untuk informasi atribusi dan lisensi yang sesuai.',
  alarmAlreadyInSnooze: 'Anda akan diingatkan di {{durationString}}.',
  carrierChargesMayApply: 'Biaya operator mungkin berlaku.',
  verficationCodeInfo:
    'Anda akan menerima pesan teks atau panggilan telepon dengan kode 4 digit.',
  selectCascadingInterval:
    'Peserta pertama akan diberi tahu {{firstParticipantDurationString}} setelah waktu alarm, peserta kedua akan diberi tahu {{secondParticipantDurationString}} setelah waktu alarm dan seterusnya jika Anda tidak menandai alarm sebagai selesai.',
  reorderParticipantsScreenDescription:
    'Peserta akan diberi tahu sesuai urutan yang ditampilkan.',
  noMembers: 'Tidak ada anggota',
  selectedGroupDescription: {
    zero: '{{numActiveMembers}} peserta',
    one: '{{numActiveMembers}} aktif dan {{numInactiveMembers}} peserta tidak aktif',
    other:
      '{{numActiveMembers}} aktif dan {{numInactiveMembers}} peserta yang tidak aktif'
  },
  groupAlarmWillRingAtSameTime:
    'Semua peserta akan diberitahukan di {{timeString}}.',
  welcomeToGalarm: 'Selamat datang di Galarm',
  checkingConnection: 'Memeriksa koneksi',
  deletePastAlarmsTitle: 'Menghapus alarm tidak aktif yang lebih lama dari',
  deletePastAlarmsOption: {
    zero: '{{option}} (0 alarm)',
    one: '{{option}} (1 alarm)',
    other: '{{option}} ({{count}} alarm)'
  },
  deletePastAlarmsNotificationMessage:
    'Menghapus alarm yang tidak aktif untuk menggunakannya kembali.',
  chooseSnoozeInterval: 'Ingatkan saya lagi di',
  personalAlarmDescription:
    'Gunakan alarm pribadi untuk bangun tidur, minum obat, atau tugas lain untuk diri Anda sendiri. Anda dapat menambahkan peserta yang akan diberi tahu jika Anda melewatkan alarm sehingga mereka dapat mengingatkan Anda. Para peserta juga akan diberi tahu ketika Anda menandai alarm selesai.',
  groupAlarmDescription:
    'Gunakan alarm grup untuk aktivitas kelompok seperti tamasya, pesta, atau acara lainnya. Alarm akan berbunyi pada waktu yang sama untuk semua peserta dan mereka dapat mengonfirmasi keikutsertaan mereka.',
  recipientAlarmDescription:
    'Membuat alarm untuk orang lain ("teman") untuk mengingatkan mereka akan hal-hal yang perlu dilakukan. Anda akan diberi tahu untuk mengingatkan buddy jika mereka melewatkan alarm. Anda juga akan diberi tahu setelah teman menandai alarm selesai.\n  \nPerhatikan bahwa alarm dibuat di zona waktu Anda.',
  whatsThis: 'Apa ini?',
  alarmCurrentlyDisabled: 'Alarm ini saat ini dinonaktifkan',
  alarmCurrentlyRejected: 'Alarm ini saat ini ditolak',
  currentlyTypingUsers: {
    one: '{{currentlyTypingUsers}} sedang mengetik...',
    other: '{{currentlyTypingUsers}} sedang mengetik...'
  },
  noSearchResultsFound: 'Tidak ditemukan {{type}} ',
  noGroupsCreated: 'Anda belum membuat grup apa pun.',
  noGroupsWeb: {
    one: 'Anda belum membuat grup apa pun.',
    other:
      'Silakan mendaftar di aplikasi seluler untuk mulai membuat grup dan menambahkannya ke alarm.'
  },
  internalErrorDuringRegistration:
    'Terjadi kesalahan internal selama pendaftaran pengguna. Silakan coba lagi setelah beberapa menit',
  selectRecipientAlarmInterval: {
    zero: 'Anda akan diberi tahu pada saat yang sama dengan penerima',
    one: "Anda akan diberi tahu {{durationString}} setelah waktu alarm jika penerima tidak menandai alarm 'Selesai'",
    other:
      "Anda akan diberi tahu {{durationString}} setelah waktu alarm jika penerima tidak menandai alarm 'Selesai'"
  },
  selectRecipient: 'Pilih Penerima',
  acknowledgedExpiredRecipientAlarm:
    'Alarm ini telah kedaluwarsa {{durationString}} yang lalu. Anda menandai alarm ini "Selesai".',
  skippedExpiredRecipientAlarm:
    'Alarm ini sudah kedaluwarsa {{durationString}} lalu. Anda melewatkan alarm ini.',
  expiredRecipientAlarm:
    'Alarm ini telah kedaluwarsa {{durationString}} yang lalu. Ketuk "Selesai" untuk menyelesaikan alarm dan memberi tahu {{creatorName}}.',
  acknowledgedRecipientAlarm:
    'Anda telah menandai alarm ini "Selesai" {{durationString}} tadi.',
  skippedRecipientAlarm:
    'Anda melewatkan alarm ini {{durationString}} yang lalu.',
  acknowledgedExpiredRecipientAlarmForCreator:
    'Alarm ini telah kedaluwarsa {{durationString}} yang lalu. {{recipientName}} menandai alarm "Selesai".',
  skippedExpiredRecipientAlarmForCreator:
    'Alarm ini telah kedaluwarsa {{durationString}} yang lalu. {{recipientName}} melewatkan alarm.',
  expiredRecipientAlarmForCreator:
    'Alarm ini telah kedaluwarsa {{durationString}} lalu. Mohon diingatkan {{recipientName}}.',
  acknowledgedRecipientAlarmForCreator:
    '{{recipientName}} menandai alarm "Selesai" {{durationString}} lalu.',
  selectRecipientForRecipientAlarm: 'Memilih penerima untuk alarm',
  selectAtLeastOneParticipantForGroupAlarm:
    'Tambahkan setidaknya satu peserta ke alarm',
  addAtLeastOneMemberToGroup: 'Tambahkan setidaknya satu anggota ke grup',
  atTheSameTime: 'Pada saat yang sama',
  myself: 'Aku sendiri.',
  group: 'Kelompok',
  someoneElse: 'Orang lain',
  participantsAreNotified: 'Peserta akan diberitahu.',
  creatorIsNotified: '{{creatorName}} diberitahukan.',
  membersAddedToGroup: {
    one: '{{memberNames}} ditambahkan ke grup {{groupName}}.',
    other: '{{memberNames}} ditambahkan ke grup {{groupName}}.'
  },
  memberRemovedFromGroup:
    '{{memberName}} telah dihapus dari grup {{groupName}} yang mungkin berdampak pada beberapa alarm Anda.',
  youRemovedFromGroup:
    'Anda telah dihapus dari grup {{groupName}} yang mungkin berdampak pada beberapa alarm Anda.',
  memberLeftGroup:
    '{{memberName}} grup kiri {{groupName}} yang mungkin berdampak pada beberapa alarm Anda.',
  youLeftGroup:
    'Anda keluar dari grup {{groupName}} yang mungkin berdampak pada beberapa alarm Anda.',
  groupLeft: 'Anda telah meninggalkan grup {{groupName}}.',
  memberMovedToADifferentTimezone:
    '{{memberName}} pindah ke zona waktu yang berbeda yang mungkin berdampak pada beberapa alarm Anda.',
  memberDstChanged: {
    zero: '{{memberName}} berhenti mengamati waktu musim panas. Alarm yang relevan telah diperbarui.',
    one: '{{memberName}} mulai mengamati waktu musim panas. Alarm yang relevan telah diperbarui.'
  },
  impactedAlarms: 'Alarm yang terkena dampak:',
  alwaysRingParticipantAlarmsOn:
    'Alarm dari orang lain akan berdering kecuali jika Anda menolaknya.',
  alwaysRingParticipantAlarmsOff:
    'Alarm dari orang lain tidak akan berdering kecuali Anda menerimanya.',
  agreeToTermsOfServiceAndPrivacyPolicy:
    'Dengan melanjutkan, Anda menerima Ketentuan Layanan dan Kebijakan Privasi Galarm.',
  deleteAccountWarningHeader:
    'Peringatan: Tindakan ini tidak dapat dibatalkan. Menghapus akun Anda akan menghapus akun Anda:',
  deleteAccountDeleteAlarms: '- Menghapus semua alarm yang Anda buat',
  deleteAccountDeleteRecipientAlarms:
    '- Menghapus semua alarm teman di mana Anda adalah penerimanya',
  deleteAccountRemoveFromGroups: '- Menghapus Anda dari semua grup',
  deleteAccountWarningFooter: {
    zero: 'Jika Anda berganti ponsel, maka Anda cukup menginstal aplikasi di ponsel baru dan mendaftar menggunakan nomor yang sama. Anda dapat menghapus aplikasi di ponsel ini.\n\nSetelah Anda menghapus akun, Anda tidak akan dapat memulihkannya.',
    one: 'Jika Anda berganti ponsel, maka Anda cukup menginstal aplikasi di ponsel baru dan mendaftar menggunakan nomor yang sama. Anda dapat menghapus aplikasi di ponsel ini.\n\nSetelah Anda menghapus akun Anda, Anda tidak akan dapat memulihkannya. Anda dapat mengelola langganan Galarm yang masih aktif di Play Store.',
    other:
      'Jika Anda berganti ponsel, maka Anda bisa menginstal aplikasi di ponsel baru dan menghapus aplikasi di ponsel ini.\n\nSetelah Anda menghapus akun Anda, Anda tidak akan dapat memulihkannya. Anda dapat mengelola langganan Galarm yang masih aktif di App Store.'
  },
  typeConfirmationStringToDeleteAccount:
    'Ketik {{confirmationString}} untuk menghapus akun Anda',
  unableToDeleteAccount: 'Tidak dapat menghapus akun. {{error}}',
  impactedGroups: 'Kelompok-kelompok yang terkena dampak:',
  memberDeletedAccount:
    '{{memberName}} telah menghapus akun mereka yang mungkin berdampak pada beberapa alarm dan grup Anda.',
  confirmCountryCodeAndEnterNumber:
    'Harap konfirmasikan kode negara Anda dan masukkan nomor telepon Anda.',
  confirmCountryCodeAndEnterNumberForExistingUser:
    'Konfirmasikan kode negara Anda dan masukkan nomor telepon yang Anda gunakan saat mendaftar sebelumnya.',
  requiredPermissionsDisabledWarningIos:
    'Pemberitahuan dinonaktifkan. Alarm tidak akan berdering. Ketuk untuk memperbaiki.',
  requiredPermissionsDisabledWarningAndroid:
    'Galarm tidak memiliki izin yang diperlukan untuk membunyikan alarm. Ketuk untuk memperbaiki.',
  notificationChannelPopupDisabledWarningAndroid:
    'Pemberitahuan alarm tidak akan muncul di layar. Ketuk untuk memperbaiki.',
  alarmLockScreenNotificationDisabledWarningAndroid:
    'Pemberitahuan alarm tidak akan muncul pada layar kunci. Ketuk untuk memperbaiki.',
  why: 'Mengapa',
  how: 'Bagaimana',
  registrationRequired: 'Diperlukan Pendaftaran',
  phoneNumberRequired: 'Nomor Telepon yang Diperlukan',
  fewSeconds: 'beberapa detik',
  lessThanMinute: 'kurang dari satu menit',
  updateUnsupportedVersion:
    'Versi aplikasi yang diperbarui diperlukan untuk terus menggunakan Galarm.',
  update: 'Memperbarui',
  back: 'Kembali',
  unableToMarkAlarmDone:
    'Tidak dapat menandai alarm sebagai selesai saat ini. Silakan coba lagi dalam beberapa saat.',
  unableToMarkAlarmUndone:
    'Tidak dapat menandai alarm sebagai dibatalkan saat ini. Silakan coba lagi dalam beberapa saat.',
  unableToMarkAlarmUnskipped:
    'Tidak dapat menandai alarm sebagai tidak terlewati saat ini. Silakan coba lagi dalam beberapa saat.',
  unableToSkipAlarm:
    'Tidak dapat melewatkan alarm saat ini. Silakan coba lagi dalam beberapa saat.',
  unableToSetGroupAlarmResponse:
    'Tidak dapat {{response}} alarm grup saat ini. Silakan coba lagi beberapa saat lagi.',
  unableToDeleteAlarm:
    'Tidak dapat menghapus alarm saat ini. Silakan coba lagi dalam beberapa saat.',
  unableToSetAlarmResponse:
    'Tidak dapat mengatur respons Anda saat ini. Silakan coba lagi dalam beberapa saat.',
  unableToSnoozeAlarm: 'Tidak dapat menunda alarm.',
  unableToStopSnoozeForAlarm: 'Tidak dapat menghentikan tunda untuk alarm.',
  unableToUpdateParticipantAlarmRingerSettings:
    'Tidak dapat memperbarui pengaturan dering saat ini. Silakan coba lagi dalam beberapa saat.',
  updateParticipantPreReminderDuration:
    'Tidak dapat memperbarui durasi pengingat awal saat ini. Silakan coba lagi dalam beberapa saat.',
  unableToUpdateAlarmRingerSettings:
    'Tidak dapat memperbarui pengaturan dering saat ini. Silakan coba lagi dalam beberapa saat.',
  unableToUpdateAlarmPreReminderDuration:
    'Tidak dapat memperbarui jangka waktu pengingat awal saat ini. Silakan coba lagi dalam beberapa saat.',
  retrievingAlarmsFromServer: 'Mengambil alarm dari server...',
  startApp: 'Mulai >',
  phoneNumberRequiredGroupListHeaderText:
    'Grup adalah kumpulan kontak Galarm yang memungkinkan Anda membuat alarm dengan cepat bersama mereka.\n\nSilakan mendaftar menggunakan nomor telepon Anda untuk menemukan kontak Anda yang menggunakan Galarm. Anda dapat menambahkan mereka ke grup.',
  phoneNumberRequiredBuddyAlarmHeaderText:
    'Alarm teman dapat digunakan untuk menyetel alarm bagi teman dan keluarga untuk mengingatkan mereka akan hal-hal yang perlu dilakukan.\n\nUntuk menggunakan fitur ini dan banyak fitur aplikasi keren lainnya, silakan mendaftar menggunakan nomor telepon Anda.',
  phoneNumberRequiredContactListHeaderText:
    'Kontak adalah orang yang dapat ditambahkan sebagai peserta alarm.\n\nSilakan mendaftar menggunakan nomor telepon Anda untuk melihat kontak Anda yang menggunakan Galarm. Anda dapat menambahkan mereka sebagai peserta ke alarm Anda.',
  phoneNumberRequiredGroupAlarmHeaderText:
    'Alarm grup dapat digunakan untuk mengatur pengingat untuk sekelompok orang.\n\nUntuk menggunakan fitur ini dan banyak fitur aplikasi keren lainnya, silakan mendaftar menggunakan nomor telepon Anda.',
  phoneNumberRequiredParticipantsHeaderText:
    'Peserta bertindak sebagai cadangan untuk alarm ini. Mereka akan diingatkan jika Anda melewatkan alarm.\n\nUntuk menggunakan fitur ini dan banyak fitur aplikasi keren lainnya, silakan mendaftar menggunakan nomor telepon Anda.',
  youHaveNotRegistered:
    'Alarm Anda saat ini tidak dicadangkan.\n\nSilakan mendaftar menggunakan nomor telepon Anda. Setelah mendaftar, alarm Anda akan dicadangkan di cloud. Hal ini memungkinkan Anda untuk memulihkan alarm dan pengaturan lainnya ketika Anda mengganti ponsel.\n',
  registerNow: 'Daftar Sekarang',
  hoursAndMinutes: 'Jam dan menit',
  days: 'Hari',
  weeks: 'Minggu',
  months: 'Bulan',
  years: 'Tahun',
  daysOfWeek: 'Mingguan (hari tertentu)',
  monthly: 'Bulanan',
  yearly: 'Tahunan',
  everyDay: 'Setiap hari',
  selectDaysOfWeek: 'Pilih hari dalam seminggu',
  alarmRingMessageForSpecificDates:
    'Alarm ini hanya akan berdering pada bulan-bulan yang memiliki setidaknya {{dayOfMonth}} hari. Apakah Anda ingin melanjutkan?',
  alarmRingMessageForSpecificYear:
    'Alarm ini hanya akan berdering pada tahun kabisat. Apakah Anda ingin melanjutkan?',
  alarmRingMessageForSpecificDayOfWeekInMonth:
    'Alarm ini hanya akan berdering pada bulan-bulan yang memiliki {{weekNumberOfDayInMonth}} {{dayName}} s. Apakah Anda ingin melanjutkan? ',
  alarmRepetitionAdjustedBasedOnNewDate:
    'Pengulangan alarm telah diperbarui berdasarkan tanggal yang baru.',
  inviteFriendsToInstallGalarm:
    'Kontak adalah orang yang dapat ditambahkan sebagai peserta alarm Anda. Anda dapat mengundang kontak:\n\n1. Dari buku alamat Anda\n\n2. Dengan memberikan nama dan nomor mereka secara manual.\n\nSentuh Refresh di kiri atas untuk memuat ulang kontak Galarm setelah mereka mendaftar.',
  whyCreateNewGroup:
    'Grup adalah kumpulan kontak yang dapat ditambahkan sebagai peserta ke alarm Anda.\n  \nAnda belum membuat grup apa pun.',
  noNotifications: 'Anda tidak memiliki notifikasi baru.',
  createGroup: 'Membuat Grup',
  galarmRequiresAccessToContactsPermission:
    'Galarm meminta izin untuk mengakses kontak Anda. Informasi ini dikirim ke servernya untuk mengidentifikasi kontak Anda yang menggunakan Galarm. Pengguna ini dapat ditambahkan sebagai peserta alarm Anda.',
  galarmRequiresAccessToAudioFilesPermission:
    'Beberapa alarm menggunakan musik telepon sebagai nada dering. Berikan izin untuk mengakses file audio pada perangkat Anda untuk membunyikan alarm tersebut dengan nada dering yang dipilih.',
  galarmRequiresAccessToContactsSelectScreen:
    'Galarm tidak memiliki izin untuk mengakses kontak. Izinkan akses ke kontak jika Anda ingin menggunakan fitur ini.',
  allow: 'Izinkan',
  deny: 'Menyangkal',
  galarmRequiresAccessToContacts:
    'Kontak adalah orang yang dapat ditambahkan sebagai peserta alarm Anda. Anda dapat menambahkan kontak dengan:\n\n1. Mengimpor secara otomatis dari kontak telepon. Ketuk "Izinkan Akses" dan aktifkan "Kontak".\n\n2. Menambahkan nama dan nomor secara manual. Ketuk "Tambah Kontak" untuk memulai.',
  galarmRequiresAccessToAlarmAndRemindersPermission:
    'Galarm memerlukan izin "Alarm & pengingat" agar dapat membunyikan alarm tepat pada waktu yang ditentukan. Silakan aktifkan pada layar berikutnya.',
  accessToContactsPermissionRequiredAndroid:
    'Galarm tidak memiliki izin untuk mengakses kontak telepon Anda.\n\nKetika Anda mengizinkan akses, orang-orang dalam kontak telepon Anda yang menggunakan Galarm akan muncul sebagai kontak Galarm. Orang-orang tersebut dapat ditambahkan sebagai peserta alarm. Ketuk "Izinkan Akses", pilih "Izin" dan aktifkan "Kontak".\n\nAnda masih dapat menambahkan kontak secara manual ke Galarm.',
  accessToContactsPermissionRequiredIos:
    'Galarm tidak memiliki izin untuk mengakses kontak telepon Anda.\n\nKetika Anda mengizinkan akses, orang-orang dalam kontak telepon Anda yang menggunakan Galarm akan muncul sebagai kontak Galarm. Orang-orang tersebut dapat ditambahkan sebagai peserta alarm. Ketuk "Izinkan Akses" dan aktifkan "Kontak".\n\nAnda masih dapat menambahkan kontak secara manual ke Galarm.',
  giveAccess: 'Izinkan Akses',
  enterValidMobileNumber: 'Masukkan nomor telepon yang valid untuk mendaftar.',
  proceedAnyway: 'Lanjutkan saja',
  checkInvalidPhoneNumber:
    'Kami rasa Anda telah memasukkan nomor yang tidak valid sebagai {{mobileNumber}}.',
  checkInvalidCountryCode:
    'Kode negara yang ditentukan sebagai {{countryCode}} tidak valid. Jika ada lebih dari satu kode negara, edit bidang untuk mempertahankan kode negara yang berlaku.',
  editNumber: 'Edit nomor',
  editCountryCode: 'Edit Kode Negara',
  sendingCodeOnCall: 'Memanggil Anda dengan kode.',
  sendCodeOnCall: 'Hubungi Saya Dengan Kode',
  registrationLandingScreenRegisterText:
    'Ketuk Lanjutkan untuk mendaftar menggunakan nomor telepon Anda.\n\nDengan mendaftar:\n1) Anda akan dapat menambahkan peserta ke alarm dan mengobrol dengan mereka.\n2) Alarm Anda akan dicadangkan di cloud sehingga ketika Anda mengganti ponsel, alarm Anda akan muncul di ponsel yang baru.',
  continue: 'Lanjutkan',
  registerLater: 'Lewati Pendaftaran',
  cancelRegistration: 'Membatalkan Pendaftaran',
  welcome: 'Selamat datang.',
  haventReceivedCodeYet: {
    one: 'Belum menerima kode? Anda dapat meminta kode lagi dalam 1 detik.',
    other:
      'Belum menerima kode? Anda dapat meminta kode lagi di {{count}} detik.'
  },
  storagePermissionDenied:
    'Tidak dapat mengakses file musik karena izin penyimpanan ditolak.',
  havingTroubleRegisteringMessage:
    'Galarm tidak dapat memverifikasi kode setelah beberapa kali mencoba.\n\nApakah Anda ingin melewatkan pendaftaran dan mulai menggunakan aplikasi?',
  autoDetectVerificationCode:
    'Untuk menyederhanakan pendaftaran, Galarm dapat secara otomatis mendeteksi kode verifikasi jika Anda mengizinkan Galarm untuk melihat pesan SMS.',
  notNow: 'Tidak Sekarang',
  verifyNumberText:
    'Kami akan mengirimkan SMS berisi kode verifikasi di:\n\n{{formattedMobileNumber}}',
  verifyNumberCall:
    'Anda akan menerima panggilan telepon dengan kode verifikasi di:\n\n{{formattedMobileNumber}}',
  addToContacts: 'Tambahkan ke Kontak',
  todayString: 'Hari ini',
  tomorrowString: 'Besok',
  backups: 'Cadangan',
  acintyoTrademark:
    '© 2024 Acintyo, Inc. Semua hak dilindungi undang-undang.\nGalarm adalah merek dagang terdaftar dari Acintyo, Inc.\nDilindungi oleh Paten AS 10382613, 10867286.\nPaten lainnya masih dalam proses.',
  acintyoTrademarkWeb:
    '© 2024 Acintyo, Inc. Semua hak dilindungi undang-undang. Galarm adalah merek dagang terdaftar dari Acintyo, Inc.\nDilindungi oleh Paten AS 10382613, 10867286. Paten lainnya masih dalam proses.',
  acintyoTrademarkEnterprise:
    '© 2024 Acintyo, Inc.\nSemua hak cipta dilindungi undang-undang.',
  markAlarmDone: 'Tandai Alarm sebagai Selesai',
  skipAlarm: 'Lewati Alarm',
  confirmGroupAlarm: 'Konfirmasi Keikutsertaan',
  declineGroupAlarm: 'Menolak Partisipasi',
  snoozeAlarm: 'Tunda Alarm',
  deleteAlarmQuickAction: 'Hapus Alarm',
  disableAlarm: 'Nonaktifkan Alarm',
  confirmDisableAlarm: 'Nonaktifkan Alarm?',
  saveToCalendar: 'Ekspor ke Kalender',
  stillRingGroupAlarmAfterConfirmingInAdvance:
    'Anda mengonfirmasi alarm grup terlebih dahulu.  Apakah Anda masih menginginkan pengingat pada waktu alarm?',
  updateNow: 'Perbarui sekarang',
  nParticipants: {
    one: '1 peserta lainnya',
    other: '{{count}} peserta lainnya'
  },
  details: 'Detail',
  changeParanthesis: '(perubahan)',
  loadMoreHistory: 'Lihat lebih lanjut',
  alarmSummaryForHistory:
    'Tanggapan sebelumnya untuk {{alarmName}}.\nPengulangan {{alarmRepetitionString}}\n{{previousOccurrencesString}}',
  alarmSummaryForHistoryWeb: 'Tanggapan sebelumnya untuk {{alarmName}}',
  alarmSummaryForHistoryRepetitionWeb: 'Mengulang {{alarmRepetitionString}}',
  dontSeeCompleteHistory: 'Tidak melihat riwayat lengkap?',
  dontSeeCompleteHistoryExplanation:
    'Riwayat alarm direkam sejak alarm dibuat. Kami hanya menyimpan riwayat terbatas untuk setiap alarm, tergantung pada pengulangan alarm. Misalnya, jika alarm berulang setiap hari, kami menyimpan riwayat selama 30 hari.\n\nMengubah waktu atau pengulangan alarm akan menyetel ulang riwayat alarm.',
  noActionAvailableForAlarmOccurrence:
    'Tidak ada tindakan yang tersedia untuk kejadian ini.',
  enterEmailIdForFeedback: 'Digunakan untuk merespons dan berbagi pembaruan',
  noPreviousOccurrences: 'Belum ada riwayat alarm',
  nMissedOccurrencesOfAlarmInLastNDays: {
    zero: 'Belum ada riwayat alarm',
    one: '{{missedAlarmOccurrences}} ketinggalan dari yang terakhir {{count}}',
    other: '{{missedAlarmOccurrences}} ketinggalan dari yang terakhir {{count}}'
  },
  soundAndVibrationSummaryString: {
    zero: '{{volume}}% volume, {{ringtoneName}}',
    one: '{{volume}}% volume, {{ringtoneName}}, Umumkan Judul'
  },
  soundAndVibrationSummaryStringIos: {
    zero: '{{ringtoneName}}',
    one: 'Kritis, {{volume}}% volume, {{ringtoneName}}'
  },
  previousOccurrences: 'Riwayat Alarm',
  retrieving: 'Mengambil...',
  startTimeOfDay: 'Mulai dari',
  endTimeOfDay: 'Berakhir di',
  ringEveryHours: 'Cincin setiap',
  everyNHours: 'Setiap jam',
  everyMHoursNMinutes: 'Jam dan Menit',
  selectHourlyRepetition: 'Pilih pengulangan per jam',
  everyNHoursRepetitionString: {
    one: 'Setiap jam dari {{startTimeString}} ke {{endTimeString}} setiap {{daysString}}',
    other:
      'Setiap {{count}} jam dari {{startTimeString}} ke {{endTimeString}} setiap {{daysString}}'
  },
  everyNHoursAndMinutesRepetitionString:
    'Setiap {{hoursAndMinutes}} dari {{startTimeString}} ke {{endTimeString}} setiap {{daysString}}',
  everyMHoursAndNMinutesRepetitionString: 'Setiap {{repeatString}}',
  everyNHoursRepetitionStringForCalendar: {
    one: 'Setiap jam dari {{startTimeString}} ke {{endTimeString}}',
    other:
      'Setiap {{count}} jam dari {{startTimeString}} hingga {{endTimeString}}'
  },
  everyNHoursAndMinutesRepetitionStringForCalendar:
    'Setiap {{hoursAndMinutes}} dari {{startTimeString}} ke {{endTimeString}}',
  everyNHoursAndMinutesRepetitionStringForAlarmSummary:
    'Setiap {{hoursAndMinutes}} sampai {{endTimeString}}',
  everyNHoursRepetitionStringForAlarmSummary: {
    one: 'Setiap jam sampai {{endTimeString}}',
    other: 'Setiap {{count}} jam sampai {{endTimeString}}'
  },
  daysRepetitionOddString: {
    other: 'Semua tanggal bernomor ganjil',
    one: 'Tanggal bernomor ganjil ({{daysString}})'
  },
  daysRepetitionEvenString: {
    other: 'Semua tanggal bernomor genap',
    one: 'Tanggal bernomor genap ({{daysString}})'
  },
  oddNumberedRepetitionPreviewString: {
    one: 'Alarm akan berdering pada tanggal bernomor ganjil yang terdapat di {{daysString}} pada waktu yang ditentukan.',
    other:
      'Alarm akan berdering pada semua tanggal bernomor ganjil pada waktu yang ditentukan.'
  },
  evenNumberedRepetitionPreviewString: {
    one: 'Alarm akan berdering pada tanggal bernomor genap yang jatuh pada {{daysString}} pada waktu yang ditentukan.',
    other:
      'Alarm akan berdering pada semua tanggal bernomor genap pada waktu yang ditentukan.'
  },
  hourlyRepetitionPreviewString: {
    one: 'Alarm ini akan berdering sekali setiap {{daysString}} at:',
    other:
      'Alarm ini akan berdering {{count}} kali setiap {{daysString}} pukul:'
  },
  daysRepetitionPreviewString: {
    one: 'Alarm ini akan berdering setiap hari.',
    other: 'Alarm ini akan berdering setiap {{count}} hari.'
  },
  weeksRepetitionPreviewString: {
    one: 'Alarm ini akan berdering setiap minggu.',
    other: 'Alarm ini akan berdering setiap {{count}} minggu.'
  },
  everyNDays: { one: 'Setiap hari', other: 'Setiap {{count}} hari' },
  everyNWeeks: { one: 'Setiap minggu', other: 'Setiap {{count}} minggu' },
  everyNMonths: { one: 'Setiap bulan', other: 'Setiap {{count}} bulan' },
  everyNYears: { one: 'Setiap tahun', other: 'Setiap {{count}} tahun' },
  cantSetEndTime: 'Tidak dapat mengatur waktu akhir',
  endTimeShouldBeGreater: 'Waktu akhir harus lebih besar dari waktu mulai.',
  cantSetStartTime: 'Tidak dapat mengatur waktu mulai',
  startTimeShouldBeLess: 'Waktu mulai harus kurang dari waktu akhir.',
  everyDaySmall: 'hari',
  everyGivenDay: '{{day}}',
  everyGivenDays: '{{days}}',
  adjustingAlarmsAfterTimezoneChange:
    'Kami mendeteksi bahwa Anda telah pindah ke zona waktu yang berbeda. Kami sedang menyesuaikan alarm Anda.',
  ringOnDays: 'Berdering',
  yourAlarmsMayNotRing: 'Alarm Anda mungkin tidak berdering',
  yourAlarmsMayNotRingTapToFix:
    'Alarm Anda mungkin tidak berdering. Ketuk untuk memperbaiki.',
  xiaomiSetupInstructions:
    'Ikuti petunjuk berikut ini agar alarm Anda selalu berbunyi:\n\n - Ketuk "Aktifkan Mulai Otomatis" dan izinkan "Galarm" agar alarm Anda selalu berdering. \n\nUntuk mempelajari lebih lanjut, kunjungi www.galarmapp.com/setup-mi',
  oppoSetupInstructions:
    'Ikuti petunjuk berikut ini agar alarm Anda selalu berbunyi:\n\n - Ketuk "Aktifkan Mulai Otomatis" dan aktifkan "Izinkan Mulai Otomatis".\n\n - Ketuk tombol "Aplikasi Terbaru" pada ponsel Anda, geser ikon aplikasi Galarm ke bawah dan ketuk "Kunci".  \n\nUntuk mempelajari lebih lanjut, kunjungi www.galarmapp.com/setup-oppo',
  oppo8AndLowerSetupInstructions:
    'Ikuti petunjuk berikut ini agar alarm Anda selalu berbunyi:\n\n - Ketuk "Aktifkan Mulai Otomatis" dan aktifkan "Izinkan Mulai Otomatis".\n\n - Ketuk tombol "Aplikasi Terbaru" pada ponsel Anda, geser ikon aplikasi Galarm ke bawah dan ketuk "Kunci".\n\nUntuk mempelajari lebih lanjut, kunjungi www.galarmapp.com/setup-oppo-8-and-lower',
  vivo9And10SetupInstructions:
    'Ikuti petunjuk berikut ini agar alarm Anda selalu berbunyi:\n\n - Ketuk "Aktifkan Mulai Otomatis" dan izinkan "Galarm".\n\n - Ketuk "Penggunaan Baterai", pilih "Konsumsi daya latar belakang yang tinggi" dan izinkan "Galarm".\n\nUntuk mempelajari lebih lanjut, kunjungi www.galarmapp.com/setup-vivo',
  vivo11SetupInstructions:
    'Ikuti petunjuk berikut ini agar alarm Anda selalu berbunyi:\n\n- Ketuk "Aktifkan Mulai Otomatis" dan izinkan "Galarm".\n\n- Ketuk "Penggunaan Baterai", pilih "Konsumsi daya latar belakang yang tinggi" dan izinkan "Galarm".\n\nUntuk mempelajari lebih lanjut, kunjungi www.galarmapp.com/setup-vivo-11',
  vivoSetupInstructions:
    'Ikuti petunjuk ini agar alarm Anda selalu berdering:\n\n- Buka "Pengaturan Telepon > Aplikasi > Akses aplikasi khusus > Mulai otomatis" dan aktifkan mulai otomatis untuk Galarm.\n\n- Buka "Pengaturan Telepon > Baterai > Manajemen konsumsi daya latar belakang > Galarm" dan pilih "Jangan batasi penggunaan daya latar belakang".\n\nUntuk mempelajari lebih lanjut, kunjungi www.galarmapp.com/setup-vivo-12-and-above',
  vivo8AndLowerSetupInstructions:
    'Ikuti petunjuk ini agar alarm Anda selalu berdering:\n\n - Ketuk "Aktifkan Mulai Otomatis" dan izinkan "Galarm".\n\n - Ketuk "Penggunaan Baterai", pilih "Konsumsi daya latar belakang yang tinggi" dan izinkan "Galarm".\n\nUntuk mempelajari lebih lanjut, kunjungi www.galarmapp.com/setup-vivo-8-and-lower',
  huawei7AndLowerAdditionalSetupInstructions:
    'Ikuti petunjuk berikut ini agar alarm Anda selalu berbunyi:\n\n - Ketuk "Aplikasi yang Dilindungi" dan izinkan "Galarm".\n\nUntuk mempelajari lebih lanjut, kunjungi www.galarmapp.com/setup-huawei-7-and-lower',
  huaweiAdditionalSetupInstructions:
    'Ikuti petunjuk berikut ini agar alarm Anda selalu berbunyi:\n\n - Ketuk "Kelola Peluncuran Aplikasi" dan matikan "Galarm" ke "Kelola secara manual". Pada prompt berikutnya, pastikan "Luncurkan Otomatis" dan "Jalankan di latar belakang" diaktifkan.\n\nUntuk mempelajari lebih lanjut, kunjungi www.galarmapp.com/setup-huawei',
  meizuSetupInstructions:
    'Ikuti petunjuk pada tautan di bawah ini agar alarm Anda selalu berdering: https://dontkillmyapp.com/meizu',
  asusSetupInstructions:
    'Ikuti petunjuk berikut ini agar alarm Anda selalu berbunyi:\n\n - Ketuk "Aktifkan Mulai Otomatis" dan klik "Pengelola mulai otomatis". Aktifkan "Galarm" di "Pengelola mulai otomatis".\n\nUntuk mempelajari lebih lanjut, kunjungi www.galarmapp.com/setup-asus',
  nokia8AndLowerAdditionalSetupInstructions:
    'Ikuti petunjuk berikut ini agar alarm Anda selalu berbunyi:\n\n - Ketuk "Aktifkan Mulai Otomatis" dan izinkan "Galarm".\n\nUntuk mempelajari lebih lanjut, kunjungi www.galarmapp.com/setup-nokia-8-and-lower',
  nokiaAdditionalSetupInstructions:
    'Ikuti petunjuk berikut ini agar alarm Anda selalu berbunyi:\n\n - Ketuk "Aplikasi yang Dibatasi" dan nonaktifkan "Galarm".\n\nUntuk mempelajari lebih lanjut, kunjungi www.galarmapp.com/setup-nokia',
  lenovoSetupInstructions:
    'Ikuti petunjuk berikut ini agar alarm Anda selalu berbunyi:\n\n - Ketuk tombol "Aplikasi Terbaru" pada ponsel Anda, cari aplikasi Galarm dan ketuk ikon "Kunci" di sudut kanan atas.\n  \nUntuk mempelajari lebih lanjut, kunjungi https://dontkillmyapp.com/lenovo',
  samsungAdditionalSetupInstructions:
    'Ikuti petunjuk berikut ini agar alarm Anda selalu berbunyi:\n\n - Ketuk "Aplikasi yang Tidak Dipantau" dan izinkan "Galarm".\n\nUntuk mempelajari lebih lanjut, kunjungi www.galarmapp.com/setup-samsung',
  tecnoSetupInstructions:
    'Ikuti petunjuk pada tautan di bawah ini agar alarm Anda selalu berdering: https://www.galarmapp.com/setup-tecno',
  infinixSetupInstructions:
    'Ikuti petunjuk pada tautan di bawah ini agar alarm Anda selalu berdering: https://www.galarmapp.com/setup-infinix',
  samsungAdditionalSetupInstructionsAndroid11AndAbove:
    'Secara default, Samsung menampilkan notifikasi "Singkat" yang akan menghilang dengan cepat. Ikuti petunjuk ini untuk melihat notifikasi alarm "Detail".\n\n - Buka pengaturan telepon dan pilih "Pemberitahuan"\n - Pilih gaya notifikasi "Detail"\n  \nUntuk mempelajari lebih lanjut, kunjungi www.galarmapp.com/setup-samsung-android11-and-above',
  iosSetupInstructions:
    'Secara default, gaya spanduk iOS untuk notifikasi diatur ke "Sementara" yang menyebabkan notifikasi menghilang dengan cepat. Ikuti petunjuk ini untuk mengatur gaya banner ke "Persisten".\n\n- Buka pengaturan ponsel dan gulir ke Galarm\n- Pilih Pemberitahuan dan atur Gaya Spanduk ke Tetap\n\nUntuk mempelajari lebih lanjut, kunjungi www.galarmapp.com/setup-ios',
  iosSetupInstructions15AndBelow:
    'Secara default, gaya spanduk iOS untuk notifikasi diatur ke "Sementara" yang menyebabkan notifikasi menghilang dengan cepat. Ikuti petunjuk ini untuk mengatur gaya banner ke "Persisten".\n\n- Buka pengaturan ponsel dan gulir ke Galarm\n- Pilih Pemberitahuan dan atur Gaya Spanduk ke Tetap\n\nUntuk mempelajari lebih lanjut, kunjungi www.galarmapp.com/setup-ios-15-below',
  setNotificationsStyle: 'Mengatur Gaya Pemberitahuan',
  setNotificationsStyleToDetailed: 'Mengatur Gaya Pemberitahuan ke "Detail"',
  setBannerStyle: 'Atur Gaya Spanduk',
  setBannerStyleToPersistent: 'Atur Gaya Banner ke "Persisten"',
  selectAtleastOneDay: 'Pilih hari di mana alarm ini akan berdering.',
  selectAtLeastOneHourOrMinute: 'Baik jam atau menit harus bernilai bukan nol',
  hourlyAlarmDefaultStartTimeSetAlert:
    'Waktu mulai alarm telah ditetapkan ke jam 8 pagi.',
  batteryUsage: 'Penggunaan Baterai',
  dontShowAgain: 'Jangan Tunjukkan Lagi',
  alarmWillRingEveryHourTillSeen:
    'Alarm akan ditunda setiap jam sampai Anda mengambil tindakan.',
  unknown: 'Tidak diketahui',
  alarmMarkedDone: '{{name}} telah menandai alarm "Selesai".',
  alarmMarkedSkipped: '{{name}} telah melewatkan alarm.',
  alarmIsMarkedDone: '{{alarmName}} ditandai selesai.',
  alarmIsSkipped: '{{alarmName}} dilewati.',
  alarmIsConfirmed: '{{alarmName}} dikonfirmasi.',
  alarmIsDeclined: '{{alarmName}} ditolak.',
  alarmIsSnoozed: '{{alarmName}} ditunda.',
  alarmIsAccepted: '{{alarmName}} diterima.',
  alarmIsRejected: '{{alarmName}} ditolak.',
  newContactInfo:
    'Mohon berikan nama dan nomor telepon kontak.\n\nHanya pengguna yang terdaftar yang dapat ditambahkan sebagai kontak. Jika pengguna bukan pengguna Galarm yang terdaftar, maka Anda dapat mengundang mereka menggunakan "Pengaturan > Bagikan Aplikasi Ini". Anda dapat menambahkan mereka sebagai kontak setelah mereka mendaftar menggunakan nomor telepon mereka. ',
  cantEditContactMobileNumber:
    'Anda tidak dapat mengedit nomor telepon. Hapus kontak dan tambahkan lagi jika Anda ingin mengubah nomor.',
  phoneContactsNotSyncedPermissionDenied:
    'Galarm tidak memiliki izin untuk mengakses kontak telepon. Kontak telepon tidak akan disinkronkan.',
  inviteContactMessage:
    'Halo {{contactName}}, saya ingin memasukkan Anda ke dalam alarm dan pengingat saya menggunakan Galarm. Unduh aplikasinya dari {{downloadLink}}. Silakan mendaftar menggunakan nomor ini dan mulai berbagi alarm.',
  noRepeat: 'Tidak mengulangi',
  deepLinkNoLongerValidTitle: 'Ups!',
  deepLinkNoLongerValidMessage:
    'Alarm tidak dibuat. Ini mungkin merupakan tautan lama atau tautan yang tidak valid.',
  inviteContact: 'Mengundang Kontak',
  fromPhoneBook: 'Dari Buku Alamat',
  addFromPhoneBook: 'Mengundang dari Buku Alamat',
  manually: 'Secara manual',
  addManualContact: 'Undang Secara Manual',
  noOtherContactsFound: 'Semua kontak di buku alamat Anda menggunakan Galarm.',
  cantAddPhoneContactPermissionDenied:
    'Galarm tidak memiliki izin untuk mengakses kontak telepon. Izinkan izin untuk mengakses kontak.',
  alarmWillRingWithRandomRingtone: 'Nada dering acak akan dipilih untuk alarm.',
  alarmWillRingWithRandomRingtoneGlobal:
    'Nada dering acak akan dipilih untuk alarm.',
  createdAlarmFromDeepLinkTitle: 'Anda sudah siap!',
  createdAlarmFromDeepLinkMessage:
    'Anda akan diingatkan tentang "{{alarmName}}" di {{alarmTime}} pada {{alarmDate}}',
  createdRepeatingAlarmFromDeepLinkMessage: {
    zero: 'Anda akan diingatkan tentang "{{alarmName}}" {{repetitionString}}',
    one: 'Anda akan diingatkan tentang "{{alarmName}}" {{repetitionString}} di {{alarmTime}} mulai {{alarmDate}}'
  },
  deleteCategoryAlertTitle: 'Menghapus Kategori?',
  deleteCategoryAlertMessage: {
    zero: '',
    one: '1 alarm termasuk dalam kategori ini. Menghapus kategori ini akan mengatur ulang kategori pada alarm tersebut.',
    other:
      '{{count}} alarm termasuk dalam kategori ini. Menghapus kategori ini akan mengatur ulang kategori pada alarm tersebut.'
  },
  updateReadyToInstallAlertTitle: 'Instal Pembaruan?',
  updateReadyToInstallAlertMessage:
    'Pembaruan siap untuk diinstal. Apakah Anda ingin menginstal pembaruan sekarang? ',
  hideCategoryAlertTitle: 'Sembunyikan Kategori?',
  hideCategoryAlertMessage: {
    zero: 'Anda tidak akan dapat menggunakan kategori ini di masa mendatang.',
    one: '1 alarm termasuk dalam kategori ini. Menyembunyikan kategori ini akan mengatur ulang kategori pada alarm tersebut.\n  \nAnda tidak akan dapat menggunakan kategori ini di masa mendatang.',
    other:
      '{{count}} alarm termasuk dalam kategori ini. Menyembunyikan kategori ini akan mengatur ulang kategori pada alarm tersebut.\n  \nAnda tidak akan dapat menggunakan kategori ini di masa mendatang.'
  },
  alarmCategoryDeleteAllAlarmsPrompt: {
    one: '1 alarm termasuk dalam kategori ini. Apakah Anda ingin menghapusnya?\n\nPerhatikan bahwa alarm peserta tidak akan dihapus.',
    other:
      '{{count}} alarm termasuk dalam kategori ini. Apakah Anda ingin menghapusnya?\n\nPerhatikan bahwa alarm peserta tidak akan dihapus.'
  },
  enterNameForGroup: 'Masukkan nama untuk grup',
  enterMobileNumber: 'Masukkan nomor telepon Anda',
  enterNotesForAlarm: 'Memasukkan catatan untuk alarm',
  enterUserName: 'Masukkan nama Anda',
  enterEmailId: 'Opsional',
  enterFeedback: 'Bagaimana kita bisa meningkatkannya?',
  describeProblem: 'Jelaskan masalah Anda',
  invited: 'Diundang',
  resend: 'Kirim ulang',
  addContact: 'Tambahkan Kontak',
  inviteAgain: 'Undang Lagi',
  contactNotRegistered:
    '{{contactName}} belum menginstal Galarm. Anda akan dapat menambahkan mereka ke alarm setelah mereka menginstal dan mendaftar menggunakan nomor telepon mereka.',
  registration: 'Pendaftaran',
  verify: 'Verifikasi',
  profile: 'Profil',
  profileImage: 'Gambar Profil',
  addProfileImage: 'Menambahkan gambar profil',
  groupDetails: 'Detail Grup',
  newGroup: 'Grup Baru',
  uploadImage: 'Ubah Gambar',
  removeImage: 'Menghapus gambar',
  selectParticipants: 'Pilih Peserta',
  selectMembers: 'Pilih Anggota',
  selectTimezone: 'Pilih zona waktu',
  searchForATimezone: 'Mencari zona waktu',
  timezone: 'Zona Waktu',
  deviceTimezone: 'Zona waktu perangkat',
  specifyTimezoneForAlarm: 'Menentukan zona waktu untuk alarm',
  editName: 'Edit Nama',
  groupImage: 'Gambar Grup',
  addGroupImage: 'Menambahkan gambar grup',
  selectAlarmType: 'Membuat alarm untuk',
  contactDetails: 'Detail Kontak',
  commonAlarms: 'Alarm',
  commonGroups: 'Grup',
  groupAlarmsTitle: 'Alarm',
  selectRingtone: 'Pilih Nada Dering',
  selectMusic: 'Pilih Musik',
  select: 'Pilih',
  personalAlarm: 'Alarm Pribadi',
  groupAlarm: 'Alarm Grup',
  newPersonalAlarm: 'Alarm Pribadi Baru',
  newGroupAlarm: 'Alarm Grup Baru',
  editPersonalAlarm: 'Edit Alarm Pribadi',
  editGroupAlarm: 'Edit Alarm Grup',
  rearrangeParticipants: 'Mengatur Ulang Peserta',
  alarmConversation: 'Obrolan Alarm',
  timezoneMismatchDetected:
    'Sesuatu memberi tahu kami bahwa Anda berada di zona waktu yang berbeda dari terakhir kali Anda menggunakan Galarm.\n\nSilakan mulai ulang Galarm agar kami dapat beradaptasi dengan zona waktu yang baru.',
  deviceRestartRequired: 'Perlu Memulai Ulang Aplikasi',
  disabledAlarm: 'Alarm dinonaktifkan {{name}}',
  newContact: 'Kontak Baru',
  contactName: 'Nama Kontak',
  contactMobileNumber: 'Nomor Telepon Kontak',
  enterNameForContact: 'Masukkan nama kontak',
  pickCountryForContact: 'Negara Kontak',
  enterMobileNumberForContact: 'Masukkan nomor telepon kontak',
  specifyNameForContact: 'Nama kontak diperlukan',
  specifyValidPhoneNumberForContact: 'Diperlukan nomor telepon yang valid',
  addingContact: 'Menambahkan kontak',
  deletingContact: 'Menghapus kontak',
  editingContact: 'Kontak pengeditan',
  pending: 'Tertunda',
  deleteContact: 'Menghapus Kontak',
  galarmContact: 'Ditambahkan secara manual',
  phoneContact: 'Ditambahkan dari kontak telepon',
  confirmDeleteContactTitle: 'Hapus {{name}}?',
  confirmDeleteContact: 'Anda tidak akan dapat menambahkannya ke alarm Anda.',
  contactAdded:
    'Bagus! Sekarang Anda dapat menambahkan {{name}} sebagai peserta pada alarm Anda.',
  contactInvited:
    'Sebuah pesan teks telah dikirim ke {{name}} yang mengundang mereka untuk menggunakan Galarm.',
  changeSnoozeDuration: 'Ubah Durasi',
  durationOfEvent: 'Durasi acara',
  upgradeToPremium: 'Tingkatkan ke Premium',
  premium: 'Premium',
  alarmLimitReached: 'Batas Alarm Tercapai',
  unableToConnectToGooglePlay:
    'Tidak dapat terhubung ke Google Play. Pastikan Anda telah masuk ke akun Google Anda.',
  cantLoadVideo: 'Tidak Dapat Memuat Video',
  cantUpgradeToPremium: 'Tidak dapat meningkatkan ke Premium',
  unableToConnectToIapAndroid:
    'Tidak dapat terhubung ke Play Store. Buka aplikasi Play Store dan pastikan Anda sudah masuk.',
  unableToConnectToIapIos:
    'Tidak dapat tersambung ke App Store. Buka aplikasi App Store dan pastikan Anda sudah masuk.',
  premiumFeaturesIos:
    'Sebagai pengguna premium, Anda mendapatkan peningkatan batas {{maxLimit}} alarm simultan.\n\nPembelian Anda juga membantu kami mengembangkan lebih banyak fitur.',
  premiumFeaturesAndroid:
    'Buat alarm tak terbatas dengan meningkatkan ke premium.\n\nPembelian Anda juga membantu kami mengembangkan lebih banyak fitur.',
  upgrade: 'Tingkatkan',
  rvInternalError:
    'Kami tidak dapat memuat video karena kesalahan internal. Namun, kami telah meningkatkan batas alarm Anda sebesar 1.',
  rvNetworkError:
    'Pastikan Anda memiliki koneksi internet yang baik dan coba lagi.',
  rvNotFoundError:
    'Kami tidak dapat menemukan video yang sesuai saat ini. Namun, kami telah meningkatkan batas alarm Anda sebesar 1.',
  dontRemindMe: 'Jangan ingatkan aku.',
  sameTimeAsBuddy: 'Waktu yang sama dengan teman',
  noAlertForBuddyAlarm:
    'Anda memilih untuk tidak diperingatkan untuk alarm ini.',
  noAlertRecipientIntervalDescription: 'Anda tidak akan diberi tahu.',
  chooseHours: 'Pilih jam',
  chooseMins: 'Pilih menit',
  changeHours: 'Ubah jam',
  moreOptions: 'Opsi lainnya...',
  set: 'Set',
  chooseNumberOfDays: 'Masukkan jumlah hari',
  chooseNumberOfWeeks: 'Masukkan jumlah minggu',
  numberOfDaysShouldBeAtLeastOne: 'Jumlah hari harus minimal 1',
  numberOfWeeksShouldBeAtLeastOne: 'Jumlah minggu harus minimal 1',
  numberOfMonthsShouldBeAtLeastOne: 'Jumlah bulan harus minimal 1',
  numberOfYearsShouldBeAtLeastOne: 'Jumlah tahun harus minimal 1',
  frequency: 'Frekuensi',
  configuration: 'Konfigurasi',
  endDate: 'Tanggal Akhir Alarm',
  noEndDate: 'Tidak Ada Tanggal Akhir',
  alarmEndDateSetOnAlarm: 'Tanggal akhir alarm diatur ke {{endDateString}}.',
  noAlarmDateSetForAlarm:
    'Tidak ada tanggal akhir yang ditetapkan untuk alarm ini.',
  noOccurrenceForThisAlarmAsPerEndDate:
    'Tanggal akhir tidak memungkinkan terjadinya alarm.',
  alarmDateShouldBeBiggerThanAlarmStartDate:
    'Tanggal akhir alarm harus setelah tanggal mulai alarm.',
  aWeek: 'Seminggu',
  aMonth: 'Sebulan',
  allInactiveAlarms: 'Semua Alarm Tidak Aktif',
  chooseAlarmEndDate: 'Memilih Tanggal Akhir Alarm',
  next: 'Berikutnya',
  next7Days: '7 Hari dari Hari Ini',
  next30Days: '30 Hari dari Hari Ini',
  next12Months: '12 Bulan dari Sekarang',
  next10Years: '10 Tahun dari Sekarang',
  chooseDate: 'Pilih Tanggal...',
  learnMore: 'Pelajari lebih lanjut',
  repeatEvery: 'Mengulangi setiap',
  repeatEveryDays: 'hari (s)',
  repeatEveryWeeks: 'minggu (s)',
  repeatEveryMonths: 'bulan',
  repeatEveryYears: 'tahun (tahun)',
  autoSnooze: 'Tunda Alarm Secara Otomatis',
  autoSnoozePerAlarm: 'Tunda Alarm Secara Otomatis',
  unableToUploadGroupAvatar:
    'Tidak dapat mengunggah gambar grup sekarang. Silakan coba lagi nanti',
  addNewMembersToExistingAlarms:
    'Anda memiliki alarm berikut dengan grup ini:\n\n{{alarmNames}}\n    \nApakah Anda ingin menambahkan anggota baru ke alarm ini?',
  removeGroupMemberAlert:
    'Menghapus anggota akan berdampak pada alarm berikut ini:\n\n{{alarmNames}}\n\nApakah Anda masih ingin menghapus anggota tersebut?',
  leaveGroupAlert:
    'Meninggalkan grup akan berdampak pada alarm berikut ini:\n\n{{alarmNames}}\n\nApakah Anda masih ingin keluar dari grup?',
  updateAppAfterFeedback:
    'Kami menyadari bahwa Anda menjalankan versi aplikasi yang lebih lama. Harap perbarui untuk pengalaman yang lebih baik.',
  changesAreLost:
    'Anda memiliki perubahan yang belum disimpan.\n\nApakah Anda ingin menyimpan sebelum pergi?',
  recipientAlarmIntervalGreaterThanRepetitionInterval:
    'Interval "Ingatkan saya" harus kurang dari interval pengulangan.',
  cascadingAlarmIntervalGreaterThanRepetitionInterval:
    'Interval "waktu antara peringatan peserta" harus kurang dari interval pengulangan.',
  subscriptionCanceled:
    'Langganan premium Anda telah dibatalkan. Fitur premium tidak akan tersedia hingga Anda memperbarui langganan.',
  unableToRenewSubscription:
    'Ada masalah dalam memperbarui langganan premium Anda. Pastikan informasi pembayaran Anda sudah diperbarui.',
  notice: 'Pemberitahuan',
  ringerSettingsWillApplyToNewAlarms:
    'Perubahan yang Anda lakukan pada pengaturan suara dan getaran hanya akan berlaku untuk alarm baru.\n\nUntuk alarm yang sudah ada, Anda dapat mengedit alarm untuk mengubah pengaturan suara dan getaran.',
  renew: 'Memperbaharui',
  galarmPremium: 'Galarm Premium',
  payMonthly: 'Bayar bulanan di {{price}} per bulan',
  payYearly: 'Bayar tahunan di {{price}} per tahun',
  free: 'Gratis',
  unlimited: 'Tidak terbatas',
  personalAlarms: 'Alarm Pribadi',
  groupAlarms: 'Alarm Grup',
  buddyAlarms: 'Alarm Teman',
  alarmRepetitions: 'Pengulangan',
  ringtones: 'Nada dering',
  alarmHistory: 'Riwayat Alarm',
  base: 'Dasar',
  advanced: 'Lanjutan',
  complete: 'Penuh',
  alarmHistoryFree: 'Terbatas',
  phoneMusicAsRingtone: 'Musik Telepon Sebagai Nada Dering',
  startFreeTrial: 'Mulai Uji Coba Gratis 14 hari',
  chargedAtEndOfFreeTrial:
    'Anda akan dikenakan biaya setelah masa uji coba gratis 14 hari berakhir, kecuali jika Anda membatalkan langganan.',
  monthlySubscription: 'Bulanan',
  yearlySubscription: 'Tahunan',
  premiumRingtoneRequiresUpgrade:
    'Nada dering ini memerlukan langganan Premium.',
  defaultPremiumFeaturesDescription:
    'Tingkatkan ke Premium untuk mengaktifkan fitur-fitur keren ini.',
  groupAlarmsPremiumFeaturesDescription:
    'Anda memerlukan langganan premium untuk membuat lebih dari 1 alarm grup.',
  buddyAlarmsPremiumFeaturesDescription:
    'Anda memerlukan langganan premium untuk membuat lebih dari 1 alarm untuk orang lain ("teman").',
  shareableAlarmLinksPremiumFeaturesDescription:
    'Anda memerlukan langganan premium untuk membuat lebih banyak tautan alarm yang dapat dibagikan.',
  viewPremiumFeaturesDescription:
    'Terima kasih telah menjadi pengguna premium. Anda dapat memanfaatkan fitur-fitur keren ini sebagai pelanggan premium.',
  accessToPremiumFeatures:
    'Anda akan mendapatkan akses ke fitur-fitur ini sebagai bagian dari langganan:',
  alarmCategoryPremiumFeaturesDescription:
    'Anda memerlukan langganan premium untuk membuat kategori baru.',
  galarmWebPremiumFeaturesDescription:
    'Anda memerlukan langganan premium untuk menggunakan Galarm di peramban web.',
  instantAlarmPremiumFeaturesDescription:
    'Anda memerlukan langganan premium untuk membuat lebih banyak alarm instan.',
  personalAlarmsFeatureDescription:
    'Gunakan alarm pribadi untuk bangun tidur, minum obat, atau tugas lain untuk diri Anda sendiri. Anda dapat menambahkan peserta yang akan diberi tahu jika Anda melewatkan alarm sehingga mereka dapat mengingatkan Anda. Para peserta juga akan diberi tahu ketika Anda menandai alarm selesai.',
  groupAlarmsFeatureDescription:
    'Gunakan alarm grup untuk aktivitas kelompok seperti tamasya, pesta, atau acara lainnya. Alarm akan berbunyi pada waktu yang sama untuk semua peserta dan mereka dapat mengonfirmasi keikutsertaan mereka.',
  buddyAlarmsFeatureDescription:
    'Membuat alarm untuk orang lain ("teman") untuk mengingatkan mereka akan hal-hal yang perlu dilakukan. Anda akan diberi tahu untuk mengingatkan buddy jika mereka melewatkan alarm. Anda juga akan diberi tahu setelah teman menandai alarm selesai.',
  unlimitedAlarmsFeatureDescription:
    'Buat lebih banyak grup, teman, dan alarm instan.',
  alarmRepetitionsFeatureDescription:
    'Buat alarm dengan pengulangan tingkat lanjut seperti setiap 30 menit, setiap 2 jam 45 menit, dua hari sekali, dua mingguan, dan banyak lagi.',
  ringtonesFeatureDescription:
    'Pilih dari berbagai macam nada dering premium untuk alarm Anda. Kami terus menambahkan lebih banyak nada dering ke penawaran premium.',
  phoneMusicAsRingtoneFeatureDescription:
    'Pilih lagu/musik apa pun di ponsel Anda sebagai nada dering alarm.',
  shareableAlarmLinksFeatureDescription:
    'Anda dapat berbagi alarm dengan orang lain menggunakan tautan.\n\nMisalnya, guru dapat membagikan tautan alarm kelas kepada siswa menggunakan email, WhatsApp, atau aplikasi perpesanan apa pun. Ketika siswa mengetuk tautan tersebut, salinan alarm kelas akan dibuat di ponsel mereka. Semua siswa akan diberi tahu pada waktu yang ditentukan untuk bergabung dengan kelas.',
  instantAlarmFeatureDescription:
    'Seketika memberi tahu sekelompok orang dengan mengklik tombol.\n\nGunakan alarm ini untuk memperingatkan mereka tentang latihan darurat, dimulainya permainan, tugas/pertemuan penting, dan peristiwa lain yang memerlukan perhatian segera.',
  alarmHistoryFeatureDescription:
    'Melihat semua tanggapan sebelumnya untuk setiap alarm yang berulang. Hal ini memungkinkan Anda untuk dengan mudah mengetahui kapan Anda melewatkan kelas olahraga, ibu Anda melewatkan pengobatan, dll.',
  announceAlarmTitleFeatureDescription:
    'Mengumumkan judul alarm saat alarm berdering.',
  exportToCalendarPremiumFeaturesDescription:
    'Anda memerlukan langganan premium untuk mengekspor lebih banyak alarm ke kalender.',
  exportToCalendarFeatureDescription:
    'Anda dapat mengekspor alarm ke kalender Anda. Hal ini memungkinkan Anda melihat alarm dengan mudah di kalender perangkat Anda dan mencerminkan ketersediaan Anda dengan lebih baik.',
  galarmWebFeatureDescription:
    'Gunakan Galarm di browser web untuk membuat, mengedit, dan menghapus alarm.',
  preReminderFeatureDescription:
    'Mengatur pengingat awal untuk mendapatkan peringatan tentang alarm yang akan datang. Anda dapat mengonfigurasi durasi relatif untuk mendapatkan peringatan sebelum waktu alarm yang sebenarnya, misalnya 30 menit sebelumnya, 1 hari sebelumnya, dll.',
  calendarViewFeatureDescription:
    'Melihat alarm di kalender untuk melihat alarm Anda dengan mudah untuk setiap hari.',
  updatePhoneNumberFeatureDescription:
    'Anda dapat mengubah nomor telepon yang digunakan untuk akun ini. Semua alarm, kontak, grup, dan pengaturan lainnya akan dimigrasikan ke nomor telepon yang baru.',
  alarmPhotoFeatureDescription:
    'Menambahkan foto ke alarm untuk petunjuk visual yang cepat, seperti foto: obat, instruksi untuk tugas, item, dll. Foto akan ditampilkan ketika alarm berdering.',
  taskListFeatureDescription:
    'Buat daftar tugas untuk menyatukan tugas-tugas terkait.',
  calendarViewPremiumFeature: 'Tampilan Kalender',
  updatePhoneNumberPremiumFeature: 'Ubah Nomor Telepon',
  alarmNotFound: 'Alarm Tidak Ditemukan',
  alarmNotFoundMessage:
    'Alarm ini mungkin baru saja dihapus. Ketuk "Hapus" untuk menonaktifkan pemberitahuan lebih lanjut untuk alarm ini.',
  alarmExistsWithDeepLinkTitle: 'Alarm Duplikat',
  alarmExistsWithDeepLinkMessage:
    'Alarm yang dibuat menggunakan tautan ini sudah ada di ponsel Anda.',
  alarmDoesntHaveNextDate:
    'Alarm tidak memiliki tanggal berikutnya yang valid.',
  sharePersonalAlarmDeepLinkDescription:
    'Ketuk tautan ini untuk membuat alarm untuk acara ini di {{dateString}}.',
  joinGroupAlarmDeepLinkDescription:
    'Ketuk tautan ini untuk bergabung dengan acara grup ini di {{dateString}}',
  subscribedAlarmEdited:
    '{{alarmCreatorName}} telah memperbarui {{alarmName}}. Apakah Anda ingin memperbarui alarm Anda?',
  subscribedAlarmDeleted:
    '{{alarmCreatorName}} telah menghapus {{alarmName}}. Apakah Anda ingin menghapus alarm Anda?',
  responseReceivedForTicket:
    'Kami telah menanggapi tiket Anda #{{ticketId}} di: {{email}}. Jika tidak ada di Kotak Masuk Anda, silakan periksa folder spam Anda untuk mencari email dari support@galarm.zendesk.com.',
  newTicketReceived:
    'Terima kasih telah menulis kepada kami. Kami telah membuat tiket #{{ticketId}} untuk melacak permintaan Anda.\n\nKami akan merespons di alamat email yang Anda berikan: {{email}}. Jika ini tidak benar, silakan kirimkan umpan balik Anda lagi dengan alamat email yang benar.\n\nHarap tambahkan juga support@galarm.zendesk.com ke buku alamat email Anda untuk memastikan pengiriman tanggapan kami dengan benar. Kami juga akan mengirimkan pemberitahuan di sini ketika kami telah menanggapi permintaan Anda.',
  sentAccountBackup:
    'Kami telah mengirimkan data alarm Anda ke {{email}}. Jika tidak ada di Kotak Masuk Anda, silakan periksa folder spam untuk email dari noreply@acintyo.com.',
  alarmDoesntExist: 'Alarm tidak ada.',
  shareAlarm: 'Bagikan Tautan Alarm',
  shareAlarmDescription:
    'Anda dapat membagikan alarm ini kepada siapa pun menggunakan tautan, misalnya, guru dapat membagikan tautan untuk mengingatkan siswa agar bergabung dalam kelas. Saat seseorang mengetuk tautan tersebut, salinan alarm dibuat di ponsel mereka.',
  shareLink: 'Bagikan Tautan',
  joinedGroupAlarmFromDeepLinkMessage:
    'Anda akan diingatkan tentang "{{alarmName}}" di {{alarmTime}} pada {{alarmDate}}',
  joinedRepeatingGroupAlarmFromDeepLinkMessage: {
    zero: 'Anda akan diingatkan tentang "{{alarmName}}" {{repetitionString}}',
    one: 'Anda akan diingatkan tentang "{{alarmName}}" {{repetitionString}} di {{alarmTime}} mulai {{alarmDate}}'
  },
  youHaveSubscribedToThisAlarm:
    'Anda mengikuti alarm ini dari {{creatorName}}.',
  alreadySharedAlarmDescription: {
    zero: 'Anda tidak memiliki pengikut untuk alarm ini.\n\nAnda dapat terus membagikan alarm ini dengan lebih banyak orang menggunakan tautan. Ketika mereka mengetuk tautan, salinan alarm ini akan dibuat di ponsel mereka.',
    one: '{{count}} orang mengikuti alarm ini.\n\nAnda dapat terus membagikan alarm ini dengan lebih banyak orang menggunakan tautan. Ketika mereka mengetuk tautan, salinan alarm ini akan dibuat di ponsel mereka.',
    other:
      '{{count}} orang mengikuti alarm ini.\n\nAnda dapat terus membagikan alarm ini dengan lebih banyak orang menggunakan tautan. Ketika mereka mengetuk tautan, salinan alarm ini akan dibuat di ponsel mereka.'
  },
  shareParentAlarmDescription:
    'Anda mengikuti alarm ini dari {{creatorName}}.\n\nAnda juga dapat membagikan alarm ini dengan orang lain menggunakan tautan. Ketika mereka mengetuk tautan, salinan alarm ini akan dibuat di ponsel mereka dan mereka juga dapat mulai mengikuti alarm ini.',
  shareableAlarmLinks: 'Tautan yang Dapat Dibagikan',
  nPeopleFollowingAlarm: {
    zero: 'Anda tidak memiliki pengikut untuk alarm ini.',
    one: '{{count}} orang mengikuti alarm ini.',
    other: '{{count}} orang mengikuti alarm ini.'
  },
  likeUsOnFacebook: 'Facebook',
  followUsOnTwitter: 'Twitter',
  followUsOnInstagram: 'Instagram',
  youtube: 'YouTube',
  mediumBlog: 'Blog di Medium',
  howtoVideos: 'Video Petunjuk',
  followGalarmOnSocialMedia: 'Ikuti Galarm di Media Sosial',
  galarmOnSocialMedia: 'Galarm di Media Sosial',
  followGalarmOnSocialMediaDescription:
    'Lihat berita menarik tentang pembaruan aplikasi, penggunaan fitur, fitur-fitur yang akan datang, serta ulasan aplikasi utama. Anda juga bisa berinteraksi dengan tim Galarm di sana.',
  sharedAlarm: 'Edit Alarm Bersama',
  editingPublishedAlarm: {
    one: 'Anda sedang mengedit alarm yang telah Anda bagikan sebelumnya menggunakan tautan. {{count}} orang saat ini mengikuti alarm ini.\n\nKetika Anda mengedit alarm ini, mereka akan diberi tahu tentang perubahannya.',
    other:
      'Anda sedang mengedit alarm yang telah Anda bagikan sebelumnya menggunakan tautan. {{count}} orang saat ini mengikuti alarm ini.\n\nKetika Anda mengedit alarm ini, mereka akan diberi tahu tentang perubahannya.'
  },
  editingSubscribedAlarm:
    'Anda mengikuti alarm ini dari {{creatorName}}. Anda tidak akan menerima pembaruan apa pun untuk alarm ini setelah Anda mengedit alarm.',
  viewAlarm: 'Lihat Alarm',
  view: 'Melihat',
  editAlarm: 'Edit Alarm',
  thanksForTryingPremiumTitle: 'Anda sudah siap!',
  thanksForTryingPremiumMessage:
    'Kami harap Anda dapat menikmati fitur-fitur premiumnya.\n\nSilakan hubungi dukungan melalui "Pengaturan > Umpan Balik" atau kirimkan email kepada kami di galarm@acintyo.com jika Anda memiliki pertanyaan.',
  subscribedAlarmEditedInAlarmDetails:
    '{{creatorName}} telah mengubah alarm mereka.\nMemperbarui alarm Anda?',
  subscribedAlarmDeletedInAlarmDetails:
    '{{creatorName}} telah menghapus alarm mereka.\nMenghapus alarm Anda?',
  updateSubscribedAlarm: 'Ya, perbarui',
  deleteSubscribedAlarm: 'Ya, hapus',
  ignoreSubscribedAlarm: 'Tidak, abaikan',
  customRepetitionPremiumScreenDescription:
    'Pengulangan khusus adalah fitur premium.\n\nAnda dapat menggunakan fitur ini untuk membuat alarm dengan pengulangan tingkat lanjut. Contohnya antara lain:\n',
  repeatAlternateDate: '✅ Ulangi pada hari yang berbeda',
  repeatEveryNDay: '✅ Ulangi setiap 3 hari sekali',
  repeatEveryNHoursAndMMinutes: '✅ Ulangi setiap 2 jam 30 menit',
  repeatEveryNMinutes: '✅ Ulangi setiap 45 menit',
  repeatBiweekly: '✅ Ulangi setiap 2 minggu sekali',
  repeatEveryNWeeks: '✅ Ulangi setiap 6 minggu sekali',
  repeatEveryNMonths: '✅ Ulangi setiap 3 bulan (triwulanan)',
  repeatEveryLastDayOfMonth: '✅ Ulangi hari terakhir setiap bulan',
  repeatDayOfWeekOfMonth: '✅ Ulangi pada hari Sabtu ke-2 setiap bulan',
  repeatLastDayOfWeekOfMonth: '✅ Ulangi pada hari Jumat terakhir setiap bulan',
  customRepetition: 'Pengulangan Khusus',
  lastDayOfMonth: 'Hari terakhir setiap bulan',
  dayOfWeekInMonth: '{{weekNumberOfDayInMonth}} {{dayName}} setiap bulan',
  lastDayOfWeekInMonth: '{{dayName}} terakhir setiap bulan',
  dayOfWeekInMonthInYearSummary:
    '{{weekNumberOfDayInMonth}} {{dayName}} dari {{monthName}}',
  lastDayOfWeekInMonthInYearSummary: 'Terakhir {{dayName}} dari {{monthName}}',
  lastDayOfMonthInYearSummary: 'Hari terakhir {{monthName}}',
  dayOfWeekInMonthInYear:
    '{{weekNumberOfDayInMonth}} {{dayName}} dari {{monthName}} setiap tahun',
  lastDayOfWeekInMonthInYear:
    'Terakhir {{dayName}} dari {{monthName}} setiap tahun',
  lastDayOfMonthInYear: 'Hari terakhir {{monthName}} setiap tahun',
  chooseAMonthsConfiguration: 'Silakan pilih konfigurasi.',
  chooseADaysConfiguration: 'Silakan pilih konfigurasi',
  chooseAYearsConfiguration: 'Silakan pilih konfigurasi.',
  sendCodeOnSmsAgain: 'Kirim Kode Lagi',
  didntGetCodeNewUser:
    'Tidak mendapatkan kodenya?\n\nAnda bisa meminta kode lagi atau melewatkan pendaftaran untuk saat ini. Anda dapat mendaftar nanti.',
  didntGetCodeExistingUser:
    'Tidak mendapatkan kodenya?\n\nAnda dapat meminta kode lagi atau membatalkan pendaftaran. Anda dapat mendaftar kembali nanti.',
  didntGetCodeUpdatePhoneNumber:
    'Tidak mendapatkan kodenya?\n\nAnda dapat meminta kode lagi atau mencoba lagi nanti.',
  alreadySentCodeAgain:
    'Kami telah mengirimkan ulang kode tersebut kepada Anda. Jika Anda mengalami kesulitan mendapatkan kode, lewati pendaftaran untuk saat ini dan daftar nanti.',
  theme: 'Tema',
  announceAlarmName: 'Mengumumkan Judul Alarm',
  ttsHelperText:
    'Judul alarm akan diumumkan ketika alarm berdering. Anda dapat mengetuk tombol putar di depan judul alarm untuk mendengar pratinjau.',
  initializingTts: 'Menginisialisasi teks ke ucapan',
  errorSpeakingAlarmName:
    'Tidak dapat mengucapkan judul alarm. Silakan hubungi dukungan dari "Pengaturan > Umpan Balik".',
  entityAlarm: 'alarm',
  entityGroup: 'kelompok',
  entityContact: 'kontak',
  admin: 'Admin',
  specifyAlarmTitleToSpeak: 'Tentukan judul alarm yang akan diucapkan',
  wakeup: 'Bangun.',
  ttsPremiumScreenDescription:
    'Mengumumkan judul alarm adalah fitur premium.\n\nApabila diaktifkan, judul alarm akan diumumkan ketika alarm berdering.\n\nUntuk melihat pratinjau, berikan judul alarm dan ketuk putar.',
  unknownAlarmCategory: 'Kategori alarm tidak diketahui',
  cantAnnounceAlarmTitle: 'Judul alarm tidak akan diumumkan',
  ringtoneDurationIsSmall:
    'Durasi nada dering minimal 5 detik agar judul alarm dapat diumumkan.\n\nApakah Anda ingin melanjutkan pengaturan ini?',
  noActionItems: 'Tidak ada item tindakan.',
  personNotInContacts: 'Orang ini tidak ada dalam kontak Galarm Anda.',
  light: 'Cahaya',
  dark: 'Gelap',
  systemDefault: 'Menggunakan Pengaturan Perangkat',
  chooseTheme: 'Pilih Tema',
  dontSeeAllYourContacts: 'Tidak melihat semua kontak Anda?',
  lastContactsSyncedAt: 'Terakhir disinkronkan pada pukul {{dateString}}',
  refresh: 'Menyegarkan',
  forever: 'Selamanya',
  autoSnoozeConfiguration: 'Mengonfigurasi Tunda',
  autoSnoozeConfigurationSummary:
    '{{autoSnoozeDurationLabel}}, {{autoSnoozeCountLabel}}',
  uncategorizedCategoryName: 'Tidak Dikategorikan',
  uncategorizedCategoryScreenTitle: 'Uncategorized',
  setAlarmCategory: 'Mengatur Kategori Alarm',
  changeAlarmCategory: 'Mengubah Kategori Alarm',
  viewAlarmHistory: 'Melihat Riwayat Alarm',
  computingAlarmHistory: 'Ringkasan penghitungan untuk riwayat alarm...',
  alarmByCategories: '{{name}} Alarm',
  alarmCategories: 'Kategori',
  editAlarmCategory: 'Edit Kategori',
  deleteAlarmCategory: 'Hapus Kategori',
  newAlarmCategoryTitle: 'Kategori Baru',
  newAlarmCategoryNamePlaceholder: 'Tentukan nama',
  editAlarmCategoryTitle: 'Edit Kategori',
  alarmCategorySummary: {
    zero: '{{alarmsSummary}}',
    one: '{{alarmsSummary}}, {{count}} tidak terjawab',
    other: '{{alarmsSummary}}, {{count}} tidak terjawab'
  },
  numAlarms: {
    zero: 'Tidak ada alarm',
    one: '{{count}} alarm',
    other: '{{count}} alarm'
  },
  numIncidents: {
    zero: 'Tidak ada insiden',
    one: '{{count}} insiden',
    other: '{{count}} insiden'
  },
  openedIncidents: 'Insiden terbuka',
  closedIncidents: 'Insiden tertutup',
  incidentChart: 'Grafik Insiden (30 hari terakhir)',
  incidentCalendar: 'Kalender Insiden',
  incidentDistribution: 'Distribusi Insiden (30 hari terakhir)',
  noIncidentsOpenedClosedInLast30Days:
    'Tidak ada insiden yang dibuka atau ditutup dalam 30 hari terakhir',
  noIncidentsOpenedInLast30Days:
    'Tidak ada insiden yang dibuka dalam 30 hari terakhir',
  color: 'Warna',
  createNewAlarmCategory:
    'Buat kategori baru dengan mengetuk tombol + di kanan bawah',
  setValidName: 'Tetapkan nama yang valid',
  specifyTimezoneForAlarmMessage:
    'Anda dapat menentukan zona waktu saat membuat alarm. Alarm akan berdering sesuai zona waktu yang ditentukan. \n\nJika Anda memilih "Zona waktu perangkat", alarm akan berdering sesuai zona waktu perangkat Anda.',
  alarmCategoryFeatureDescription:
    'Buat kategori Anda sendiri untuk alarm. Kategori memungkinkan Anda mengatur alarm dengan mudah.',
  markAlarmDoneInAdvance: 'Tandai alarm "Selesai" lebih awal?',
  skipAlarmInAdvance: '"Lewati" alarm lebih awal?',
  markAlarmDoneInAdvanceMessageRepeatingAlarm:
    'Alarm tidak akan berdering di {{dateTimeString}} jika Anda menandai "Selesai" sekarang.',
  skipAlarmInAdvanceMessageRepeatingAlarm:
    'Alarm tidak akan berdering di {{dateTimeString}} jika Anda melewatkannya sekarang.',
  markAlarmDoneInAdvanceMessageOneTimeAlarm:
    'Alarm tidak akan berdering di {{dateTimeString}} jika Anda menandai "Selesai" sekarang.',
  skipAlarmInAdvanceMessageOneTimeAlarm:
    'Alarm tidak akan berdering di {{dateTimeString}} jika Anda melewatkannya sekarang.',
  alarmsByCategories: 'Alarm berdasarkan Kategori',
  workAlarmCategory: 'Pekerjaan',
  homeAlarmCategory: 'Beranda',
  birthdaysAlarmCategory: 'Ulang tahun',
  more: 'Lebih lanjut',
  alarmCategory: 'Kategori Alarm',
  editAlarmCategoryColor: 'Edit Warna',
  editAlarmCategoryColorTitle: 'Edit Warna untuk {{name}}',
  allUserCategoriesDeleted:
    'Semua kategori alarm yang Anda buat telah dihapus karena langganan premium dibatalkan. Alarm dalam kategori tersebut akan muncul di "Tidak Dikategorikan".',
  calendar: 'Kalender',
  eventExportedFromGalarm:
    '"Diekspor dari Galarm di {{dateString}} di {{timeString}}."',
  unableToExportEventToCalendar:
    'Tidak dapat mengekspor acara ke kalender. Pastikan Anda telah memberikan izin untuk mengakses kalender pada perangkat. Kirimkan umpan balik kepada kami dengan menggunakan "Pengaturan > Umpan Balik" jika masalah terus berlanjut.',
  scanQrCode:
    'Untuk mengakses Galarm di browser, buka web.galarm.app dan pindai kode QR.',
  logOut: 'Log Out',
  useHere: 'Gunakan di sini',
  appRunningElsewhere:
    'Anda telah keluar dari sesi ini. Apakah Anda ingin melanjutkan sesi ini?',
  dateTime: 'Tanggal & Waktu',
  selectAtLeastOneDayForRepetition:
    'Pilih setidaknya satu hari di mana alarm harus diulang.',
  chooseNumberOfDaysForAlarmRepetition:
    'Masukkan jumlah hari setelah alarm akan berulang.',
  chooseNumberOfWeeksForAlarmRepetition:
    'Masukkan jumlah minggu setelah itu alarm akan berulang.',
  chooseNumberOfMonthsForAlarmRepetition:
    'Masukkan jumlah bulan setelah itu alarm akan berulang.',
  chooseNumberOfYearsForAlarmRepetition:
    'Masukkan jumlah tahun setelah itu alarm akan berulang.',
  previouslySelectedContactsAreUnselected:
    'Kontak yang dipilih sebelumnya tidak dapat dipilih.',
  previouslySelectedGroupIsUnselected:
    'Grup yang dipilih sebelumnya tidak dapat dipilih.',
  galarmWebInstructions:
    '1. Buka aplikasi Galarm di ponsel Anda\n2. Buka tab "Pengaturan" dan ketuk "Galarm Web"\n3. Pastikan Anda menjalankan aplikasi terbaru jika Anda tidak melihat opsi tersebut\n4. Pindai kode QR di sebelah kanan',
  likeToCreateAlarm: 'Membuat alarm untuk...',
  payUtilityBills: 'Membayar tagihan',
  wakeupAlarmName: 'Bangun.',
  exerciseAlarmName: 'Latihan',
  drinkWaterAlarmName: 'Minum air putih',
  everyNHoursRepetitionStringShortSummary: {
    one: '{{startTimeString}} ke {{endTimeString}} Setiap jam',
    other: '{{startTimeString}} ke {{endTimeString}} Setiap {{count}} jam'
  },
  firstTimeManagementQuote:
    '"Kabar buruknya adalah waktu terus berlalu. Kabar baiknya adalah Anda adalah pilotnya."',
  firstTimeManagementQuoteAuthor: 'Michael Altshuler',
  secondTimeManagementQuote:
    '"Semua manajemen waktu dimulai dengan perencanaan."',
  secondTimeManagementQuoteAuthor: 'Tom Greening',
  thirdTimeManagementQuote:
    '"Jangan pernah tunda sampai besok apa yang bisa Anda lakukan hari ini."',
  thirdTimeManagementQuoteAuthor: 'Benjamin Franklin',
  fourthTimeManagementQuote:
    '"Waktu adalah hal yang paling kita inginkan, tetapi yang paling buruk kita gunakan."',
  fourthTimeManagementQuoteAuthor: 'William Penn',
  fifthTimeManagementQuote:
    '"Setahun dari sekarang, Anda mungkin berharap Anda telah memulainya hari ini."',
  fifthTimeManagementQuoteAuthor: 'Karen Lamb',
  sixthTimeManagementQuote:
    '"Anda dapat menunda, tetapi waktu tidak akan menunda."',
  sixthTimeManagementQuoteAuthor: 'Benjamin Franklin',
  tapToGetStarted: 'Ketuk "+" untuk memulai.',
  allCategories: 'Semua Kategori',
  createAlarmWithContact: 'Buat alarm dengan {{name}}',
  createAlarmWithGroup: 'Buat alarm dengan {{name}}',
  editGroup: 'Edit Grup',
  discardChanges: 'Perubahan saat ini akan dibuang. Lanjutkan?',
  alarmWillRingEveryNDays: {
    zero: 'Alarm akan berdering setiap hari.',
    one: 'Alarm akan berdering pada hari yang bergantian.',
    other: 'Alarm akan berdering setiap {{dayWithOrdinal}} hari.'
  },
  hoursString: { zero: '', one: '1 jam', other: '{{count}} jam' },
  minutesString: { zero: '', one: '1 menit', other: '{{count}} menit' },
  alarmWillRingEveryMHoursAndNMinutes:
    'Alarm akan berdering setelah setiap {{repeatString}} mulai {{dayString}}. Setelah itu, {{otherDaysString}} dan seterusnya.',
  alarmWillRingEveryNWeeks: {
    zero: 'Alarm akan berdering setiap minggu.',
    one: 'Alarm akan berdering dua kali seminggu.',
    other: 'Alarm akan berdering setiap {{weekWithOrdinal}} minggu.'
  },
  alarmWillRingEveryNMonths: {
    zero: 'Alarm akan berdering setiap bulan.',
    one: 'Alarm akan berdering setiap dua bulan sekali.',
    other: 'Alarm akan berdering setiap {{monthWithOrdinal}} bulan.'
  },
  alarmWillRingEveryNYears: {
    zero: 'Alarm akan berdering setiap tahun.',
    one: 'Alarm akan berdering dua kali setahun.',
    other: 'Alarm akan berdering setiap {{yearWithOrdinal}} tahun.'
  },
  ringParticipantAlarmsByDefaultTooltipMessage:
    'Apabila preferensi ini diaktifkan, alarm dari orang lain akan berdering tanpa menerimanya. Anda tetap harus menerima alarm untuk memberi tahu pembuatnya bahwa Anda berniat untuk berpartisipasi dalam alarm tersebut.\n\nKetika preferensi ini dinonaktifkan, alarm yang ditambahkan untuk Anda oleh orang lain tidak akan berdering di ponsel Anda kecuali jika Anda menerimanya secara eksplisit.',
  specifyTimezoneForAlarmDefaultTooltipMessage:
    'Apabila preferensi ini diaktifkan, Anda dapat menentukan zona waktu saat membuat alarm. Alarm akan berdering sesuai zona waktu yang ditentukan.\n\nJika preferensi ini dinonaktifkan, alarm akan berdering sesuai zona waktu perangkat Anda.',
  ringOnEarphoneOnlyTooltipTitle: 'Perilaku alarm saat earphone tersambung',
  ringOnEarphoneOnlyTooltipMessage:
    'Ketika earphone tersambung, Anda dapat mengonfigurasi alarm agar berdering pada earphone saja atau pada speaker telepon dan earphone.\n\nKetika earphone tidak tersambung, alarm akan berdering pada speaker telepon.',
  gestureOptionsTooltipTitle: 'Gerakan untuk mematikan alarm',
  gestureOptionsTooltipMessage:
    'Ketika alarm berdering pada layar kunci, Anda dapat mengabaikannya dengan mengetuk tombol atau menggeser tombol.\n\nPengaturan ini memungkinkan Anda untuk memilih cara mematikan alarm.',
  notificationSettingsTooltipTitle: 'Pengaturan Pemberitahuan',
  notificationSettingsTooltipMessage:
    'Aktifkan notifikasi yang ingin Anda terima dan nonaktifkan notifikasi yang tidak ingin Anda terima.',
  messagesForAlarm: 'Pesan untuk {{name}}',
  systemNotificationsTooltipMessage:
    'Pemberitahuan yang dihasilkan oleh aplikasi untuk peristiwa seperti:\n  - ketika alarm terpengaruh karena kontak mengubah zona waktu\n  - ketika kontak yang diundang mendaftar di Galarm\n  - ketika alarm terpengaruh karena seseorang menghapus akun mereka',
  galarmWebNotificationsTooltipMessage:
    'Pemberitahuan yang dikirimkan kepada Anda dari Galarm Web.',
  alarmsfromOthersNotificationsTooltipMessage:
    'Pemberitahuan yang dikirim ketika Anda ditambahkan ke alarm sebagai peserta.',
  alarmChatNotifictionsTooltipMessage:
    'Pemberitahuan yang dikirim untuk pesan obrolan dalam alarm.',
  groupNotificationsTooltipMessage:
    'Pemberitahuan yang dikirim ketika Anda ditambahkan ke grup, dihapus dari grup, atau orang lain ditambahkan/dihapus dari grup.',
  alarmAcknowledgementNotificationsTooltipMessage:
    'Pemberitahuan yang dikirim ketika seorang peserta mengakui alarm di mana Anda juga merupakan peserta.',
  sharedAlarmNotificationsTooltipMessage:
    'Pemberitahuan yang dikirim:\n  - ketika seseorang berlangganan alarm yang Anda bagikan melalui tautan\n  - ketika alarm yang Anda berlangganan telah diubah',
  sleepTimer: 'Musik Tidur',
  sleepTimerTitle: 'Memutar Musik untuk',
  ongoingSleepTimer: 'Musik Tidur Sedang Berlangsung',
  stopSleepTimer: 'Apakah Anda ingin menghentikan musik yang sedang diputar?',
  restartSleepTimer:
    'Apakah Anda ingin menghentikan musik saat ini dan memulai musik yang baru?',
  play: 'Bermain',
  youAreOffline:
    'Saat ini Anda sedang offline. Online untuk melakukan operasi apa pun.',
  alarmLinkRelativeToTimezone:
    'Alarm setiap orang akan berbunyi pada {{timeString}} di zona waktunya masing-masing.',
  alarmLinkNotRelativeToTimezone:
    'Alarm semua orang akan berbunyi pada {{timeString}}',
  sleepMusicTooltip:
    'Memutar suara alami untuk merilekskan tubuh dan pikiran Anda. Musik berhenti diputar setelah durasi yang ditentukan.',
  alarmLinkCopiedToClipboard: 'Tautan alarm disalin ke papan klip.',
  confirmLogOut: 'Apakah Anda ingin keluar?',
  appNotRunningMessage:
    'Kami telah mendeteksi bahwa Galarm tidak berjalan di ponsel Anda. Ketuk untuk memulai Galarm agar Anda tidak melewatkan alarm penting.',
  tapToEnterNotesForAlarm: 'Ketuk untuk memasukkan catatan',
  accountAlreadyExists: 'Akun yang Sudah Ada Ditemukan',
  accountsMerged:
    'Kami menemukan akun yang sudah ada yang terkait dengan nomor ponsel ini. Kami telah menggabungkan kedua akun tersebut.',
  upgradeToPremiumToAssignUserDefinedCategory:
    'Tingkatkan ke Premium untuk memilih kategori yang ditentukan pengguna.',
  userDefinedCategoryNotSetForAlarm:
    'Tingkatkan ke Premium untuk menetapkan kategori yang ditentukan pengguna secara otomatis. Kategori tidak ditetapkan untuk alarm.',
  alarmDetails: 'Detail Alarm',
  profileDetails: 'Profil',
  activeWebSession:
    'Anda sudah memiliki sesi web yang aktif. Anda dapat keluar dari sesi tersebut atau memulai sesi baru.\n\nMemulai sesi baru akan secara otomatis mengeluarkan Anda dari sesi web yang sedang aktif.',
  startNewSession: 'Memulai Sesi Baru',
  confirmWebSessionLogout:
    'Ini akan membuat Anda keluar dari sesi web Anda saat ini. Anda dapat memulai sesi baru dengan memindai kode lagi.',
  defaultInstantAlarmTitleForGroup:
    '{{groupName}} Peringatan dari {{creatorName}}',
  defaultInstantAlarmTitleForContact: 'Peringatan dari {{creatorName}}',
  atleastOneMemberShouldBeActive: 'Setidaknya satu peserta harus aktif.',
  activeParticipants: 'Peserta Aktif',
  acceptAlarm: 'Terima Alarm',
  rejectAlarm: 'Tolak Alarm',
  nConfirmed: '{{count}} dikonfirmasi',
  nDeclined: '{{count}} ditolak',
  nPending: '{{count}} tertunda',
  everyoneConfirmed: 'Semua orang telah mengonfirmasi',
  everyoneDeclined: 'Semua orang telah menolak',
  appTagline: 'Jangan Pernah Lewatkan Apa Pun',
  usePreviousBackup: 'Saya adalah pengguna terdaftar >',
  viewAlarmsByCategories: 'Melihat Alarm berdasarkan Kategori',
  viewAllAlarms: 'Lihat Semua Alarm',
  switchToSummaryView: 'Beralih ke Tampilan Ringkasan',
  switchToListView: 'Beralih ke Tampilan Daftar',
  hideHourlyAlarms: 'Menyembunyikan alarm per jam',
  calendarView: 'Kalender Galarm',
  viewRecentlyDeletedAlarms: 'Melihat Alarm yang Baru Saja Dihapus',
  deletedOnDate: 'Dihapus pada {{alarmDate}}',
  recentlyDeletedAlarmFooterText: 'Alarm ini telah dihapus pada {{alarmDate}}',
  noRecentlyDeletedAlarms: 'Tidak ada alarm yang baru saja dihapus',
  restoreAlarmsFromMobileApp:
    'Alarm ini akan secara otomatis dihapus setelah 30 hari setelah penghapusan. Jika Anda ingin mengembalikan salah satu dari alarm tersebut, buka layar "Alarm yang Baru Saja Dihapus" di aplikasi seluler Anda, dan ketuk ikon pengembalian.',
  deleteInactiveAlarms: 'Menghapus Alarm Tidak Aktif',
  defaultConfigurationUpdated: 'Konfigurasi default telah diperbarui.',
  notAlerted: '(tidak termasuk)',
  alarmKeepsRingingHelp: 'Tolong! Alarm saya terus berdering',
  alarmKeepsRingingHelpMessage:
    'Kami telah menyegarkan alarm Anda dan mengirimkan laporan masalah.',
  alarmKeepsRingingHelpAlert:
    'Kami akan menyegarkan alarm Anda dan Anda seharusnya tidak lagi mengalami masalah ini. Kami juga akan mengirimkan laporan masalah kepada tim dukungan kami untuk penyelidikan lebih lanjut.\n\n  Jika masalah terus berlanjut, silakan hubungi kami menggunakan "Pengaturan > Umpan Balik".',
  sendingFeedback: 'Mengirim umpan balik',
  sendingProblemReport: 'Mengirim laporan masalah',
  fixingAlarms: 'Menyegarkan alarm Anda',
  existingAccountFound: 'Cadangan Dipulihkan',
  existingAccountDataRestored: {
    zero: 'Selamat, semua data telah dipulihkan dari cadangan.',
    one: 'Selamat, semua data telah dipulihkan dari cadangan.',
    other: 'Selamat, semua data telah dipulihkan dari cadangan.'
  },
  noExistingAccountFound: 'Tidak Ditemukan Cadangan',
  createdNewAccount:
    'Nomor telepon ini belum terdaftar di Galarm. Kami telah membuat akun baru menggunakan nomor telepon ini.',
  iosCloseToAllowedNumNotificationsScheduled: {
    zero: 'iOS memungkinkan aplikasi untuk menjadwalkan hingga 64 pemberitahuan. Anda telah menggunakan semua notifikasi tersebut. Ada kemungkinan Anda melewatkan beberapa alarm.',
    one: 'iOS memungkinkan aplikasi untuk menjadwalkan hingga 64 pemberitahuan. Anda telah menggunakan {{numNotifications}} dari pemberitahuan tersebut.'
  },
  acknowledgeAlarmToScheduleNextOccurrence:
    'Ini adalah notifikasi terakhir untuk alarm ini. Tekan lama notifikasi ini dan lakukan tindakan untuk menjadwalkan kemunculan alarm berikutnya.',
  lastNotificationForOneTimeAlarm:
    'Ini adalah notifikasi terakhir untuk alarm ini.',
  criticalAlertsMessageTitle: 'Membunyikan alarm saat ponsel Anda dibisukan',
  criticalAlertsMessageDescription:
    '\nAnda dapat mengonfigurasi Galarm untuk menggunakan Peringatan Kritis iOS untuk membunyikan alarm ketika ponsel Anda dibisukan. Apakah Anda ingin mengaktifkannya untuk semua alarm?\n\nAnda juga dapat mengaktifkannya secara selektif untuk beberapa alarm dengan mengedit pengaturan "Suara dan Getaran > Peringatan Kritis".',
  allowCriticalAlerts: 'Izinkan Peringatan Kritis',
  criticalAlertsPermissionDenied:
    'Galarm memerlukan izin dari ponsel Anda untuk menampilkan peringatan penting. Aktifkan izin ini di pengaturan ponsel Anda.',
  enableCriticalAlertsForAllAlarms: 'Ya, aktifkan untuk semua alarm',
  selectivelyEnableCriticalAlerts:
    'Tidak, saya akan mengaktifkan secara selektif',
  firstName: 'Nama depan',
  lastName: 'Nama belakang',
  email: 'Email',
  password: 'Kata sandi',
  confirmPassword: 'Konfirmasi Kata Sandi',
  signup: 'Daftar',
  login: 'Masuk',
  alreadySignedUp: 'Sudah mendaftar?',
  notAUser: 'Bukan pengguna?',
  subscribe: 'Berlangganan',
  choosePlan: 'Mengonfigurasi Paket',
  billingCycle: 'Siklus Penagihan',
  needHelpToGetStarted: 'Perlu bantuan untuk memulai?',
  browser: 'Browser: {{name}}',
  deviceOs: 'Perangkat: {{name}}',
  lastActive: 'Terakhir aktif: {{timestamp}}',
  signUp: 'Daftar',
  useGalarmOnWeb: 'Gunakan Galarm di Web',
  welcomeUser: 'Selamat datang. {{name}}',
  changePassword: 'Ubah Kata Sandi',
  saveAlarm: 'Simpan Alarm',
  galarmUserTip: 'Apa kau tahu?',
  sendAlarm: 'Kirim Alarm',
  saveGroup: 'Simpan Grup',
  webAppBestViewedOnDesktop:
    'URL (web.galarm.app) adalah untuk meluncurkan antarmuka Galarm pada peramban web di laptop atau desktop Anda.\n\nUntuk menggunakan Galarm di perangkat seluler ini, silakan instal aplikasi Android dari Google Play atau aplikasi iOS dari App Store menggunakan salah satu tautan di bawah ini:',
  scanQrCodeMessage: 'Pindai Kode QR',
  reloadQrCode: 'Klik untuk memuat ulang kode QR',
  invalidQrCodeScanned: 'Kode QR yang dipindai tidak valid.',
  unableToScanCode: 'Tidak dapat memindai kode',
  unableToScanCodeMessage:
    'Ada masalah dalam memindai kode. Silakan coba lagi. Jika Anda terus mengalami masalah, silakan kirimkan umpan balik dari aplikasi seluler menggunakan "Pengaturan > Umpan Balik".',
  problemWithEnterpriseAccountSetup:
    'Ada masalah dalam menyiapkan akun Galarm Pro Anda. Silakan hubungi dukungan teknis.',
  problemWithCreatingCheckoutSession:
    'Ada masalah saat membuat sesi pembayaran. Silakan hubungi dukungan teknis.',
  problemWithCreatingCustomerPortalSession:
    'Ada masalah saat membuat sesi portal pelanggan. Silakan hubungi dukungan teknis.',
  problemWithCreatingApiKey:
    'Ada masalah dalam membuat kunci API. Silakan hubungi dukungan teknis.',
  creatingAnAccount: 'Membuat akun',
  currentPlan: 'Paket saat ini: {{name}} ({{quantity}} kursi)',
  myAccount: 'Informasi Akun',
  new: 'Baru',
  deleteAllAlarmHistoryAlertTitle: 'Menghapus Riwayat Alarm',
  deleteAllAlarmHistoryAlertMessage:
    'Ketuk "Lanjutkan" untuk menghapus riwayat alarm ini. Tindakan ini tidak dapat dibatalkan.',
  ageOutInactiveAlarmsMessage:
    'Anda memiliki {{count}} alarm yang tidak aktif selama lebih dari 60 hari. Memiliki banyak alarm yang tidak aktif dapat memengaruhi kinerja aplikasi.\n\nApakah Anda ingin menghapus alarm ini?',
  duplicateAlarm: 'Alarm Duplikat',
  graduallyIncreaseVolume: 'Tingkatkan volume secara bertahap',
  graduallyIncreaseVolumeTooltipMessage:
    'Apabila preferensi ini diaktifkan, volume alarm secara bertahap meningkat ke volume nada dering yang dikonfigurasi. Hal ini memungkinkan Anda untuk menghentikan alarm sebelum terlalu keras.\n\nBila preferensi ini dinonaktifkan, alarm akan berdering pada volume nada dering yang dikonfigurasikan selama durasi nada dering.',
  teams: 'Tim',
  alerts: 'Peringatan',
  listeners: 'Pendengar',
  noMembersConfiguredInEnterpriseAccount:
    'Anda belum menambahkan anggota. Anggota adalah pengguna yang dapat ditambahkan ke kebijakan eskalasi pendengar dan diberi tahu untuk insiden yang diterima oleh pendengar. Orang-orang ini harus sudah terdaftar di Galarm.',
  noTeamsConfiguredInEnterpriseAccount:
    'Anda belum membuat tim apa pun. Tim adalah kelompok anggota yang dapat ditambahkan ke kebijakan eskalasi pendengar dan disiagakan untuk insiden yang diterima oleh pendengar.',
  noAlertsConfiguredInEnterpriseAccount:
    'Anda belum membuat pendengar apa pun. Anda dapat mengonfigurasi pendengar untuk mendengarkan insiden yang dilaporkan oleh alat pemantauan. Setelah Anda membuat pendengar, Anda dapat melihat petunjuk tentang cara mengintegrasikan pendengar dengan berbagai alat pemantauan.\n\nAnda dapat mengonfigurasi beberapa tingkat eskalasi untuk setiap pendengar.',
  noShiftsConfiguredInEnterpriseAccount:
    'Anda belum membuat konfigurasi shift apa pun.',
  noRotationsConfiguredInEnterpriseAccount:
    'Anda belum membuat rotasi apa pun.',
  rotations: 'Rotasi',
  addRotation: 'Tambahkan Rotasi',
  viewRotation: 'Lihat Rotasi',
  enterRotationName: 'Masukkan nama rotasi',
  rotationType: 'Jenis Rotasi',
  numberOfHoursInRotation: 'Jumlah jam dalam rotasi',
  numberOfDaysInRotation: 'Jumlah hari dalam rotasi',
  numberOfWeeksInRotation: 'Jumlah minggu dalam rotasi',
  specify: 'Tentukan...',
  noIntegrationsConfiguredInEnterpriseAccount:
    'Anda belum mengonfigurasi konfigurasi integrasi apa pun.',
  loadingUser: 'Memuat informasi pengguna',
  addMember: 'Tambah Anggota',
  add: 'Menambahkan',
  noUserFoundWithMobileNumber:
    'Tidak ditemukan pengguna Galarm yang terdaftar dengan nomor telepon ini. Pastikan Anda memasukkan nomor telepon dengan kode negara tanpa karakter khusus seperti tanda kurung, tanda hubung, spasi, dll.',
  memberWithPhoneNumberAlreadyAdded:
    'Anggota dengan nomor telepon ini telah ditambahkan.',
  memberWithEmailAlreadyAdded: 'Anggota dengan email ini telah ditambahkan.',
  assignKeyToMember: 'Menetapkan kunci lisensi',
  revokeKeyFromMember: 'Mencabut kunci lisensi',
  cantAssignKeyToMember: 'Tidak Dapat Memberikan Kunci Kepada Anggota',
  noEnterpriseLicenseKeyAvailable:
    'Tidak ada kunci lisensi yang tersedia. Silakan tingkatkan paket Anda.',
  addTeam: 'Tambah Tim',
  enterTeamName: 'Masukkan nama tim',
  addAlert: 'Tambahkan Pendengar',
  low: 'Rendah',
  medium: 'Sedang',
  high: 'Tinggi',
  severity: 'Keparahan',
  enterAlertName: 'Masukkan nama untuk pendengar',
  organization: 'Organisasi',
  startTime: 'Waktu Mulai',
  endTime: 'Akhir Zaman',
  shiftName: 'Shift {{index}}',
  addShift: 'Tambahkan Shift',
  assignees: 'Orang yang ditugaskan',
  shiftStartEndTime: '{{startTime}} - {{endTime}}',
  assignee: 'Penerima',
  specifyAssigneeForShift: 'Menentukan penerima tugas untuk shift',
  selectAtLeastOneMember: 'Pilih setidaknya satu anggota',
  editMember: 'Edit Anggota',
  editTeam: 'Tim Penyunting',
  cantRemoveMemberAssignedToTeamsOrAlerts:
    'Anggota adalah bagian dari tim atau ditugaskan ke pendengar. Harap keluarkan anggota dari tim/pendengar tersebut sebelum menghapus.\nTim {{teams}}\nPendengar: {{alerts}}',
  cantRevokeKeyFromMemberAssignedToTeamsOrAlerts:
    'Anggota adalah bagian dari tim atau ditugaskan ke pendengar. Harap keluarkan anggota dari tim/pendengar tersebut sebelum mencabut kunci.\nTim {{teams}}\nPendengar: {{alerts}}',
  cantDeleteTeamAssignedToAlerts:
    'Tim adalah bagian dari pendengar. Harap hapus tim dari pendengar sebelum menghapus.\nPendengar: {{alerts}}',
  cantDeleteRotationAssignedToAlerts:
    'Rotasi adalah bagian dari pendengar. Harap hapus tim dari pendengar sebelum menghapus.\nPendengar: {{alerts}}',
  editAlert: 'Edit Pendengar',
  timezoneDisplayString: '(GMT{{offset}}) {{timezone}}',
  getCode: 'Dapatkan Kode',
  apiKey: 'Kunci API',
  viewApiKey: 'Lihat Kunci API',
  apiKeyWarningMessage:
    'Jaga keamanan kunci API Anda setiap saat. Jangan membagikannya dengan pihak mana pun. Jika Anda merasa kunci API Anda telah disusupi, ketuk tombol "Ubah" di atas untuk mengubahnya. Setelah Anda mengubah kunci, Anda harus menentukan kunci baru di semua tempat Anda memicu insiden menggunakan REST API',
  integrationConfigs: 'Konfigurasi Integrasi',
  addIntegrationConfig: 'Tambahkan Konfigurasi Integrasi',
  editIntegrationConfig: 'Edit Konfigurasi Integrasi',
  integrationSource: 'Sumber Integrasi',
  apiToken: 'Token API',
  integrations: 'Integrasi',
  selectPlaceholder: 'Pilih...',
  zendeskSubdomain: 'Subdomain Zendesk',
  creatorTimeForBuddyAlarm: '{{timeString}} ({{remindMeAfter}})',
  nextFiveAlarms: 'Berikutnya 5 Alarm',
  categories: 'Kategori',
  viewingAlarmsDueToday: 'Melihat alarm yang jatuh tempo hari ini',
  viewingAlarmsDueTomorrow: 'Melihat alarm yang akan jatuh tempo besok',
  expiredAlarms: 'Alarm Kedaluwarsa',
  viewingExpiredAlarms: 'Melihat Alarm Kedaluwarsa',
  noAlarmsDueToday: 'Tidak ada alarm yang jatuh tempo hari ini',
  noAlarmsDueTomorrow: 'Tidak ada alarm yang jatuh tempo besok',
  noExpiredAlarms: 'Tidak ada alarm kedaluwarsa',
  viewingAllAlarms: 'Melihat Semua Alarm',
  viewingRecentlyDeletedAlarms: 'Melihat Alarm yang Baru Saja Dihapus',
  summaryView: 'Coba Tampilan Ringkasan?',
  summaryViewDescription:
    'Lihat alarm yang diatur berdasarkan tanggal jatuh tempo dan kategori.\n\nAnda dapat beralih kembali ke tampilan daftar dengan menggunakan tombol ⋮ di kanan atas.',
  disableBatteryOptimizationMessageOnStartup:
    'Galarm harus berjalan di latar belakang agar alarm dapat berdering dengan baik. Izinkan izin pada layar berikutnya.',
  disableBatteryOptimizationMessage:
    'Galarm harus berjalan di latar belakang agar alarm dapat berdering dengan andal.',
  allowNotificationsToDisplayAlarms:
    'Galarm memerlukan izin untuk menampilkan notifikasi ketika alarm berdering.',
  scheduleExactAlarmsMessage:
    'Galarm memerlukan izin untuk menjadwalkan alarm yang tepat.',
  alarmCategoryWithSameNameExists:
    'Kategori dengan nama ini sudah ada. Silakan tentukan nama yang berbeda.',
  unlimitedAlarmsPremiumFeature: 'Grup, Teman, dan Alarm Instan Tanpa Batas',
  alarmRepetitionsPremiumFeature: 'Opsi Pengulangan Lanjutan',
  ringtonesPremiumFeature: 'Nada Dering Lainnya',
  galarmWebPremiumFeature: 'Gunakan Galarm di Web',
  taskListPremiumFeature: 'Membuat Beberapa Daftar Tugas',
  preReminderPremiumFeature: 'Pra-Pengingat untuk Alarm',
  alarmCategoriesPremiumFeature: 'Kategori Alarm Khusus',
  shareableAlarmLinksPremiumFeature: 'Tautan Alarm yang Dapat Dibagikan',
  announceAlarmNamePremiumFeature: 'Mengumumkan Judul Alarm',
  manageSubscription: 'Mengelola Langganan',
  purchaseRestored: 'Pembelian dipulihkan.',
  unableToRetrieveSubscriptionPlansAndroid:
    'Kami tidak dapat terhubung ke Google Play untuk melakukan peningkatan. Pastikan Anda terhubung ke internet, lalu coba lagi. \n\nSilakan laporkan masalah ke tim dukungan kami, jika masalah ini terus berlanjut.',
  unableToRetrieveSubscriptionPlansIos:
    'Kami tidak dapat terhubung ke App Store untuk melakukan peningkatan. Pastikan Anda tersambung ke internet, lalu coba lagi. \n\nSilakan laporkan masalah ke tim dukungan kami, jika masalah ini terus berlanjut.',
  drawOverOtherApps: 'Menampilkan di atas aplikasi lain',
  drawOverOtherAppsHelperText:
    'Izinkan Galarm untuk menampilkan peringatan alarm di bagian atas',
  nTimes: { one: 'Satu kali', other: '{{count}} kali' },
  learnHowToUseFocus: 'Belajar Fokus',
  enhancedNotificationsDeprecated:
    'Pemberitahuan yang Disempurnakan Tidak Digunakan Lagi',
  enhancedNotificationsDeprecatedMessage:
    'Kami menemukan bahwa Pemberitahuan yang Disempurnakan tidak dapat diandalkan dan menyebabkan pengurasan baterai yang berlebihan. Gunakan iOS Focus untuk mengizinkan Galarm membunyikan alarm saat ponsel Anda dalam mode Jangan Ganggu.',
  usingIosFocus: 'Menggunakan Fokus iOS',
  iosFocusModePara1:
    'Galarm tidak akan berdering ketika sakelar dering telepon dimatikan atau ketika volume dering 0.\n\nApple menyarankan untuk menggunakan "Fokus" saat Anda ingin mengheningkan ponsel namun tetap mengizinkan beberapa aplikasi untuk memberi tahu Anda. Gunakan petunjuk berikut untuk mengatur Fokus pada ponsel Anda.',
  iosFocusModePara2:
    'Anda dapat melihat petunjuk lebih rinci dengan tangkapan layar di https://galarm.zendesk.com/hc/en-us/articles/10906890793236-Allow-alarms-to-ring-on-silent-on-iOS',
  settingFocus: 'Menyiapkan Fokus:',
  settingFocusFootNotes:
    'Harap diperhatikan bahwa Focus tersedia pada iOS 15 dan di atasnya.\n\nUntuk pertanyaan atau umpan balik, silakan hubungi kami menggunakan "Pengaturan > Umpan Balik".',
  enablingFocus: 'Mengaktifkan Fokus:',
  settingFocusInstruction1: 'Buka "Pengaturan Telepon > Fokus".',
  settingFocusInstruction2: 'Ketuk profil fokus "Jangan Ganggu".',
  settingFocusInstruction3: 'Ketuk "Aplikasi" dan tambahkan Galarm.',
  settingFocusInstruction4:
    'Lakukan hal yang sama untuk profil Focus lainnya yang Anda tetapkan.',
  enablingFocusInstruction1:
    'Usap ke bawah dari tepi kanan atas untuk melihat Control Center.',
  enablingFocusInstruction2: 'Ketuk pada Fokus.',
  enablingFocusInstruction3:
    'Ketuk Jangan Ganggu atau profil Fokus lainnya yang ingin Anda aktifkan.',
  enablingFocusInstruction4:
    'Fokus yang saat ini diaktifkan tampak dipilih. Anda dapat mengetuknya lagi untuk menonaktifkannya.',
  startDateForRotation: 'Tanggal mulai rotasi',
  startTimeForRotation: 'Waktu mulai rotasi',
  member: 'Anggota',
  multipleShiftsInADay: 'Beberapa shift dalam sehari',
  escalationPolicy: 'Kebijakan Eskalasi',
  atTimeOfEvent: 'Pada saat acara berlangsung',
  afterNMinutes: 'Setelah {{count}} menit',
  afterNHours: { one: 'Setelah 1 jam', other: 'Setelah {{count}} jam' },
  afterDuration: 'Setelah {{durationString}}',
  addTrigger: 'Tambahkan Pemicu',
  viewCode: 'Lihat Kode',
  apiCodes: 'Kode API',
  viewIncidents: 'Lihat Insiden',
  incidentsForAlert: 'Insiden untuk pendengar {{alertName}}',
  timestamp: 'Cap waktu',
  acknowledged: 'Diakui',
  noIncidentsForAlert: 'Belum ada insiden untuk pendengar ini',
  apiCodeCopiedToClipboard: 'Kode API telah disalin ke clipboard.',
  triggerName: 'Pemicu {{index}}',
  delay: 'Penundaan',
  chooseAssigneePlaceholder: 'Pilih Penerima Tugas...',
  delayForTrigger: 'Penundaan untuk Pemicu',
  assigneeWillBeAlertedAfterDelay:
    'Penerima tugas akan diberitahu setelah {{durationString}}.',
  escalationForIncidentsForAlert:
    'Eskalasi untuk insiden di {{incidentDate}} untuk pendengar {{alertName}}',
  alertIncidentAcknowledged: 'Diakui oleh {{memberName}} di {{dateString}}',
  alertIncidentRecovered: 'Dipulihkan di {{dateString}}',
  alertIncidents: 'Insiden',
  viewingAlertIncidents: 'Melihat Insiden',
  createOrganization: 'Buat Organisasi',
  youAreNotPartOfAnyOrganization:
    'Anda bukan bagian dari organisasi mana pun. Buat organisasi untuk memulai.',
  tellUsAboutYourOrganization: 'Ceritakan kepada kami tentang organisasi Anda',
  noActiveSubscription: 'Anda tidak memiliki langganan aktif.',
  chooseOrganization: 'Pilih organisasi untuk melanjutkan.',
  noTriggersForIncident: 'Tidak ada pemicu yang ditemukan untuk insiden ini.',
  role: 'Peran',
  viewer: 'Penampil',
  newOrganization: 'Organisasi Baru',
  noOrganizations: 'Anda bukan bagian dari organisasi mana pun.',
  maxOrganizationsReached:
    'Pengguna dapat menjadi bagian dari paling banyak 10 organisasi.',
  yourOrganizations: 'Organisasi',
  urlNotFound: 'URL tidak cocok dengan rute yang didukung.',
  triggerAlert: 'Insiden Pemicu',
  recaptchaFailed: 'Verifikasi reCAPTCHA gagal. {{error}}',
  loadingAccountInformation: 'Memuat informasi akun',
  loadingAuthInfo: 'Memuat informasi autentikasi',
  allAlerts: 'Semua Pendengar',
  recentlyTriggeredAlerts: 'Pendengar yang Baru Saja Dipicu',
  recentlyClosedIncidents: 'Insiden yang Baru Saja Ditutup',
  noRecentlyTriggeredAlerts: 'Tidak ada pendengar yang terpicu baru-baru ini.',
  noRecentlyClosedIncidents: 'Tidak ada insiden yang ditutup baru-baru ini.',
  closedAt: 'Ditutup pada pukul {{dateTimeString}}',
  dashboard: 'Dasbor',
  loggedIntoOrganizationAccount:
    '(organisasi: {{organizationName}}, peran: {{role}})',
  viewIncidentEscalation: 'Lihat Eskalasi Insiden',
  closeIncident: 'Insiden Tertutup',
  closingIncident: 'Insiden penutupan',
  unableToRefreshEnterpriseAccount: 'Tidak dapat menyegarkan akun Anda.',
  viewAuthToken: 'Lihat Token Auth',
  problemWithCreatingAuthToken:
    'Ada masalah saat membuat token autentikasi. Silakan hubungi dukungan teknis.',
  authToken: 'Token Auth',
  authTokenWarningMessage:
    'Jaga keamanan token autentikasi ini setiap saat. Jangan bagikan dengan pihak mana pun. Jika Anda merasa kunci API Anda telah disusupi, ketuk tombol "Ubah" di atas untuk mengubahnya. Setelah Anda mengubah token, Anda perlu menentukan token baru di semua tempat Anda memicu insiden menggunakan REST API',
  authTokenCopiedToClipboard: 'Token Auth telah disalin ke papan klip.',
  resendVerificationEmail: 'Kirim Ulang Email Verifikasi',
  loggedInUsingEmail: 'Masuk menggunakan email {{email}}.',
  checkingIfMemberIsAppUser:
    'Memeriksa apakah anggota adalah pengguna aplikasi.',
  settingMemberDetails: 'Mengatur detail anggota.',
  addingTeam: 'Menambah tim.',
  editingTeam: 'Tim penyunting.',
  addingRotation: 'Menambahkan rotasi.',
  editingRotation: 'Rotasi pengeditan.',
  assigningKeyToMember: 'Menetapkan kunci kepada anggota.',
  revokingKeyFromMember: 'Mencabut kunci dari anggota.',
  deletingTeam: 'Menghapus tim.',
  deletingRotation: 'Menghapus rotasi.',
  addingAlert: 'Menambahkan pendengar.',
  editingAlert: 'Mengedit pendengar.',
  deletingAlert: 'Menghapus pendengar.',
  triggeringAlert: 'Insiden pemicu.',
  creatingOrganizationAccount: 'Membuat akun organisasi.',
  creatingCheckoutSession: 'Meluncurkan sesi pembayaran Anda.',
  creatingAnAccountForMember: 'Membuat akun untuk anggota.',
  passwordResetEmailSent:
    'Kami telah mengirimkan email untuk mengatur ulang kata sandi Anda. Ubah kata sandi Anda dan masuk kembali.',
  changeOwner: 'Ubah Pemilik',
  changeOrganizationOwner: 'Ubah Pemilik Organisasi',
  newOwnerEmail: 'Email Pemilik Baru',
  saveOnYearlySubscription: 'Simpan {{percent}}%',
  annual: 'Tahunan',
  resetAlarms: 'Menyegarkan Alarm',
  resetAlarmsAlert:
    'Semua alarm telah diperbarui. Jika Anda terus mengalami masalah dengan alarm, silakan kirimkan tiket menggunakan opsi "Laporkan Masalah".',
  resetAlarmsTooltipMessage:
    'Jika Anda mengalami masalah dengan alarm, seperti alarm yang telah dihapus tetap berdering di ponsel, silakan menyegarkan alarm menggunakan opsi ini. Jika masalah terus berlanjut, silakan kirim tiket menggunakan opsi "Laporkan Masalah".',
  resetTempPassword: 'Atur Ulang Kata Sandi Sementara',
  currPassword: 'Kata Sandi Saat Ini',
  tempPassword: 'Kata Sandi Sementara',
  newPassword: 'Kata Sandi Baru',
  reset: 'Atur ulang',
  loggingIn: 'Masuk',
  resettingTempPassword: 'Menyetel ulang kata sandi sementara',
  changingPassword: 'Mengubah kata sandi',
  numSeats: 'Jumlah Kursi',
  helpMeDecide: 'Bantu aku memutuskan',
  numEnterpriseSeatsHelper:
    'Anda harus memilih jumlah kursi sesuai dengan jumlah orang dalam organisasi Anda yang akan menggunakan aplikasi untuk menerima insiden. Saat mengonfigurasi pendengar, Anda dapat menentukan kebijakan eskalasinya. Anda dapat memiliki beberapa level dalam kebijakan eskalasi. Misalnya, Anda dapat menentukan bahwa tingkat eskalasi pertama adalah untuk tim yang terdiri dari 3 orang. Jika eskalasi tingkat pertama tidak merespons dalam waktu 5 menit, eskalasi tingkat kedua adalah ke tim yang terdiri dari 5 orang. Anda dapat menentukan jumlah kursi sebagai 8 dalam kasus ini.',
  numBusinessSeatsHelper:
    'Pilih jumlah kursi yang sesuai dengan jumlah orang yang akan menggunakan Galarm untuk menerima alarm dan pengingat.',
  perSeatPrice: '{{price}} per {{interval}} per kursi',
  doneForToday: 'Diakui',
  getStarted: 'Memulai',
  firstAlertInstructions: 'Petunjuk Pendengar Pertama',
  learnMoreAboutGalarmEnterprise: 'Pelajari lebih lanjut tentang Galarm Pro',
  home: 'Beranda',
  team: 'Tim',
  alert: 'Waspada',
  listener: 'Pendengar',
  forgotPassword: 'Lupa Kata Sandi?',
  forgotPasswordTitle: 'Lupa Kata Sandi',
  sendPasswordResetEmail: 'Kirim Email Pengaturan Ulang Kata Sandi',
  sendingPasswordResetEmail: 'Mengirim email pengaturan ulang kata sandi',
  field: 'Bidang',
  description: 'Deskripsi',
  requestIntegration: 'Meminta integrasi lain',
  integrationDetails: 'Detail Integrasi',
  enterIntegrationDetails: 'Berikan detail integrasi',
  galarmEnterpriseFreeTrial: 'Mendaftar untuk uji coba gratis selama 14 hari',
  galarmEnterpriseFreeTrialDescription: 'Mengotomatiskan manajemen insiden',
  bySigningUpYouAgreeToOur: 'Dengan mendaftar, Anda menyetujui ketentuan',
  and: 'dan',
  alarmSubscribers: 'Pengikut Alarm',
  otherSubscribersCount: {
    one: '1 pengikut lagi yang tidak ada di kontak Anda',
    other: '{{count}} lebih banyak pengikut yang tidak ada dalam kontak Anda'
  },
  getAccountData: 'Ekspor Data Alarm',
  getAccountDataPrompt:
    'Berikan alamat email tempat kami akan mengirimkan file teks yang berisi semua alarm Anda.',
  getAccountDataConfirmation:
    'Harap diperhatikan bahwa kami akan mengirimi Anda file teks yang berisi semua alarm Anda melalui email di {{email}}.',
  sendingAccountData: 'Mengekspor data alarm...',
  sentAccountData: 'Data alarm dikirim ke {{email}}.',
  errorSendingAccountData: 'Kesalahan mengekspor data alarm: {{error}}',
  sendingEmailVerificationLink: 'Mengirim tautan verifikasi email',
  viewLogs: 'Lihat Log',
  message: 'Pesan',
  billing: 'Penagihan',
  newIncidentForAlert: 'Kejadian baru untuk pendengar {{alertName}}',
  clickNextAfterAddingMembers: 'Klik "Berikutnya" setelah menambahkan anggota',
  clickDoneAfterAddingAlerts:
    'Klik "Selesai" untuk menyelesaikan penyiapan dan masuk ke Dasbor.',
  alertCreatedSuccessfully:
    'Pendengar telah berhasil dibuat. Anda dapat memicu pendengar ini dari ikon menu tiga titik di depan nama pendengar.',
  fixSubscription: 'Perbaiki Langganan',
  fixSubscriptionDescription:
    'Kami telah mendeteksi adanya masalah dengan langganan Anda. Langganan Anda berisi {{numSeats}} kursi tetapi Anda telah menetapkan kunci untuk anggota {{numSeatsAssigned}}. Anda dapat menetapkan paling banyak {{numSeats}} kunci.',
  fixSubscriptionChooseOption:
    'Perbaiki langganan Anda dengan menggunakan salah satu opsi di bawah ini.',
  fixSubscriptionContactAdmin: 'Silakan hubungi administrator organisasi Anda.',
  unassignKeysFromMembers:
    'Batalkan penugasan setidaknya kunci {{extraKeysAssigned}} dari anggota',
  addMoreSeatsToSubscription:
    'Tambahkan setidaknya {{extraKeysAssigned}} kursi ke langganan Anda',
  revokeMembersKey: 'Mencabut Kunci Anggota',
  revokeMembersKeyDescription: 'Pilih anggota yang kuncinya ingin Anda cabut.',
  revoke: 'Mencabut',
  unableToRevokeMembersKey:
    'Terjadi kesalahan dalam mencabut kunci anggota. Silakan hubungi dukungan.',
  problemWithLoadingProducts: 'Masalah dengan memuat produk. {{error}}',
  verificationCompleted: 'Verifikasi Selesai',
  deviceAlreadyRegistered:
    'Nomor telepon ini sebelumnya telah diverifikasi pada perangkat ini.',
  phoneNumberAutoFilled:
    'Galarm akan menggunakan nomor telepon yang dipilih dari akun Google Anda.',
  unableToRegisterPreVerifiedUser:
    'Tidak dapat mendaftarkan pengguna yang telah diverifikasi sebelumnya.',
  allowNotifications: 'Izinkan Pemberitahuan',
  allowNotificationsDescription: 'Untuk menampilkan alarm saat berdering.',
  scheduleExactAlarms: 'Jadwalkan Alarm',
  scheduleExactAlarmsHelperText:
    'Izinkan Galarm membunyikan alarm pada waktu yang tepat.',
  scheduleExactAlarmsDescription:
    'Untuk membunyikan alarm pada waktu yang tepat.',
  disableBatteryOptimization: 'Mengelola Pengoptimalan Baterai',
  disableBatteryOptimizationDescription:
    'Untuk memungkinkan Galarm berjalan di latar belakang.',
  requestRequiredPermissions:
    'Galarm memerlukan izin berikut ini untuk membunyikan alarm',
  allowDisallowedPermissions: 'Ketuk untuk mengizinkan',
  notificationsAreEnabled: 'Izin untuk menampilkan notifikasi sudah diizinkan.',
  connectingToStore: 'Menghubungkan ke toko...',
  restartApp: 'Mulai Ulang Aplikasi',
  updateAvailable: 'Pembaruan Tersedia',
  english: 'Bahasa Inggris',
  russian: 'Rusia',
  german: 'Bahasa Jerman',
  french: 'Bahasa Prancis',
  spanish: 'Bahasa Spanyol',
  italian: 'Bahasa Italia',
  portuguese: 'Bahasa Portugis',
  japanese: 'Bahasa Jepang',
  korean: 'Bahasa Korea',
  indonesian: 'Bahasa Indonesia',
  changeAppLanguage: 'Mengubah Bahasa Aplikasi',
  appLanguage: 'Bahasa Aplikasi',
  iosChangeAppLanguagePara1:
    'Galarm memilih bahasa aplikasi dari pengaturan ponsel Anda. Ikuti langkah-langkah berikut untuk mengubah bahasa aplikasi Galarm:',
  iosChangeAppLanguageInstruction1:
    'Buka "Pengaturan Telepon > Umum > Bahasa & Wilayah". Pastikan bahasa yang ingin Anda gunakan untuk Galarm ada dalam daftar bahasa pilihan.',
  iosChangeAppLanguageInstruction2:
    'Kembali ke "Pengaturan Telepon" dan ketuk "Galarm" dalam daftar aplikasi di bagian bawah.',
  iosChangeAppLanguageInstruction3:
    'Ketuk "Bahasa" dan pilih bahasa yang ingin Anda atur untuk Galarm. Kembali ke aplikasi setelah memilih bahasa',
  androidChangeAppLanguagePara1:
    'Galarm memilih bahasa aplikasi dari pengaturan ponsel Anda. Ikuti langkah-langkah berikut untuk mengubah bahasa aplikasi Galarm:',
  androidChangeAppLanguageInstruction1:
    'Ketuk tombol "Buka Pengaturan Aplikasi" di bawah ini.',
  androidChangeAppLanguageInstruction2:
    'Ketuk "Bahasa" dan pilih bahasa yang ingin Anda gunakan untuk Galarm.',
  androidChangeAppLanguageInstruction3:
    'Setelah memilih bahasa, kembali ke sini dan klik tombol "Mulai Ulang Aplikasi" di bawah ini.',
  problemRetrievingSubscriptionData:
    'Ada masalah dalam mengambil informasi langganan Anda. Kami mengarahkan Anda ke halaman akun. Silakan periksa status langganan Anda dan hubungi dukungan teknis jika Anda terus mengalami masalah.',
  deletingOrganization: 'Menghapus organisasi...',
  deleteOrganization: 'Menghapus Organisasi',
  deleteOrganizationDescription:
    'Apakah Anda yakin ingin menghapus organisasi Anda?\n  \n  Ini adalah operasi yang tidak dapat diubah. Semua data organisasi Anda akan dihapus.\n  \n  Pastikan Anda telah membatalkan langganan sebelum menghapus organisasi.',
  galarmEnterpriseTrialDescription:
    'Terdapat uji coba gratis selama 14 hari di mana Anda bisa membatalkan langganan tanpa dikenakan biaya apa pun.',
  galarmEnterpriseTrialAlreadyAvailed:
    'Anda telah memanfaatkan uji coba gratis selama 14 hari. Hubungi dukungan jika Anda membutuhkan lebih banyak waktu untuk mengevaluasi produk.',
  signinWithGoogle: 'Masuk dengan Google',
  signinWithGithub: 'Masuk dengan GitHub',
  verifyAccount: 'Verifikasi Akun',
  verifyAccountMessage:
    'Anda seharusnya telah menerima email berisi kode verifikasi. Masukkan kode di bawah ini untuk memverifikasi akun Anda. Jika Anda belum menerima kode, Anda dapat meminta kode baru dengan mengeklik tombol "Kirim Ulang Kode".',
  verificationCode: 'Kode Verifikasi',
  optionalString: '{{string}}',
  quickReminderTitle: 'Judul (opsional)',
  accountVerified: 'Akun Terverifikasi',
  accountVerifiedMessage: 'Silakan masuk menggunakan kredensial Anda.',
  multipleUsersWithSameEmail:
    'Ada beberapa pengguna dengan email yang sama. Silakan hubungi dukungan.',
  multipleUsersWithSameMobileNumber:
    'Ada beberapa pengguna dengan nomor ponsel yang sama. Silakan hubungi dukungan.',
  startTrial: 'Mulai Uji Coba',
  nextSteps: 'Langkah Selanjutnya',
  firstTimeSetupComplete:
    'Anda telah menyelesaikan penyiapan awal untuk Galarm Enterprise dan membuat pendengar pertama Anda. Berikut ini adalah beberapa langkah selanjutnya yang dapat Anda lakukan dari sini.',
  testTheAlertInstruction:
    'Lihat petunjuk di {{link}} untuk mengetahui cara menguji pendengar yang baru saja Anda buat.',
  integrateTheAlertInstruction:
    'Lihat petunjuk di {{link}} untuk mengintegrasikan pendengar dengan berbagai alat pemantauan.',
  createMoreAlertsInstruction:
    'Anda dapat menambahkan lebih banyak pendengar dengan membuka tab "Pendengar" di sebelah kiri.',
  addMoreMembersInstruction:
    'Anda dapat menambahkan lebih banyak anggota dengan membuka tab "Anggota" di sebelah kiri.',
  createTeamsInstruction:
    'Anda dapat mengelompokkan anggota ke dalam "Tim" dan memperingatkan seluruh tim ketika terjadi insiden. Buat tim dengan membuka tab "Tim" di sebelah kiri dan gunakan dalam konfigurasi pendengar.',
  testTheAlert: 'Menguji Pendengar',
  integrateTheAlert: 'Mengintegrasikan Pendengar',
  createMoreAlerts: 'Buat Lebih Banyak Pendengar',
  addMoreMembers: 'Tambahkan Lebih Banyak Anggota',
  createTeams: 'Buat Tim',
  docs: 'Docs',
  contactSupport: 'Hubungi Dukungan',
  pastDue: 'Lewat Jatuh Tempo',
  canceled: 'Dibatalkan',
  unpaid: 'Belum dibayar',
  incomplete: 'Tidak lengkap',
  incomplete_expired: 'Tidak Lengkap Kadaluarsa',
  trialing: 'Uji Coba Gratis',
  paused: 'Berhenti sejenak',
  plan: 'Rencana',
  status: 'Status',
  renewsOn: 'Memperbaharui',
  yourRoles: 'Peran Anda',
  enterprisePlan: '{{quantity}} kursi, memperbarui setiap {{interval}}',
  currentOrganization: 'Organisasi saat ini',
  useThisOrganization: 'Gunakan organisasi ini',
  fixPayment: 'Perbaiki Pembayaran',
  fixingPayment: 'Memperbaiki pembayaran',
  addPaymentInformation: 'Tambahkan Informasi Pembayaran',
  noPaymentInformationAvailable:
    'Tidak ada informasi pembayaran yang tersedia. Silakan refresh halaman jika Anda baru saja menambahkan informasi pembayaran.',
  openBillingPortal: 'Buka Portal Penagihan',
  startupWizardDescription:
    'Wizard ini akan membantu Anda memulai dan membuat pendengar pertama Anda.',
  userNotFound: 'Pengguna tidak ditemukan',
  wrongPassword: 'Kata sandi salah',
  emailAlreadyInUse: 'Email yang sudah digunakan',
  restoreDeletedAlarms: 'Mengembalikan Alarm yang Dihapus',
  deleteAlarmAlert:
    'Alarm yang dihapus dapat dipulihkan dari "Alarm yang Baru Saja Dihapus". Anda dapat melihatnya dengan mengetuk menu tiga titik (⋮) di kanan atas tab "Alarm".\n\nAlarm yang dihapus akan tetap berada di sana selama 30 hari, setelah itu alarm akan dihapus secara permanen.',
  slackVerificationCode:
    'Kode Verifikasi Slack Anda adalah {{verificationCode}}.',
  dndAccess: 'Jangan Ganggu Akses',
  dndHelperText:
    'Izinkan Galarm membunyikan alarm ketika ponsel Anda dalam mode Jangan Ganggu.',
  cantRestoreInstantAlarmMessage:
    'Alarm instan tidak dapat dipulihkan. Anda dapat melihat obrolan dan informasi lain untuk alarm ini jika diperlukan. Alarm ini akan dihapus secara permanen setelah 30 hari sejak penghapusan.',
  upgradeToUnlockAllFeatures: 'Tingkatkan untuk membuka kunci semua fitur',
  otherSignupOptions: 'Opsi Pendaftaran Lainnya:',
  renewSubscription: 'Perpanjang Langganan',
  subscriptionOnHold:
    'Ditahan (Paket Anda memungkinkan kursi {{subscriptionQty}} \ntetapi Anda telah menetapkan kursi {{numSubscriptionKeys}}.',
  checklists: 'Tugas',
  checklist: 'Daftar Tugas',
  typeToAddTask: 'Ketik untuk menambahkan tugas',
  specifyTaskName: 'Menentukan nama tugas',
  otherChecklists: 'Daftar Tugas',
  defaultChecklistName: 'tanpa judul',
  addTasksToChecklist:
    'Tambahkan tugas menggunakan kotak input di bagian bawah',
  addTasksToDefaultChecklist:
    'Tambahkan tugas menggunakan kotak input di bagian bawah... atau mulai daftar tugas baru dengan mengeklik tombol + di kanan atas',
  createNewChecklist:
    'Anda dapat membuat daftar tugas baru untuk melacak tugas-tugas terkait di satu tempat.',
  addNewChecklist: 'Daftar Tugas Baru',
  alarmPhoto: 'Foto Alarm',
  alarmPhotoFeatureTitle: 'Foto Alarm',
  alarmPhotoFeatureDescriptionPremiumUser:
    'Dengan menggunakan fitur ini, Anda dapat menambahkan foto ke alarm untuk petunjuk visual yang cepat, misalnya foto: obat, instruksi untuk tugas, item, dll. Foto akan ditampilkan ketika alarm berdering.',
  alarmPhotoFeatureDescriptionFreeUser:
    'Dengan menggunakan fitur ini, Anda dapat menambahkan foto ke alarm untuk petunjuk visual yang cepat, misalnya foto: obat, instruksi untuk tugas, item, dll. Foto akan ditampilkan ketika alarm berdering.\n  \nIni adalah fitur premium. Anda akan diminta untuk meningkatkan ke premium untuk menggunakan fitur ini.',
  alarmPhotoPremiumFeature: 'Menambahkan Foto ke Catatan Alarm',
  installPendingAppUpdate:
    'Pembaruan aplikasi terbaru telah diunduh dan siap diinstal.',
  numTasks: {
    zero: 'Tidak ada tugas',
    one: '1 tugas',
    other: '{{count}} tugas'
  },
  numTasksWithCompleted: {
    one: '1 tugas, {{completed}} selesai',
    other: '{{count}} tugas, {{completed}} selesai'
  },
  muteAlarms: 'Membisukan Alarm',
  unmuteAlarms: 'Bunyikan Alarm',
  alarmsAreMuted:
    'Semua alarm Anda saat ini dibisukan. Ketuk untuk membunyikan.',
  alarmsHaveBeenMuted:
    'Alarm Anda telah dibisukan. Alarm tidak akan berdering sampai Anda membunyikannya.',
  alarmsHaveBeenUnmuted:
    'Alarm Anda telah dibisukan. Alarm akan berdering seperti yang dikonfigurasikan.',
  deleteChecklist: 'Menghapus Daftar Tugas',
  confirmDeleteChecklist: 'Menghapus Daftar Tugas?',
  confirmDeleteCompletedTasks: 'Menghapus Tugas yang Sudah Selesai?',
  confirmResetCompletedTasks: 'Mengatur Ulang Tugas yang Telah Selesai?',
  confirmDeleteTask: 'Hapus {{taskName}}?',
  showCompletedTasks: 'Tampilkan Tugas yang Telah Selesai',
  hideCompletedTasks: 'Sembunyikan Tugas yang Sudah Selesai',
  deleteCompletedTasks: 'Menghapus Tugas yang Sudah Selesai',
  resetCompletedTasks: 'Mengatur Ulang Tugas yang Telah Selesai',
  yourGroups: 'Grup Anda',
  newAlarmWith: 'Alarm Baru dengan {{name}}',
  newGroupWith: 'Grup Baru dengan {{name}}',
  alertContact: 'Waspada {{name}}',
  alertGroup: 'Waspada {{name}}',
  instantAlarmMovedToFabNotice:
    'Fitur Alarm Instan sekarang tersedia dari tombol "+" di kanan bawah layar ini. Ikon "loudspeaker" akan dihapus dari kanan atas dalam rilis mendatang.',
  tasksIntroTitle: 'Tugas dan Daftar Tugas',
  tasksIntroDescription:
    'Tugas adalah daftar pekerjaan Anda yang tidak memiliki tenggat waktu. Anda dapat dengan cepat menambahkan dan mengelolanya di sini, mencentangnya saat Anda menangani setiap tugas.\n\nAnda juga dapat membuat daftar tugas untuk mengelompokkan tugas-tugas serupa. Misalnya, Anda dapat membuat daftar tugas untuk belanja bahan makanan, daftar tugas lainnya untuk tugas pekerjaan, dan seterusnya.',
  groupsMovedToContactsScreen:
    '"Grup" telah digabungkan ke dalam tab ini. Grup Anda muncul di bagian atas di bawah "Grup Anda". Anda dapat membuat grup baru dengan mengetuk tombol "+" di kanan bawah.',
  loadingTasks: 'Memuat tugas...',
  tryPremium: 'Coba Premium',
  buyPremiumToUnlockFeature:
    'Ini adalah fitur premium. Buka fitur ini dan banyak fitur keren lainnya dengan paket premium kami yang dilengkapi dengan uji coba gratis selama 14 hari.',
  newTask: 'Tugas Baru',
  newTaskList: 'Daftar Tugas Baru',
  enterTaskName: 'Masukkan nama tugas',
  enterTaskListName: 'Masukkan nama daftar tugas',
  taskName: 'Nama Tugas',
  taskListName: 'Nama Daftar Tugas',
  checklistTasks: '{{name}}',
  editTaskListName: 'Mengedit nama daftar tugas',
  editTask: 'Edit tugas',
  releaseNotes: 'Catatan Rilis',
  currentRelease: '(saat ini terpasang)',
  loadingReleaseNotes:
    'Memuat catatan rilis. Pastikan Anda terhubung ke internet.',
  id: 'ID',
  subscriptionData: 'Data Langganan',
  organizations: 'Organisasi',
  showRotationCalendar: 'Tampilkan Kalender Rotasi',
  rotationCalendar: 'Kalender Rotasi',
  editShift: 'Edit Shift',
  startDateForShift: 'Tanggal mulai kerja untuk shift',
  startTimeForShift: 'Waktu mulai kerja untuk shift',
  endDateForShift: 'Tanggal akhir untuk shift',
  endTimeForShift: 'Waktu berakhirnya shift',
  editingShift: 'Pergeseran pengeditan',
  addingShift: 'Menambahkan shift',
  products: 'Produk',
  prices: 'Rencana',
  planType: 'Jenis Paket',
  business: 'Bisnis',
  enterprise: 'Perusahaan',
  compare: 'Bandingkan',
  apiSupport: 'Dukungan API untuk memantau layanan',
  accessToConsoleToViewAndCloseIncidents: 'Dasbor untuk mengelola insiden',
  triggerInstantAlertsFromTheConsole: 'Memicu insiden dari Konsol Enterprise',
  coreFeatures: 'Fitur Inti:',
  everythingInBusiness: 'Semua yang ada di Rencana bisnis dan:',
  updatePlanInstructions:
    'Anda dapat memperbarui paket Anda dengan mengklik tombol "Buka Portal Penagihan" di sebelah kanan dan memilih tombol "Perbarui Paket" pada portal penagihan.',
  membersDescription:
    'Anda telah membeli kursi {{subscriptionKeysCount}} - {{assignedSubscriptionKeysCount}} ditugaskan dan {{unassignedSubscriptionKeysCount}} tersedia. Tanda centang di depan nama anggota menunjukkan kursi yang telah ditetapkan.',
  centralBilling: 'Penagihan Pusat',
  saveTwoMonths: 'Hemat 2 bulan',
  owner: 'Pemilik',
  enterpriseAccountSummary:
    'Anda adalah bagian dari organisasi {{organizationName}} yang terdaftar dalam paket Galarm Pro {{planType}}.',
  perYearPerUser: 'per pengguna/tahun',
  perMonthPerUser: 'per pengguna/bulan',
  creationDate: 'Tanggal Pembuatan',
  updatePhoneNumber: 'Ubah Nomor Telepon',
  confirmCountryCodeAndEnterUpdatedPhoneNumber:
    'Konfirmasikan kode negara dan masukkan nomor telepon Anda yang baru. Akun Anda akan ditautkan ke nomor telepon ini setelah Anda menyelesaikan verifikasi.',
  unableToUpdatePhoneNumber:
    'Tidak dapat mengubah nomor telepon: {{error}}. Silakan hubungi dukungan.',
  phoneNumberUpdated: 'Nomor telepon berhasil diubah.',
  phoneNumberUpdatedMessage:
    'Anda dapat mulai menggunakan aplikasi secara normal. Minta kontak Anda untuk memperbarui nomor telepon Anda di buku alamat mereka.',
  memberChangedPhoneNumber:
    '{{memberName}} telah mengubah nomor telepon mereka. Perbarui buku alamat Anda untuk terus menambahkannya ke alarm.',
  preferredSourceForIncidents: 'Sumber Pilihan untuk Insiden',
  apiCodesDescription:
    'Gunakan petunjuk berikut untuk mengintegrasikan pendengar dengan sumber',
  rotation: 'Rotasi',
  noAlarmsForThisDay: 'Tidak ada alarm untuk hari ini',
  markAlarmsAsComplete: 'Tandai alarm sebagai selesai',
  markAlarmsAsCompleteExplanation:
    'Satu kali alarm akan ditandai sebagai selesai dan akan muncul di bagian alarm "Tidak aktif".\n\nAlarm yang berulang akan ditandai sebagai selesai dan akan dipindahkan ke kejadian berikutnya.',
  enterIncidentPrefix: 'Maksimal 5 karakter',
  incidentPrefix: 'Awalan Insiden',
  incidentPrefixAlreadyExists: 'Awalan insiden sudah ada',
  removeTask: 'Hapus Tugas?',
  incidentPrefixLengthExceeded: 'Awalan insiden lebih panjang dari 5 karakter',
  criticalAlerts: 'Peringatan Kritis',
  criticalAlertsConfiguration:
    'Alarm akan berdering pada {{criticalAlertsVolume}}% volume bahkan ketika ponsel Anda dibisukan atau Fokus aktif.',
  criticalAlertsDisabledShort:
    'Alarm tidak akan berdering ketika ponsel Anda dibisukan.',
  criticalAlertsConfigurationGlobal:
    'Alarm akan berdering pada volume {{criticalAlertsVolume}}% bahkan ketika ponsel Anda dibisukan atau Fokus aktif.',
  criticalAlertsDisabledShortGlobal:
    'Alarm tidak akan berdering ketika ponsel Anda dibisukan.',
  criticalAlertsEnabled:
    'Peringatan Kritis diaktifkan. Alarm akan berdering meskipun ponsel Anda dibisukan atau Fokus aktif.',
  criticalAlertsDisabled:
    'Peringatan Kritis dinonaktifkan. Alarm tidak akan berdering saat ponsel Anda dibisukan.',
  enableCriticalAlerts: 'Mengaktifkan Peringatan Kritis',
  summer_soft: 'Musim Panas Lembut',
  beeps: 'Bunyi bip',
  bollywood_nights: 'Malam Bollywood',
  gentle_reminder: 'Pengingat Lembut',
  himalayan_piper: 'Piper Himalaya',
  holiday_joy: 'Kegembiraan Liburan',
  hollywood_adventure: 'Petualangan Hollywood',
  melody: 'Melodi',
  morning_rooster: 'Ayam jantan pagi',
  morning_sunshine: 'Sinar Matahari Pagi',
  sweet_sea: 'Laut Manis',
  old_school_ring: 'Tring Tring',
  MyAlarmNotification: '{{alarmName}} - Sudah waktunya.',
  ParticipantCascadingAlarmNotification:
    '{{alarmName}} - Saatnya untuk mengingatkan {{alarmCreatorName}}.',
  ParticipantSimultaneousAlarmNotification:
    '{{alarmName}}. Saatnya mengonfirmasi partisipasi Anda.',
  InstantAlarmNotification: '{{alarmName}}. Mohon perhatian Anda segera.',
  MyAlarmNotificationShort: 'Sudah waktunya.',
  ParticipantCascadingAlarmNotificationShort:
    'Saatnya untuk mengingatkan {{alarmCreatorName}}.',
  ParticipantSimultaneousAlarmNotificationShort:
    'Saatnya mengonfirmasi keikutsertaan Anda.',
  InstantAlarmNotificationShort: 'Mohon perhatian Anda segera.',
  MyRecipientAlarmNotification:
    '{{alarmName}} - Saatnya untuk mengingatkan {{alarmRecipientName}}.',
  MyRecipientAlarmNotificationShort:
    'Saatnya untuk mengingatkan {{alarmRecipientName}}.',
  RecipientAlarmNotification:
    '{{alarmName}} (oleh {{alarmCreatorName}}) - Sudah waktunya.',
  RecipientAlarmNotificationShort:
    'Sudah waktunya - oleh {{alarmCreatorName}}.',
  calendarView: 'Kalender Galarm',
  noAlarmsForThisDay: 'Tidak ada alarm untuk hari ini',
  selectADate: 'Silakan pilih tanggal untuk melihat alarm'
}
