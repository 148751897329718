module.exports = {
  alarms: 'Alarme',
  contacts: 'Kontakte',
  groups: 'gruppen',
  settings: 'Einstellungen',
  notificationsTab: 'Benachrichtigungen',
  specifyTimeInFutureForAlarm:
    'Der Alarm darf nicht in der Vergangenheit liegen.',
  specifyTimeInFutureForPreReminder:
    'Die Vorab-Erinnerung für den Alarm liegt in der Vergangenheit.',
  specifyNameForGroup: 'Der Name der Gruppe ist erforderlich.',
  updatingGroupName: 'Aktualisieren von Gruppeninformationen.',
  unableToEditGroupName:
    'Die Gruppeninformationen können nicht aktualisiert werden. Bitte versuchen Sie es nach einiger Zeit erneut.',
  selectSmallerImage:
    'Das Bild ist größer als 10 MB. Wählen Sie ein kleineres Bild.',
  sendingVerificationCode:
    'Übermittlung des Verifizierungscodes an {{formattedMobileNumber}}.',
  verifyingCode: 'Überprüfen des Codes.',
  creatingUserProfile: 'Einstellen...',
  unabeToCreateUserProfile:
    'Sie können zu dieser Zeit kein Konto einrichten. Bitte versuchen Sie es in einigen Augenblicken erneut.',
  unableToSendVerificationCode:
    'Überprüfungscode kann nicht gesendet werden. {{error}}',
  unableToVerifyCode: 'Code kann nicht verifiziert werden. {{error}}',
  unableToRegisterUserWithoutMobileNumber:
    'Die Einrichtung kann nicht durchgeführt werden. {{error}}',
  permissionDeniedToAccessCameraIos:
    'Galarm hat keine Berechtigung, auf die Kamera zuzugreifen. \n\nGehen Sie zu Einstellungen > Galarm und erlauben Sie den Zugriff auf die Kamera.',
  permissionDeniedToAccessCameraAndroid:
    'Galarm hat keine Berechtigung, auf die Kamera zuzugreifen.\n\nGehen Sie zu Einstellungen > Apps > Galarm > Berechtigungen und erlauben Sie den Zugriff auf die Kamera.',
  permissionDeniedToAccessPhotosIos:
    'Galarm hat keine Berechtigung, auf Fotos zuzugreifen.\n\nGehen Sie zu Einstellungen > Galarm und erlauben Sie den Zugriff auf Fotos.',
  permissionDeniedToAccessPhotosAndroid:
    'Galarm hat keine Berechtigung, auf Fotos zuzugreifen.\n\nGehen Sie zu Einstellungen > Apps > Galarm > Berechtigungen und erlauben Sie den Zugriff auf den Speicher.',
  permissionDeniedToAccessCalendarIos:
    'Galarm hat keine Berechtigung für den Zugriff auf den Kalender.\nGehen Sie zu Einstellungen > Kalender und erlauben Sie den Zugriff auf Galarm.',
  permissionDeniedToAccessCalendarAndroid:
    'Galarm hat keine Berechtigung für den Zugriff auf den Kalender.\nGehen Sie zu Einstellungen > Apps > Galarm > Berechtigungen und erlauben Sie den Zugriff auf den Kalender.',
  verificationCodeSent:
    'Der Verifizierungscode wird an {{formattedMobileNumber}} gesendet.',
  rateTheApp:
    'Wir hoffen, Sie finden Galarm nützlich. Bitte nehmen Sie sich einen Moment Zeit, um uns im Play Store zu bewerten. Vielen Dank für Ihre Unterstützung.',
  backgroundAppRefreshDisabledPermission:
    'Alarme klingeln möglicherweise nicht, weil Galarm keine Berechtigung hat, die App im Hintergrund zu aktualisieren.\n\nTippen Sie auf "Aktivieren", und aktivieren Sie "App-Hintergrundaktualisierung".',
  selectedGroupUnselected:
    'Sie können entweder eine Gruppe oder eine Gruppe von Kontakten als Teilnehmer auswählen. Ihre zuvor ausgewählte Gruppe wird abgewählt. Fortfahren?',
  selectedContactsUnselected:
    'Sie können entweder eine Gruppe oder eine Reihe von Kontakten als Teilnehmer auswählen. Ihre zuvor ausgewählten Kontakte werden abgewählt. Fortfahren?',
  notificationsDisabledIos:
    'Alarme werden nicht klingeln, weil Galarm keine Berechtigung hat, Benachrichtigungen anzuzeigen.\n\nGehen Sie zu Einstellungen > Galarm > Benachrichtigungen und aktivieren Sie "Alarme", "Klänge" und "Banner".',
  notificationsDisabledAndroid:
    'Alarm-Benachrichtigungen werden nicht auf dem Bildschirm angezeigt.\n\nTippen Sie auf "Aktivieren", wählen Sie "Benachrichtigungen" und lassen Sie Benachrichtigungen zu.',
  alarmNotificationChannelDisabled:
    'Alarm-Benachrichtigungen werden nicht mehr auf dem Bildschirm angezeigt. \n\nTippen Sie auf "Aktivieren", damit die Alarm-Benachrichtigungen auf dem Bildschirm angezeigt werden können.',
  alarmLockScreenNotificationlDisabled:
    'Alarm-Benachrichtigungen werden nicht mehr auf dem Sperrbildschirm angezeigt. \n\nTippen Sie auf "Aktivieren", damit die Alarm-Benachrichtigungen auf dem Sperrbildschirm angezeigt werden.',
  alarmWillNotRingDueToNotificationsDisabledIos:
    'Alarme klingeln nicht, da die Benachrichtigungen deaktiviert sind.\n\nGehen Sie zu Einstellungen > Galarm > Benachrichtigungen und aktivieren Sie "Alarme", "Klänge" und "Banner".',
  alarmWillNotRingDueToCriticalAlertsDisabledIos:
    'Alarme klingeln nicht, wenn das Telefon stumm geschaltet ist, da kritische Alarme deaktiviert sind.\n\nGehen Sie zu Einstellungen > Galarm > Benachrichtigungen und aktivieren Sie "Kritische Alarme".',
  alarmWillNotRingDueToNotificationsDisabledAndroid:
    'Der Alarm wird nicht auf dem Bildschirm angezeigt, da die Benachrichtigungen deaktiviert sind.\n\nTippen Sie auf "Aktivieren", wählen Sie "Benachrichtigungen" und lassen Sie Benachrichtigungen zu.',
  noNotificationsScheduledAlarmInPast:
    'Der Alarm liegt in der Vergangenheit. Es sind keine Benachrichtigungen geplant.',
  alarmOverAllowedLimit: {
    zero: '\nSie haben Ihre Alarmgrenze von {{limit}} erreicht.\n\nBitte löschen Sie einen bestehenden Alarm oder verwenden Sie ihn erneut.',
    one: 'Sie haben Ihre Alarmgrenze von {{limit}} erreicht.\n\nSie haben 1 inaktiven Alarm, den Sie löschen oder wiederverwenden können.',
    other:
      'Sie haben Ihre Alarmgrenze von {{limit}} erreicht.\n\nSie haben {{count}} inaktive Alarme, die Sie löschen oder wiederverwenden können.'
  },
  alarmAlreadyConfirmed: 'Sie haben es bereits bestätigt.',
  alarmAlreadyDeclined: 'Sie haben bereits abgelehnt.',
  participantMovedToPosition: '{{name}} auf die Position {{count}} verschoben.',
  memberIsFirstParticpant: '{{name}} ist der erste Teilnehmer.',
  memberIsLastParticpant: '{{name}} ist der letzte Teilnehmer.',
  cantContinueWithRegistrationWhenOffline:
    'Die Registrierung kann nicht fortgesetzt werden, da das Gerät offline ist.',
  cantContinueWithRegistrationWhenOfflineWithTryAgain:
    'Die Registrierung kann nicht fortgesetzt werden, da das Gerät offline ist. Stellen Sie sicher, dass Sie mit dem Internet verbunden sind.',
  confirmBlockContact:
    'Sie werden keine Alarme mehr von {{name}} erhalten. Weitermachen?',
  confirmUnblockContact:
    'Wenn Sie {{name}} entsperren, werden auch die blockierten Alarme wiederhergestellt. Fortfahren?',
  unblockingContact:
    'Aufhebung der Sperrung von {{name}} und Wiederherstellung von Alarmen.',
  unableToUnblockContact:
    'Die Sperrung des Kontakts kann nicht aufgehoben werden. {{error}}',
  blockingContact: 'Blockieren {{name}}.',
  unableToBlockContact: 'Der Kontakt kann nicht blockiert werden. {{error}}',
  cantAddContactToAlarmBecauseBlocked:
    'Sie haben {{name}} blockiert. Heben Sie die Blockierung auf, um sie als Teilnehmer des Alarms hinzuzufügen.',
  cantAddContactToGroupBecauseBlocked:
    'Sie haben {{name}} blockiert. Heben Sie die Blockierung auf, um sie als Mitglied der Gruppe hinzuzufügen.',
  sendMessageNotAllowedForPastAlarms:
    'Bei inaktiven Alarmen können keine Nachrichten gesendet werden.',
  nameIsRequired: 'Name ist erforderlich.',
  confirmDisableGroupAlarm:
    'Wenn Sie den Gruppenalarm deaktivieren, wird auch der Alarm für die anderen Teilnehmer deaktiviert. Fortfahren?',
  removeMemberFromGroup: 'Möchten Sie {{name}} aus dieser Gruppe entfernen?',
  leaveGroupConfirm: 'Willst du diese Gruppe verlassen?',
  deviceOffline:
    'Das Gerät ist offline. Stellen Sie sicher, dass Sie mit dem Internet verbunden sind und versuchen Sie es erneut.',
  cantUpdateGroupDefaulConfigWhenOffline:
    'Die geänderte Gruppenkonfiguration wird nicht auf zukünftige Alarme angewendet, da das Gerät offline ist.',
  specifyFeedback: 'Rückmeldung ist erforderlich.',
  specifyEmailId: 'Eine E-Mail-Adresse ist erforderlich.',
  specifyValidEmailId: 'Die E-Mail-Adresse ist ungültig.',
  specifyProblem: 'Eine Problembeschreibung ist erforderlich.',
  appInstalledUsingSameNumberOnDifferentPhone:
    'Sie haben Galarm auf einem anderen Gerät mit der gleichen Nummer installiert. Die App auf diesem Gerät wird zurückgesetzt.',
  invalidUid:
    'Dieses Benutzerkonto existiert nicht mehr. Die Galarm-App auf diesem Gerät wird zurückgesetzt.',
  accountDeleted: 'Ihr Konto wurde gelöscht.',
  deleteAccount: 'Mein Konto löschen',
  deletingAccount: 'Löschung Ihres Kontos',
  howCanWeImprove:
    'Wir bedauern, Sie gehen zu sehen. Wie können wir uns verbessern?',
  noMusicAlert: 'Keine Musikdateien gefunden.',
  noLongerAvailableMusic:
    '{{fileName}} ist nicht mehr verfügbar. Der Klingelton wurde geändert in {{defaultRingtoneName}}',
  userInactiveTitle: 'Willkommen zurück!',
  userInactiveMessage:
    'Erstellen Sie Alarme für wichtige Dinge und verpassen Sie sie nicht.',
  createAlarm: 'Alarm erstellen',
  updatedAppAvailable: 'Eine neue Version der App ist verfügbar.',
  alarmOccurrenceDisabled: 'Deaktiviert für dieses Vorkommnis',
  ownAlarmOccurrenceDone: {
    zero: 'Erledigt am {{timeString}}',
    one: 'Erledigt markiert unter {{timeString}} am {{dateString}}'
  },
  ownAlarmOccurrenceSkipped: {
    zero: 'Überspringen bei {{timeString}}',
    one: 'Überspringen auf {{timeString}} am {{dateString}}'
  },
  enabledAlarmsCount: {
    one: '1 Alarm aktiviert.',
    other: '{{count}} Alarme aktiviert.'
  },
  enabledAndNotEnabledAlarmsCount: {
    one: '1 Alarm aktiviert. Alarme ohne zukünftiges Auftreten bleiben deaktiviert.',
    other:
      '{{count}} Alarme aktiviert. Alarme ohne zukünftiges Auftreten bleiben deaktiviert.'
  },
  disabledAlarmsCount: {
    one: '1 Alarm deaktiviert.',
    other: '{{count}} Alarme deaktiviert.'
  },
  ownAlarmOccurrenceUnacknowledged: 'Nicht geantwortet',
  participantAlarmOccurrenceDone: {
    zero: 'Erledigt von {{participantName}} unter {{timeString}}',
    one: 'Erledigt von {{participantName}} unter {{timeString}} am {{dateString}}'
  },
  participantAlarmOccurrenceSkipped: {
    zero: 'Übersprungen von {{participantName}} unter {{timeString}}',
    one: 'Übersprungen von {{participantName}} unter {{timeString}} am {{dateString}}'
  },
  participantAlarmOccurrenceUnacknowledged:
    '{{participantName}} hat nicht geantwortet',
  groupAlarmOccurrenceConfirmed: {
    zero: 'Bestätigt am {{timeString}}',
    one: 'Bestätigt unter {{timeString}} am {{dateString}}'
  },
  groupAlarmOccurrenceDeclined: {
    zero: 'Abgelehnt bei {{timeString}}',
    one: 'Abgelehnt unter {{timeString}} am {{dateString}}'
  },
  warning: 'Warnung',
  info: 'Infos',
  confirm: 'Bestätigen',
  error: 'Fehler',
  cantUploadImage: 'Bild kann nicht hochgeladen werden',
  cantDeleteImage: 'Bild kann nicht gelöscht werden',
  cantEditGroup: 'Gruppe kann nicht bearbeitet werden',
  cantAddNewContact: 'Kann keinen neuen Kontakt hinzufügen',
  cantDeleteContact: 'Kontakt kann nicht gelöscht werden',
  cantEditContact: 'Kontakt kann nicht bearbeitet werden',
  cantSaveAlarm: 'Kann den Alarm nicht speichern',
  cantSaveGroup: 'Kann die Gruppe nicht speichern',
  cantReloadContacts: 'Kontakte können nicht neu geladen werden',
  cantTakePhoto: 'Kann kein Foto machen',
  cantExportToCalendar: 'Kann nicht in den Kalender exportieren',
  cantOpenPhotoLibrary: 'Fotobibliothek kann nicht geöffnet werden',
  cantDeleteAlarmHistory: 'Alarmverlauf kann nicht gelöscht werden',
  cannotDuplicateAlarm: 'Alarm kann nicht dupliziert werden',
  upgradeToPremiumToDuplicateAlarm:
    'Aktualisieren Sie auf Premium, um mehr Alarme für Gruppen und Freunde zu erstellen.',
  confirmAcceptAlarm: 'Akzeptieren Sie den Alarm?',
  confirmRejectAlarm: 'Ablehnen des Alarms?',
  deleteAlarm: 'Alarm löschen?',
  restoreAlarmConfirmation: 'Alarm wiederherstellen?',
  respondToRepeatingAlarm: {
    zero: 'Sie werden von {{alarmCreatorName}} als Empfänger des Alarms {{alarmName}} hinzugefügt. Sie werden unter {{alarmTimeString}} {{alarmRepetitionString}} alarmiert. {{alarmDateString}}',
    one: 'Sie werden als Teilnehmer des Alarms {{alarmName}} von {{alarmCreatorName}} hinzugefügt. Sie werden unter {{alarmTimeString}} {{alarmRepetitionString}} alarmiert. {{alarmDateString}}'
  },
  respondToOneTimeAlarm: {
    zero: 'Sie werden als Empfänger des Alarms {{alarmName}} von {{alarmCreatorName}} hinzugefügt. Sie werden unter {{alarmTimeString}} alarmiert. {{alarmDateString}}',
    one: 'Sie werden als Teilnehmer des Alarms {{alarmName}} von {{alarmCreatorName}} hinzugefügt. Sie werden unter {{alarmTimeString}} alarmiert. {{alarmDateString}}'
  },
  cantMoveMemberUp: "Can't Move {{name}} Up",
  cantMoveMemberDown: "Can't Move {{name}} Down",
  decline: 'Ablehnen',
  cantEditProfile: 'Profil kann nicht bearbeitet werden',
  cantSelectContact: 'Kontakt kann nicht ausgewählt werden',
  cantSendMessage: 'Nachricht kann nicht gesendet werden',
  cantSendVerificationCode: 'Verifizierungscode kann nicht gesendet werden',
  confirmBlockContactTitle: 'Bestätigen Sie den Block Kontakt',
  confirmUnblockContactTitle: 'Bestätigen Sie den Kontakt entsperren',
  cantSaveProfile: 'Profil kann nicht gespeichert werden',
  cantBlockContact: 'Kontakt kann nicht blockiert werden',
  cantUnblockContact: 'Kontakt kann nicht entsperrt werden',
  cantLoadEarlierMessages: 'Frühere Nachrichten können nicht geladen werden',
  cantRestoreAlarm: 'Alarm kann nicht wiederhergestellt werden',
  cantSendFeedback: 'Rückmeldung kann nicht übermittelt werden',
  cantSubmitProblem: 'Kann nicht einreichen Problem',
  cantDeletePastAlarmsInBulk:
    'Inaktive Alarme können nicht massenhaft gelöscht werden',
  cantAddNewGroup: 'Neue Gruppe kann nicht hinzugefügt werden',
  alreadySnoozing: 'Schon geschlummert',
  markAlarmUndone:
    "Sie haben diesen Alarm vor {{durationString}} als 'Erledigt' markiert. Rückgängig machen?",
  markAlarmUnskipped:
    'Sie haben diesen Alarm vor {{durationString}} übersprungen. Rückgängig machen?',
  cantUndo: 'Kann nicht rückgängig gemacht werden',
  fetchMusicFromPhone: 'Suche nach verfügbarer Musik.',
  enableAllAlarmsInCategory: 'Alle Alarme in dieser Kategorie aktivieren?',
  disableAllAlarmsInCategory: 'Alle Alarme in dieser Kategorie deaktivieren?',
  allAlarmsAlreadyEnabled:
    'Alle Alarme in dieser Kategorie sind bereits aktiviert.',
  allAlarmsAlreadyDisabled:
    'Alle Alarme in dieser Kategorie sind bereits deaktiviert.',
  time: 'Zeit',
  timeForBuddy: 'Zeit für Buddy',
  timeForYou: 'Erinnern Sie mich an',
  name: 'Name',
  title: 'titel',
  notes: 'notizen',
  repeat: 'Wiederholen',
  preReminder: 'Vorab-Erinnerung',
  remove: 'entfernen',
  preReminderShortAlert: '{{duration}} Vorab-Erinnerung',
  preReminderTitle: '{{alarmName}} unter {{date}}',
  preReminderGroupHelperText:
    'Diese Vorab-Erinnerung ist nur für Sie bestimmt. Andere Teilnehmer des Alarms können ihre eigenen Vorab-Erinnerungen einstellen, sobald sie den Alarm erhalten.',
  addPreReminder: 'Vorab-Erinnerung hinzufügen',
  helperTextForPreReminder:
    'Tippen Sie oben auf die Reset-Taste, um die Vorab-Erinnerung zu entfernen.',
  edit: 'bearbeiten',
  delete: 'Löschen',
  creator: 'Schöpfer',
  useAsDefaultGroupConfiguration: 'Auf zukünftige Alarme anwenden',
  done: 'Erledigt',
  skipped: 'Überspringen',
  save: 'Speichern',
  members: 'Mitglieder',
  mobileNumber: 'Rufnummer',
  enter: 'Eingabe',
  addMembers: 'Mitglieder hinzufügen',
  respond: 'Antworten Sie',
  yes: 'Ja',
  no: 'Nein',
  cancel: 'Abbrechen',
  participants: 'Teilnehmer',
  takePhoto: 'Foto aufnehmen',
  chooseFromLibrary: 'Aus Bibliothek wählen',
  resendCode: 'Code erneut senden',
  register: 'Register',
  inviteFriends: 'Diese App teilen',
  invite: 'Einladen',
  incorrectNumber: 'Falsche Nummer?',
  date: 'datum',
  cascadingAlarmInterval: 'Zeit zwischen den Warnmeldungen der Teilnehmer',
  ringParticipantAlarmsByDefault: 'Alarme klingeln lassen, ohne zu akzeptieren',
  alarmRingtone: 'Alarm-Klingelton',
  pickASong: 'Musik vom Telefon auswählen',
  privacyPolicy: 'Datenschutzbestimmungen',
  sendFeedback: 'Rückmeldung',
  galarmWeb: 'Galarm Web',
  galarmEnterprise: 'Galarm Pro',
  galarmAdmin: 'Galarm Verwaltung',
  troubleshooting: 'Fehlersuche',
  alarmDidntRingHelp: 'Hilfe! Mein alarm hat nicht geklingelt',
  autoStartSettingHelperText:
    'Wenn Ihr Telefon über eine Autostart-Einstellung verfügt, vergewissern Sie sich bitte, dass der Autostart für Galarm aktiviert ist.',
  batteryOptimizationHelperText: 'Batterieoptimierung für Galarm deaktivieren',
  troubleshootingHelperText:
    'Die folgenden Einstellungen verhindern möglicherweise, dass die Alarme wie erwartet klingeln. Bitte überprüfen Sie diese Einstellungen.',
  getMoreHelpText: 'Haben Sie immer noch Probleme?',
  autoStartSetting: 'Autostart',
  batteryOptimization: 'Optimierung der Batterie',
  battery: 'Batterie',
  rateTheAppSettings: 'Diese App bewerten',
  about: 'Über & Hilfe',
  leaveGroup: 'Gruppe verlassen',
  removeGroup: 'Gruppe entfernen',
  viewMember: 'Ansicht {{memberName}}',
  removeMember: 'entfernen {{memberName}}',
  changeMemberState: 'Ändern, wer alarmieren kann {{memberName}}',
  changeYourGroupState: 'Ändern Sie, wer Sie alarmieren kann',
  numMembersInGroup: 'Mitglieder: {{count}}',
  notifications: 'App-Benachrichtigungen sind deaktiviert',
  backgroundAppRefresh: 'App-Aktualisierung im Hintergrund ist deaktiviert',
  groupsInCommon: 'Gemeinsame Gruppen',
  alarmsInCommon: 'Gemeinsame Alarme',
  close: 'Schließen Sie',
  responsePending: 'Ihre Antwort?',
  later: 'Später',
  install: 'Installieren Sie',
  all: 'Alle',
  numYears: { one: '1 Jahr', other: '{{count}} Jahre' },
  numMonths: { one: '1 Monat', other: '{{count}} Monate' },
  numDays: { one: '1 Tag', other: '{{count}} Tage' },
  hours: { one: '1 Stunde', other: '{{count}} Stunden' },
  minutes: { one: '1 Minute', other: '{{count}} Minuten' },
  seconds: { one: '1 Sekunde', other: '{{count}} Sekunden' },
  minutesSmall: '{{count}} min',
  secondsSmall: '{{count}} sec',
  hoursAfter: { one: '1 Stunde nach', other: '{{count}} Stunden nach' },
  minutesAfter: { one: '1 Minute nach', other: '{{count}} Minuten nach' },
  hoursAfterBuddy: {
    one: '1 Stunde nach freund',
    other: '{{count}} Stunden nach dem Freund'
  },
  minutesAfterBuddy: {
    one: '1 Minute nach freund',
    other: '{{count}} Minuten nach dem Freund'
  },
  longerThanAnHour: '> 1 Stunde',
  licenses: 'Lizenzierung von Drittanbietern',
  version: 'Version',
  termsOfService: 'Bedingungen der Dienstleistung',
  showPrivacyPolicy: '> Datenschutzbestimmungen',
  showTermsOfService: '> Allgemeine Geschäftsbedingungen',
  showLicenses: '> Lizenzierung von Drittanbietern',
  change: 'Ändern Sie',
  snooze: 'Schlummern',
  ringOnVibrate: 'Klingeln auf Stumm',
  vibrate: 'Vibrieren',
  ringOnEarphoneOnly: 'Klingel-Alarm nur über Kopfhörer',
  ringOnSpeakerAndEarphone: 'Klingelalarm über Lautsprecher und Kopfhörer',
  whenEarphoneIsConnected: 'Wenn Kopfhörer angeschlossen sind',
  tapGesture: 'Tippen Sie auf .',
  slideGesture: 'Dia',
  silent: 'Stumm',
  volume: 'Lautstärke',
  soundAndVibration: 'Klänge und Vibrationen',
  advancedSettings: 'Erweiterte Einstellungen',
  gestureToDismissAnAlarm: 'Geste zum Ignorieren eines Alarms',
  someNotificationsAreMuted: 'Einige Benachrichtigungen sind stummgeschaltet',
  allNotificationsEnabled: 'Alle Benachrichtigungen sind aktiviert',
  notificationSettings: 'Einstellungen für die Benachrichtigung',
  galarmWebNotifications: 'Galarm Web-Benachrichtigungen',
  alarmsfromOthersNotifications: 'Alarme der Teilnehmer Benachrichtigungen',
  sharedAlarmNotifications: 'Gemeinsame Alarm-Benachrichtigungen',
  alarmChatNotifictions: 'Alarm Chat-Benachrichtigungen',
  groupNotifications: 'Gruppen-Benachrichtigungen',
  alarmAcknowledgementNotifications: 'Alarm-Quittierungs-Benachrichtigungen',
  send: 'Senden Sie',
  prev: 'Prev',
  snoozing: '{{timeString}}',
  specifySnoozeDuartion: 'Dauer des Schlummerns festlegen',
  chooseDifferentDuration: 'Wählen Sie eine andere Dauer',
  noCalendarsFound: 'Es wurden keine Kalender auf dem Gerät gefunden.',
  eventWillEndAt: 'Die Veranstaltung endet auf {{timeString}}.',
  selectDurationWithinTheSameDay:
    'Das Ereignis kann die Tagesgrenze nicht überschreiten.',
  cannotMapRepeatTypeInTheDeviceCalendar:
    'Exportieren in den Kalender" ist nicht möglich, da die Einstellung "Wiederholen" Ihres Alarms vom Kalender nicht unterstützt wird.',
  alarmSavedToCalendar: 'Der Alarm wurde erfolgreich im Kalender gespeichert.',
  faq: 'Häufig gestellte Fragen',
  openSettings: 'Einstellungen öffnen',
  openAppSettings: 'App-Einstellungen öffnen',
  alreadyDone: 'Erledigt',
  enableAutoStart: 'Autostart einschalten',
  restrictedApps: 'Eingeschränkte Apps',
  unmonitoredApps: 'Unüberwachte Apps',
  enableProtected: 'Geschützte Apps',
  manageAppLaunch: 'App-Start verwalten',
  unblockContact: 'Kontakt entsperren',
  blockContact: 'Block Kontakt',
  call: 'Rufen Sie  an. {{name}}',
  text: 'Text {{name}}',
  chat: 'Chat',
  ok: 'OK',
  stopSnooze: 'Schlummern stoppen',
  emailId: 'Ihre E-Mail Adresse',
  feedback: 'Rückmeldung',
  submit: 'Einreichen',
  reportAProblem: 'Ein Problem melden',
  enableAlarm: 'Alarm einschalten',
  restoreAlarm: 'Alarm wiederherstellen',
  deletePastAlarms: 'Inaktive Alarme löschen',
  accept: 'Akzeptieren',
  defaultPersonalAlarmName: 'Persönliche Erinnerung',
  defaultGroupAlarmName: 'Gruppe Veranstaltung',
  defaultRecipientAlarmName: 'Alarm für Freunde',
  search: 'Suche',
  recent: 'Neueste',
  upcoming: 'Demnächst',
  missed: 'Verpasst',
  expired: 'Abgelaufen',
  past: 'Vergangenheit',
  active: 'Aktiv',
  inactive: 'inaktiv',
  allAlarms: 'Alle Alarme',
  myAlarms: 'Meine Alarme',
  participantAlarms: 'Alarme für Teilnehmer',
  pendingParticipantAlarms: 'Neue Alarme von anderen',
  alarmsWithUnreadChatMessages: 'Alarme mit neuem Chat',
  systemNotifications: 'System-Meldungen',
  missedAlarms: 'Verpasste Alarme',
  recentlyDeletedAlarms: 'Kürzlich gelöschte Alarme',
  searchAlarms: 'Suche Alarme',
  participant: 'Teilnehmer',
  you: 'Sie',
  none: 'Keine',
  onceOnly: 'Nur einmal',
  weekdays: 'Wochentage',
  weekends: 'Wochenenden',
  everyDayString: 'Täglich',
  everySunday: 'Jeden Sonntag',
  everyMonday: 'Jeden Montag',
  everyTuesday: 'Jeden Dienstag',
  everyWednesday: 'Jeden Mittwoch',
  everyThursday: 'Jeden Donnerstag',
  everyFriday: 'Jeden Freitag',
  everySaturday: 'Jeden Samstag',
  sunday: 'Sonntag',
  monday: 'Montag',
  tuesday: 'Dienstag',
  wednesday: 'Mittwoch',
  thursday: 'Donnerstag',
  friday: 'Freitag',
  saturday: 'Samstag',
  january: 'Januar',
  february: 'Februar',
  march: 'März',
  april: 'April',
  may: 'Mai',
  june: 'Juni',
  july: 'Juli',
  august: 'August',
  september: 'September',
  october: 'Oktober',
  november: 'November',
  december: 'Dezember',
  jan: 'Jan',
  feb: 'Feb',
  mar: 'Mär',
  apr: 'Apr',
  shortMay: 'Mai',
  jun: 'Jun',
  jul: 'Jul',
  aug: 'Aug',
  sep: 'Sep',
  oct: 'Okt',
  nov: 'Nov',
  dec: 'Dez',
  sun: 'So',
  mon: 'Mo',
  tue: 'Di',
  wed: 'Mi',
  thu: 'Do',
  fri: 'Fr',
  sat: 'Sa',
  first: '1.',
  second: '2.',
  third: '3.',
  fourth: '4.',
  fifth: '5.',
  every: 'Alle',
  oddNumberedDays: 'Ungerade nummerierte Daten',
  evenNumberedDays: 'Geradzahlige Daten',
  noParticipants: 'Keine Teilnehmer',
  typeYourMessage: 'Geben Sie Ihre Nachricht ein',
  cantPostMessageToPastAlarm:
    'Nachricht kann nicht an einen inaktiven Alarm gesendet werden',
  groupName: 'gruppe: {{name}}',
  alarm: 'alarm',
  confirmed: 'bestätigt',
  declined: 'abgelehnt',
  Done: 'Erledigt',
  Skip: 'Überspringen',
  Confirm: 'Bestätigen',
  Decline: 'Ablehnen',
  Accept: 'Akzeptieren',
  today: 'heute',
  on: 'auf',
  recipientAlarm: 'Alarm für Freunde',
  recipient: 'Empfänger',
  remindMeAfter: 'Erinnern Sie mich',
  remindMeIn: 'Erinnern Sie mich in',
  newRecipientAlarm: 'Neuer freundlicher alarm',
  editRecipientAlarm: 'Alarm für Freunde bearbeiten',
  newInstantAlarm: 'Neuer Sofortalarm',
  instantAlarms: 'Sofortige Alarme',
  alarmDone: 'Alarm Markiert Erledigt',
  alarmSkipped: 'Alarm übersprungen',
  undo: 'Rückgängig machen',
  enterVerificationCode: 'Bitte geben Sie den 4-stelligen Code unten ein:',
  initializing: 'Initialisierung',
  tryAgain: 'Erneut versuchen',
  nMore: '+ {{count}} mehr',
  enableNotification: 'Aktivieren Sie',
  enableAlarmNotificationChannel: 'Aktivieren Sie',
  enableBackgroundAppRefresh: 'Aktivieren Sie',
  viewImpactedAlarms: 'Alarme',
  viewImpactedGroups: 'gruppen',
  dismiss: 'Ignorieren',
  minsInterval: '+ {{count}}m',
  hoursInterval: '+ {{count}}h',
  daysInterval: '+ {{count}}d',
  snoozeUnitHours: 'Stunden',
  snoozeUnitMinutes: 'Protokoll',
  snoozeUnitDays: 'Tage',
  selectMinutes: 'Minuten auswählen',
  selectHours: 'Stunden auswählen',
  selectDays: 'Tage auswählen',
  selectHoursAndMinutes: 'Stunden und Minuten auswählen',
  enterDuration: 'Dauer',
  snoozeUnit: 'Einheit',
  specifySnoozeUnit: 'Schlummern Einheit spezifizieren',
  specifySnoozeDuration: 'Dauer des Schlummerns festlegen',
  duration: 'Dauer',
  quickAlarmName: {
    zero: '{{minutes}} Min-Erinnerung',
    one: '{{hours}} Stundenerinnerung',
    other: '{{hours}} Stunde {{minutes}} min erinnerung'
  },
  alarmTitleForGoogleVoiceTimerCommand: '{{durationString}} erinnerung',
  addedAlarmFromVoiceCommand:
    'Hinzufügen eines Alarms für {{alarmName}} unter {{dateString}}',
  addedTimerFromVoiceCommand: 'Ein Timer wurde hinzugefügt für {{duration}}',
  clear: 'Klar',
  ringtoneDuration: 'Klingelton Dauer',
  chooseDuration: 'Tippen Sie auf eine Dauer. Sie können mehrmals tippen.',
  newAlarm: 'Neuer Alarm',
  quickAlarm: 'Schnelle Erinnerung',
  quickAlarmWillRingAt: 'Der Alarm klingelt unter {{timeString}}.',
  preReminderWillRingAt:
    'Eine Vorab-Erinnerung für diesen Alarm klingelt \nunter {{timeString}}.',
  random: 'Zufällig',
  enableAllAlarms: 'Alle Alarme einschalten',
  disableAllAlarms: 'Alle Alarme deaktivieren',
  deleteAllAlarms: 'Alle Alarme löschen',
  hideCategory: 'Kategorie ausblenden',
  unhideCategory: 'Kategorie ausblenden',
  dontShowHiddenCategories: 'Ausgeblendete Kategorien nicht anzeigen',
  showHiddenCategories: 'Versteckte Kategorien anzeigen',
  welcomeAlarmsTitle: 'Fangen wir an!',
  welcomeAlarmsMessage: 'Hier sind einige häufig verwendete Alarme:',
  onboardingAlarms: 'Onboarding Alarme',
  hiddenAlarmCategories: 'Versteckte Kategorien',
  restorePurchases: 'Käufe wiederherstellen',
  noAvailablePurchasesFound:
    'Keine verfügbaren Einkäufe für Ihr Konto gefunden.\n\nBitte senden Sie uns eine Rückmeldung über "Einstellungen > Feedback", wenn Sie einen gültigen Kauf haben, den Sie nicht wiederherstellen können.',
  restoringPurchases: 'Wiederherstellung von Käufen',
  fetchingContacts: 'Prüfen, welche Kontakte Galarm verwenden',
  enterNameAndUploadPhotoForUser:
    'Geben Sie unten Ihren Namen ein und laden Sie optional Ihr Profilfoto hoch',
  enterNameAndUploadPhotoForGroup:
    'Geben Sie unten einen Namen ein und laden Sie optional ein Gruppenfoto hoch',
  noContactsWithAppInstalled: 'Niemand in Ihren Kontakten verwendet Galarm.',
  galarmContacts: {
    one: '1 Galarm-Kontakt',
    other: '{{count}} Galarm-Kontakte'
  },
  contactsPendingInvitation: {
    one: '1 ausstehende Einladung',
    other: '{{count}} ausstehende Einladungen'
  },
  otherContacts: {
    one: '1 sonstiger Kontakt',
    other: '{{count}} sonstige Kontakte'
  },
  over100Contacts: 'Über 100 weitere Kontakte',
  noAlarms:
    'Sie haben noch keine Alarme. Tippen Sie auf +, um einen neuen Alarm hinzuzufügen.',
  noContactsWeb: {
    one: 'Wir haben keine Kontakte gefunden, die Galarm benutzen. Entweder haben wir keine Berechtigung, auf Ihre Kontakte zuzugreifen, oder niemand in Ihren Kontakten verwendet Galarm. Sie können Ihre Kontakte über die mobile App synchronisieren.\n\nSie können Kontakte, die Galarm verwenden, auf verschiedene Weise zu Ihren Alarmen hinzufügen, um von der App zu profitieren',
    other:
      'Bitte registrieren Sie sich in der mobilen App, um Kontakte zu Ihren Alarmen hinzuzufügen.'
  },
  viewingAlarmsInCommon: 'Gemeinsame Alarme mit {{name}}',
  viewingGroupsInCommon: 'Gemeinsame Gruppen mit {{contactName}}',
  changeGroupMemberConfigTextForCascadingAlarm:
    'Aktive Teilnehmer werden in der angezeigten Reihenfolge benachrichtigt.',
  changeGroupMemberConfigTextForSimultaneousAlarm:
    'Nur aktive Teilnehmer werden benachrichtigt.',
  changeGroupMemberStateText: {
    zero: 'Ändern Sie die Mitglieder, die Sie alarmieren können, wenn ein Alarm für die Gruppe "{{groupName}}" erstellt wird.\n\nSie werden nur benachrichtigt, wenn der Alarm von erlaubten Mitgliedern erstellt wird.',
    one: 'Ändern Sie die Mitglieder, die {{memberName}} alarmieren können, wenn ein Alarm für die Gruppe "{{groupName}}" erstellt wird.\n\n{{memberName}} wird nur alarmiert, wenn der Alarm von erlaubten Mitgliedern erstellt wird.'
  },
  allowed: 'Erlaubt',
  notAllowed: 'Nicht erlaubt',
  changeStatus: 'Erlaubte Mitglieder',
  noUpcomingAlarms: 'Keine anstehenden Alarme',
  showMore: 'Mehr anzeigen',
  nMorePastAlarms: {
    one: '1 weiterer inaktiver alarm',
    other: '{{count}} mehr inaktive alarme'
  },
  shareAppMessage:
    'Ich erledige alle meine Dinge rechtzeitig, indem ich mit der Galarm-App Erinnerungen für sie erstelle. Mit dieser App können wir uns auch gegenseitig in unsere Erinnerungen einbeziehen. Ich empfehle den Download dieser innovativen App für Alarme und Erinnerungen. {{downloadLink}}',
  addingMembers: 'Hinzufügen von Mitgliedern.',
  unableToAddMembers:
    'Es können keine Mitglieder hinzugefügt werden. {{error}}',
  removingMember: 'Mitglied wird entfernt.',
  unableToRemoveMember: 'Das Mitglied kann nicht entfernt werden. {{error}}',
  leavingGroup: 'Die Gruppe verlassen.',
  unableToLeaveGroup: 'Kann die Gruppe nicht verlassen. {{error}}',
  expiredPersonalAlarmForCreator: {
    zero: 'Dieser Alarm ist vor {{durationString}} abgelaufen. Tippen Sie auf "Erledigt", um den Alarm abzuschließen.',
    one: 'Dieser Alarm lief vor {{durationString}} ab. Tippen Sie auf "Erledigt", um den Alarm abzuschließen und die Teilnehmer zu benachrichtigen.',
    other:
      'Dieser Alarm lief vor {{durationString}} ab. Tippen Sie auf "Erledigt", um den Alarm abzuschließen und die Teilnehmer zu benachrichtigen.'
  },
  donePersonalAlarmForCreator:
    'Dieser Alarm ist vor {{durationString}} abgelaufen. Der Alarm ist als erledigt markiert.',
  skippedPersonalAlarmForCreator:
    'Dieser Alarm ist vor {{durationString}} abgelaufen. Sie haben den Alarm übersprungen.',
  expiredGroupAlarm:
    'Dieser Alarm ist vor {{durationString}} abgelaufen. Bitte bestätigen Sie Ihre Teilnahme.',
  expiredPersonalAlarmForParticipant:
    'Dieser Alarm ist vor {{durationString}} abgelaufen. Bitte erinneren Sie {{creatorName}}.',
  alarmDurationAndroid:
    'Der Alarm ist ab jetzt auf {{durationString}} eingestellt.',
  alarmDurationIos:
    'Der Alarm ist ab jetzt auf {{durationString}} eingestellt.',
  alarmFeedbackHeader:
    'Sie werden einen Teil der Informationen dieses Alarms zu Fehlersuchzwecken senden. Persönliche Daten wie der Name des Alarms, Notizen oder Teilnehmer werden nicht weitergegeben.',
  privacyPolicyHeader:
    'Der Schutz Ihrer Privatsphäre ist für uns ein wichtiges Anliegen. Genau wie Sie schätzen wir die Privatsphäre, deshalb möchten wir Sie wissen lassen, dass wir Ihre persönlichen Daten an niemanden weitergeben, es sei denn, dies ist für die Installation der Anwendung erforderlich oder gesetzlich vorgeschrieben. In jedem Fall werden wir Ihre Daten niemals verkaufen. So einfach ist das!\n \nDie von Ihnen erstellten Alarme sind nur auf Ihrem Gerät und, falls ein Alarm andere Teilnehmer einschließt, auf den Geräten dieser Teilnehmer sichtbar.\n \nWir greifen auf das Adressbuch/die Kontaktliste Ihres Telefons nur zu, um die Registerkarte "Kontakte" in Galarm aufzufüllen. Wir speichern diese Liste nicht für andere Zwecke. Wir respektieren, dass es Ihr Adressbuch ist!\n \nWir greifen auf Ihre Kamera und Fotos nur zu, um Ihnen die Möglichkeit zu geben, ein gutes Profilbild einzustellen. Dieses Bild erscheint als Ihr Avatar oder als Profilbild einer Gruppe, die Sie bearbeiten. Andere Galarm-Benutzer, die Mitglieder der von Ihnen eingestellten Alarme sind, können dieses Bild sehen.\n\nWir senden die öffentliche IP-Adresse Ihres Geräts und seine eindeutige ID bei allen Anfragen, z. B. bei der Anforderung und Überprüfung des Codes während des Registrierungsprozesses. Diese Informationen sind nicht mit Ihrem Konto verknüpft und werden nur verwendet, um mögliche bösartige Aktivitäten zu ermitteln.\n \nBitte lesen Sie die vollständige Datenschutzerklärung, um mehr über die von uns gesammelten Daten und ihre Verwendung zu erfahren.',
  termsOfServiceHeader:
    'Durch die Nutzung der Galarm-App erklären Sie sich mit unseren Nutzungsbedingungen einverstanden, die Ihren Zugang zu und Ihre Nutzung dieser mobilen App sowie der darin angebotenen Funktionen und Dienste regeln. \n\nDas Motto von Galarm ist einfach - wir helfen Ihnen dabei, kein Ereignis zu verpassen!  Ob Sie persönliche Alarme einrichten, um sich wecken zu lassen, pünktlich zu Terminen zu erscheinen und Erinnerungen für Aktivitäten wie die rechtzeitige Einnahme von Medikamenten einzustellen, oder ob Sie Gruppenalarme für die Organisation von Gruppenaktivitäten wie Kinobesuche, Sportereignisse und Partys erstellen, Galarm ermöglicht es Ihnen, einen sozialen Alarm zu haben, um sich gegenseitig zu erinnern, den Status zu verfolgen und zu kommunizieren.\n\nBitte nutzen Sie Galarm nur, wenn Sie mit diesen Bedingungen einverstanden sind. Unten finden Sie einen Link dazu.',
  acknowledgedPersonalAlarm:
    'Sie haben den Alarm vor {{durationString}} als "Erledigt" markiert.',
  skippedPersonalAlarm: 'Sie haben den Alarm übersprungen {{durationString}}.',
  authenticatingWithServer: 'Verbinden...',
  selectParticipantsForPersonalAlarm:
    'Wählen Sie eine Gruppe oder eine Reihe von Personen aus, die als Sicherungen für Ihren persönlichen Alarm fungieren.',
  selectParticipantsForGroupAlarm:
    'Wählen Sie eine Gruppe oder eine Reihe von Personen für Ihre Gruppenaktivität aus.',
  acknowledgedGroupAlarm:
    'Sie {{acknowledgedAsString}} diesen Alarm {{durationString}} vor.',
  acknowledgedExpiredGroupAlarm:
    'Dieser Alarm ist vor {{durationString}} abgelaufen. Sie {{acknowledgedAsString}} diesen Alarm.',
  acknowledgedPersonalAlarmForParticipant:
    '{{creatorName}} den Alarm vor {{durationString}} als "Erledigt" markiert.',
  acknowledgedExpiredPersonalAlarmForParticipant:
    'Dieser Alarm ist vor {{durationString}} abgelaufen. {{creatorName}} hat den Alarm als "Erledigt" markiert.',
  entityNotFound:
    'Huch! Wir konnten die ausgewählte {{entityType}} nicht finden. Es kann sein, dass wir sie noch suchen oder dass sie entfernt wurde.',
  goBack: 'Zurückgehen',
  uploadingImageToServer: 'Bild auf den Server hochladen.',
  deletingImageFromServer: 'Bild vom Server löschen',
  errorUploadingImageToServer:
    'Fehler beim Hochladen des Bildes auf den Server. Bitte versuchen Sie es später noch einmal.',
  unableToRemoveGroup: 'Die Gruppe kann nicht entfernt werden. {{error}}',
  addingGroup: 'Gruppe hinzufügen.',
  editingGroup: 'Redaktionsgruppe.',
  unableToAddGroup: 'Gruppe kann nicht hinzugefügt werden. {{error}}',
  unableToEditGroup: 'Die Gruppe kann nicht bearbeitet werden. {{error}}',
  thanksForFeedback: 'Danke, dass Sie uns helfen, uns zu verbessern.',
  licensesHeader:
    'Galarm enthält Software, die von Dritten lizenziert wurde. Bitte sehen Sie sich unsere Seite zur Lizenzierung von Drittanbietern an, um die entsprechenden Informationen zur Lizenzierung zu erhalten.',
  alarmAlreadyInSnooze: 'Sie werden unter {{durationString}} daran erinnert.',
  carrierChargesMayApply: 'Es können Beförderungsgebühren anfallen.',
  verficationCodeInfo:
    'Sie erhalten eine Textnachricht mit einem 4-stelligen Code.',
  selectCascadingInterval:
    'Der erste Teilnehmer wird {{firstParticipantDurationString}} nach der Zeit des Alarms alarmiert, der zweite Teilnehmer wird {{secondParticipantDurationString}} nach der Zeit des Alarms alarmiert und so weiter, wenn Sie den Alarm nicht als erledigt markieren.',
  reorderParticipantsScreenDescription:
    'Die Teilnehmer werden in der angezeigten Reihenfolge benachrichtigt.',
  noMembers: 'Keine Mitglieder',
  selectedGroupDescription: {
    zero: '{{numActiveMembers}} Teilnehmer',
    one: '{{numActiveMembers}} aktiver und {{numInactiveMembers}} inaktiver Teilnehmer',
    other:
      '{{numActiveMembers}} aktive und {{numInactiveMembers}} inaktive Teilnehmer'
  },
  groupAlarmWillRingAtSameTime:
    'Alle Teilnehmer werden unter {{timeString}} benachrichtigt.',
  welcomeToGalarm: 'Willkommen bei Galarm',
  checkingConnection: 'Überprüfung der Verbindung',
  deletePastAlarmsTitle: 'Löschen inaktiver Alarme, die älter sind als',
  deletePastAlarmsOption: {
    zero: '{{option}} (0 Alarme)',
    one: '{{option}} (1 Alarm)',
    other: '{{option}} ({{count}} alarme)'
  },
  deletePastAlarmsNotificationMessage:
    'Löschen Sie inaktive Alarme, um sie wieder zu verwenden.',
  chooseSnoozeInterval: 'Erinnern Sie mich wieder in',
  personalAlarmDescription:
    'Verwenden Sie einen persönlichen Alarm für das Aufwachen, die Einnahme von Medikamenten oder jede andere Aufgabe für sich selbst. Sie können optional Teilnehmer hinzufügen, die benachrichtigt werden, wenn Sie einen Alarm verpassen, damit sie Sie erinneren können. Die Teilnehmer werden auch benachrichtigt, wenn Sie den Alarm als erledigt markieren.',
  groupAlarmDescription:
    'Verwenden Sie einen Gruppenalarm für Gruppenaktivitäten wie Ausflüge, Partys oder andere Veranstaltungen. Der Alarm ertönt zur gleichen Zeit für alle Teilnehmer und sie können ihre Teilnahme bestätigen.',
  recipientAlarmDescription:
    'Erstellen Sie Alarme für eine andere Person ("Freund"), um sie an Dinge zu erinnern, die sie erledigen muss. Sie werden benachrichtigt, um den Freund zu erinnern, falls er den Alarm verpasst. Sie werden auch benachrichtigt, wenn der Freund den Alarm als erledigt markiert.\n  \nBeachten Sie, dass die Alarme in Ihrer Zeitzone erstellt werden.',
  whatsThis: 'Was ist das?',
  alarmCurrentlyDisabled: 'Dieser Alarm ist derzeit deaktiviert',
  alarmCurrentlyRejected: 'Dieser Alarm wird derzeit abgelehnt',
  currentlyTypingUsers: {
    one: '{{currentlyTypingUsers}} tippt...',
    other: '{{currentlyTypingUsers}} tippen...'
  },
  noSearchResultsFound: 'Keine {{type}} gefunden',
  noGroupsCreated: 'Sie haben keine Gruppen erstellt.',
  noGroupsWeb: {
    one: 'Sie haben keine Gruppen erstellt.',
    other:
      'Bitte registrieren Sie sich in der mobilen App, um Gruppen zu erstellen und sie zu Alarmen hinzuzufügen.'
  },
  internalErrorDuringRegistration:
    'Bei der Benutzerregistrierung ist ein interner Fehler aufgetreten. Bitte versuchen Sie es nach ein paar Minuten erneut.',
  selectRecipientAlarmInterval: {
    zero: 'Sie werden zur gleichen Zeit wie der Empfänger benachrichtigt',
    one: 'Wenn der Empfänger den Alarm nicht als "Erledigt" markiert, werden Sie nach der Zeit des Alarms auf {{durationString}} benachrichtigt.',
    other:
      'Wenn der Empfänger den Alarm nicht als "Erledigt" markiert, werden Sie nach der Zeit des Alarms auf {{durationString}} benachrichtigt.'
  },
  selectRecipient: 'Empfänger auswählen',
  acknowledgedExpiredRecipientAlarm:
    'Dieser Alarm ist vor {{durationString}} abgelaufen. Sie haben diesen Alarm als "Erledigt" markiert.',
  skippedExpiredRecipientAlarm:
    'Dieser Alarm ist vor {{durationString}} abgelaufen. Sie haben diesen Alarm übersprungen.',
  expiredRecipientAlarm:
    'Dieser Alarm lief vor {{durationString}} ab. Tippen Sie auf "Erledigt", um den Alarm abzuschließen und {{creatorName}} zu benachrichtigen.',
  acknowledgedRecipientAlarm:
    'Sie haben diesen Alarm vor {{durationString}} als "Erledigt" markiert.',
  skippedRecipientAlarm:
    'Sie haben diesen Alarm vor {{durationString}} übersprungen.',
  acknowledgedExpiredRecipientAlarmForCreator:
    'Dieser Alarm ist vor {{durationString}} abgelaufen. {{recipientName}} hat den Alarm als "Erledigt" markiert.',
  skippedExpiredRecipientAlarmForCreator:
    'Dieser Alarm ist vor {{durationString}} abgelaufen. {{recipientName}} hat den Alarm übersprungen.',
  expiredRecipientAlarmForCreator:
    'Dieser Alarm ist vor {{durationString}} abgelaufen. Bitte erinneren Sie {{recipientName}}.',
  acknowledgedRecipientAlarmForCreator:
    '{{recipientName}} den Alarm vor {{durationString}} als "Erledigt" markiert.',
  selectRecipientForRecipientAlarm: 'Wählen Sie einen Empfänger für den Alarm',
  selectAtLeastOneParticipantForGroupAlarm:
    'Mindestens einen Teilnehmer zum Alarm hinzufügen',
  addAtLeastOneMemberToGroup: 'Mindestens ein Mitglied zur Gruppe hinzufügen',
  atTheSameTime: 'Zur gleichen Zeit',
  myself: 'Ich selbst',
  group: 'gruppe',
  someoneElse: 'Jemand anders',
  participantsAreNotified: 'Die Teilnehmer werden benachrichtigt.',
  creatorIsNotified: '{{creatorName}} benachrichtigt wird.',
  membersAddedToGroup: {
    one: '{{memberNames}} wird der Gruppe {{groupName}} hinzugefügt.',
    other: '{{memberNames}} werden der Gruppe {{groupName}} hinzugefügt.'
  },
  memberRemovedFromGroup:
    '{{memberName}} wurde aus der Gruppe {{groupName}} entfernt, was sich auf einige Ihrer Alarme ausgewirkt haben könnte.',
  youRemovedFromGroup:
    'Sie wurden aus der Gruppe {{groupName}} entfernt, was sich auf einige Ihrer Alarme ausgewirkt haben kann.',
  memberLeftGroup:
    '{{memberName}} linke Gruppe {{groupName}}, was sich auf einige Ihrer Alarme ausgewirkt haben könnte.',
  youLeftGroup:
    'Sie haben die Gruppe {{groupName}} verlassen, was sich auf einige Ihrer Alarme ausgewirkt haben könnte.',
  groupLeft: 'Sie haben die Gruppe {{groupName}} verlassen.',
  memberMovedToADifferentTimezone:
    '{{memberName}} in eine andere Zeitzone umgezogen, was sich auf einige Ihrer Alarme ausgewirkt haben könnte.',
  memberDstChanged: {
    zero: '{{memberName}} nicht mehr die Sommerzeit einhalten. Die entsprechenden Alarme wurden aktualisiert.',
    one: '{{memberName}} mit der Einhaltung der Sommerzeit begonnen. Die entsprechenden Alarme wurden aktualisiert.'
  },
  impactedAlarms: 'Betroffene Alarme:',
  alwaysRingParticipantAlarmsOn:
    'Alarme von anderen werden klingeln, wenn Sie sie nicht ablehnen.',
  alwaysRingParticipantAlarmsOff:
    'Alarme von anderen Personen klingeln nur, wenn Sie sie akzeptieren.',
  agreeToTermsOfServiceAndPrivacyPolicy:
    'Wenn Sie fortfahren, akzeptieren Sie die Allgemeinen Geschäftsbedingungen und die Datenschutzrichtlinie von Galarm.',
  deleteAccountWarningHeader:
    'Warnung: Diese Aktion ist nicht umkehrbar. Die Löschung Ihres Kontos wird:',
  deleteAccountDeleteAlarms:
    '- Löschen Sie alle Alarme, die Sie erstellt haben',
  deleteAccountDeleteRecipientAlarms:
    '- Löschen Sie alle Alarme von Freunden, bei denen Sie der Empfänger sind',
  deleteAccountRemoveFromGroups: '- Sie aus allen Gruppen entfernen',
  deleteAccountWarningFooter: {
    zero: 'Wenn Sie das Telefon wechseln, können Sie die App einfach auf Ihrem neuen Telefon installieren und sich mit derselben Nummer anmelden. Sie können die App auf diesem Telefon entfernen.\n\nWenn Sie Ihr Konto löschen, können Sie es nicht mehr wiederherstellen.',
    one: 'Wenn Sie das Telefon wechseln, können Sie die App einfach auf Ihrem neuen Telefon installieren und sich mit derselben Nummer anmelden. Sie können die App auf diesem Telefon entfernen.\n\nWenn Sie Ihr Konto löschen, können Sie es nicht mehr wiederherstellen. Sie können Ihr aktives Galarm-Abonnement im Play Store verwalten.',
    other:
      'Wenn Sie das Telefon wechseln, können Sie die App einfach auf Ihrem neuen Telefon installieren und die App auf diesem Telefon löschen.\n\nSobald Sie Ihr Konto gelöscht haben, können Sie es nicht mehr wiederherstellen. Sie können Ihr aktives Galarm-Abonnement im App Store verwalten.'
  },
  typeConfirmationStringToDeleteAccount:
    'Geben Sie {{confirmationString}} ein, um Ihr Konto zu löschen.',
  unableToDeleteAccount: 'Konto kann nicht gelöscht werden. {{error}}',
  impactedGroups: 'Betroffene Gruppen:',
  memberDeletedAccount:
    '{{memberName}} hat sein Konto gelöscht, was sich auf einige Ihrer Alarme und Gruppen ausgewirkt haben kann.',
  confirmCountryCodeAndEnterNumber:
    'Bitte bestätigen Sie Ihre Landesvorwahl und geben Sie Ihre Telefonnummer ein.',
  confirmCountryCodeAndEnterNumberForExistingUser:
    'Bitte bestätigen Sie Ihre Landesvorwahl und geben Sie die Telefonnummer ein, die Sie bei der Registrierung verwendet haben.',
  requiredPermissionsDisabledWarningIos:
    'Benachrichtigungen sind deaktiviert. Alarme klingeln nicht. Zum Beheben antippen.',
  requiredPermissionsDisabledWarningAndroid:
    'Galarm verfügt nicht über die erforderlichen Berechtigungen, um Alarme zu klingeln. Tippen Sie zur Behebung.',
  notificationChannelPopupDisabledWarningAndroid:
    'Alarm-Benachrichtigungen werden nicht auf dem Bildschirm angezeigt. Tippen Sie zum Beheben auf .',
  alarmLockScreenNotificationDisabledWarningAndroid:
    'Alarm-Benachrichtigungen werden nicht auf dem Sperrbildschirm angezeigt. Tippen Sie zum Beheben auf .',
  why: 'Warum',
  how: 'Wie',
  registrationRequired: 'Registrierung erforderlich',
  phoneNumberRequired: 'Telefonnummer erforderlich',
  fewSeconds: 'wenige Sekunden',
  lessThanMinute: 'weniger als eine Minute',
  updateUnsupportedVersion:
    'Um Galarm weiterhin nutzen zu können, ist eine aktualisierte Version der App erforderlich.',
  update: 'Update',
  back: 'Zurück',
  unableToMarkAlarmDone:
    'Der Alarm kann zu diesem Zeitpunkt nicht als erledigt markiert werden. Bitte versuchen Sie es in ein paar Minuten erneut.',
  unableToMarkAlarmUndone:
    'Der Alarm kann zu dieser Zeit nicht als rückgängig gemacht markiert werden. Bitte versuchen Sie es in ein paar Minuten erneut.',
  unableToMarkAlarmUnskipped:
    'Zu dieser Zeit kann der Alarm nicht als nicht übersprungen markiert werden. Bitte versuchen Sie es in ein paar Minuten erneut.',
  unableToSkipAlarm:
    'Das Überspringen des Alarms ist zu dieser Zeit nicht möglich. Bitte versuchen Sie es in ein paar Minuten erneut.',
  unableToSetGroupAlarmResponse:
    'Der Gruppenalarm kann zu dieser Zeit nicht unter {{response}} abgerufen werden. Bitte versuchen Sie es in einigen Augenblicken erneut.',
  unableToDeleteAlarm:
    'Der Alarm kann zu dieser Zeit nicht gelöscht werden. Bitte versuchen Sie es in ein paar Minuten erneut.',
  unableToSetAlarmResponse:
    'Ihre Antwort kann zu dieser Zeit nicht eingestellt werden. Bitte versuchen Sie es in einigen Augenblicken erneut.',
  unableToSnoozeAlarm: 'Sie können den Alarm nicht schlummern lassen.',
  unableToStopSnoozeForAlarm:
    'Das Schlummern des Alarms kann nicht beendet werden.',
  unableToUpdateParticipantAlarmRingerSettings:
    'Die Ruftoneinstellungen können zu dieser Zeit nicht aktualisiert werden. Bitte versuchen Sie es in ein paar Minuten erneut.',
  updateParticipantPreReminderDuration:
    'Die Dauer der Vorab-Erinnerung kann zu dieser Zeit nicht aktualisiert werden. Bitte versuchen Sie es in ein paar Minuten erneut.',
  unableToUpdateAlarmRingerSettings:
    'Die Ruftoneinstellungen können zu dieser Zeit nicht aktualisiert werden. Bitte versuchen Sie es in ein paar Minuten erneut.',
  unableToUpdateAlarmPreReminderDuration:
    'Die Dauer der Vorab-Erinnerung kann zu dieser Zeit nicht aktualisiert werden. Bitte versuchen Sie es in ein paar Minuten erneut.',
  retrievingAlarmsFromServer: 'Abrufen von Alarmen vom Server...',
  startApp: 'Start >',
  phoneNumberRequiredGroupListHeaderText:
    'Die Gruppe ist eine Sammlung von Galarm-Kontakten, mit denen Sie schnell Alarme erstellen können.\n\nBitte registrieren Sie sich mit Ihrer Telefonnummer, um Ihre Kontakte zu finden, die Galarm benutzen. Sie können sie zu Gruppen hinzufügen.',
  phoneNumberRequiredBuddyAlarmHeaderText:
    'Mit Buddy Alarm können Sie Alarme für Freunde und Familienmitglieder einstellen, um sie an Dinge zu erinnern, die sie erledigen müssen.\n\nUm diese und viele andere coole Funktionen der App nutzen zu können, registrieren Sie sich bitte mit Ihrer Telefonnummer.',
  phoneNumberRequiredContactListHeaderText:
    'Kontakte sind Personen, die als Teilnehmer zu Alarmen hinzugefügt werden können.\n\nBitte registrieren Sie sich mit Ihrer Telefonnummer, um Ihre Kontakte, die Galarm verwenden, anzuzeigen. Sie können sie als Teilnehmer zu Ihren Alarmen hinzufügen.',
  phoneNumberRequiredGroupAlarmHeaderText:
    'Der Gruppenalarm kann verwendet werden, um eine Erinnerung für eine Gruppe von Personen einzustellen.\n\nUm diese und viele andere coole Funktionen der App nutzen zu können, registrieren Sie sich bitte mit Ihrer Telefonnummer.',
  phoneNumberRequiredParticipantsHeaderText:
    'Die Teilnehmer fungieren als Sicherungen für diesen Alarm. Sie werden erinnert, wenn Sie den Alarm verpassen.\n\nUm diese und viele andere coole Funktionen der App zu nutzen, registrieren Sie sich bitte mit Ihrer Telefonnummer.',
  youHaveNotRegistered:
    'Ihre Alarme sind derzeit nicht gesichert.\n\nBitte registrieren Sie sich mit Ihrer Telefonnummer. Sobald Sie sich registriert haben, werden Ihre Alarme in der Cloud gesichert. So können Sie Ihre Alarme und andere Einstellungen wiederherstellen, wenn Sie das Telefon wechseln.\n',
  registerNow: 'Jetzt registrieren',
  hoursAndMinutes: 'Stunden und Minuten',
  days: 'Tage',
  weeks: 'Wochen',
  months: 'Monate',
  years: 'Jahre',
  daysOfWeek: 'Wöchentlich (ausgewählte Tage)',
  monthly: 'Monatlich',
  yearly: 'Jährlich',
  everyDay: 'Täglich',
  selectDaysOfWeek: 'Wochentage auswählen',
  alarmRingMessageForSpecificDates:
    'Dieser Alarm klingelt nur in Monaten, die mindestens {{dayOfMonth}} haben. Möchten Sie fortfahren?',
  alarmRingMessageForSpecificYear:
    'Dieser Alarm klingelt nur in Schaltjahren. Möchten Sie fortfahren?',
  alarmRingMessageForSpecificDayOfWeekInMonth:
    'Dieser Alarm klingelt nur in Monaten mit {{weekNumberOfDayInMonth}} {{dayName}} s. Möchten Sie fortfahren? ',
  alarmRepetitionAdjustedBasedOnNewDate:
    'Die Wiederholung des Alarms wurde auf der Grundlage des neuen Datums aktualisiert.',
  inviteFriendsToInstallGalarm:
    'Kontakte sind Personen, die als Teilnehmer zu Ihren Alarmen hinzugefügt werden können. Sie können Kontakte einladen:\n\n1. Aus Ihrem Adressbuch\n\n2. Durch manuelle Eingabe von Name und Nummer.\n\nTippen Sie oben links auf Aktualisieren, um Galarm-Kontakte nach der Registrierung neu zu laden.',
  whyCreateNewGroup:
    'Gruppen sind Sammlungen von Kontakten, die als Teilnehmer zu Ihren Alarmen hinzugefügt werden können.\n  \nSie haben keine Gruppen erstellt.',
  noNotifications: 'Sie haben keine neuen Benachrichtigungen.',
  createGroup: 'Gruppe erstellen',
  galarmRequiresAccessToContactsPermission:
    'Galarm bittet um die Erlaubnis, auf Ihre Kontakte zuzugreifen. Diese Informationen werden an seine Server gesendet, um Ihre Kontakte mit Galarm zu identifizieren. Diese Benutzer können als Teilnehmer zu Ihren Alarmen hinzugefügt werden.',
  galarmRequiresAccessToAudioFilesPermission:
    'Bei einigen Alarmen wird Telefonmusik als Klingelton verwendet. Bitte geben Sie die Erlaubnis, auf Audiodateien auf Ihrem Gerät zuzugreifen, um diese Alarme mit dem gewählten Klingelton klingeln zu lassen.',
  galarmRequiresAccessToContactsSelectScreen:
    'Galarm hat keine Berechtigung, auf Kontakte zuzugreifen. Bitte erlauben Sie den Zugriff auf Kontakte, wenn Sie diese Funktion nutzen möchten.',
  allow: 'Erlauben Sie',
  deny: 'Verweigern',
  galarmRequiresAccessToContacts:
    'Kontakte sind Personen, die als Teilnehmer zu Ihren Alarmen hinzugefügt werden können. Sie können Kontakte hinzufügen, indem Sie:\n\n1. Automatisches Importieren aus den Telefonkontakten. Tippen Sie auf "Zugriff zulassen" und aktivieren Sie "Kontakte".\n\n2. Manuelles Hinzufügen des Namens und der Nummer. Tippen Sie auf "Kontakt hinzufügen", um zu beginnen.',
  galarmRequiresAccessToAlarmAndRemindersPermission:
    'Galarm benötigt die Berechtigung "Alarme & Erinnerungen", um Alarme genau zu den angegebenen Zeiten klingeln zu lassen. Bitte aktivieren Sie diese auf dem nächsten Bildschirm.',
  accessToContactsPermissionRequiredAndroid:
    'Galarm hat keine Berechtigung, auf Ihre Telefonkontakte zuzugreifen.\n\nWenn Sie den Zugriff erlauben, werden Personen in Ihren Telefonkontakten, die Galarm verwenden, als Galarm-Kontakte angezeigt. Diese Personen können als Alarm-Teilnehmer hinzugefügt werden. Tippen Sie auf "Zugriff zulassen", wählen Sie "Berechtigung" und aktivieren Sie "Kontakte".\n\nSie können Kontakte immer noch manuell zu Galarm hinzufügen.',
  accessToContactsPermissionRequiredIos:
    'Galarm hat keine Berechtigung, auf Ihre Telefonkontakte zuzugreifen.\n\nWenn Sie den Zugriff erlauben, werden Personen in Ihren Telefonkontakten, die Galarm verwenden, als Galarm-Kontakte angezeigt. Diese Personen können als Alarm-Teilnehmer hinzugefügt werden. Tippen Sie auf "Zugriff zulassen" und aktivieren Sie "Kontakte".\n\nSie können Kontakte immer noch manuell zu Galarm hinzufügen.',
  giveAccess: 'Zugriff zulassen',
  enterValidMobileNumber:
    'Bitte geben Sie eine gültige Telefonnummer ein, um sich zu registrieren.',
  proceedAnyway: 'Trotzdem weitermachen',
  checkInvalidPhoneNumber:
    'Wir glauben, dass Sie eine ungültige Nummer als {{mobileNumber}} eingegeben haben.',
  checkInvalidCountryCode:
    'Der als {{countryCode}} angegebene Ländercode ist ungültig. Wenn mehr als ein Ländercode vorhanden ist, bearbeiten Sie das Feld, um den zutreffenden Ländercode beizubehalten.',
  editNumber: 'Nummer bearbeiten',
  editCountryCode: 'Ländercode bearbeiten',
  sendingCodeOnCall: 'Ich rufe Sie mit dem Code an.',
  sendCodeOnCall: 'Ruf mich an mit dem Code',
  registrationLandingScreenRegisterText:
    'Tippen Sie auf Weiter, um sich mit Ihrer Telefonnummer zu registrieren.\n\nWenn Sie sich registrieren:\n1) Sie können Teilnehmer zu Ihren Alarmen hinzufügen und mit ihnen chatten.\n2) Ihre Alarme werden in der Cloud gesichert, so dass bei einem Telefonwechsel Ihre Alarme auf dem neuen Telefon angezeigt werden.',
  continue: 'Weiter',
  registerLater: 'Überspringen der Registrierung',
  cancelRegistration: 'Registrierung abbrechen',
  welcome: 'Willkommen',
  haventReceivedCodeYet: {
    one: 'Haben Sie den Code noch nicht erhalten? Sie können den Code in 1 Sekunde erneut anfordern.',
    other:
      'Haben Sie den Code noch nicht erhalten? Sie können den Code in {{count}} Sekunden erneut anfordern.'
  },
  storagePermissionDenied:
    'Der Zugriff auf Musikdateien ist nicht möglich, da die Speichererlaubnis verweigert wird.',
  havingTroubleRegisteringMessage:
    'Galarm ist nach mehreren Versuchen nicht in der Lage, den Code zu verifizieren.\n\nMöchten Sie die Registrierung überspringen und die App sofort nutzen?',
  autoDetectVerificationCode:
    'Um die Registrierung zu vereinfachen, kann Galarm den Verifizierungscode automatisch erkennen, wenn Sie Galarm erlauben, SMS-Nachrichten einzusehen.',
  notNow: 'Nicht jetzt',
  verifyNumberText:
    'Wir werden Ihnen eine SMS mit dem Verifizierungscode schicken:\n\n{{formattedMobileNumber}}',
  addToContacts: 'Zu Kontakten hinzufügen',
  todayString: 'Heute',
  tomorrowString: 'Morgen',
  backups: 'Sicherungen',
  acintyoTrademark:
    '© 2024 Acintyo, Inc. Alle Rechte vorbehalten.\nGalarm ist eine eingetragene Marke von Acintyo, Inc.\nGeschützt durch U.S.-Patente 10382613, 10867286.\nWeitere Patente sind angemeldet.',
  acintyoTrademarkWeb:
    '© 2024 Acintyo, Inc. Alle Rechte vorbehalten. Galarm ist eine eingetragene Marke von Acintyo, Inc.\nGeschützt durch U.S.-Patente 10382613, 10867286. Weitere Patente sind angemeldet.',
  acintyoTrademarkEnterprise: '© 2024 Acintyo, Inc.\nAlle Rechte vorbehalten.',
  markAlarmDone: 'Alarm als Erledigt markieren',
  skipAlarm: 'Überspringen des Alarms',
  confirmGroupAlarm: 'Bestätigen Sie die Teilnahme',
  declineGroupAlarm: 'Ablehnen Teilnahme',
  snoozeAlarm: 'Schlummern Alarm',
  deleteAlarmQuickAction: 'Alarm löschen',
  disableAlarm: 'Alarm deaktivieren',
  confirmDisableAlarm: 'Alarm deaktivieren?',
  saveToCalendar: 'In den Kalender exportieren',
  stillRingGroupAlarmAfterConfirmingInAdvance:
    'Sie bestätigen den Gruppenalarm im Voraus.  Möchten Sie noch die Erinnerung zur Alarmzeit?',
  updateNow: 'Jetzt aktualisieren',
  nParticipants: {
    one: '1 anderer Teilnehmer',
    other: '{{count}} andere Teilnehmer'
  },
  details: 'Einzelheiten',
  changeParanthesis: '(Veränderung)',
  loadMoreHistory: 'Ansicht mehr',
  alarmSummaryForHistory:
    'Frühere Antworten für {{alarmName}}.\nWiederholungen {{alarmRepetitionString}}\n{{previousOccurrencesString}}',
  alarmSummaryForHistoryWeb: 'Frühere Antworten für {{alarmName}}',
  alarmSummaryForHistoryRepetitionWeb:
    'Wiederholungen {{alarmRepetitionString}}',
  dontSeeCompleteHistory: 'Sie sehen die Geschichte nicht vollständig?',
  dontSeeCompleteHistoryExplanation:
    'Der Alarmverlauf wird ab der Zeit aufgezeichnet, in der der Alarm erzeugt wird. Für jeden Alarm wird nur ein begrenzter Zeitraum gespeichert, der von der Wiederholung des Alarms abhängt. Wenn sich der Alarm zum Beispiel jeden Tag wiederholt, wird eine Historie von 30 Tagen gespeichert.\n\nWenn Sie die Zeit des Alarms oder die Wiederholung ändern, wird der Alarmverlauf zurückgesetzt.',
  noActionAvailableForAlarmOccurrence:
    'Für dieses Ereignis sind keine Maßnahmen verfügbar.',
  enterEmailIdForFeedback: 'Zum Beantworten und Teilen von Updates',
  noPreviousOccurrences: 'Noch kein Alarmverlauf',
  nMissedOccurrencesOfAlarmInLastNDays: {
    zero: 'Noch kein Alarmverlauf',
    one: '{{missedAlarmOccurrences}} den letzten Platz verpasst {{count}}',
    other: '{{missedAlarmOccurrences}} den letzten Platz verpasst {{count}}'
  },
  soundAndVibrationSummaryString: {
    zero: '{{volume}}% lautstärke, {{ringtoneName}}',
    one: '{{volume}}% lautstärke, {{ringtoneName}}, Ankündigung Titel'
  },
  soundAndVibrationSummaryStringIos: {
    zero: '{{ringtoneName}}',
    one: 'Kritisch, {{volume}}% Lautstärke, {{ringtoneName}}'
  },
  previousOccurrences: 'Alarm-Verlauf',
  retrieving: 'Abrufen...',
  startTimeOfDay: 'Beginnt bei',
  endTimeOfDay: 'Endet am',
  ringEveryHours: 'Klingelt jeder',
  everyNHours: 'Stündlich',
  everyMHoursNMinutes: 'Stunden und Minuten',
  selectHourlyRepetition: 'Stündliche Wiederholung auswählen',
  everyNHoursRepetitionString: {
    one: 'Jede Stunde von {{startTimeString}} bis {{endTimeString}} jede {{daysString}}',
    other:
      'Alle {{count}} Stunden von {{startTimeString}} bis {{endTimeString}} alle {{daysString}}'
  },
  everyNHoursAndMinutesRepetitionString:
    'Jede {{hoursAndMinutes}} von {{startTimeString}} bis {{endTimeString}} jede {{daysString}}',
  everyMHoursAndNMinutesRepetitionString: 'Alle {{repeatString}}',
  everyNHoursRepetitionStringForCalendar: {
    one: 'Jede Stunde von {{startTimeString}} bis {{endTimeString}}',
    other:
      'Alle {{count}} Stunden von {{startTimeString}} bis {{endTimeString}}'
  },
  everyNHoursAndMinutesRepetitionStringForCalendar:
    'Jede {{hoursAndMinutes}} von {{startTimeString}} bis {{endTimeString}}',
  everyNHoursAndMinutesRepetitionStringForAlarmSummary:
    'Alle {{hoursAndMinutes}} bis {{endTimeString}}',
  everyNHoursRepetitionStringForAlarmSummary: {
    one: 'Jede Stunde bis {{endTimeString}}',
    other: 'Alle {{count}} Stunden bis {{endTimeString}}'
  },
  daysRepetitionOddString: {
    other: 'Alle ungerade nummerierten Daten',
    one: 'Ungerade nummerierte Daten ({{daysString}})'
  },
  daysRepetitionEvenString: {
    other: 'Alle geradzahligen Daten',
    one: 'Geradzahlige Daten ({{daysString}})'
  },
  oddNumberedRepetitionPreviewString: {
    one: 'Der Alarm klingelt an ungeraden Daten, die auf {{daysString}} fallen, zur angegebenen Zeit.',
    other: 'Der Alarm klingelt an allen ungeraden Daten zur angegebenen Zeit.'
  },
  evenNumberedRepetitionPreviewString: {
    one: 'Der Alarm klingelt an geradzahligen Daten, die auf {{daysString}} fallen, zur angegebenen Zeit.',
    other: 'Der Alarm klingelt an allen geraden Daten zur angegebenen Zeit.'
  },
  hourlyRepetitionPreviewString: {
    one: 'Dieser Alarm klingelt einmal pro {{daysString}} um:',
    other: 'Dieser Alarm klingelt {{count}} mal alle {{daysString}} an:'
  },
  daysRepetitionPreviewString: {
    one: 'Dieser Alarm wird jeden Tag klingeln.',
    other: 'Dieser Alarm klingelt alle {{count}} Tage.'
  },
  weeksRepetitionPreviewString: {
    one: 'Dieser Alarm wird jede Woche klingeln.',
    other: 'Dieser Alarm klingelt alle {{count}} Wochen.'
  },
  everyNDays: { one: 'Jeden Tag', other: 'Alle {{count}} Tage' },
  everyNWeeks: { one: 'Jede Woche', other: 'Alle {{count}} Wochen' },
  everyNMonths: { one: 'Jeden Monat', other: 'Alle {{count}} Monate' },
  everyNYears: { one: 'Jedes Jahr', other: 'Alle {{count}} Jahre' },
  cantSetEndTime: 'Endzeit kann nicht eingestellt werden',
  endTimeShouldBeGreater: 'Die Endzeit sollte größer als die Startzeit sein.',
  cantSetStartTime: 'Startzeit kann nicht eingestellt werden',
  startTimeShouldBeLess: 'Die Startzeit sollte kürzer sein als die Endzeit.',
  everyDaySmall: 'Tag',
  everyGivenDay: '{{day}}',
  everyGivenDays: '{{days}}',
  adjustingAlarmsAfterTimezoneChange:
    'Wir haben festgestellt, dass Sie in eine andere Zeitzone umgezogen sind. Wir passen Ihre Alarme an.',
  ringOnDays: 'Klingelt an',
  yourAlarmsMayNotRing: 'Ihre Alarme dürfen nicht klingeln',
  yourAlarmsMayNotRingTapToFix:
    'Ihre Alarme klingeln möglicherweise nicht. Tippen Sie auf , um dies zu beheben.',
  xiaomiSetupInstructions:
    'Befolgen Sie diese Anweisungen, damit Ihre Alarme immer klingeln:\n\n - Tippen Sie auf "Autostart aktivieren" und erlauben Sie "Galarm", damit Ihre Alarme immer klingeln. \n\nUm mehr zu erfahren, besuchen Sie www.galarmapp.com/setup-mi',
  oppoSetupInstructions:
    'Befolgen Sie diese Anweisungen, damit Ihre Alarme immer klingeln:\n\n - Tippen Sie auf "Autostart aktivieren" und aktivieren Sie "Automatisches Starten zulassen".\n\n - Tippen Sie auf die Schaltfläche "Letzte Apps" auf Ihrem Telefon, wischen Sie das Symbol der Galarm-App nach unten und tippen Sie auf "Sperren".  \n\nUm mehr zu erfahren, besuchen Sie www.galarmapp.com/setup-oppo.',
  oppo8AndLowerSetupInstructions:
    'Befolgen Sie diese Anweisungen, damit Ihre Alarme immer klingeln:\n\n - Tippen Sie auf "Autostart aktivieren" und aktivieren Sie "Automatisches Starten zulassen".\n\n - Tippen Sie auf die Schaltfläche "Letzte Apps" auf Ihrem Telefon, wischen Sie das Symbol der Galarm-App nach unten und tippen Sie auf "Sperren".\n\nUm mehr zu erfahren, besuchen Sie www.galarmapp.com/setup-oppo-8-and-lower.',
  vivo9And10SetupInstructions:
    'Befolgen Sie diese Anweisungen, damit Ihre Alarme immer klingeln:\n\n - Tippen Sie auf "Autostart aktivieren" und erlauben Sie "Galarm".\n\n - Tippen Sie auf "Batterienutzung", wählen Sie "Hoher Stromverbrauch im Hintergrund" und erlauben Sie "Galarm".\n\nUm mehr zu erfahren, besuchen Sie www.galarmapp.com/setup-vivo',
  vivo11SetupInstructions:
    'Befolgen Sie diese Anweisungen, damit Ihre Alarme immer klingeln:\n\n- Tippen Sie auf "Autostart aktivieren" und erlauben Sie "Galarm".\n\n- Tippen Sie auf "Batterienutzung", wählen Sie "Hoher Stromverbrauch im Hintergrund" und erlauben Sie "Galarm".\n\nUm mehr zu erfahren, besuchen Sie www.galarmapp.com/setup-vivo-11',
  vivoSetupInstructions:
    'Befolgen Sie diese Anweisungen, damit Ihre Alarme immer klingeln:\n\n- Öffnen Sie "Telefoneinstellungen > Apps > Spezieller App-Zugriff > Autostart" und aktivieren Sie den Autostart für Galarm.\n\n- Öffnen Sie "Telefoneinstellungen > Akku > Verwaltung des Stromverbrauchs im Hintergrund > Galarm" und wählen Sie "Stromverbrauch im Hintergrund nicht einschränken".\n\nUm mehr zu erfahren, besuchen Sie www.galarmapp.com/setup-vivo-12-and-above.',
  vivo8AndLowerSetupInstructions:
    'Befolgen Sie diese Anweisungen, damit Ihre Alarme immer klingeln:\n\n - Tippen Sie auf "Autostart aktivieren" und erlauben Sie "Galarm".\n\n - Tippen Sie auf "Batterienutzung", wählen Sie "Hoher Stromverbrauch im Hintergrund" und erlauben Sie "Galarm".\n\nUm mehr zu erfahren, besuchen Sie www.galarmapp.com/setup-vivo-8-and-lower',
  huawei7AndLowerAdditionalSetupInstructions:
    'Befolgen Sie diese Anweisungen, damit Ihre Alarme immer klingeln:\n\n - Tippen Sie auf "Geschützte Apps" und erlauben Sie "Galarm".\n\nUm mehr zu erfahren, besuchen Sie www.galarmapp.com/setup-huawei-7-and-lower',
  huaweiAdditionalSetupInstructions:
    'Befolgen Sie diese Anweisungen, damit Ihre Alarme immer klingeln:\n\n - Tippen Sie auf "App-Start verwalten" und schalten Sie "Galarm" auf "Manuell verwalten". Stellen Sie in der folgenden Eingabeaufforderung sicher, dass "Automatisch starten" und "Im Hintergrund ausführen" aktiviert sind.\n\nUm mehr zu erfahren, besuchen Sie www.galarmapp.com/setup-huawei.',
  meizuSetupInstructions:
    'Befolgen Sie die Anweisungen unter dem folgenden Link, damit Ihre Alarme immer klingeln: https://dontkillmyapp.com/meizu',
  asusSetupInstructions:
    'Befolgen Sie diese Anweisungen, damit Ihre Alarme immer klingeln:\n\n - Tippen Sie auf "Autostart aktivieren" und klicken Sie auf "Autostart-Manager". Aktivieren Sie "Galarm" im "Autostart-Manager".\n\nUm mehr zu erfahren, besuchen Sie www.galarmapp.com/setup-asus.',
  nokia8AndLowerAdditionalSetupInstructions:
    'Befolgen Sie diese Anweisungen, damit Ihre Alarme immer klingeln:\n\n - Tippen Sie auf "Autostart aktivieren" und erlauben Sie "Galarm".\n\nUm mehr zu erfahren, besuchen Sie www.galarmapp.com/setup-nokia-8-and-lower',
  nokiaAdditionalSetupInstructions:
    'Befolgen Sie diese Anweisungen, damit Ihre Alarme immer klingeln:\n\n - Tippen Sie auf "Eingeschränkte Apps" und deaktivieren Sie "Galarm".\n\nUm mehr zu erfahren, besuchen Sie www.galarmapp.com/setup-nokia',
  lenovoSetupInstructions:
    'Befolgen Sie diese Anweisungen, damit Ihre Alarme immer klingeln:\n\n - Tippen Sie auf die Schaltfläche "Letzte Apps" auf Ihrem Telefon, suchen Sie die Galarm-App und tippen Sie auf das Symbol "Sperren" in der oberen rechten Ecke.\n  \nUm mehr zu erfahren, besuchen Sie https://dontkillmyapp.com/lenovo',
  samsungAdditionalSetupInstructions:
    'Befolgen Sie diese Anweisungen, damit Ihre Alarme immer klingeln:\n\n - Tippen Sie auf "Unüberwachte Apps" und erlauben Sie "Galarm".\n\nUm mehr zu erfahren, besuchen Sie www.galarmapp.com/setup-samsung',
  tecnoSetupInstructions:
    'Befolgen Sie die Anweisungen unter dem folgenden Link, damit Ihre Alarme immer klingeln: https://www.galarmapp.com/setup-tecno',
  infinixSetupInstructions:
    'Befolgen Sie die Anweisungen unter dem folgenden Link, damit Ihre Alarme immer klingeln: https://www.galarmapp.com/setup-infinix',
  samsungAdditionalSetupInstructionsAndroid11AndAbove:
    'Standardmäßig zeigt Samsung "kurze" Benachrichtigungen an, die schnell wieder verschwinden. Befolgen Sie diese Anweisungen, um "detaillierte" alarm-Benachrichtigungen anzuzeigen.\n\n - Öffnen Sie die Telefoneinstellungen und wählen Sie "Benachrichtigungen".\n - Wählen Sie den Stil "Ausführliche" Benachrichtigungen\n  \nWeitere Informationen finden Sie unter www.galarmapp.com/setup-samsung-android11-and-above.',
  iosSetupInstructions:
    'Standardmäßig ist der iOS-Bannerstil für Benachrichtigungen auf "Temporär" eingestellt, wodurch die Benachrichtigungen schnell verschwinden. Befolgen Sie diese Anweisungen, um den Bannerstil auf "Dauerhaft" einzustellen.\n\n- Öffnen Sie die Telefoneinstellungen und blättern Sie zu Galarm\n- Wählen Sie "Benachrichtigungen" und stellen Sie den Banner-Stil auf "Dauerhaft" ein.\n\nWeitere Informationen finden Sie unter www.galarmapp.com/setup-ios.',
  iosSetupInstructions15AndBelow:
    'Standardmäßig ist der iOS-Bannerstil für Benachrichtigungen auf "Temporär" eingestellt, wodurch die Benachrichtigungen schnell verschwinden. Befolgen Sie diese Anweisungen, um den Bannerstil auf "Dauerhaft" einzustellen.\n\n- Öffnen Sie die Telefoneinstellungen und blättern Sie zu Galarm\n- Wählen Sie "Benachrichtigungen" und stellen Sie den Banner-Stil auf "Dauerhaft" ein.\n\nWeitere Informationen finden Sie unter www.galarmapp.com/setup-ios-15-below.',
  setNotificationsStyle: 'Stil der Benachrichtigungen einstellen',
  setNotificationsStyleToDetailed:
    'Stil der Benachrichtigungen auf "Detailliert" einstellen',
  setBannerStyle: 'Banner-Stil einstellen',
  setBannerStyleToPersistent: 'Banner-Stil auf "Dauerhaft" einstellen',
  selectAtleastOneDay:
    'Wählen Sie die Tage aus, an denen der Alarm klingeln soll.',
  selectAtLeastOneHourOrMinute:
    'Entweder Stunden oder Minuten sollten ein Wert ungleich Null sein',
  hourlyAlarmDefaultStartTimeSetAlert:
    'Die Startzeit des Alarms wurde auf 8 Uhr morgens eingestellt.',
  batteryUsage: 'Verwendung der Batterie',
  dontShowAgain: 'Nicht wieder auftauchen',
  alarmWillRingEveryHourTillSeen:
    'Der Alarm wird jede Stunde schlummern, bis Sie etwas unternehmen.',
  unknown: 'Unbekannt',
  alarmMarkedDone: '{{name}} hat den Alarm als "Erledigt" markiert.',
  alarmMarkedSkipped: '{{name}} hat den Alarm übersprungen.',
  alarmIsMarkedDone: '{{alarmName}} wird als Erledigt markiert.',
  alarmIsSkipped: '{{alarmName}} wird übersprungen.',
  alarmIsConfirmed: '{{alarmName}} wird bestätigt.',
  alarmIsDeclined: '{{alarmName}} wird abgelehnt.',
  alarmIsSnoozed: '{{alarmName}} wird geschlummert.',
  alarmIsAccepted: '{{alarmName}} wird akzeptiert.',
  alarmIsRejected: '{{alarmName}} wird abgelehnt.',
  newContactInfo:
    'Bitte geben Sie den Namen und die Telefonnummer des Kontakts an.\n\nNur registrierte Benutzer können als Kontakte hinzugefügt werden. Wenn der Benutzer kein registrierter Galarm-Benutzer ist, können Sie ihn über "Einstellungen > Diese App teilen" einladen. Sie können ihn als Kontakt hinzufügen, nachdem er sich mit seiner Telefonnummer registriert hat. ',
  cantEditContactMobileNumber:
    'Sie können die Rufnummer nicht bearbeiten. Löschen Sie den Kontakt und fügen Sie ihn erneut hinzu, wenn Sie die Nummer ändern möchten.',
  phoneContactsNotSyncedPermissionDenied:
    'Galarm hat keine Berechtigung, auf Telefonkontakte zuzugreifen. Telefonkontakte werden nicht synchronisiert.',
  inviteContactMessage:
    'Hallo {{contactName}}, ich möchte Sie in meine Alarme und Erinnerungen mit Galarm aufnehmen. Laden Sie die App von {{downloadLink}} herunter. Bitte registrieren Sie sich mit dieser Nummer und beginnen Sie mit dem Austausch von Alarmen.',
  noRepeat: 'Wiederholt sich nicht',
  deepLinkNoLongerValidTitle: 'Huch!',
  deepLinkNoLongerValidMessage:
    'Alarm wurde nicht erstellt. Möglicherweise handelt es sich um eine alte oder ungültige Verknüpfung.',
  inviteContact: 'Kontakt einladen',
  fromPhoneBook: 'Aus dem Adressbuch',
  addFromPhoneBook: 'Aus Adressbuch einladen',
  manually: 'Manuell',
  addManualContact: 'Manuell einladen',
  noOtherContactsFound: 'Alle Kontakte in Ihrem Adressbuch verwenden Galarm.',
  cantAddPhoneContactPermissionDenied:
    'Galarm hat keine Berechtigung, auf Telefonkontakte zuzugreifen. Erlauben Sie den Zugriff auf Kontakte.',
  alarmWillRingWithRandomRingtone:
    'Es wird ein zufälliger Klingelton für den Alarm gewählt.',
  alarmWillRingWithRandomRingtoneGlobal:
    'Bei Alarmen wird ein zufälliger Klingelton gewählt.',
  createdAlarmFromDeepLinkTitle: 'Sie sind bereit!',
  createdAlarmFromDeepLinkMessage:
    'Sie werden unter {{alarmTime}} an "{{alarmName}}" erinnert. {{alarmDate}}',
  createdRepeatingAlarmFromDeepLinkMessage: {
    zero: 'Sie werden an "{{alarmName}}" erinnert. {{repetitionString}}',
    one: 'Unter {{alarmTime}} werden Sie an "{{alarmName}}" {{repetitionString}} erinnert. {{alarmDate}}'
  },
  deleteCategoryAlertTitle: 'Kategorie löschen?',
  deleteCategoryAlertMessage: {
    zero: '',
    one: '1 Alarm gehört zu dieser Kategorie. Wenn Sie diese Kategorie löschen, wird die Kategorie für diesen Alarm zurückgesetzt.',
    other:
      '{{count}} Alarme gehören zu dieser Kategorie. Wenn Sie diese Kategorie löschen, wird die Kategorie für diese Alarme zurückgesetzt.'
  },
  updateReadyToInstallAlertTitle: 'Update installieren?',
  updateReadyToInstallAlertMessage:
    'Die Updates können jetzt installiert werden. Möchten Sie das Update jetzt installieren? ',
  hideCategoryAlertTitle: 'Kategorie ausblenden?',
  hideCategoryAlertMessage: {
    zero: 'Sie werden diese Kategorie in Zukunft nicht mehr verwenden können.',
    one: '1 Alarm gehört zu dieser Kategorie. Wenn Sie diese Kategorie ausblenden, wird die Kategorie für diesen Alarm zurückgesetzt.\n  \nSie können diese Kategorie in Zukunft nicht mehr verwenden.',
    other:
      '{{count}} Alarme gehören zu dieser Kategorie. Wenn Sie diese Kategorie ausblenden, wird die Kategorie für diese Alarme zurückgesetzt.\n  \nSie können diese Kategorie in Zukunft nicht mehr verwenden.'
  },
  alarmCategoryDeleteAllAlarmsPrompt: {
    one: '1 Alarm gehört zu dieser Kategorie. Möchten Sie ihn löschen?\n\nBeachten Sie, dass Alarme von Teilnehmern nicht gelöscht werden.',
    other:
      '{{count}} Alarme gehören zu dieser Kategorie. Möchten Sie sie löschen?\n\nBeachten Sie, dass die Alarme der Teilnehmer nicht gelöscht werden.'
  },
  enterNameForGroup: 'Geben Sie einen Namen für die Gruppe ein',
  enterMobileNumber: 'Geben Sie Ihre Rufnummer ein',
  enterNotesForAlarm: 'Notizen zum Alarm eingeben',
  enterUserName: 'Geben Sie Ihren Namen ein',
  enterEmailId: 'Optional',
  enterFeedback: 'Wie können wir uns verbessern?',
  describeProblem: 'Beschreiben Sie Ihr Problem',
  invited: 'Eingeladen',
  resend: 'Erneut senden',
  addContact: 'Kontakt hinzufügen',
  inviteAgain: 'Erneut einladen',
  contactNotRegistered:
    '{{contactName}} Galarm noch nicht installiert hat. Sie können sie zu Alarmen hinzufügen, sobald sie installiert und mit ihrer Telefonnummer registriert sind.',
  registration: 'Anmeldung',
  verify: 'Überprüfung',
  profile: 'Profil',
  profileImage: 'Profilbild',
  addProfileImage: 'Profilbild hinzufügen',
  groupDetails: 'Details zur Gruppe',
  newGroup: 'Neue gruppe',
  uploadImage: 'Bild ändern',
  removeImage: 'Bild entfernen',
  selectParticipants: 'Teilnehmer auswählen',
  selectMembers: 'Mitglieder auswählen',
  selectTimezone: 'Zeitzone auswählen',
  searchForATimezone: 'Suche nach einer Zeitzone',
  timezone: 'Zeit Zone',
  deviceTimezone: 'Gerät Zeitzone',
  specifyTimezoneForAlarm: 'Festlegen der Zeitzone für Alarme',
  editName: 'Name bearbeiten',
  groupImage: 'Gruppe Bild',
  addGroupImage: 'Gruppenbild hinzufügen',
  selectAlarmType: 'Erstellen eines Alarms für',
  contactDetails: 'Kontakt Details',
  commonAlarms: 'Alarme',
  commonGroups: 'Gruppen',
  groupAlarmsTitle: 'Alarme',
  selectRingtone: 'Klingelton auswählen',
  selectMusic: 'Musik auswählen',
  select: 'Wählen Sie',
  personalAlarm: 'Persönlicher Alarm',
  groupAlarm: 'Gruppe Alarm',
  newPersonalAlarm: 'Neuer persönlicher Alarm',
  newGroupAlarm: 'Neue Gruppe Alarm',
  editPersonalAlarm: 'Persönlichen Alarm bearbeiten',
  editGroupAlarm: 'Gruppe bearbeiten Alarm',
  rearrangeParticipants: 'Teilnehmer neu anordnen',
  alarmConversation: 'Alarm Chat',
  timezoneMismatchDetected:
    'Irgendetwas sagt uns, dass Sie sich in einer anderen Zeitzone befinden als das letzte Mal, als Sie Galarm benutzt haben.\n\nBitte starten Sie Galarm neu, damit wir uns an die neue Zeitzone anpassen können.',
  deviceRestartRequired: 'App-Neustart erforderlich',
  disabledAlarm: 'Deaktivierter Alarm {{name}}',
  newContact: 'Neuer Kontakt',
  contactName: 'Kontakt Name',
  contactMobileNumber: 'Kontakt Telefonnummer',
  enterNameForContact: 'Name des Kontakts eingeben',
  pickCountryForContact: 'Land des Kontaktes',
  enterMobileNumberForContact: 'Rufnummer des Kontakts eingeben',
  specifyNameForContact: 'Name des Kontakts ist erforderlich',
  specifyValidPhoneNumberForContact:
    'Eine gültige Telefonnummer ist erforderlich',
  addingContact: 'Hinzufügen eines Kontakts',
  deletingContact: 'Entfernen des Kontakts',
  editingContact: 'Redaktioneller Kontakt',
  pending: 'Anhängig',
  deleteContact: 'Kontakt entfernen',
  galarmContact: 'Manuell hinzugefügt',
  phoneContact: 'Aus Telefonkontakten hinzugefügt',
  confirmDeleteContactTitle: '{{name}} entfernen?',
  confirmDeleteContact: 'Sie können sie nicht zu Ihren Alarmen hinzufügen.',
  contactAdded:
    'Großartig! Jetzt können Sie {{name}} als Teilnehmer zu Ihren Alarmen hinzufügen.',
  contactInvited:
    'Eine Textnachricht wurde an {{name}} gesandt, in der sie zur Nutzung von Galarm aufgefordert werden.',
  changeSnoozeDuration: 'Veränderung Dauer',
  durationOfEvent: 'Dauer des Ereignisses',
  upgradeToPremium: 'Aktualisierung auf Premium',
  premium: 'Prämie',
  alarmLimitReached: 'Alarmgrenze erreicht',
  unableToConnectToGooglePlay:
    'Verbindung zu Google Play kann nicht hergestellt werden. Stellen Sie sicher, dass Sie bei Ihrem Google-Konto angemeldet sind.',
  cantLoadVideo: 'Video kann nicht geladen werden',
  cantUpgradeToPremium: 'Keine Aktualisierung auf Premium möglich',
  unableToConnectToIapAndroid:
    'Die Verbindung zum Play Store kann nicht hergestellt werden. Bitte öffnen Sie die Play Store-App und vergewissern Sie sich, dass Sie eingeloggt sind.',
  unableToConnectToIapIos:
    'Die Verbindung zum App Store kann nicht hergestellt werden. Bitte öffnen Sie die App Store App und vergewissern Sie sich, dass Sie eingeloggt sind.',
  premiumFeaturesIos:
    'Als Premium-Nutzer erhalten Sie ein erhöhtes Limit von {{maxLimit}} gleichzeitigen Alarmen.\n\nIhr Kauf hilft uns außerdem, weitere Funktionen zu entwickeln.',
  premiumFeaturesAndroid:
    'Erstellen Sie unbegrenzte Alarme durch eine Aktualisierung auf premium.\n\nIhr Kauf hilft uns auch bei der Entwicklung weiterer Funktionen.',
  upgrade: 'Aktualisierung',
  rvInternalError:
    'Wir konnten das Video aufgrund eines internen Fehlers nicht laden. Wir haben jedoch Ihre Alarmgrenze um 1 erhöht.',
  rvNetworkError:
    'Bitte stellen Sie sicher, dass Sie eine gute Internetverbindung haben und versuchen Sie es erneut.',
  rvNotFoundError:
    'Wir konnten zu dieser Zeit kein passendes Video finden. Wir haben jedoch Ihre Alarmgrenze um 1 erhöht.',
  dontRemindMe: 'Erinnern Sie mich nicht daran',
  sameTimeAsBuddy: 'Zur gleichen Zeit wie mein Freund',
  noAlertForBuddyAlarm:
    'Sie haben sich dafür entschieden, bei diesem Alarm nicht alarmiert zu werden.',
  noAlertRecipientIntervalDescription: 'Sie werden nicht gewarnt.',
  chooseHours: 'Stunden wählen',
  chooseMins: 'Minuten wählen',
  changeHours: 'Stunden ändern',
  moreOptions: 'Mehr Optionen...',
  set: 'Einstellen',
  chooseNumberOfDays: 'Anzahl der Tage eingeben',
  chooseNumberOfWeeks: 'Anzahl der Wochen eingeben',
  numberOfDaysShouldBeAtLeastOne:
    'Die Anzahl der Tage sollte mindestens 1 betragen.',
  numberOfWeeksShouldBeAtLeastOne:
    'Die Anzahl der Wochen sollte mindestens 1 betragen.',
  numberOfMonthsShouldBeAtLeastOne:
    'Die Anzahl der Monate sollte mindestens 1 betragen.',
  numberOfYearsShouldBeAtLeastOne:
    'Die Anzahl der Jahre sollte mindestens 1 betragen.',
  frequency: 'Frequenz',
  configuration: 'Konfiguration',
  endDate: 'Datum des Alarmendes',
  noEndDate: 'Kein Enddatum',
  alarmEndDateSetOnAlarm:
    'Das Datum für das Ende des Alarms ist auf {{endDateString}} eingestellt.',
  noAlarmDateSetForAlarm: 'Für diesen Alarm ist kein Enddatum eingestellt.',
  noOccurrenceForThisAlarmAsPerEndDate:
    'Das Datum des Endes lässt kein Auftreten des Alarms zu.',
  alarmDateShouldBeBiggerThanAlarmStartDate:
    'Das Datum für das Ende des Alarms sollte nach dem Datum für den Beginn des Alarms liegen.',
  aWeek: 'Eine Woche',
  aMonth: 'Ein Monat',
  allInactiveAlarms: 'Alle inaktiven Alarme',
  chooseAlarmEndDate: 'Wählen Sie das Datum für das Ende des Alarms',
  next: 'Nächstes',
  next7Days: '7 Tage ab heute',
  next30Days: '30 Tage ab heute',
  next12Months: '12 Monate ab heute',
  next10Years: '10 Jahre ab heute',
  chooseDate: 'Wählen Sie ein Datum...',
  learnMore: 'Mehr erfahren',
  repeatEvery: 'Wiederholt sich alle',
  repeatEveryDays: 'Tag(e)',
  repeatEveryWeeks: 'Woche(n)',
  repeatEveryMonths: 'Monat(e)',
  repeatEveryYears: 'Jahr(e)',
  autoSnooze: 'Alarme automatisch schlummern lassen',
  autoSnoozePerAlarm: 'Automatisches Schlummern des Alarms',
  unableToUploadGroupAvatar:
    'Das Gruppenbild kann nicht hochgeladen werden. Bitte versuchen Sie es später noch einmal',
  addNewMembersToExistingAlarms:
    'Bei dieser Gruppe gibt es die folgenden Alarme:\n\n{{alarmNames}}\n    \nMöchten Sie die neuen Mitglieder zu diesen Alarmen hinzufügen?',
  removeGroupMemberAlert:
    'Das Entfernen des Mitglieds hat Auswirkungen auf die folgenden Alarme:\n\n{{alarmNames}}\n\nMöchten Sie das Mitglied immer noch entfernen?',
  leaveGroupAlert:
    'Das Verlassen der Gruppe hat Auswirkungen auf die folgenden Alarme:\n\n{{alarmNames}}\n\nWollen Sie die Gruppe immer noch verlassen?',
  updateAppAfterFeedback:
    'Wir haben festgestellt, dass Sie eine ältere Version der App verwenden. Bitte aktualisieren Sie diese für ein besseres Erlebnis.',
  changesAreLost:
    'Sie haben ungespeicherte Änderungen.\n\nMöchten Sie vor der Abreise speichern?',
  recipientAlarmIntervalGreaterThanRepetitionInterval:
    '"Das Intervall für die Erinnerung sollte kürzer sein als das Wiederholungsintervall.',
  cascadingAlarmIntervalGreaterThanRepetitionInterval:
    'Das Intervall "Zeit zwischen den Warnungen der Teilnehmer" sollte kleiner sein als das Wiederholungsintervall.',
  subscriptionCanceled:
    'Ihr Premium-Abonnement wurde abgebrochen. Die Premium-Funktionen sind erst wieder verfügbar, wenn Sie Ihr Abonnement erneuern.',
  unableToRenewSubscription:
    'Bei der Verlängerung Ihres Premium-Abonnements ist ein Problem aufgetreten. Bitte vergewissern Sie sich, dass Ihre Zahlungsinformationen aktuell sind.',
  notice: 'Hinweis',
  ringerSettingsWillApplyToNewAlarms:
    'Änderungen, die Sie an den Einstellungen für Klänge und Vibrationen vornehmen, gelten nur für neue Alarme.\n\nBei bestehenden Alarmen können Sie den Alarm bearbeiten, um seine Einstellungen für Klang und Vibration zu ändern.',
  renew: 'Erneuern Sie',
  galarmPremium: 'Galarm Premium',
  payMonthly: 'Monatliche Zahlung unter {{price}} pro Monat',
  payYearly: 'Jährliche Zahlung unter {{price}} pro Jahr',
  free: 'Kostenlos',
  unlimited: 'Unbegrenzt',
  personalAlarms: 'Persönliche Alarme',
  groupAlarms: 'Gruppen Alarme',
  buddyAlarms: 'Freunde Alarme',
  alarmRepetitions: 'Wiederholungen',
  ringtones: 'Klingeltöne',
  alarmHistory: 'Alarm-Verlauf',
  base: 'Grundlegend',
  advanced: 'Fortgeschrittene',
  complete: 'Vollständig',
  alarmHistoryFree: 'Begrenzt',
  phoneMusicAsRingtone: 'Telefonmusik als Klingelton',
  startFreeTrial: '14-tägige kostenlose Testversion starten',
  chargedAtEndOfFreeTrial:
    'Nach Ablauf der 14-tägigen kostenlosen Testphase werden Ihnen die Kosten in Rechnung gestellt, sofern Sie Ihr Abonnement nicht abbrechen.',
  monthlySubscription: 'Monatlich',
  yearlySubscription: 'Jährlich',
  premiumRingtoneRequiresUpgrade:
    'Für diesen Klingelton ist ein Premium-Abonnement erforderlich.',
  defaultPremiumFeaturesDescription:
    'Aktualisieren Sie auf Premium, um diese coolen Funktionen zu aktivieren.',
  groupAlarmsPremiumFeaturesDescription:
    'Sie benötigen ein Premium-Abonnement, um mehr als 1 Gruppenalarm zu erstellen.',
  buddyAlarmsPremiumFeaturesDescription:
    'Sie benötigen ein Premium-Abonnement, um mehr als 1 Alarm für eine andere Person ("Freund") zu erstellen.',
  shareableAlarmLinksPremiumFeaturesDescription:
    'Sie benötigen ein Premium-Abonnement, um mehr teilbare Alarm-Links zu erstellen.',
  viewPremiumFeaturesDescription:
    'Vielen Dank, dass Sie ein Premium-Nutzer sind. Als Premium-Abonnent können Sie diese coolen Funktionen nutzen.',
  accessToPremiumFeatures:
    'Sie erhalten Zugang zu diesen Funktionen als Teil des Abonnements:',
  alarmCategoryPremiumFeaturesDescription:
    'Sie benötigen ein Premium-Abonnement, um neue Kategorien zu erstellen.',
  galarmWebPremiumFeaturesDescription:
    'Sie benötigen ein Premium-Abonnement, um Galarm in einem Webbrowser zu verwenden.',
  instantAlarmPremiumFeaturesDescription:
    'Sie benötigen ein Premium-Abonnement, um weitere Sofortalarme zu erstellen.',
  personalAlarmsFeatureDescription:
    'Verwenden Sie einen persönlichen Alarm für das Aufwachen, die Einnahme von Medikamenten oder jede andere Aufgabe für sich selbst. Sie können optional Teilnehmer hinzufügen, die benachrichtigt werden, wenn Sie einen Alarm verpassen, damit sie Sie erinneren können. Die Teilnehmer werden auch benachrichtigt, wenn Sie den Alarm als erledigt markieren.',
  groupAlarmsFeatureDescription:
    'Verwenden Sie einen Gruppenalarm für Gruppenaktivitäten wie Ausflüge, Partys oder andere Veranstaltungen. Der Alarm ertönt zur gleichen Zeit für alle Teilnehmer und sie können ihre Teilnahme bestätigen.',
  buddyAlarmsFeatureDescription:
    'Erstellen Sie Alarme für eine andere Person ("Freund"), um sie an Dinge zu erinnern, die sie erledigen muss. Sie werden benachrichtigt, um den Freund zu erinnern, falls er den Alarm verpasst. Sie werden auch benachrichtigt, wenn der Freund den Alarm als erledigt markiert.',
  unlimitedAlarmsFeatureDescription:
    'Erstellen Sie mehr Gruppen-, Freundes- und Sofortalarme.',
  alarmRepetitionsFeatureDescription:
    'Erstellen Sie Alarme mit erweiterten Wiederholungen, z. B. alle 30 Minuten, alle 2 Stunden und 45 Minuten, jeden zweiten Tag, zweiwöchentlich und viele mehr.',
  ringtonesFeatureDescription:
    'Wählen Sie aus einer Vielzahl von Premium-Klingeltönen für Ihre Alarme. Wir fügen dem Premium-Angebot laufend weitere Klingeltöne hinzu.',
  phoneMusicAsRingtoneFeatureDescription:
    'Wählen Sie einen beliebigen Song/Musik auf Ihrem Telefon als Alarm-Klingelton.',
  shareableAlarmLinksFeatureDescription:
    'Sie können Alarme über einen Link mit anderen teilen.\n\nEin Lehrer kann zum Beispiel den Link zu seinem Klassenalarm per E-Mail, WhatsApp oder einer anderen Nachrichten-App mit den Schülern teilen. Wenn die Schüler auf den Link tippen, wird eine Kopie des Klassenalarms auf ihren Telefonen erstellt. Alle Schüler werden zur angegebenen Zeit alarmiert, damit sie sich der Klasse anschließen können.',
  instantAlarmFeatureDescription:
    'Alarmieren Sie eine Gruppe von Personen mit einem einzigen Mausklick.\n\nVerwenden Sie diesen Alarm, um sie auf eine Notfallübung, den Beginn eines Spiels, eine dringende Aufgabe/Besprechung und andere Ereignisse aufmerksam zu machen, die sofortige Aufmerksamkeit erfordern.',
  alarmHistoryFeatureDescription:
    'Sehen Sie alle vergangenen Antworten für jeden wiederholten Alarm an. So können Sie leicht herausfinden, wann Sie den Sportunterricht verpasst haben, wann Ihre Mutter ihre Medikamente vergessen hat usw.',
  announceAlarmTitleFeatureDescription:
    'Ansage des Alarms beim Klingeln des Alarms.',
  exportToCalendarPremiumFeaturesDescription:
    'Sie benötigen ein Premium-Abonnement, um mehr Alarme in den Kalender zu exportieren.',
  exportToCalendarFeatureDescription:
    'Sie können Alarme in Ihren Kalender exportieren. So können Sie Ihre Alarme einfach im Kalender Ihres Geräts anzeigen und Ihre Verfügbarkeit besser widerspiegeln.',
  galarmWebFeatureDescription:
    'Verwenden Sie Galarm in einem Webbrowser, um Alarme zu erstellen, zu bearbeiten und zu löschen.',
  preReminderFeatureDescription:
    'Stellen Sie Vorab-Erinnerungen ein, um vor bevorstehenden Alarmen gewarnt zu werden. Sie können die relative Dauer konfigurieren, um vor der eigentlichen Zeit des Alarms benachrichtigt zu werden, z. B. 30 Minuten vorher, 1 Tag vorher usw.',
  calendarViewFeatureDescription:
    'Ansicht der Alarme in einem Kalender, um die Alarme für jeden Tag zu sehen.',
  updatePhoneNumberFeatureDescription:
    'Sie können Ihre für dieses Konto verwendete Telefonnummer ändern. Alle Ihre Alarme, Kontakte, Gruppen und andere Einstellungen werden auf die neue Telefonnummer übertragen.',
  alarmPhotoFeatureDescription:
    'Fügen Sie einem Alarm ein Foto für schnelle visuelle Hinweise hinzu, z. B. ein Foto von einem Medikament, Anweisungen für eine Aufgabe, einem Gegenstand usw. Das Foto wird angezeigt, wenn der Alarm klingelt.',
  taskListFeatureDescription:
    'Erstellen Sie Aufgabenlisten, um zusammengehörige Aufgaben zusammenzuhalten.',
  calendarViewPremiumFeature: 'Ansicht des Kalenders',
  updatePhoneNumberPremiumFeature: 'Telefonnummer ändern',
  alarmNotFound: 'Alarm nicht gefunden',
  alarmNotFoundMessage:
    'Dieser Alarm wurde möglicherweise kürzlich gelöscht. Tippen Sie auf "Entfernen", um weitere Benachrichtigungen für diesen Alarm zu deaktivieren.',
  alarmExistsWithDeepLinkTitle: 'Duplizierter Alarm',
  alarmExistsWithDeepLinkMessage:
    'Ein über diesen Link erstellter Alarm ist bereits auf Ihrem Telefon vorhanden.',
  alarmDoesntHaveNextDate:
    'Für den Alarm gibt es kein gültiges nächstes Datum.',
  sharePersonalAlarmDeepLinkDescription:
    'Tippen Sie auf diesen Link, um einen Alarm für dieses Ereignis unter {{dateString}} zu erstellen.',
  joinGroupAlarmDeepLinkDescription:
    'Klicken Sie auf diesen Link, um an dieser Gruppenveranstaltung teilzunehmen {{dateString}}',
  subscribedAlarmEdited:
    '{{alarmCreatorName}} hat {{alarmName}} aktualisiert. Möchten Sie Ihren Alarm aktualisieren?',
  subscribedAlarmDeleted:
    '{{alarmCreatorName}} hat {{alarmName}} gelöscht. Möchten Sie Ihren Alarm löschen?',
  responseReceivedForTicket:
    'Wir haben auf Ihr Ticket #{{ticketId}} geantwortet: {{email}}. Wenn sie nicht in Ihrem Posteingang ist, überprüfen Sie bitte Ihre Spam-Ordner auf eine E-Mail von support@galarm.zendesk.com.',
  newTicketReceived:
    'Danke, dass Sie uns geschrieben haben. Wir haben Ticket #{{ticketId}} erstellt, um Ihre Anfrage zu verfolgen.\n\nWir werden Ihnen an die von Ihnen angegebene E-Mail-Adresse antworten: {{email}}. Sollte diese nicht korrekt sein, übermitteln Sie Ihre Rückmeldung bitte erneut mit der richtigen E-Mail-Adresse.\n\nBitte fügen Sie auch support@galarm.zendesk.com zu Ihrem E-Mail-Adressbuch hinzu, um die ordnungsgemäße Zustellung unserer Antworten sicherzustellen. Wir werden auch hier eine Benachrichtigung senden, wenn wir auf Ihre Anfrage geantwortet haben.',
  sentAccountBackup:
    'Wir haben Ihre Alarmdaten an {{email}} gesendet. Wenn sie nicht in Ihrem Posteingang sind, überprüfen Sie bitte Ihre Spam-Ordner auf eine E-Mail von noreply@acintyo.com.',
  alarmDoesntExist: 'Der Alarm existiert nicht.',
  shareAlarm: 'Teilen Sie den Alarmlink',
  shareAlarmDescription:
    'Sie können diesen Alarm über einen Link mit jedem teilen, z. B. kann ein Lehrer einen Link teilen, um Schüler daran zu erinnern, einer Klasse beizutreten. Wenn jemand auf den Link tippt, wird eine Kopie des Alarms auf seinem Telefon erstellt.',
  shareLink: 'Link teilen',
  joinedGroupAlarmFromDeepLinkMessage:
    'Sie werden unter {{alarmTime}} an "{{alarmName}}" erinnert. {{alarmDate}}',
  joinedRepeatingGroupAlarmFromDeepLinkMessage: {
    zero: 'Sie werden an "{{alarmName}}" erinnert. {{repetitionString}}',
    one: 'Unter {{alarmTime}} werden Sie an "{{alarmName}}" {{repetitionString}} erinnert. {{alarmDate}}'
  },
  youHaveSubscribedToThisAlarm:
    'Sie verfolgen diesen Alarm von {{creatorName}}.',
  alreadySharedAlarmDescription: {
    zero: 'Sie haben keine Anhänger für diesen Alarm.\n\nSie können diesen Alarm über einen Link mit weiteren Personen teilen. Wenn sie auf den Link tippen, wird eine Kopie dieses Alarms auf ihrem Telefon erstellt.',
    one: '{{count}} Person folgt diesem Alarm.\n\nSie können diesen Alarm über einen Link mit weiteren Personen teilen. Wenn sie auf den Link tippen, wird eine Kopie dieses Alarms auf ihrem Telefon erstellt.',
    other:
      '{{count}} Menschen folgen diesem Alarm.\n\nSie können diesen Alarm über einen Link mit weiteren Personen teilen. Wenn sie auf den Link tippen, wird eine Kopie dieses Alarms auf ihrem Telefon erstellt.'
  },
  shareParentAlarmDescription:
    'Sie verfolgen diesen Alarm von {{creatorName}}.\n\nSie können diesen Alarm auch über einen Link mit anderen teilen. Wenn diese auf den Link tippen, wird eine Kopie dieses Alarms auf ihrem Telefon erstellt und sie können ebenfalls diesem Alarm folgen.',
  shareableAlarmLinks: 'Teilbare Links',
  nPeopleFollowingAlarm: {
    zero: 'Sie haben keine Anhänger für diesen Alarm.',
    one: '{{count}} Person folgt diesem Alarm.',
    other: '{{count}} Menschen folgen diesem Alarm.'
  },
  likeUsOnFacebook: 'Facebook',
  followUsOnTwitter: 'Twitter',
  followUsOnInstagram: 'Instagram',
  youtube: 'YouTube',
  mediumBlog: 'Blogs auf Medium',
  howtoVideos: 'Anleitungsvideos',
  followGalarmOnSocialMedia: 'Folgen Sie Galarm auf sozialen Medien',
  galarmOnSocialMedia: 'Galarm auf sozialen Medien',
  followGalarmOnSocialMediaDescription:
    'Sehen Sie sich spannende Neuigkeiten über App-Updates, die Nutzung von Funktionen, kommende Features sowie wichtige App-Bewertungen an. Sie können dort auch mit dem Galarm-Team interagieren.',
  sharedAlarm: 'Gemeinsamen Alarm bearbeiten',
  editingPublishedAlarm: {
    one: 'Sie bearbeiten einen Alarm, den Sie zuvor über einen Link freigegeben haben. {{count}} Person folgt derzeit diesem Alarm.\n\nWenn Sie Änderungen an diesem Alarm vornehmen, wird diese Person über die Änderungen benachrichtigt.',
    other:
      'Sie bearbeiten einen Alarm, den Sie zuvor über einen Link freigegeben haben. {{count}} Personen folgen derzeit diesem Alarm.\n\nWenn Sie Änderungen an diesem Alarm vornehmen, werden diese Personen über die Änderungen benachrichtigt.'
  },
  editingSubscribedAlarm:
    'Sie verfolgen diesen Alarm von {{creatorName}}. Sie erhalten keine Aktualisierungen für diesen Alarm, nachdem Sie den Alarm bearbeitet haben.',
  viewAlarm: 'Ansicht Alarm',
  view: 'Ansicht',
  editAlarm: 'Alarm bearbeiten',
  thanksForTryingPremiumTitle: 'Sie sind bereit!',
  thanksForTryingPremiumMessage:
    'Wir wünschen Ihnen viel Spaß mit den Premium-Funktionen.\n\nBitte kontaktieren Sie den Support über "Einstellungen > Feedback" oder senden Sie uns eine E-Mail an galarm@acintyo.com, wenn Sie Fragen haben.',
  subscribedAlarmEditedInAlarmDetails:
    '{{creatorName}} hat seinen Alarm geändert.\nAktualisieren Sie Ihren Alarm?',
  subscribedAlarmDeletedInAlarmDetails:
    '{{creatorName}} hat seinen Alarm gelöscht.\nHaben Sie Ihren Alarm gelöscht?',
  updateSubscribedAlarm: 'Ja, aktualisieren',
  deleteSubscribedAlarm: 'Ja, löschen',
  ignoreSubscribedAlarm: 'Nein, ignorieren',
  customRepetitionPremiumScreenDescription:
    'Die benutzerdefinierte Wiederholung ist eine Premium-Funktion.\n\nMit dieser Funktion können Sie Alarme mit erweiterten Wiederholungen erstellen. Beispiele hierfür sind:\n',
  repeatAlternateDate: '✅ An abwechselnden Tagen wiederholen',
  repeatEveryNDay: '✅ Alle 3 Tage wiederholen',
  repeatEveryNHoursAndMMinutes: '✅ Alle 2 Stunden 30 Minuten wiederholen',
  repeatEveryNMinutes: '✅ Alle 45 Minuten wiederholen',
  repeatBiweekly: '✅ Alle 2 Wochen wiederholen',
  repeatEveryNWeeks: '✅ Alle 6 Wochen wiederholen',
  repeatEveryNMonths: '✅ Alle 3 Monate wiederholen (vierteljährlich)',
  repeatEveryLastDayOfMonth:
    '✅ Wiederholen Sie den letzten Tag eines jeden Monats',
  repeatDayOfWeekOfMonth: '✅ Wiederholung am 2. Samstag eines jeden Monats',
  repeatLastDayOfWeekOfMonth:
    '✅ Wiederholung am letzten Freitag eines jeden Monats',
  customRepetition: 'Benutzerdefinierte Wiederholung',
  lastDayOfMonth: 'Letzter Tag eines jeden Monats',
  dayOfWeekInMonth: '{{weekNumberOfDayInMonth}} {{dayName}} eines jeden Monats',
  lastDayOfWeekInMonth: 'Letzte {{dayName}} eines jeden Monats',
  dayOfWeekInMonthInYearSummary:
    '{{weekNumberOfDayInMonth}} {{dayName}} von {{monthName}}',
  lastDayOfWeekInMonthInYearSummary: 'Zuletzt {{dayName}} von {{monthName}}',
  lastDayOfMonthInYearSummary: 'Letzter Tag des {{monthName}}',
  dayOfWeekInMonthInYear:
    '{{weekNumberOfDayInMonth}} {{dayName}} von {{monthName}} jedes Jahr',
  lastDayOfWeekInMonthInYear: 'Letzte {{dayName}} von {{monthName}} jedes Jahr',
  lastDayOfMonthInYear: 'Letzter Tag von {{monthName}} jedes Jahr',
  chooseAMonthsConfiguration: 'Bitte wählen Sie eine Konfiguration.',
  chooseADaysConfiguration: 'Bitte wählen Sie eine Konfiguration',
  chooseAYearsConfiguration: 'Bitte wählen Sie eine Konfiguration.',
  sendCodeOnSmsAgain: 'Code erneut senden',
  didntGetCodeNewUser:
    'Sie haben den Code nicht erhalten?\n\nSie können den Code erneut anfordern oder die Registrierung vorerst überspringen. Sie können sich später registrieren.',
  didntGetCodeExistingUser:
    'Sie haben den Code nicht erhalten?\n\nSie können den Code erneut anfordern oder die Registrierung abbrechen. Sie können sich später registrieren.',
  didntGetCodeUpdatePhoneNumber:
    'Sie haben den Code nicht erhalten?\n\nSie können den Code erneut anfordern oder es später noch einmal versuchen.',
  alreadySentCodeAgain:
    'Wir haben Ihnen den Code erneut zugeschickt. Wenn Sie Schwierigkeiten haben, den Code zu erhalten, überspringen Sie bitte die Registrierung und registrieren Sie sich später.',
  theme: 'thema',
  announceAlarmName: 'Alarm-Titel ankündigen',
  ttsHelperText:
    'Der Titel des Alarms wird angesagt, wenn der Alarm klingelt. Sie können auf die Wiedergabetaste vor dem Titel des Alarms tippen, um eine Vorschau zu hören.',
  initializingTts: 'Initialisierung von Text in Sprache',
  errorSpeakingAlarmName:
    'Sie können den Titel des Alarms nicht sprechen. Bitte kontaktieren Sie den Support unter "Einstellungen > Rückmeldung".',
  entityAlarm: 'alarm',
  entityGroup: 'gruppe',
  entityContact: 'kontakt',
  admin: 'Verwaltung',
  specifyAlarmTitleToSpeak:
    'Geben Sie den Titel des Alarms an, der gesprochen werden soll',
  wakeup: 'Aufwachen',
  ttsPremiumScreenDescription:
    'Die Ankündigung des Alarms mit einem Titel ist eine Premium-Funktion.\n\nWenn diese Funktion aktiviert ist, wird der Titel des Alarms angesagt, wenn der Alarm klingelt.\n\nGeben Sie zur Vorschau einen Titel für den Alarm ein und tippen Sie auf Wiedergabe.',
  unknownAlarmCategory: 'Unbekannte alarm kategorie',
  cantAnnounceAlarmTitle: 'Der Titel des Alarms wird nicht bekannt gegeben',
  ringtoneDurationIsSmall:
    'Die Dauer des Klingeltons sollte mindestens 5 Sekunden betragen, damit der Titel des Alarms angesagt werden kann.\n\nMöchten Sie mit diesen Einstellungen fortfahren?',
  noActionItems: 'Keine Aktionspunkte.',
  personNotInContacts:
    'Diese Person befindet sich nicht in Ihren Galarm-Kontakten.',
  light: 'Leicht',
  dark: 'Dunkel',
  systemDefault: 'Geräteeinstellung verwenden',
  chooseTheme: 'Thema wählen',
  dontSeeAllYourContacts: 'Sie sehen nicht alle Ihre Kontakte?',
  lastContactsSyncedAt: 'Zuletzt synchronisiert am {{dateString}}',
  refresh: 'Aktualisieren',
  forever: 'Für immer',
  autoSnoozeConfiguration: 'Schlummern konfigurieren',
  autoSnoozeConfigurationSummary:
    '{{autoSnoozeDurationLabel}}, {{autoSnoozeCountLabel}}',
  uncategorizedCategoryName: 'Nicht kategorisiert',
  uncategorizedCategoryScreenTitle: 'Uncategorized',
  setAlarmCategory: 'Einstellen der Alarm-Kategorie',
  changeAlarmCategory: 'Alarm-Kategorie ändern',
  viewAlarmHistory: 'Ansicht Alarmverlauf',
  computingAlarmHistory: 'Berechnungszusammenfassung für den Alarmverlauf...',
  alarmByCategories: '{{name}} Alarme',
  alarmCategories: 'kategorien',
  editAlarmCategory: 'Kategorie bearbeiten',
  deleteAlarmCategory: 'Kategorie löschen',
  newAlarmCategoryTitle: 'Neue kategorie',
  newAlarmCategoryNamePlaceholder: 'Geben Sie einen Namen an',
  editAlarmCategoryTitle: 'Kategorie bearbeiten',
  alarmCategorySummary: {
    zero: '{{alarmsSummary}}',
    one: '{{alarmsSummary}}, {{count}} verpasst',
    other: '{{alarmsSummary}}, {{count}} verpasst'
  },
  numAlarms: {
    zero: 'Keine Alarme',
    one: '{{count}} alarm',
    other: '{{count}} Alarme'
  },
  numIncidents: {
    zero: 'Keine Vorfälle',
    one: '{{count}} Vorfall',
    other: '{{count}} Vorfälle'
  },
  openedIncidents: 'Eröffnete Vorfälle',
  closedIncidents: 'Abgeschlossene Vorfälle',
  incidentChart: 'Tabelle der Vorfälle (letzte 30 Tage)',
  incidentCalendar: 'Kalender der Vorfälle',
  incidentDistribution: 'Verteilung der Vorfälle (letzte 30 Tage)',
  noIncidentsOpenedClosedInLast30Days:
    'Keine in den letzten 30 Tagen eröffneten oder abgeschlossenen Vorfälle',
  noIncidentsOpenedInLast30Days:
    'Keine Vorfälle in den letzten 30 Tagen eröffnet',
  color: 'Farbe',
  createNewAlarmCategory:
    'Erstellen Sie eine neue Kategorie, indem Sie auf die Schaltfläche + unten rechts tippen',
  setValidName: 'Einstellen eines gültigen Namens',
  specifyTimezoneForAlarmMessage:
    'Sie können eine Zeitzone angeben, wenn Sie Alarme erstellen. Die Alarme klingeln dann entsprechend der angegebenen Zeitzone. \n\nWenn Sie "Zeitzone des Geräts" wählen, klingeln die Alarme in der Zeitzone Ihres Geräts.',
  alarmCategoryFeatureDescription:
    'Erstellen Sie Ihre eigenen Kategorien für Alarme. Mit Kategorien können Sie Ihre Alarme einfach organisieren.',
  markAlarmDoneInAdvance: 'Alarm frühzeitig als "Erledigt" markieren?',
  skipAlarmInAdvance: '"Alarm vorzeitig überspringen"?',
  markAlarmDoneInAdvanceMessageRepeatingAlarm:
    'Wenn Sie jetzt "Erledigt" markieren, klingelt der Alarm nicht unter {{dateTimeString}}.',
  skipAlarmInAdvanceMessageRepeatingAlarm:
    'Der Alarm klingelt nicht auf {{dateTimeString}}, wenn Sie ihn jetzt überspringen.',
  markAlarmDoneInAdvanceMessageOneTimeAlarm:
    'Wenn Sie jetzt "Erledigt" markieren, klingelt der Alarm nicht unter {{dateTimeString}}.',
  skipAlarmInAdvanceMessageOneTimeAlarm:
    'Der Alarm klingelt nicht auf {{dateTimeString}}, wenn Sie ihn jetzt überspringen.',
  alarmsByCategories: 'Alarme nach Kategorien',
  workAlarmCategory: 'Arbeit',
  homeAlarmCategory: 'Startseite',
  birthdaysAlarmCategory: 'Geburtstage',
  more: 'Mehr',
  alarmCategory: 'Alarm Kategorie',
  editAlarmCategoryColor: 'Farbe bearbeiten',
  editAlarmCategoryColorTitle: 'Farbe bearbeiten für {{name}}',
  allUserCategoriesDeleted:
    'Alle von Ihnen erstellten Kategorien für Alarme wurden entfernt, da das Premium-Abonnement abgebrochen wurde. Alarme in diesen Kategorien werden unter "Nicht kategorisiert" angezeigt.',
  calendar: 'Kalender',
  eventExportedFromGalarm:
    '"Exportiert von Galarm auf {{dateString}} unter {{timeString}}."',
  unableToExportEventToCalendar:
    'Es ist nicht möglich, ein Ereignis in den Kalender zu exportieren. Bitte vergewissern Sie sich, dass Sie die Berechtigung zum Zugriff auf den Kalender auf dem Gerät erteilt haben. Senden Sie uns eine Rückmeldung über "Einstellungen > Feedback", wenn das Problem weiterhin besteht.',
  scanQrCode:
    'Um über einen Browser auf Galarm zuzugreifen, gehen Sie zu web.galarm.app und scannen Sie den QR-Code.',
  logOut: 'Abmelden',
  useHere: 'Hier verwenden',
  appRunningElsewhere:
    'Sie wurden von dieser Sitzung abgemeldet. Möchten Sie diese Sitzung fortsetzen?',
  dateTime: 'Datum und Zeit',
  selectAtLeastOneDayForRepetition:
    'Wählen Sie mindestens einen Tag, an dem der Alarm wiederholt werden soll.',
  chooseNumberOfDaysForAlarmRepetition:
    'Geben Sie die Anzahl der Tage ein, nach denen der Alarm wiederholt werden soll.',
  chooseNumberOfWeeksForAlarmRepetition:
    'Geben Sie die Anzahl der Wochen ein, nach denen der Alarm wiederholt werden soll.',
  chooseNumberOfMonthsForAlarmRepetition:
    'Geben Sie die Anzahl der Monate ein, nach denen der Alarm wiederholt werden soll.',
  chooseNumberOfYearsForAlarmRepetition:
    'Geben Sie die Anzahl der Jahre ein, nach denen der Alarm wiederholt werden soll.',
  previouslySelectedContactsAreUnselected:
    'Der/die zuvor ausgewählte(n) Kontakt(e) wird/werden abgewählt.',
  previouslySelectedGroupIsUnselected:
    'Die zuvor ausgewählte Gruppe ist nicht ausgewählt.',
  galarmWebInstructions:
    '1. Öffnen Sie die Galarm-App auf Ihrem Telefon\n2. Gehen Sie zur Registerkarte "Einstellungen" und tippen Sie auf "Galarm Web".\n3. Vergewissern Sie sich, dass Sie die neueste App verwenden, wenn Sie die Option nicht sehen.\n4. Scannen Sie den QR-Code auf der rechten Seite',
  likeToCreateAlarm: 'Erstellen Sie einen Alarm für...',
  payUtilityBills: 'Rechnungen bezahlen',
  wakeupAlarmName: 'Aufwachen',
  exerciseAlarmName: 'Übung',
  drinkWaterAlarmName: 'Wasser trinken',
  everyNHoursRepetitionStringShortSummary: {
    one: '{{startTimeString}} an {{endTimeString}} Jede Stunde',
    other: '{{startTimeString}} auf {{endTimeString}} Alle {{count}} Stunden'
  },
  firstTimeManagementQuote:
    '"Die schlechte Nachricht ist, dass die Zeit vergeht. Die gute Nachricht ist, dass du der Pilot bist."',
  firstTimeManagementQuoteAuthor: 'Michael Altshuler',
  secondTimeManagementQuote: '"Jedes Zeitmanagement beginnt mit der Planung."',
  secondTimeManagementQuoteAuthor: 'Tom Greening',
  thirdTimeManagementQuote:
    '"Was du heute kannst besorgen, das verschiebe nicht auf morgen".',
  thirdTimeManagementQuoteAuthor: 'Benjamin Franklin',
  fourthTimeManagementQuote:
    '"Zeit ist das, was wir am meisten wollen, aber das, was wir am schlechtesten nutzen."',
  fourthTimeManagementQuoteAuthor: 'William Penn',
  fifthTimeManagementQuote:
    '"In einem Jahr werden Sie sich vielleicht wünschen, Sie hätten heute angefangen.',
  fifthTimeManagementQuoteAuthor: 'Karen Lamm',
  sixthTimeManagementQuote:
    '"Ihr könnt verzögern, aber die Zeit wird es nicht."',
  sixthTimeManagementQuoteAuthor: 'Benjamin Franklin',
  tapToGetStarted: 'Tippen Sie auf "+", um zu beginnen.',
  allCategories: 'Alle kategorien',
  createAlarmWithContact: 'Erstellen Sie einen Alarm mit {{name}}',
  createAlarmWithGroup: 'Erstellen Sie einen Alarm mit {{name}}',
  editGroup: 'Gruppe bearbeiten',
  discardChanges: 'Die aktuellen Änderungen werden verworfen. Fortfahren?',
  alarmWillRingEveryNDays: {
    zero: 'Der Alarm klingelt jeden Tag.',
    one: 'Der Alarm klingelt an abwechselnden Tagen.',
    other: 'Der Alarm klingelt jeden {{dayWithOrdinal}} Tag.'
  },
  hoursString: { zero: '', one: '1 Stunde', other: '{{count}} Stunden' },
  minutesString: { zero: '', one: '1 Minute', other: '{{count}} Minuten' },
  alarmWillRingEveryMHoursAndNMinutes:
    'Der Alarm klingelt nach jedem {{repeatString}}, beginnend mit {{dayString}}. Danach {{otherDaysString}} und so weiter.',
  alarmWillRingEveryNWeeks: {
    zero: 'Der Alarm klingelt jede Woche.',
    one: 'Der Alarm klingelt alle zwei Wochen.',
    other: 'Der Alarm klingelt jede {{weekWithOrdinal}} Woche.'
  },
  alarmWillRingEveryNMonths: {
    zero: 'Der Alarm klingelt jeden Monat.',
    one: 'Der Alarm klingelt alle zwei Monate.',
    other: 'Der Alarm klingelt alle {{monthWithOrdinal}} Monate.'
  },
  alarmWillRingEveryNYears: {
    zero: 'Der Alarm wird jedes Jahr klingeln.',
    one: 'Der Alarm wird halbjährlich klingeln.',
    other: 'Der Alarm klingelt alle {{yearWithOrdinal}} Jahre.'
  },
  ringParticipantAlarmsByDefaultTooltipMessage:
    'Wenn diese Einstellung aktiviert ist, klingeln Alarme von anderen, ohne dass Sie sie akzeptieren. Sie sollten den Alarm dennoch akzeptieren, um dem Ersteller mitzuteilen, dass Sie an dem Alarm teilnehmen möchten.\n\nWenn diese Einstellung deaktiviert ist, klingeln Alarme, die von anderen für Sie hinzugefügt wurden, nicht auf Ihrem Telefon, es sei denn, Sie akzeptieren sie ausdrücklich.',
  specifyTimezoneForAlarmDefaultTooltipMessage:
    'Wenn diese Einstellung aktiviert ist, können Sie eine Zeitzone angeben, wenn Sie Alarme erstellen. Die Alarme klingeln dann gemäß der angegebenen Zeitzone.\n\nWenn diese Einstellung deaktiviert ist, klingeln die Alarme gemäß der Zeitzone Ihres Geräts.',
  ringOnEarphoneOnlyTooltipTitle:
    'Alarmverhalten bei angeschlossenen Kopfhörern',
  ringOnEarphoneOnlyTooltipMessage:
    'Wenn Kopfhörer angeschlossen sind, können Sie Ihre Alarme so konfigurieren, dass sie entweder nur auf den Kopfhörern oder sowohl auf dem Telefonlautsprecher als auch auf den Kopfhörern klingeln.\n\nWenn die Ohrhörer nicht angeschlossen sind, klingeln die Alarme über den Telefonlautsprecher.',
  gestureOptionsTooltipTitle: 'Geste zum Ignorieren eines Alarms',
  gestureOptionsTooltipMessage:
    'Wenn ein Alarm auf dem Sperrbildschirm klingelt, können Sie ihn ignorieren, indem Sie entweder auf eine Taste tippen oder eine Taste verschieben.\n\nMit dieser Einstellung können Sie wählen, wie Sie einen Alarm ignorieren möchten.',
  notificationSettingsTooltipTitle: 'Einstellungen für die Benachrichtigung',
  notificationSettingsTooltipMessage:
    'Aktivieren Sie die Benachrichtigungen, die Sie erhalten möchten, und deaktivieren Sie diejenigen, über die Sie nicht benachrichtigt werden möchten.',
  messagesForAlarm: 'Nachrichten für {{name}}',
  systemNotificationsTooltipMessage:
    'Von der App generierte Benachrichtigungen für Ereignisse wie:\n  - wenn Alarme betroffen sind, weil ein Kontakt die Zeitzone wechselt\n  - wenn ein eingeladener Kontakt sich auf Galarm registriert\n  - wenn Alarme betroffen sind, weil jemand sein Konto gelöscht hat',
  galarmWebNotificationsTooltipMessage:
    'Benachrichtigungen, die Sie von Galarm Web erhalten.',
  alarmsfromOthersNotificationsTooltipMessage:
    'Benachrichtigungen, die gesendet werden, wenn Sie als Teilnehmer zu einem Alarm hinzugefügt werden.',
  alarmChatNotifictionsTooltipMessage:
    'Benachrichtigungen für Chat-Nachrichten in einem Alarm.',
  groupNotificationsTooltipMessage:
    'Benachrichtigungen, die gesendet werden, wenn Sie zu einer Gruppe hinzugefügt oder aus einer Gruppe entfernt werden, oder wenn jemand anderes zu einer Gruppe hinzugefügt oder aus einer Gruppe entfernt wird.',
  alarmAcknowledgementNotificationsTooltipMessage:
    'Benachrichtigungen, die gesendet werden, wenn ein Teilnehmer einen Alarm quittiert, bei dem Sie ebenfalls Teilnehmer sind.',
  sharedAlarmNotificationsTooltipMessage:
    'Benachrichtigungen, die gesendet werden:\n  - wenn jemand einen von Ihnen über einen Link freigegebenen Alarm abonniert\n  - wenn ein Alarm, den Sie abonniert haben, geändert wurde',
  sleepTimer: 'Schlaf-Musik',
  sleepTimerTitle: 'Musik spielen für',
  ongoingSleepTimer: 'Schlafmusik Fortlaufend',
  stopSleepTimer: 'Möchten Sie die aktuelle Musik anhalten?',
  restartSleepTimer:
    'Möchten Sie die aktuelle Musik anhalten und eine neue starten?',
  play: 'Spielen',
  youAreOffline:
    'Sie sind derzeit offline. Gehen Sie online, um Operationen durchzuführen.',
  alarmLinkRelativeToTimezone:
    'Der Wecker jedes Einzelnen sollte um {{timeString}} in seiner Zeitzone klingeln.',
  alarmLinkNotRelativeToTimezone:
    'Der Wecker aller sollte um {{timeString}} klingeln',
  sleepMusicTooltip:
    'Spielen Sie natürliche Klänge, um Ihren Körper und Geist zu entspannen. Die Musik hört nach der angegebenen Dauer auf zu spielen.',
  alarmLinkCopiedToClipboard: 'Alarm-Link in die Zwischenablage kopiert.',
  confirmLogOut: 'Möchten Sie sich abmelden?',
  appNotRunningMessage:
    'Wir haben festgestellt, dass Galarm nicht auf Ihrem Telefon läuft. Tippen Sie auf , um Galarm zu starten, damit Sie keine wichtigen Alarme verpassen.',
  tapToEnterNotesForAlarm: 'Tippen Sie auf , um Notizen einzugeben',
  accountAlreadyExists: 'Bestehendes Konto gefunden',
  accountsMerged:
    'Wir haben ein bestehendes Konto gefunden, das mit dieser Handynummer verbunden ist. Wir haben die beiden Konten zusammengeführt.',
  upgradeToPremiumToAssignUserDefinedCategory:
    'Aktualisieren Sie auf Premium, um eine benutzerdefinierte Kategorie auszuwählen.',
  userDefinedCategoryNotSetForAlarm:
    'Aktualisieren Sie auf Premium, um die benutzerdefinierte Kategorie automatisch zuzuweisen. Für den Alarm ist keine Kategorie eingestellt.',
  alarmDetails: 'Details zum Alarm',
  profileDetails: 'Profil',
  activeWebSession:
    'Sie haben bereits eine aktive Web-Sitzung. Sie können sich von dieser abmelden oder eine neue Sitzung starten.\n\nWenn Sie eine neue Sitzung beginnen, werden Sie automatisch von der derzeit aktiven Web-Sitzung abgemeldet.',
  startNewSession: 'Eine neue Sitzung beginnen',
  confirmWebSessionLogout:
    'Dadurch werden Sie von Ihrer aktuellen Web-Sitzung abgemeldet. Sie können eine neue Sitzung starten, indem Sie den Code erneut scannen.',
  defaultInstantAlarmTitleForGroup: '{{groupName}} Warnung von {{creatorName}}',
  defaultInstantAlarmTitleForContact: 'Warnung von {{creatorName}}',
  atleastOneMemberShouldBeActive:
    'Mindestens ein Teilnehmer sollte aktiv sein.',
  activeParticipants: 'Aktive Teilnehmer',
  acceptAlarm: 'Akzeptieren des Alarms',
  rejectAlarm: 'Ablehnen des Alarms',
  nConfirmed: '{{count}} bestätigt',
  nDeclined: '{{count}} abgelehnt',
  nPending: '{{count}} anhängig',
  everyoneConfirmed: 'Alle haben bestätigt',
  everyoneDeclined: 'Alle sind abgelehnt worden',
  appTagline: 'Nie etwas verpassen',
  usePreviousBackup: 'Ich bin ein registrierter Benutzer >',
  viewAlarmsByCategories: 'Ansicht Alarme nach Kategorien',
  viewAllAlarms: 'Ansicht Alle Alarme',
  switchToSummaryView: 'Zur Ansicht "Zusammenfassung" wechseln',
  switchToListView: 'Umschalten auf Listenansicht',
  hideHourlyAlarms: 'Stündliche Alarme ausblenden',
  calendarView: 'Galarm Kalender',
  viewRecentlyDeletedAlarms: 'Ansicht kürzlich gelöschter Alarme',
  deletedOnDate: 'Gelöscht am {{alarmDate}}',
  recentlyDeletedAlarmFooterText:
    'Dieser Alarm wurde gelöscht am {{alarmDate}}',
  noRecentlyDeletedAlarms: 'Keine kürzlich gelöschten Alarme',
  restoreAlarmsFromMobileApp:
    'Diese Alarme werden 30 Tage nach ihrer Löschung automatisch gelöscht. Wenn Sie einen dieser Alarme wiederherstellen möchten, gehen Sie bitte zum Bildschirm "Kürzlich gelöschte Alarme" Ihrer mobilen App und tippen Sie auf das Symbol für die Wiederherstellung.',
  deleteInactiveAlarms: 'Inaktive Alarme löschen',
  defaultConfigurationUpdated: 'Die Standardkonfiguration wurde aktualisiert.',
  notAlerted: '(ausgeschlossen)',
  alarmKeepsRingingHelp: 'Hilfe! Mein Alarm klingelt ständig',
  alarmKeepsRingingHelpMessage:
    'Wir haben Ihre Alarme aufgefrischt und den Problembericht verschickt.',
  alarmKeepsRingingHelpAlert:
    'Wir werden Ihre Alarme aktualisieren und Sie sollten das Problem nicht mehr bemerken. Wir werden auch einen Problembericht an unser Support-Team zur weiteren Untersuchung senden.\n\n  Wenn das Problem weiterhin besteht, kontaktieren Sie uns bitte über "Einstellungen > Feedback".',
  sendingFeedback: 'Rückmeldung senden',
  sendingProblemReport: 'Problembericht abschicken',
  fixingAlarms: 'Aktualisieren Ihrer Alarme',
  existingAccountFound: 'Sicherung wiederhergestellt',
  existingAccountDataRestored: {
    zero: 'Glückwunsch, alle Daten wurden von der Sicherung wiederhergestellt.',
    one: 'Glückwunsch, alle Daten wurden von der Sicherung wiederhergestellt.',
    other: 'Glückwunsch, alle Daten wurden von der Sicherung wiederhergestellt.'
  },
  noExistingAccountFound: 'Keine Sicherung gefunden',
  createdNewAccount:
    'Diese Rufnummer wurde nicht bei Galarm registriert. Wir haben ein neues Konto mit dieser Rufnummer erstellt.',
  iosCloseToAllowedNumNotificationsScheduled: {
    zero: 'iOS erlaubt einer App, bis zu 64 Benachrichtigungen zu planen. Sie haben alle diese Benachrichtigungen verbraucht. Es ist möglich, dass Sie einige Alarme verpassen.',
    one: 'iOS erlaubt einer App, bis zu 64 Benachrichtigungen zu planen. Sie haben {{numNotifications}} dieser Benachrichtigungen verbraucht.'
  },
  acknowledgeAlarmToScheduleNextOccurrence:
    'Dies ist die letzte Benachrichtigung für diesen Alarm. Drücken Sie lange auf diese Benachrichtigung und führen Sie eine Aktion durch, um das nächste Auftreten dieses Alarms zu planen.',
  lastNotificationForOneTimeAlarm:
    'Dies ist die letzte Benachrichtigung für diesen Alarm.',
  criticalAlertsMessageTitle:
    'Alarme klingeln lassen, wenn Ihr Telefon stumm geschaltet ist',
  criticalAlertsMessageDescription:
    '\nSie können Galarm so konfigurieren, dass es iOS Critical Alerts verwendet, um Alarme zu klingeln, wenn Ihr Telefon stummgeschaltet ist. Möchten Sie dies für alle Alarme aktivieren?\n\nSie können es auch selektiv für einige Alarme aktivieren, indem Sie deren "Ton und Vibration > Kritische Alarme" Einstellung bearbeiten.',
  allowCriticalAlerts: 'Kritische Alarme zulassen',
  criticalAlertsPermissionDenied:
    'Galarm benötigt die Erlaubnis Ihres Telefons, um kritische Alarme anzuzeigen. Bitte aktivieren Sie diese Erlaubnis in den Einstellungen Ihres Telefons.',
  enableCriticalAlertsForAllAlarms: 'Ja, für alle Alarme aktivieren',
  selectivelyEnableCriticalAlerts: 'Nein, ich werde selektiv aktivieren',
  firstName: 'Vorname',
  lastName: 'Nachname',
  email: 'E-Mail',
  password: 'Passwort',
  confirmPassword: 'Bestätigen Sie das Passwort',
  signup: 'Anmeldung',
  login: 'Anmeldung',
  alreadySignedUp: 'Sie haben sich bereits angemeldet?',
  notAUser: 'Sie sind kein Nutzer?',
  subscribe: 'Abonnieren',
  choosePlan: 'Plan konfigurieren',
  billingCycle: 'Abrechnungszyklus',
  needHelpToGetStarted: 'Benötigen Sie Hilfe bei den ersten Schritten?',
  browser: 'Browser: {{name}}',
  deviceOs: 'Gerät: {{name}}',
  lastActive: 'Zuletzt aktiv: {{timestamp}}',
  signUp: 'Registrieren Sie sich',
  useGalarmOnWeb: 'Galarm im Web verwenden',
  welcomeUser: 'Willkommen {{name}}',
  changePassword: 'Passwort ändern',
  saveAlarm: 'Alarm speichern',
  galarmUserTip: 'Wussten Sie das?',
  sendAlarm: 'Alarm senden',
  saveGroup: 'Gruppe speichern',
  webAppBestViewedOnDesktop:
    'Die URL (web.galarm.app) dient zum Starten einer Galarm-Oberfläche in einem Webbrowser auf Ihrem Laptop oder Desktop.\n\nUm Galarm auf diesem mobilen Gerät zu verwenden, installieren Sie bitte die Android-App von Google Play oder die iOS-App aus dem App Store über einen der unten stehenden Links:',
  scanQrCodeMessage: 'QR-Code scannen',
  reloadQrCode: 'Zum Nachladen QR-Code anklicken',
  invalidQrCodeScanned: 'Ungültiger QR-Code gescannt.',
  unableToScanCode: 'Code kann nicht gescannt werden',
  unableToScanCodeMessage:
    'Beim Scannen des Codes ist ein Problem aufgetreten. Bitte versuchen Sie es erneut. Wenn Sie weiterhin Probleme haben, senden Sie uns bitte eine Rückmeldung über die mobile App unter "Einstellungen > Feedback".',
  problemWithEnterpriseAccountSetup:
    'Bei der Einstellung Ihres Galarm Pro-Kontos ist ein Problem aufgetreten. Bitte kontaktieren Sie den technischen Support.',
  problemWithCreatingCheckoutSession:
    'Es ist ein Problem bei der Erstellung einer Checkout-Sitzung aufgetreten. Bitte kontaktieren Sie den technischen Support.',
  problemWithCreatingCustomerPortalSession:
    'Es gab ein Problem beim Erstellen einer Kundenportalsitzung. Bitte kontaktieren Sie den technischen Support.',
  problemWithCreatingApiKey:
    'Beim Erstellen eines API-Schlüssels ist ein Problem aufgetreten. Bitte kontaktieren Sie den technischen Support.',
  creatingAnAccount: 'Ein Konto erstellen',
  currentPlan: 'Derzeitiger Plan: {{name}} ({{quantity}} seats)',
  myAccount: 'Konto-Informationen',
  new: 'Neu',
  deleteAllAlarmHistoryAlertTitle: 'Alarmverlauf löschen',
  deleteAllAlarmHistoryAlertMessage:
    'Tippen Sie auf "Weiter", um den Verlauf für diesen Alarm zu löschen. Diese Aktion kann nicht rückgängig gemacht werden.',
  ageOutInactiveAlarmsMessage:
    'Sie haben {{count}} Alarme, die seit mehr als 60 Tagen inaktiv sind. Eine große Anzahl inaktiver Alarme kann die Leistung der App beeinträchtigen.\n\nMöchten Sie diese Alarme löschen?',
  duplicateAlarm: 'Duplizierter Alarm',
  graduallyIncreaseVolume: 'Lautstärke schrittweise erhöhen',
  graduallyIncreaseVolumeTooltipMessage:
    'Wenn diese Einstellung aktiviert ist, erhöht sich die Lautstärke des Alarms allmählich auf die eingestellte Lautstärke des Klingeltons. So können Sie den Alarm stoppen, bevor er zu laut wird.\n\nWenn diese Einstellung deaktiviert ist, klingeln Alarme während der gesamten Dauer des Klingeltons mit der eingestellten Lautstärke.',
  teams: 'Mannschaften',
  alerts: 'Warnungen',
  listeners: 'Hörer',
  noMembersConfiguredInEnterpriseAccount:
    'Sie haben noch keine Mitglieder hinzugefügt. Mitglieder sind Benutzer, die der Eskalationsrichtlinie eines Hörers hinzugefügt werden können und bei Vorfällen, die beim Hörer eingehen, benachrichtigt werden. Diese Personen sollten bereits bei Galarm registriert sein.',
  noTeamsConfiguredInEnterpriseAccount:
    'Sie haben noch keine Teams erstellt. Teams sind Gruppen von Mitgliedern, die der Eskalationsrichtlinie eines Listeners hinzugefügt werden können und bei Vorfällen, die beim Listener eingehen, benachrichtigt werden.',
  noAlertsConfiguredInEnterpriseAccount:
    'Sie haben noch keine Listener erstellt. Sie können Listener so konfigurieren, dass sie auf von Überwachungstools gemeldete Vorfälle warten. Sobald Sie einen Listener erstellt haben, finden Sie eine Anleitung, wie Sie den Listener in verschiedene Überwachungstools integrieren können.\n\nSie können für jeden Listener mehrere Eskalationsstufen konfigurieren.',
  noShiftsConfiguredInEnterpriseAccount:
    'Sie haben noch keine Schichtkonfigurationen erstellt.',
  noRotationsConfiguredInEnterpriseAccount:
    'Sie haben noch keine Rotationen erstellt.',
  rotations: 'Umdrehungen',
  addRotation: 'Rotation hinzufügen',
  viewRotation: 'Ansicht Rotation',
  enterRotationName: 'Name der Rotation eingeben',
  rotationType: 'Drehung Typ',
  numberOfHoursInRotation: 'Anzahl der Stunden in der Rotation',
  numberOfDaysInRotation: 'Anzahl der Tage im Turnus',
  numberOfWeeksInRotation: 'Anzahl der Wochen im Turnus',
  specify: 'Spezifizieren Sie...',
  noIntegrationsConfiguredInEnterpriseAccount:
    'Sie haben noch keine Integrationskonfigurationen konfiguriert.',
  loadingUser: 'Laden von Benutzerinformationen',
  addMember: 'Mitglied hinzufügen',
  add: 'hinzufügen',
  noUserFoundWithMobileNumber:
    'Kein registrierter Galarm-Benutzer mit dieser Rufnummer gefunden. Bitte stellen Sie sicher, dass Sie die Telefonnummer mit der Landesvorwahl ohne Sonderzeichen wie Klammern, Bindestriche, Leerzeichen usw. eingeben.',
  memberWithPhoneNumberAlreadyAdded:
    'Das Mitglied mit dieser Telefonnummer wurde bereits hinzugefügt.',
  memberWithEmailAlreadyAdded:
    'Das Mitglied mit dieser E-Mail wurde bereits hinzugefügt.',
  assignKeyToMember: 'Lizenzschlüssel zuweisen',
  revokeKeyFromMember: 'Lizenzschlüssel widerrufen',
  cantAssignKeyToMember: 'Schlüssel kann dem Mitglied nicht zugewiesen werden',
  noEnterpriseLicenseKeyAvailable:
    'Es sind keine Lizenzschlüssel verfügbar. Bitte aktualisieren Sie Ihren Plan.',
  addTeam: 'Team hinzufügen',
  enterTeamName: 'Eingabe eines Teamnamens',
  addAlert: 'Hörer hinzufügen',
  low: 'Niedrig',
  medium: 'Mittel',
  high: 'Hoch',
  severity: 'Schweregrad',
  enterAlertName: 'Name für den Hörer eingeben',
  organization: 'Organisation',
  startTime: 'Start Zeit',
  endTime: 'Ende Zeit',
  shiftName: 'Schicht {{index}}',
  addShift: 'Verschiebung hinzufügen',
  assignees: 'Beauftragte',
  shiftStartEndTime: '{{startTime}} - {{endTime}}',
  assignee: 'Rechtsnachfolger',
  specifyAssigneeForShift: 'Zuweiser für die Schicht angeben',
  selectAtLeastOneMember: 'Mindestens ein Mitglied auswählen',
  editMember: 'Bearbeiten Mitglied',
  editTeam: 'Team bearbeiten',
  cantRemoveMemberAssignedToTeamsOrAlerts:
    'Das Mitglied ist Teil eines Teams oder einem Hörer zugewiesen. Bitte entfernen Sie das Mitglied aus diesen Teams/Hörern, bevor Sie es löschen.\nTeams: {{teams}}\nHörer: {{alerts}}',
  cantRevokeKeyFromMemberAssignedToTeamsOrAlerts:
    'Das Mitglied ist Teil eines Teams oder einem Hörer zugewiesen. Bitte entfernen Sie das Mitglied aus diesen Teams/Hörern, bevor Sie den Schlüssel entziehen.\nTeams: {{teams}}\nHörer: {{alerts}}',
  cantDeleteTeamAssignedToAlerts:
    'Das Team ist Teil eines Hörers. Bitte entfernen Sie das Team aus dem Hörer, bevor Sie es löschen.\nHörer: {{alerts}}',
  cantDeleteRotationAssignedToAlerts:
    'Die Rotation ist Teil eines Hörers. Bitte entfernen Sie das Team aus dem Hörer, bevor Sie es löschen.\nHörer: {{alerts}}',
  editAlert: 'Listener bearbeiten',
  timezoneDisplayString: '(GMT{{offset}}) {{timezone}}',
  getCode: 'Code erhalten',
  apiKey: 'API-Schlüssel',
  viewApiKey: 'Ansicht API-Schlüssel',
  apiKeyWarningMessage:
    'Bewahren Sie Ihren API-Schlüssel zu jeder Zeit sicher auf. Geben Sie ihn nicht an Dritte weiter. Wenn Sie glauben, dass Ihr API-Schlüssel kompromittiert wurde, tippen Sie oben auf die Schaltfläche "Ändern", um ihn zu ändern. Nachdem Sie den Schlüssel geändert haben, müssen Sie den neuen Schlüssel an allen Stellen angeben, an denen Sie Vorfälle über die REST-API auslösen',
  integrationConfigs: 'Integration-Konfigurationen',
  addIntegrationConfig: 'Integrationskonfiguration hinzufügen',
  editIntegrationConfig: 'Integrationskonfiguration bearbeiten',
  integrationSource: 'Integration Quelle',
  apiToken: 'API-Token',
  integrations: 'Integrationen',
  selectPlaceholder: 'Wählen Sie...',
  zendeskSubdomain: 'Zendesk Subdomain',
  creatorTimeForBuddyAlarm: '{{timeString}} ({{remindMeAfter}})',
  nextFiveAlarms: 'Die nächsten 5 Alarme',
  categories: 'kategorien',
  viewingAlarmsDueToday: 'Ansicht der heute fälligen Alarme',
  viewingAlarmsDueTomorrow: 'Ansicht der morgen fälligen Alarme',
  expiredAlarms: 'Abgelaufene Alarme',
  viewingExpiredAlarms: 'Ansicht der abgelaufenen Alarme',
  noAlarmsDueToday: 'Heute keine Alarme fällig',
  noAlarmsDueTomorrow: 'Keine Alarme für morgen',
  noExpiredAlarms: 'Keine abgelaufenen Alarme',
  viewingAllAlarms: 'Ansicht aller Alarme',
  viewingRecentlyDeletedAlarms: 'Ansicht der kürzlich gelöschten Alarme',
  summaryView: 'Versuchen Sie die Ansicht "Zusammenfassung"?',
  summaryViewDescription:
    'Siehe Alarme nach Daten und Kategorien geordnet.\n\nMit der Schaltfläche ⋮ oben rechts können Sie wieder zur Listenansicht wechseln.',
  disableBatteryOptimizationMessageOnStartup:
    'Galarm muss im Hintergrund laufen, damit die Alarme zuverlässig klingeln können. Erlauben Sie dies bitte auf dem nächsten Bildschirm.',
  disableBatteryOptimizationMessage:
    'Galarm muss im Hintergrund laufen, damit die Alarme zuverlässig klingeln.',
  allowNotificationsToDisplayAlarms:
    'Galarm benötigt die Erlaubnis, Benachrichtigungen anzuzeigen, wenn Alarme klingeln.',
  scheduleExactAlarmsMessage:
    'Galarm benötigt die Erlaubnis, genaue Alarme zu planen.',
  alarmCategoryWithSameNameExists:
    'Eine Kategorie mit diesem Namen existiert bereits. Bitte geben Sie einen anderen Namen an.',
  unlimitedAlarmsPremiumFeature:
    'Unbegrenzte Gruppen-, Freundes- und Sofortalarme',
  alarmRepetitionsPremiumFeature: 'Erweiterte Wiederholungsoptionen',
  ringtonesPremiumFeature: 'Mehr Klingeltöne',
  galarmWebPremiumFeature: 'Galarm im Web verwenden',
  taskListPremiumFeature: 'Mehrere Aufgabenlisten erstellen',
  preReminderPremiumFeature: 'Vorab-Erinnerung für Alarme',
  alarmCategoriesPremiumFeature: 'Benutzerdefinierte Kategorien für Alarme',
  shareableAlarmLinksPremiumFeature: 'Freigegebene Alarm-Links',
  announceAlarmNamePremiumFeature: 'Alarm-Titel ankündigen',
  manageSubscription: 'Abonnement verwalten',
  purchaseRestored: 'Kauf wiederhergestellt.',
  unableToRetrieveSubscriptionPlansAndroid:
    'Wir können keine Verbindung zu Google Play herstellen, um die Aktualisierung durchzuführen. Bitte vergewissern Sie sich, dass Sie mit dem Internet verbunden sind, und versuchen Sie es dann erneut. \n\nBitte melden Sie das Problem an unser Support-Team, wenn es weiterhin besteht.',
  unableToRetrieveSubscriptionPlansIos:
    'Wir können keine Verbindung zum App Store herstellen, um die Aktualisierung durchzuführen. Bitte vergewissern Sie sich, dass Sie mit dem Internet verbunden sind, und versuchen Sie es dann erneut. \n\nBitte melden Sie das Problem an unser Support-Team, wenn es weiterhin besteht.',
  drawOverOtherApps: 'Anzeige über anderen Anwendungen',
  drawOverOtherAppsHelperText: 'Erlauben Sie Galarm, Alarme oben anzuzeigen',
  nTimes: { one: 'Einmalig', other: '{{count}} mal' },
  learnHowToUseFocus: 'Fokus lernen',
  enhancedNotificationsDeprecated: 'Verbesserte Benachrichtigungen Veraltet',
  enhancedNotificationsDeprecatedMessage:
    'Wir haben festgestellt, dass die erweiterten Benachrichtigungen nicht zuverlässig sind und den Akku übermäßig belasten. Bitte verwenden Sie iOS Focus, um Galarm zu erlauben, Alarme zu klingeln, wenn Ihr Telefon auf "Nicht stören" eingestellt ist.',
  usingIosFocus: 'iOS Focus verwenden',
  iosFocusModePara1:
    'Galarm klingelt nicht, wenn der Ruftonschalter des Telefons ausgeschaltet ist oder wenn die Lautstärke des Ruftons auf 0 steht.\n\nApple empfiehlt die Verwendung von "Focus", wenn Sie Ihr Telefon stumm schalten möchten, aber dennoch die Benachrichtigung durch bestimmte Anwendungen zulassen möchten. Befolgen Sie die folgenden Anweisungen, um Focus auf Ihrem Telefon einzurichten.',
  iosFocusModePara2:
    'Eine ausführliche Anleitung mit Screenshots finden Sie unter https://galarm.zendesk.com/hc/en-us/articles/10906890793236-Allow-alarms-to-ring-on-silent-on-iOS',
  settingFocus: 'Einstellen des Fokus:',
  settingFocusFootNotes:
    'Bitte beachten Sie, dass Focus unter iOS 15 und höher verfügbar ist.\n\nBei Fragen oder Rückmeldungen kontaktieren Sie uns bitte über "Einstellungen > Feedback".',
  enablingFocus: 'Enabling Focus:',
  settingFocusInstruction1: 'Gehen Sie zu "Telefoneinstellungen > Fokus".',
  settingFocusInstruction2: 'Tippen Sie auf das Profil "Bitte nicht stören".',
  settingFocusInstruction3: 'Tippen Sie auf "Apps" und fügen Sie Galarm hinzu.',
  settingFocusInstruction4:
    'Verfahren Sie mit den anderen von Ihnen eingestellten Focus-Profilen genauso.',
  enablingFocusInstruction1:
    'Wischen Sie von der oberen rechten Ecke nach unten, um die Ansicht des Kontrollzentrums anzuzeigen.',
  enablingFocusInstruction2: 'Tippen Sie auf Fokus.',
  enablingFocusInstruction3:
    'Tippen Sie auf "Nicht stören" oder ein anderes Fokusprofil, das Sie aktivieren möchten.',
  enablingFocusInstruction4:
    'Der derzeit aktivierte Fokus scheint ausgewählt zu sein. Sie können erneut darauf tippen, um ihn zu deaktivieren.',
  startDateForRotation: 'Startdatum für die Rotation',
  startTimeForRotation: 'Startzeit für die Rotation',
  member: 'Mitglied',
  multipleShiftsInADay: 'Mehrere Schichten an einem Tag',
  escalationPolicy: 'Eskalationspolitik',
  atTimeOfEvent: 'Zur Zeit des Ereignisses',
  afterNMinutes: 'Nach {{count}} Minuten',
  afterNHours: { one: 'Nach 1 Stunde', other: 'Nach {{count}} Stunden' },
  afterDuration: 'Nach {{durationString}}',
  addTrigger: 'Auslöser hinzufügen',
  viewCode: 'Ansicht Code',
  apiCodes: 'API-Codes',
  viewIncidents: 'Ansicht Vorfälle',
  incidentsForAlert: 'Vorfälle für Zuhörer {{alertName}}',
  timestamp: 'Zeitstempel',
  acknowledged: 'Anerkannt',
  noIncidentsForAlert: 'Noch keine Vorfälle für diesen Hörer',
  apiCodeCopiedToClipboard: 'Der API-Code wurde in die Zwischenablage kopiert.',
  triggerName: 'Auslöser {{index}}',
  delay: 'Verzögerung',
  chooseAssigneePlaceholder: 'Empfänger auswählen...',
  delayForTrigger: 'Verzögerung für Trigger',
  assigneeWillBeAlertedAfterDelay:
    'Der Abtretungsempfänger wird nach {{durationString}} benachrichtigt.',
  escalationForIncidentsForAlert:
    'Eskalation für Vorfälle unter {{incidentDate}} für Zuhörer {{alertName}}',
  alertIncidentAcknowledged:
    'Anerkannt von {{memberName}} unter {{dateString}}',
  alertIncidentRecovered: 'Wiedererlangt am {{dateString}}',
  alertIncidents: 'Vorfälle',
  viewingAlertIncidents: 'Ansicht von Vorfällen',
  createOrganization: 'Organisation erstellen',
  youAreNotPartOfAnyOrganization:
    'Sie sind nicht Teil einer Organisation. Erstellen Sie eine Organisation, um loszulegen.',
  tellUsAboutYourOrganization: 'Erzählen Sie uns von Ihrer Organisation',
  noActiveSubscription: 'Sie haben kein aktives Abonnement.',
  chooseOrganization: 'Wählen Sie eine Organisation aus, um fortzufahren.',
  noTriggersForIncident:
    'Es wurden keine Auslöser für diesen Vorfall gefunden.',
  role: 'Rolle',
  viewer: 'Betrachter',
  newOrganization: 'Neue Organisation',
  noOrganizations: 'Sie sind nicht Teil einer Organisation.',
  maxOrganizationsReached:
    'Ein Benutzer kann höchstens 10 Organisationen angehören.',
  yourOrganizations: 'Organisationen',
  urlNotFound: 'Die URL stimmt mit keiner unterstützten Route überein.',
  triggerAlert: 'Auslösender Vorfall',
  recaptchaFailed: 'reCAPTCHA-Überprüfung fehlgeschlagen. {{error}}',
  loadingAccountInformation: 'Laden von Kontoinformationen',
  loadingAuthInfo: 'Laden von Authentifizierungsinformationen',
  allAlerts: 'Alle Hörer',
  recentlyTriggeredAlerts: 'Kürzlich ausgelöste Hörer',
  recentlyClosedIncidents: 'Kürzlich abgeschlossene Vorfälle',
  noRecentlyTriggeredAlerts: 'In letzter Zeit wurden keine Hörer ausgelöst.',
  noRecentlyClosedIncidents:
    'In letzter Zeit wurden keine Vorfälle abgeschlossen.',
  closedAt: 'Geschlossen am {{dateTimeString}}',
  dashboard: 'Dashboard',
  loggedIntoOrganizationAccount:
    '(Organisation: {{organizationName}}, Rolle: {{role}})',
  viewIncidentEscalation: 'Ansicht Vorfall-Eskalation',
  closeIncident: 'Vorfall schließen',
  closingIncident: 'Abschließendes Ereignis',
  unableToRefreshEnterpriseAccount: 'Sie können Ihr Konto nicht aktualisieren.',
  viewAuthToken: 'Ansicht Auth Token',
  problemWithCreatingAuthToken:
    'Es gab ein Problem bei der Erstellung eines Authentifizierungstokens. Bitte kontaktieren Sie den technischen Support.',
  authToken: 'Auth Token',
  authTokenWarningMessage:
    'Bewahren Sie dieses Authentifizierungs-Token zu jeder Zeit sicher auf. Geben Sie es nicht an Dritte weiter. Wenn Sie glauben, dass Ihr API-Schlüssel kompromittiert wurde, tippen Sie oben auf die Schaltfläche "Ändern", um ihn zu ändern. Nachdem Sie das Token geändert haben, müssen Sie das neue Token an allen Stellen angeben, an denen Sie Vorfälle über die REST-API auslösen',
  authTokenCopiedToClipboard:
    'Das Auth-Token wurde in die Zwischenablage kopiert.',
  resendVerificationEmail: 'Verifizierungs-E-Mail erneut senden',
  loggedInUsingEmail: 'Eingeloggt mit E-Mail {{email}}.',
  checkingIfMemberIsAppUser: 'Prüfen, ob das Mitglied ein App-Benutzer ist.',
  settingMemberDetails: 'Einstellen der Details eines Mitglieds.',
  addingTeam: 'Team hinzufügen.',
  editingTeam: 'Das Redaktionsteam.',
  addingRotation: 'Hinzufügen von Rotation.',
  editingRotation: 'Rotation der Bearbeitung.',
  assigningKeyToMember: 'Zuweisung eines Schlüssels an ein Mitglied.',
  revokingKeyFromMember: 'Entzug des Schlüssels eines Mitglieds.',
  deletingTeam: 'Löschung des Teams.',
  deletingRotation: 'Löschen der Rotation.',
  addingAlert: 'Hinzufügen eines Hörers.',
  editingAlert: 'Hörer editieren.',
  deletingAlert: 'Löschung des Hörers.',
  triggeringAlert: 'Auslösendes Ereignis.',
  creatingOrganizationAccount: 'Erstellen eines Organisationskontos.',
  creatingCheckoutSession: 'Starten Sie Ihre Checkout-Sitzung.',
  creatingAnAccountForMember: 'Erstellen eines Kontos für ein Mitglied.',
  passwordResetEmailSent:
    'Wir haben Ihnen eine E-Mail geschickt, um Ihr Passwort zurückzusetzen. Ändern Sie Ihr Passwort und melden Sie sich erneut an.',
  changeOwner: 'Besitzer wechseln',
  changeOrganizationOwner: 'Organisationseigentümer ändern',
  newOwnerEmail: 'E-Mail des neuen Besitzers',
  saveOnYearlySubscription: 'Speichern {{percent}}%',
  annual: 'Jährlich',
  resetAlarms: 'Alarme aktualisieren',
  resetAlarmsAlert:
    'Alle Alarme wurden aufgefrischt. Wenn Sie weiterhin Probleme mit den Alarmen feststellen, reichen Sie bitte ein Ticket über die Option "Problem melden" ein.',
  resetAlarmsTooltipMessage:
    'Wenn Sie Probleme mit Alarmen haben, wie z. B. gelöschte Alarme, die auf dem Telefon klingeln, aktualisieren Sie bitte die Alarme mit dieser Option. Wenn das Problem weiterhin besteht, reichen Sie bitte ein Ticket über die Option "Problem melden" ein.',
  resetTempPassword: 'Temporäres Passwort zurücksetzen',
  currPassword: 'Aktuelles Passwort',
  tempPassword: 'Temporäres Passwort',
  newPassword: 'Neues Passwort',
  reset: 'Zurücksetzen',
  loggingIn: 'Einloggen',
  resettingTempPassword: 'Temporäres Passwort zurücksetzen',
  changingPassword: 'Passwort ändern',
  numSeats: 'Anzahl der Sitze',
  helpMeDecide: 'Helfen Sie mir zu entscheiden',
  numEnterpriseSeatsHelper:
    'Sie sollten die Anzahl der Sitze als die Anzahl der Personen in Ihrer Organisation wählen, die die App zum Empfang von Vorfällen verwenden werden. Bei der Konfiguration eines Hörers können Sie dessen Eskalationsrichtlinie festlegen. In einer Eskalationsrichtlinie können Sie mehrere Stufen festlegen. Sie können zum Beispiel festlegen, dass die erste Eskalationsstufe an ein Team von 3 Personen geht. Wenn die erste Eskalationsstufe nicht innerhalb von 5 Minuten reagiert, geht die zweite Eskalationsstufe an ein Team von 5 Personen. In diesem Fall können Sie die Anzahl der Plätze auf 8 festlegen.',
  numBusinessSeatsHelper:
    'Wählen Sie die Anzahl der Plätze entsprechend der Anzahl der Personen, die Galarm für den Empfang von Alarmen und Erinnerungen nutzen werden.',
  perSeatPrice: '{{price}} pro {{interval}} pro Sitzplatz',
  doneForToday: 'Anerkannt',
  getStarted: "Los geht's",
  firstAlertInstructions: 'Anweisungen für den ersten Hörer',
  learnMoreAboutGalarmEnterprise: 'Erfahren Sie mehr über Galarm Pro',
  home: 'Startseite',
  team: 'Team',
  alert: 'Alarmierung',
  listener: 'Hörer',
  forgotPassword: 'Passwort vergessen?',
  forgotPasswordTitle: 'Passwort vergessen',
  sendPasswordResetEmail: 'E-Mail zum Zurücksetzen des Passworts senden',
  sendingPasswordResetEmail: 'E-Mail zum Zurücksetzen des Passworts senden',
  field: 'Feld',
  description: 'Beschreibung',
  requestIntegration: 'Weitere Integration anfordern',
  integrationDetails: 'Details zur Integration',
  enterIntegrationDetails: 'Details zur Integration bereitstellen',
  galarmEnterpriseFreeTrial:
    'Registrieren Sie sich für einen 14-tägigen kostenlosen Test',
  galarmEnterpriseFreeTrialDescription:
    'Automatisieren Sie das Vorfallmanagement',
  bySigningUpYouAgreeToOur: 'Mit der Anmeldung erklären Sie sich mit unseren',
  and: 'und',
  alarmSubscribers: 'Alarm Follower',
  otherSubscribersCount: {
    one: '1 weiterer Follower nicht in Ihren Kontakten',
    other: '{{count}} mehr Follower, die nicht in Ihren Kontakten sind'
  },
  getAccountData: 'Alarmdaten exportieren',
  getAccountDataPrompt:
    'Geben Sie eine E-Mail-Adresse an, an die wir eine Textdatei mit allen Ihren Alarmen senden werden.',
  getAccountDataConfirmation:
    'Bitte beachten Sie, dass wir Ihnen eine Textdatei mit allen Ihren Alarmen per E-Mail an {{email}} zusenden werden.',
  sendingAccountData: 'Exportieren von Alarmdaten...',
  sentAccountData: 'Alarmdaten werden an {{email}} gesendet.',
  errorSendingAccountData: 'Fehler beim Exportieren von Alarmdaten: {{error}}',
  sendingEmailVerificationLink: 'E-Mail-Verifizierungslink senden',
  viewLogs: 'Ansicht Logs',
  message: 'Nachricht',
  billing: 'Rechnungsstellung',
  newIncidentForAlert: 'Neues Ereignis für Hörer {{alertName}}',
  clickNextAfterAddingMembers:
    'Klicken Sie nach dem Hinzufügen von Mitgliedern auf "Weiter".',
  clickDoneAfterAddingAlerts:
    'Klicken Sie auf "Erledigt", um die Einrichtung abzuschließen und zum Dashboard zu gelangen.',
  alertCreatedSuccessfully:
    'Der Listener wurde erfolgreich erstellt. Sie können diesen Listener über das Menüsymbol mit den drei Punkten vor dem Namen des Listeners auslösen.',
  fixSubscription: 'Fix-Abonnement',
  fixSubscriptionDescription:
    'Wir haben ein Problem mit Ihrem Abonnement festgestellt. Ihr Abonnement enthält {{numSeats}} Platz(e), aber Sie haben Schlüssel an {{numSeatsAssigned}} Mitglied(er) vergeben. Sie können höchstens {{numSeats}} Schlüssel zuweisen.',
  fixSubscriptionChooseOption:
    'Bitte korrigieren Sie Ihr Abonnement mit einer der unten aufgeführten Optionen.',
  fixSubscriptionContactAdmin:
    'Bitte kontaktieren Sie den Administrator Ihrer Organisation.',
  unassignKeysFromMembers:
    'Heben Sie die Zuweisung von mindestens {{extraKeysAssigned}} Schlüssel(n) von Mitgliedern auf',
  addMoreSeatsToSubscription:
    'Fügen Sie Ihrem Abonnement mindestens {{extraKeysAssigned}} Platz(e) hinzu.',
  revokeMembersKey: 'Schlüssel der Mitglieder widerrufen',
  revokeMembersKeyDescription:
    'Wählen Sie die Mitglieder aus, deren Schlüssel Sie widerrufen möchten.',
  revoke: 'Widerrufen',
  unableToRevokeMembersKey:
    'Es ist ein Fehler beim Entzug des Schlüssels eines Mitglieds aufgetreten. Bitte kontaktieren Sie den Support.',
  problemWithLoadingProducts: 'Problem beim Laden von Produkten. {{error}}',
  verificationCompleted: 'Überprüfung abgeschlossen',
  deviceAlreadyRegistered:
    'Diese Rufnummer wurde bereits auf diesem Gerät verifiziert.',
  phoneNumberAutoFilled:
    'Galarm verwendet die ausgewählte Telefonnummer aus Ihrem Google-Konto.',
  unableToRegisterPreVerifiedUser:
    'Vorverifizierter Benutzer kann nicht registriert werden.',
  allowNotifications: 'Benachrichtigungen zulassen',
  allowNotificationsDescription: 'Um Alarme anzuzeigen, wenn sie klingeln.',
  scheduleExactAlarms: 'Zeitplan für Alarme',
  scheduleExactAlarmsHelperText:
    'Erlauben Sie Galarm, Alarme zur genauen Zeit zu klingeln.',
  scheduleExactAlarmsDescription:
    'Um Alarme genau zur richtigen Zeit klingeln zu lassen.',
  disableBatteryOptimization: 'Verwalten der Batterie-Optimierung',
  disableBatteryOptimizationDescription:
    'Um Galarm im Hintergrund laufen zu lassen.',
  requestRequiredPermissions:
    'Galarm benötigt die folgenden Berechtigungen, um Alarme zu klingeln',
  allowDisallowedPermissions: 'Antippen, um zuzulassen',
  notificationsAreEnabled:
    'Die Erlaubnis, Benachrichtigungen anzuzeigen, ist bereits erteilt.',
  connectingToStore: 'Verbindung zum Speicher...',
  restartApp: 'App neu starten',
  updateAvailable: 'Update verfügbar',
  english: 'Englisch',
  russian: 'Russisch',
  german: 'Deutsch',
  french: 'Französisch',
  spanish: 'Spanisch',
  italian: 'Italienisch',
  portuguese: 'Portugiesisch',
  japanese: 'Japanisch',
  korean: 'Koreanisch',
  indonesian: 'Indonesisch',
  changeAppLanguage: 'App-Sprache ändern',
  appLanguage: 'App Sprache',
  iosChangeAppLanguagePara1:
    'Galarm wählt die Sprache der App aus den Einstellungen Ihres Telefons aus. Bitte folgen Sie diesen Schritten, um die Sprache der App für Galarm zu ändern:',
  iosChangeAppLanguageInstruction1:
    'Öffnen Sie "Telefoneinstellungen > Allgemein > Sprache & Region". Stellen Sie sicher, dass die Sprache, die Sie für Galarm verwenden möchten, in der Liste der bevorzugten Sprachen enthalten ist.',
  iosChangeAppLanguageInstruction2:
    'Gehen Sie zurück zu den "Telefoneinstellungen" und tippen Sie auf "Galarm" in der Liste der Apps am unteren Rand.',
  iosChangeAppLanguageInstruction3:
    'Tippen Sie auf "Sprache" und wählen Sie die Sprache, die Sie für Galarm einstellen möchten. Kehren Sie zur App zurück, nachdem Sie die Sprache ausgewählt haben',
  androidChangeAppLanguagePara1:
    'Galarm wählt die Sprache der App aus den Einstellungen Ihres Telefons aus. Bitte folgen Sie diesen Schritten, um die Sprache der App für Galarm zu ändern:',
  androidChangeAppLanguageInstruction1:
    'Tippen Sie unten auf die Schaltfläche "App-Einstellungen öffnen".',
  androidChangeAppLanguageInstruction2:
    'Tippen Sie auf "Sprache" und wählen Sie die Sprache, die Sie für Galarm verwenden möchten.',
  androidChangeAppLanguageInstruction3:
    'Nachdem Sie die Sprache ausgewählt haben, kehren Sie hierher zurück und klicken Sie unten auf die Schaltfläche "App neu starten".',
  problemRetrievingSubscriptionData:
    'Beim Abrufen Ihrer Abonnementinformationen ist ein Problem aufgetreten. Wir leiten Sie jetzt zur Kontoseite weiter. Bitte überprüfen Sie Ihren Abonnementstatus und kontaktieren Sie den technischen Support, wenn Sie weiterhin Probleme haben.',
  deletingOrganization: 'Löschung der Organisation...',
  deleteOrganization: 'Organisation löschen',
  deleteOrganizationDescription:
    'Sind Sie sicher, dass Sie Ihre Organisation löschen möchten?\n  \n  Dies ist ein irreversibler Vorgang. Alle Daten Ihrer Organisation werden gelöscht.\n  \n  Bitte stellen Sie sicher, dass Sie Ihr Abonnement abgebrochen haben, bevor Sie die Organisation löschen.',
  galarmEnterpriseTrialDescription:
    'Es gibt eine 14-tägige kostenlose Testphase, in der Sie das Abo kostenlos abbrechen können.',
  galarmEnterpriseTrialAlreadyAvailed:
    'Sie haben die 14-tägige kostenlose Testversion bereits in Anspruch genommen. Kontaktieren Sie den Support, wenn Sie mehr Zeit benötigen, um das Produkt zu testen.',
  signinWithGoogle: 'Mit Google anmelden',
  signinWithGithub: 'Mit GitHub anmelden',
  verifyAccount: 'Konto verifizieren',
  verifyAccountMessage:
    'Sie sollten eine E-Mail mit einem Bestätigungscode erhalten haben. Geben Sie den Code unten ein, um Ihr Konto zu verifizieren. Wenn Sie den Code nicht erhalten haben, können Sie einen neuen Code anfordern, indem Sie auf die Schaltfläche "Code erneut senden" klicken.',
  verificationCode: 'Verifizierungscode',
  optionalString: '{{string}}',
  quickReminderTitle: 'Titel (optional)',
  accountVerified: 'Konto verifiziert',
  accountVerifiedMessage: 'Bitte melden Sie sich mit Ihren Anmeldedaten an.',
  multipleUsersWithSameEmail:
    'Es gibt mehrere Benutzer mit derselben E-Mail. Bitte kontaktieren Sie den Support.',
  multipleUsersWithSameMobileNumber:
    'Es gibt mehrere Benutzer mit derselben Handynummer. Bitte kontaktieren Sie den Support.',
  startTrial: 'Versuch starten',
  nextSteps: 'Nächste Schritte',
  firstTimeSetupComplete:
    'Sie haben die Ersteinrichtung von Galarm Enterprise abgeschlossen und Ihren ersten Hörer erstellt. Im Folgenden finden Sie einige nächste Schritte, die Sie von hier aus unternehmen können.',
  testTheAlertInstruction:
    'Lesen Sie die Anweisungen unter {{link}}, um zu erfahren, wie Sie den soeben erstellten Hörer testen können.',
  integrateTheAlertInstruction:
    'Schauen Sie sich die Anweisungen unter {{link}} an, um den Listener mit verschiedenen Überwachungsprogrammen zu integrieren.',
  createMoreAlertsInstruction:
    'Sie können weitere Hörer hinzufügen, indem Sie auf die Registerkarte "Hörer" auf der linken Seite gehen.',
  addMoreMembersInstruction:
    'Sie können weitere Mitglieder hinzufügen, indem Sie auf die Registerkarte "Mitglieder" auf der linken Seite gehen.',
  createTeamsInstruction:
    'Sie können die Mitglieder in "Teams" gruppieren und ein ganzes Team alarmieren, wenn ein Vorfall eintritt. Erstellen Sie Teams auf der Registerkarte "Teams" auf der linken Seite und verwenden Sie sie in der Konfiguration des Hörers.',
  testTheAlert: 'Testen Sie den Listener',
  integrateTheAlert: 'Integrieren Sie den Listener',
  createMoreAlerts: 'Mehr Zuhörer schaffen',
  addMoreMembers: 'Weitere Mitglieder hinzufügen',
  createTeams: 'Teams erstellen',
  docs: 'Dokumente',
  contactSupport: 'Kontakt zum Support',
  pastDue: 'Überfällig',
  canceled: 'Abgebrochen',
  unpaid: 'Unbezahlt',
  incomplete: 'Unvollständig',
  incomplete_expired: 'Unvollständig Abgelaufen',
  trialing: 'Kostenlose Testversion',
  paused: 'Pausiert',
  plan: 'Plan',
  status: 'Status',
  renewsOn: 'Erneuert am',
  yourRoles: 'Ihre Rollen',
  enterprisePlan: '{{quantity}} Sitze, erneuert jede {{interval}}',
  currentOrganization: 'Aktuelle Organisation',
  useThisOrganization: 'Verwenden Sie diese Organisation',
  fixPayment: 'Zahlung fixieren',
  fixingPayment: 'Festsetzung der Zahlung',
  addPaymentInformation: 'Zahlungsinformationen hinzufügen',
  noPaymentInformationAvailable:
    'Keine Zahlungsinformationen verfügbar. Bitte aktualisieren Sie die Seite, wenn Sie kürzlich Zahlungsinformationen hinzugefügt haben.',
  openBillingPortal: 'Abrechnungsportal öffnen',
  startupWizardDescription:
    'Dieser Assistent hilft Ihnen bei den ersten Schritten und der Erstellung Ihres ersten Hörers.',
  userNotFound: 'Benutzer nicht gefunden',
  wrongPassword: 'Falsches Passwort',
  emailAlreadyInUse: 'E-Mail bereits in Gebrauch',
  restoreDeletedAlarms: 'Gelöschte Alarme wiederherstellen',
  deleteAlarmAlert:
    'Gelöschte Alarme können unter "Zuletzt gelöschte Alarme" wiederhergestellt werden. Sie können sie durch Tippen auf das Drei-Punkte-Menü (⋮) oben rechts auf der Registerkarte "Alarme" anzeigen.\n\nGelöschte Alarme bleiben dort 30 Tage lang gespeichert, danach werden sie endgültig gelöscht.',
  slackVerificationCode:
    'Ihr Slack-Verifizierungscode lautet {{verificationCode}}.',
  dndAccess: 'Bitte nicht stören Zugang',
  dndHelperText:
    'Erlauben Sie Galarm, Alarme zu klingeln, wenn Ihr Telefon auf "Nicht stören" eingestellt ist.',
  cantRestoreInstantAlarmMessage:
    'Sofortige Alarme können nicht wiederhergestellt werden. Sie können bei Bedarf den Chat und andere Informationen zu diesem Alarm anzeigen. Dieser Alarm wird 30 Tage nach der Löschung dauerhaft entfernt.',
  upgradeToUnlockAllFeatures:
    'Aktualisierung zur Freischaltung aller Funktionen',
  otherSignupOptions: 'Andere Anmeldemöglichkeiten:',
  renewSubscription: 'Abonnement verlängern',
  subscriptionOnHold:
    'In Wartestellung (Ihr Plan erlaubt {{subscriptionQty}} Plätze\naber Sie haben {{numSubscriptionKeys}} Plätze zugewiesen.',
  checklists: 'Aufgaben',
  checklist: 'Aufgabenliste',
  typeToAddTask: 'Typ zum Hinzufügen der Aufgabe',
  specifyTaskName: 'Aufgabenname angeben',
  otherChecklists: 'Aufgabenlisten',
  defaultChecklistName: 'unbetitelt',
  addTasksToChecklist:
    'Fügen Sie Aufgaben über das Eingabefeld am unteren Rand hinzu',
  addTasksToDefaultChecklist:
    'Fügen Sie Aufgaben über das Eingabefeld unten hinzu... oder beginnen Sie eine neue Aufgabenliste, indem Sie auf die Schaltfläche + oben rechts klicken.',
  createNewChecklist:
    'Sie können neue Aufgabenlisten erstellen, um verwandte Aufgaben an einem Ort zu verfolgen.',
  addNewChecklist: 'Neue Aufgabenliste',
  alarmPhoto: 'Alarm Foto',
  alarmPhotoFeatureTitle: 'Alarm Foto',
  alarmPhotoFeatureDescriptionPremiumUser:
    'Mit dieser Funktion können Sie einem Alarm ein Foto hinzufügen, um schnelle visuelle Hinweise zu geben, z. B. ein Foto von einem Medikament, Anweisungen für eine Aufgabe, einem Gegenstand usw. Das Foto wird angezeigt, wenn der Alarm klingelt.',
  alarmPhotoFeatureDescriptionFreeUser:
    'Mit dieser Funktion können Sie einem Alarm ein Foto hinzufügen, um schnelle visuelle Hinweise zu geben, z. B. ein Foto von einem Medikament, Anweisungen für eine Aufgabe, einem Gegenstand usw. Das Foto wird angezeigt, wenn der Alarm klingelt.\n  \nDies ist eine Premium-Funktion. Um diese Funktion nutzen zu können, werden Sie aufgefordert, eine Aktualisierung auf Premium vorzunehmen.',
  alarmPhotoPremiumFeature: 'Foto zu Alarmnotizen hinzufügen',
  installPendingAppUpdate:
    'Das neueste App-Update wurde heruntergeladen und ist bereit zur Installation.',
  numTasks: {
    zero: 'Keine Aufgaben',
    one: '1 Aufgabe',
    other: '{{count}} Aufgaben'
  },
  numTasksWithCompleted: {
    one: '1 Aufgabe, {{completed}} abgeschlossen',
    other: '{{count}} Aufgaben, {{completed}} abgeschlossen'
  },
  muteAlarms: 'Alarme stummschalten',
  unmuteAlarms: 'Alarme stumm schalten',
  alarmsAreMuted:
    'Alle Ihre Alarme sind derzeit stummgeschaltet. Tippen Sie auf , um die Stummschaltung aufzuheben.',
  alarmsHaveBeenMuted:
    'Ihre Alarme wurden stummgeschaltet. Sie klingeln erst wieder, wenn Sie die Stummschaltung aufheben.',
  alarmsHaveBeenUnmuted:
    'Ihre Alarme wurden entstummt. Sie sollten wie konfiguriert klingeln.',
  deleteChecklist: 'Aufgabenliste löschen',
  confirmDeleteChecklist: 'Aufgabenliste löschen?',
  confirmDeleteCompletedTasks: 'Erledigte Aufgaben löschen?',
  confirmResetCompletedTasks: 'Erledigte Aufgaben zurücksetzen?',
  confirmDeleteTask: '{{taskName}} löschen?',
  showCompletedTasks: 'Erledigte Aufgaben anzeigen',
  hideCompletedTasks: 'Erledigte Aufgaben ausblenden',
  deleteCompletedTasks: 'Erledigte Aufgaben löschen',
  resetCompletedTasks: 'Erledigte Aufgaben zurücksetzen',
  yourGroups: 'Ihre Gruppen',
  newAlarmWith: 'Neuer Alarm mit {{name}}',
  newGroupWith: 'Neue Gruppe mit {{name}}',
  alertContact: 'Alarmierung {{name}}',
  alertGroup: 'Alarmierung {{name}}',
  instantAlarmMovedToFabNotice:
    'Die Funktion "Sofortiger Alarm" ist jetzt über die Schaltfläche "+" unten rechts auf diesem Bildschirm verfügbar. Das "Lautsprechersymbol" wird in einer zukünftigen Version von der oberen rechten Seite entfernt werden.',
  tasksIntroTitle: 'Aufgaben und Aufgabenlisten',
  tasksIntroDescription:
    'Aufgaben sind Ihre Aufgaben, die keine Frist haben. Sie können sie hier schnell hinzufügen und verwalten, indem Sie sie abhaken, wenn Sie eine Aufgabe erledigen.\n\nSie können auch Aufgabenlisten erstellen, um ähnliche Aufgaben zu gruppieren. Sie können z. B. eine Aufgabenliste für Ihre Lebensmitteleinkäufe erstellen, eine andere für Ihre Arbeitsaufgaben und so weiter.',
  groupsMovedToContactsScreen:
    '"Gruppen" wurden in dieser Registerkarte zusammengeführt. Ihre Gruppen erscheinen oben unter "Ihre Gruppen". Sie können neue Gruppen erstellen, indem Sie unten rechts auf die Schaltfläche "+" tippen.',
  loadingTasks: 'Aufgaben laden...',
  tryPremium: 'Premium ausprobieren',
  buyPremiumToUnlockFeature:
    'Dies ist eine Premium-Funktion. Schalten Sie diese und viele andere coole Funktionen mit unserem Premium-Abo frei, das Sie 14 Tage lang kostenlos testen können.',
  newTask: 'Neue Aufgabe',
  newTaskList: 'Neue Aufgabenliste',
  enterTaskName: 'Aufgabenname eingeben',
  enterTaskListName: 'Name der Aufgabenliste eingeben',
  taskName: 'Aufgabe Name',
  taskListName: 'Name der Aufgabenliste',
  checklistTasks: '{{name}}',
  editTaskListName: 'Name der Aufgabenliste bearbeiten',
  editTask: 'Aufgabe bearbeiten',
  releaseNotes: 'Notizen zum Release',
  currentRelease: '(derzeit installiert)',
  loadingReleaseNotes:
    'Laden der Notizen zur Version. Bitte stellen Sie sicher, dass Sie mit dem Internet verbunden sind.',
  id: 'ID',
  subscriptionData: 'Abonnement-Daten',
  organizations: 'Organisationen',
  showRotationCalendar: 'Rotationskalender anzeigen',
  rotationCalendar: 'Rotationskalender',
  editShift: 'Verschiebung bearbeiten',
  startDateForShift: 'Datum des Schichtbeginns',
  startTimeForShift: 'Zeit für den Schichtbeginn',
  endDateForShift: 'Enddatum der Schicht',
  endTimeForShift: 'Zeit für das Schichtende',
  editingShift: 'Editierschicht',
  addingShift: 'Hinzufügen einer Schicht',
  products: 'Produkte',
  prices: 'Pläne',
  planType: 'Plan Typ',
  business: 'Business',
  enterprise: 'Unternehmen',
  compare: 'Vergleichen Sie',
  apiSupport: 'API-Unterstützung zur Überwachung von Diensten',
  accessToConsoleToViewAndCloseIncidents:
    'Dashboard zur Verwaltung von Vorfällen',
  triggerInstantAlertsFromTheConsole:
    'Auslösen von Vorfällen über die Enterprise Console',
  coreFeatures: 'Wesentliche Merkmale:',
  everythingInBusiness: 'Alles in Businessplan und:',
  updatePlanInstructions:
    'Sie können Ihren Plan aktualisieren, indem Sie auf die Schaltfläche "Abrechnungsportal öffnen" auf der rechten Seite klicken und im Abrechnungsportal die Schaltfläche "Plan aktualisieren" wählen.',
  membersDescription:
    'Sie haben {{subscriptionKeysCount}} Plätze gekauft - {{assignedSubscriptionKeysCount}} zugewiesen und {{unassignedSubscriptionKeysCount}} verfügbar. Ein Häkchen vor dem Namen eines Mitglieds bedeutet, dass es einen zugewiesenen Sitzplatz hat.',
  centralBilling: 'Zentrale Abrechnung',
  saveTwoMonths: '2 Monate speichern',
  owner: 'Eigentümer',
  enterpriseAccountSummary:
    'Sie gehören zu der Organisation {{organizationName}}, die für den Plan Galarm Pro {{planType}} angemeldet ist.',
  perYearPerUser: 'pro Nutzer/Jahr',
  perMonthPerUser: 'pro Benutzer/Monat',
  creationDate: 'Datum der Erstellung',
  updatePhoneNumber: 'Telefonnummer ändern',
  confirmCountryCodeAndEnterUpdatedPhoneNumber:
    'Bitte bestätigen Sie die Landesvorwahl und geben Sie Ihre neue Telefonnummer ein. Ihr Konto wird mit dieser Telefonnummer verknüpft, nachdem Sie die Überprüfung abgeschlossen haben.',
  unableToUpdatePhoneNumber:
    'Die Rufnummer kann nicht geändert werden: {{error}}. Bitte kontaktieren Sie den Support.',
  phoneNumberUpdated: 'Rufnummer erfolgreich geändert.',
  phoneNumberUpdatedMessage:
    'Sie können die App ganz normal verwenden. Bitten Sie Ihre Kontakte, Ihre Rufnummer in ihrem Adressbuch zu aktualisieren.',
  memberChangedPhoneNumber:
    '{{memberName}} hat seine Rufnummer geändert. Bitte aktualisieren Sie Ihr Adressbuch, um sie weiterhin zu Ihren Alarmen hinzuzufügen.',
  preferredSourceForIncidents: 'Bevorzugte Quelle für Vorfälle',
  apiCodesDescription:
    'Verwenden Sie die folgenden Anweisungen, um den Hörer in eine Quelle zu integrieren',
  rotation: 'Drehung',
  noAlarmsForThisDay: 'Keine Alarme für diesen Tag',
  markAlarmsAsComplete: 'Alarme als abgeschlossen markieren',
  markAlarmsAsCompleteExplanation:
    'Einmalige Alarme werden als abgeschlossen markiert und erscheinen in der Rubrik "Inaktive" Alarme.\n\nWiederholte Alarme werden als abgeschlossen markiert und zum nächsten Ereignis verschoben.',
  enterIncidentPrefix: 'Maximal 5 Zeichen',
  incidentPrefix: 'Vorfall-Präfix',
  incidentPrefixAlreadyExists: 'Vorgangs-Präfix existiert bereits',
  removeTask: 'Aufgabe entfernen?',
  incidentPrefixLengthExceeded: 'Vorfallpräfix ist länger als 5 Zeichen',
  criticalAlerts: 'Kritische Alarme',
  criticalAlertsConfiguration:
    'Der Alarm klingelt mit {{criticalAlertsVolume}}% Lautstärke, auch wenn Ihr Telefon stummgeschaltet oder ein Fokus eingeschaltet ist.',
  criticalAlertsDisabledShort:
    'Der Alarm klingelt nicht, wenn Ihr Telefon stummgeschaltet ist.',
  criticalAlertsConfigurationGlobal:
    'Alarme klingeln mit {{criticalAlertsVolume}}% Lautstärke, auch wenn Ihr Telefon stummgeschaltet ist oder ein Fokus eingeschaltet ist.',
  criticalAlertsDisabledShortGlobal:
    'Alarme klingeln nicht, wenn Ihr Telefon stummgeschaltet ist.',
  criticalAlertsEnabled:
    'Kritische Alarme sind aktiviert. Alarme klingeln auch dann, wenn Ihr Telefon stummgeschaltet oder ein Fokus eingeschaltet ist.',
  criticalAlertsDisabled:
    'Kritische Alarme sind deaktiviert. Alarme klingeln nicht, wenn Ihr Telefon stummgeschaltet ist.',
  enableCriticalAlerts: 'Aktivieren Sie kritische Alarme',
  summer_soft: 'Weicher Sommer',
  beeps: 'Pieptöne',
  bollywood_nights: 'Bollywood-Nächte',
  gentle_reminder: 'Sanfte Erinnerung',
  himalayan_piper: 'Himalaya-Waldmeister',
  holiday_joy: 'Urlaubsfreude',
  hollywood_adventure: 'Hollywood-Abenteuer',
  melody: 'Melody',
  morning_rooster: 'Morgenhahn',
  morning_sunshine: 'Morgensonne',
  sweet_sea: 'Süßes Meer',
  old_school_ring: 'Tring Tring',
  MyAlarmNotification: '{{alarmName}} - Es ist an der Zeit.',
  ParticipantCascadingAlarmNotification:
    '{{alarmName}} - Es ist an der Zeit, {{alarmCreatorName}} zu erinneren.',
  ParticipantSimultaneousAlarmNotification:
    '{{alarmName}}. Es ist an der Zeit, Ihre Teilnahme zu bestätigen.',
  InstantAlarmNotification:
    '{{alarmName}}. Wir bitten um Ihre sofortige Aufmerksamkeit.',
  MyAlarmNotificationShort: 'Es ist an der Zeit.',
  ParticipantCascadingAlarmNotificationShort:
    'Es ist an der Zeit, {{alarmCreatorName}} zu erinneren.',
  ParticipantSimultaneousAlarmNotificationShort:
    'Es ist an der Zeit, Ihre Teilnahme zu bestätigen.',
  InstantAlarmNotificationShort: 'Wir bitten um Ihre sofortige Aufmerksamkeit.',
  MyRecipientAlarmNotification:
    '{{alarmName}} - Es ist an der Zeit, {{alarmRecipientName}} zu erinneren.',
  MyRecipientAlarmNotificationShort:
    'Es ist an der Zeit, {{alarmRecipientName}} zu erinneren.',
  RecipientAlarmNotification:
    '{{alarmName}} (von {{alarmCreatorName}}) - Es ist Zeit.',
  RecipientAlarmNotificationShort:
    'Es ist an der Zeit - von {{alarmCreatorName}}.',
  calendarView: 'Galarm Kalender',
  noAlarmsForThisDay: 'Keine Alarme für diesen Tag',
  selectADate: 'Bitte wählen Sie ein Datum, um die Alarme anzuzeigen'
}
