import { LogUtils } from 'galarm-ps-api'
import { Dimensions, Platform } from 'react-native'

const Utils = (function () {
  const getIndexOfObjectWithId = (objects, objectId) => {
    if (!objects) {
      LogUtils.logError(
        new Error('Received undefined objects in Utils.getIndexOfObjectWithId')
      )
      return -1
    }
    let objIndex = objects.findIndex(obj => {
      return obj.id === objectId
    })
    return objIndex
  }

  const getObjectWithId = (objects, objectId) => {
    if (!objects) {
      LogUtils.logError(
        new Error('Received undefined objects in Utils.getObjectWithId')
      )
      return undefined
    }
    let object = objects.find(obj => {
      return obj.id === objectId
    })
    return object
  }

  // Remove everything except digits
  // Keep in sync with the server version
  const sanitizePhoneNumber = phoneNumber => {
    return phoneNumber
      .split('')
      .map(number => (number >= '0' && number <= '9' ? number : ''))
      .join('')
  }

  const sanitizeName = name => {
    return name
      .trim()
      .split('')
      .map(char => {
        if (char >= '0' && char <= '9') {
          return char
        } else if (char >= 'a' && char <= 'z') {
          return char
        } else if (char >= 'A' && char <= 'Z') {
          return char
        } else if (char === ' ') {
          return char
        } else {
          return ''
        }
      })
      .join('')
  }

  const alphabeticallyCompareObjsBasedOnAPropCS = (obj1, obj2, prop) => {
    const text1 = obj1[prop]
    const text2 = obj2[prop]
    return text1 < text2 ? -1 : text1 > text2 ? 1 : 0
  }

  const alphabeticallyCompareObjsBasedOnAPropCI = (obj1, obj2, prop) => {
    const text1 = obj1[prop].toLowerCase()
    const text2 = obj2[prop].toLowerCase()
    return text1 < text2 ? -1 : text1 > text2 ? 1 : 0
  }

  const isIphoneX = () => {
    let dimen = Dimensions.get('window')
    return (
      Platform.OS === 'ios' &&
      !Platform.isPad &&
      !Platform.isTVOS &&
      (dimen.height === 812 ||
        dimen.width === 812 ||
        dimen.height === 896 ||
        dimen.width === 896)
    )
  }

  const compareObjectsProperties = (obj1, obj2, properties) => {
    let retval = true
    properties.forEach(property => {
      if (obj1[property] !== obj2[property]) {
        retval = false
      }
    })
    return retval
  }

  const printObjectsPropertiesThatAreDifferent = (obj1, obj2, properties) => {
    properties.forEach(property => {
      if (obj1[property] !== obj2[property]) {
        console.tron.log(
          property + ': ' + obj1[property] + ' - ' + obj2[property]
        )
      }
    })
  }

  const doesPhoneHaveAutostartSetting = deviceManufacturerStr => {
    const deviceManufacturer = deviceManufacturerStr.toLowerCase()
    return (
      (deviceManufacturer.indexOf('xiaomi') !== -1 &&
        deviceManufacturer.indexOf('mi a1') === -1) ||
      deviceManufacturer.indexOf('vivo') !== -1 ||
      deviceManufacturer.indexOf('huawei') !== -1 ||
      deviceManufacturer.indexOf('oppo') !== -1
    )
  }

  const getDeviceStringForAutostartDevice = deviceManufacturerStr => {
    const deviceManufacturer = deviceManufacturerStr.toLowerCase()
    if (deviceManufacturer.indexOf('xiaomi') !== -1) {
      return 'Xiaomi'
    } else if (deviceManufacturer.indexOf('vivo') !== -1) {
      return 'Vivo'
    } else if (deviceManufacturer.indexOf('huawei') !== -1) {
      return 'Huawei'
    } else if (deviceManufacturer.indexOf('oppo') !== -1) {
      return 'Oppo'
    } else {
      return 'Android'
    }
  }

  const getSetupInstructionsLinkForDevice = deviceManufacturerStr => {
    const deviceManufacturer = deviceManufacturerStr.toLowerCase()
    if (deviceManufacturer.indexOf('xiaomi') !== -1) {
      return 'https://www.galarmapp.com/setup-mi'
    } else if (deviceManufacturer.indexOf('vivo') !== -1) {
      return 'https://www.galarmapp.com/setup-vivo'
    } else if (deviceManufacturer.indexOf('oppo') !== -1) {
      return 'https://www.galarmapp.com/setup-oppo'
    } else {
      return 'https://www.galarmapp.com/setup-android'
    }
  }

  const escapeRegExp = string => {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&') // $& means the whole matched string
  }

  const convertNumberToAtLeastTwoDigitsString = number => {
    return number > 9 ? '' + number : '0' + number
  }

  const getRandomInteger = (min = 1, max = 100) => {
    return Math.floor(Math.random() * (max - min + 1)) + min //The maximum is inclusive and the minimum is inclusive
  }

  const createContactName = function (contact) {
    var contactName = contact.givenName ? contact.givenName : ''
    contactName =
      contactName +
      (contact.middleName ? (contactName ? ' ' : '') + contact.middleName : '')
    contactName =
      contactName +
      (contact.familyName ? (contactName ? ' ' : '') + contact.familyName : '')

    return contactName
  }

  const makeCancelablePromise = promise => {
    let hasCanceled_ = false

    const wrappedPromise = new Promise((resolve, reject) => {
      promise.then(
        val => (hasCanceled_ ? reject({ isCanceled: true }) : resolve(val)),
        error => (hasCanceled_ ? reject({ isCanceled: true }) : reject(error))
      )
    })

    return {
      promise: wrappedPromise,
      cancel() {
        hasCanceled_ = true
      }
    }
  }

  function getNumberWithOrdinal(n) {
    var s = ['th', 'st', 'nd', 'rd'],
      v = n % 100
    return n + (s[(v - 20) % 10] || s[v] || s[0])
  }

  function waitForTime(msecs) {
    const promise = new Promise(resolve => {
      setTimeout(() => resolve(), msecs)
    })
    return promise
  }

  function isMobileDevice() {
    const ua = navigator.userAgent
    if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
      return true // tablet
    } else if (
      /Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
        ua
      )
    ) {
      return true // mobile device
    }
    return false
  }

  function objArrayMin(arr, property) {
    return arr.reduce(function (p, v) {
      return p[property] < v[property] ? p : v
    })
  }

  function objArrayMax(arr, property) {
    return arr.reduce(function (p, v) {
      return p[property] > v[property] ? p : v
    })
  }

  return {
    getIndexOfObjectWithId,
    getObjectWithId,
    sanitizePhoneNumber,
    alphabeticallyCompareObjsBasedOnAPropCI,
    alphabeticallyCompareObjsBasedOnAPropCS,
    sanitizeName,
    isIphoneX,
    compareObjectsProperties,
    printObjectsPropertiesThatAreDifferent,
    doesPhoneHaveAutostartSetting,
    getDeviceStringForAutostartDevice,
    getSetupInstructionsLinkForDevice,
    escapeRegExp,
    convertNumberToAtLeastTwoDigitsString,
    getRandomInteger,
    createContactName,
    makeCancelablePromise,
    getNumberWithOrdinal,
    waitForTime,
    isMobileDevice,
    objArrayMax,
    objArrayMin
  }
})()

export default Utils
